import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import validator from 'validator'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'
import ReactHtmlParser from 'react-html-parser'
import {Link} from "react-router-dom"

class ExerciseLog extends Component {
  constructor() {
    super()
    var today = new Date()
    var sevenDaysAgo = new Date()
    sevenDaysAgo.setDate(today.getDate() - 7)
    this.state = {
      gid: 1,
      data_list: [],
      group: 1,
      from_date: sevenDaysAgo.getFullYear()+'-'+("0" + (sevenDaysAgo.getMonth() + 1)).slice(-2)+'-'+("0" + sevenDaysAgo.getDate()).slice(-2),
      to_date: today.getFullYear()+'-'+("0" + (today.getMonth() + 1)).slice(-2)+'-'+("0" + today.getDate()).slice(-2),
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/reports/exercise_log_individual',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      this.setState({ data_list: result['data'] })
    })
  }

  render() {
    return (
    <div className="assm_page1 reports">
      <div className="row">
          <div className="small-12 columns ">
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>Date</th>
                    <th>Detail</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.data_list.map((each, index) =>
                  <tr key={'p'+index}>
                    <td>{each.month+'/'+each.day+'/'+each.year}</td>
                    <td><a href={'/admin/exercise/'+each.uid+'/'+each.month+'/'+each.day+'/'+each.year}>Detail</a></td>
                  </tr>
                )}
              </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ExerciseLog)
