import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, Link} from "react-router-dom"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Pagination from '../../functions/pagination'
import '../../../styles/assets/css/admin/template.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import FaceIcon from '@material-ui/icons/Face'
import MessageIcon from '@material-ui/icons/Message'
import LensIcon from '@material-ui/icons/Lens'
import ChatList from './chat_list'
import Conversation from './conversation'

class MessageList extends Component {
  constructor() {
    super()
    this.state = {
      chat_user_id: 0,
    }
  }

  componentDidMount() {
    this.setState({
      chat_user_id: this.props.match.params.cid > 0 ? this.props.match.params.cid : 0
    })
  }

  getDataList = () => {

  }

  render() {
    console.log(this.state)
    return (
    <div className="assm_page1 getintouch">
      <div className="row">
        <div className="small-6 medium-9 columns headerPage1">
          Instant Messages
        </div>
        <div className="small-6 medium-3 columns">
          <NavLink to='/member/getintouch/new_message' className="menu_list_active new_bttn">
            New Instant Message
          </NavLink>
        </div>
      </div>
      <div className="row">
        <div className="small-12 columns txt_language1">
        The red dot means you have a new instant message. <br />
        To see the conversation, click on a name below.
        </div>
      </div>
      <div className="row ">
      { typeof this.props.locationReducer.txt_message !== 'undefined' &&
        this.props.locationReducer.txt_message !== '' &&
        <div className="small-12 columns ">
          <div className="warning_msg3 warning_msg3_1">
            <div className="float_left1"><CheckCircleOutlineIcon /></div>
            <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
          </div>
        </div>
      }
        <div className="small-12 columns" >
          <div className="message_list box_assm1">
            <div className="row table_cell1">
              <div className="small-3 columns right_border1 table_cell1_col chat_name_list">
                <ChatList />
              </div>
              <div className="small-9 columns message_list_content table_cell1_col">
                <Conversation chat_user_id={this.state.chat_user_id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MessageList)
