import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import { yes_no, english_answers } from '../../../variables/registration.js'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { loadLocationWithMessage } from '../../../../actions/location'
import PatientHead from "./participant_head"

class EditStatus extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        status_update: 0,
        reason: '',
        status_list: []
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/participant/get_status_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
      console.log(result['data'])
      if(result['data']) {
        this.setState({
          status_list: result['data'],
        })
      }
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.status_update > 0 ) {
        var pid = this.props.match.params.pid
        var reason = this.state.reason
        if(this.state.status_update == 2) {
          reason = ''
        }
        axios({
          method: 'post',
          url: connectNode + 'admin/participant/change_status',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            pid: pid,
            status: this.state.status_update,
            reason: reason,
          }
        })
        .then((result) => {
          var message = ''
          var st_submit = 0
          console.log(result['data'])
          if(result['data'] == pid) {
            message = 'Status changed!'
            st_submit = 1
          } else {
            message = 'Something went wrong. Please try again.'
            st_submit = 2
          }
          if(message !== '') {
            this.setState({
              message: message,
              status_submit: st_submit,
            })
            window.scrollTo(0, 0)
          }
        })
    } else {
      this.setState({
        message: 'Please select answer',
        status_submit: 2,
      })
      window.scrollTo(0, 0)
    }
  }

  render() {
    console.log(this.state)
    return (
    <div className="box_border4">
      <div className="row">
        <div className="small-12 columns headerPage1">
          Participant
        </div>
      </div>
      <div className="row">
        <div className="small-12 columns ">
          <PatientHead />
          <div className="row box_general1 top_space1">
              <div className="headerPage3_1">
                Change Status
              </div>
              <div className="small-12 columns box_assm1 more_padding1">
                { this.state.message !== '' &&
                <div className="row margin-bottom1">
                  <div className={(this.state.status_submit == '1' && 'small-12 columns warning_msg7')
                                  || (this.state.status_submit == '2' && 'small-12 columns warning_msg6')} >
                    <div className="float_left1"><WarningIcon /></div>
                    <div className="txt_warning_msg1">{ this.state.message }</div>
                  </div>
                </div>}

                <div className="row space_bottom2">
                  <div className="small-12 columns">
                    <div className="small-12 columns float_left">
                      {this.state.status_list.map(each =>
                      <div className="small-12 columns float_left" key={'ce'+each.value}>
                        <input type="radio" name="status_update" value={each.vid}
                        onChange={(e) => this.handleChange(e)}/>
                        <span>{each.name}</span>
                      </div>)}
                      {this.state.status_update == 4 &&
                      <div className="small-12 columns float_left top_space1" >
                      <TextField onChange={this.handleChange} variant="outlined"
                      type="text" value={this.state.reason} className="w_100per"
                      InputProps={{ inputProps: { name: 'reason', maxLength: 250, placeholder: 'Please specify reason' }}} />
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
              {!this.state.completed_status &&
              <div className="small-12 columns  top_space1">
                <Button variant="contained" color="primary"
                   onClick={this.handleSubmit} type="submit">
                    Submit
                </Button>
              </div>}
          </div>
        </div>
      </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(EditStatus)
