import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { NavLink} from "react-router-dom"
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import ReactHtmlParser from 'react-html-parser'

class GroupSettings extends Component {
  constructor() {
    super()
    this.state = {
      group1: {
        zoom_link: '',
        name: '',
        w8: '',
        w12: '',
      },
      group2: {
        zoom_link: '',
        name: '',
        w8: '',
        w12: '',
      },
      group3: {
        zoom_link: '',
        name: '',
        w8: '',
        w12: '',
      },
      group4: {
        zoom_link: '',
        name: '',
        w8: '',
        w12: '',
      },
    }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/activity/get_class_info_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
    })
    .then((result) => {
      console.log(result['data'])
      var zoom_link1 = ''
      var name_1 = ''
      var w8_1 = ''
      var w12_1 = ''
      var zoom_link2 = ''
      var name_2 = ''
      var w8_2 = ''
      var w12_2 = ''
      var zoom_link3 = ''
      var name_3 = ''
      var w8_3 = ''
      var w12_3 = ''
      var zoom_link4 = ''
      var name_4 = ''
      var w8_4 = ''
      var w12_4 = ''
      if(result['data'].length > 0) {
        result['data'].map(each => {
          if(each.group == 1) {
            zoom_link1 = each.zoom_link
            name_1 = each.name
            w8_1 = each.date_8weeks
            w12_1 = each.date_3months
          } else if(each.group == 2) {
            zoom_link2 = each.zoom_link
            name_2 = each.name
            w8_2 = each.date_8weeks
            w12_2 = each.date_3months
          } else if(each.group == 3) {
            zoom_link3 = each.zoom_link
            name_3 = each.name
            w8_3 = each.date_8weeks
            w12_3 = each.date_3months
          } else if(each.group == 4) {
            zoom_link4 = each.zoom_link
            name_4 = each.name
            w8_4 = each.date_8weeks
            w12_4 = each.date_3months
          }
        })
        this.setState({
          group1: {
            zoom_link: zoom_link1,
            name: name_1,
            w8: this.reformatDate(w8_1),
            w12: this.reformatDate(w12_1),
          },
          group2: {
            zoom_link: zoom_link2,
            name: name_2,
            w8: this.reformatDate(w8_2),
            w12: this.reformatDate(w12_2),
          },
          group3: {
            zoom_link: zoom_link3,
            name: name_3,
            w8: this.reformatDate(w8_3),
            w12: this.reformatDate(w12_3),
          },
          group4: {
            zoom_link: zoom_link4,
            name: name_4,
            w8: this.reformatDate(w8_4),
            w12: this.reformatDate(w12_4),
          },
        })
      }
    })
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  reformatDate(gdate) {
    const [year, month, day] = gdate.split('-')
    const dateObj = month + '/' + day + '/' + year
    return dateObj
  }

  render() {
    return (
    <div className="assm_page1">
      <div className="row">
        <div className="small-12 columns headerPage1">
          Group Settings
        </div>
      </div>
      <div className="row">
          <div className="small-12 columns ">
            { typeof this.props.locationReducer.txt_message !== 'undefined' &&
              this.props.locationReducer.txt_message !== '' &&
              <div className="warning_msg3">
                <div className="float_left1"><CheckCircleOutlineIcon /></div>
                <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
              </div>
            }
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>Group</th>
                    <th>Zoom</th>
                    <th>8-week questionnaires</th>
                    <th>Final questionnaires</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                  <td className="bold"><a href="/admin/group_setting/group/1">{this.state.group1.name}</a></td>
                  <td>{this.state.group1.zoom_link}</td>
                  <td>{this.state.group1.w8}</td>
                  <td>{this.state.group1.w12}</td>
                </tr>
                <tr>
                  <td className="bold"><a href="/admin/group_setting/group/2">{this.state.group2.name}</a></td>
                  <td>{this.state.group2.zoom_link}</td>
                  <td>{this.state.group2.w8}</td>
                  <td>{this.state.group2.w12}</td>
                </tr>
              </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(GroupSettings)
