import React, { Component } from 'react'
import { connect } from 'react-redux'
import ZoomLogo from '../../../images/zoom-logo.png'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import ReactHtmlParser from 'react-html-parser'
import { Link } from "react-router-dom"
import Cookies from 'js-cookie'

class OnlineClasses extends Component {
  constructor() {
    super()
    this.state = {
      zoom_link: '',
      zoom_link_language: '',
      explanation_language: '',
    }
  }

  componentDidMount() {
    this.getProcess()
  }

  logClick = async () => {
    await axios({
      method: 'post',
      url: connectNode + 'participants/activity/add_class_click',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
      }
    })
  }

  getProcess = () => {
    // get timeline //
    axios({
      method: 'post',
      url: connectNode + 'participants/activity/get_class_info1',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      this.setState({
        zoom_link: result['data'].zoom_link,
        zoom_link_language: result['data'].class_name+': '+result['data'].conv_class_date+', '+result['data'].class_start_time+'-'+result['data'].class_end_time,
        explanation_language: result['data'].explanation,
      })
    })
  }

  render() {
    const lang = Cookies.get('lang_')
    return (
    <div className="row box_online_classes">
      <div className="small-12 columns">
        <div className="box_header1">
          {(lang=='en' && 'Upcoming Live Fitness Classes') || lang=='es' && 'Próxima Clase de Ejercicio En Vivo'}
        </div>
        {this.state.zoom_link != undefined &&
        <div>
          <div className="upcoming_class_link" onClick={this.logClick}>
            <Link to={{ pathname: this.state.zoom_link}} target="_blank" >
              {this.state.zoom_link_language}
            </Link>
          </div>
          <div className="zoom_logo" onClick={this.logClick}>
            <Link to={{ pathname: this.state.zoom_link}} target="_blank" >
              <img src={ZoomLogo} />
            </Link>
          </div>
          <div className="schedule_time">
            {ReactHtmlParser(this.state.explanation_language)}
          </div>
        </div>}
        {this.state.zoom_link == undefined &&
        <div className="noclass">{(lang=='en' && 'No class') || lang=='es' && 'No hay clase'}</div>}
        <div className="recorded_classes">
          <a href="/member/recorded_classes">{(lang=='en' && 'Recorded Classes (Click here)') || lang=='es' && 'Clase Grabada (CLIQUEE AQUI)'}</a>
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(OnlineClasses)
