import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Cookies from 'js-cookie'
import moment from 'moment'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'

import { baseline_assm_steps } from '../../variables/baseline_assm.js'
import { followup_assm_steps } from '../../variables/followup_assm.js'
import { womac_pain_questions, womac_pain_questions_es,
         womac_pain_scales, womac_pain_scales_es,
         womac_joint_stifness_questions, womac_joint_stifness_questions_es,
         womac_joint_stifness_scales, womac_joint_stifness_scales_es,
         womac_physical_function_questions, womac_physical_function_questions_es,
         womac_physical_function_scales, womac_physical_function_scales_es} from '../../variables/baseline_womac.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

class Womac extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        compTxt: '',
        timepoint: 0,
        pain_1: 0,
        pain_2: 0,
        pain_3: 0,
        pain_4: 0,
        pain_5: 0,
        joint_1: 0,
        joint_2: 0,
        physical_1: 0,
        physical_2: 0,
        physical_3: 0,
        physical_4: 0,
        physical_5: 0,
        physical_6: 0,
        physical_7: 0,
        physical_8: 0,
        physical_9: 0,
        physical_10: 0,
        physical_11: 0,
        physical_12: 0,
        physical_13: 0,
        physical_14: 0,
        physical_15: 0,
        physical_16: 0,
        physical_17: 0,
    }
  }

  componentDidMount() {
    this.getProcess()
  }

  getProcess = () => {
    var timepoint = 0
    if(this.props.match.params.timepoint > 0) {
      timepoint = this.props.match.params.timepoint
    }
    var get_uri = this.props.location.pathname
    var sep_uri = get_uri.split("/")
    //console.log(sep_uri[2])
    if(sep_uri[2] == 'followup') {
      axios({
        method: 'post',
        url: connectNode + 'participants/followup/get_followup_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: this.props.authReducer.uid,
          assm: 'womac',
          tp_type: this.props.match.params.tp_type,
          timepoint: timepoint,
        }
      })
      .then((result) => {
        //console.log(result['data'])
        if(result['data'].assm_val.id > 0) {
          const get_val = result['data'].assm_val
          this.setState({
            completed_status: true,
            timepoint: get_val.timepoint,
            pain_1: get_val.pain_1,
            pain_2: get_val.pain_2,
            pain_3: get_val.pain_3,
            pain_4: get_val.pain_4,
            pain_5: get_val.pain_5,
            joint_1: get_val.joint_1,
            joint_2: get_val.joint_2,
            physical_1: get_val.physical_1,
            physical_2: get_val.physical_2,
            physical_3: get_val.physical_3,
            physical_4: get_val.physical_4,
            physical_5: get_val.physical_5,
            physical_6: get_val.physical_6,
            physical_7: get_val.physical_7,
            physical_8: get_val.physical_8,
            physical_9: get_val.physical_9,
            physical_10: get_val.physical_10,
            physical_11: get_val.physical_11,
            physical_12: get_val.physical_12,
            physical_13: get_val.physical_13,
            physical_14: get_val.physical_14,
            physical_15: get_val.physical_15,
            physical_16: get_val.physical_16,
            physical_17: get_val.physical_17,
          })
        }
        //if(result['data'].followup_step !== 'womac') {
        //  this.props.history.push('/member/followup/'+result['data'].followup_step+'/'+timepoint)
        //}
      })
    } else {
      axios({
        method: 'post',
        url: connectNode + 'participants/registration/get_registration_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { pid: this.props.authReducer.uid }
      })
      .then((result) => {
          if(result['data'] !== 'womac') {
            this.props.history.push('/member/registration/'+result['data'].reg_step)
          }
        }
      )
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value,
    })
  }

  validateAllField = () => {
    var chk = true
    var txt_warning = ''
    var arr = ['pain_1', 'pain_2', 'pain_3', 'pain_4', 'pain_5',
               'joint_1', 'joint_2',
               'physical_1', 'physical_2', 'physical_3', 'physical_4', 'physical_5',
               'physical_6', 'physical_7', 'physical_8', 'physical_9', 'physical_10',
               'physical_11', 'physical_12', 'physical_13', 'physical_14', 'physical_15',
               'physical_16', 'physical_17']
    const lang = Cookies.get('lang_')
    arr.map(each => {
      if(this.state[each] == 0) {
        chk = false
        if(lang=='es') {
          txt_warning = 'Por favor entre una respuesta para todas las preguntas'
        } else {
          txt_warning = 'Please enter a response to all questions.'
        }
        return ''
      }
    })

    return txt_warning
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var tp_type = this.props.match.params.tp_type
    var timepoint = this.props.match.params.timepoint
    if(this.state.completed_status) {
      this.props.onLoadLocation('/member/followup/exercise_confidence/'+tp_type+'/'+timepoint, 'Thank you for submitting your PROMIS Physical Function!')
      this.props.history.push('/member/followup/exercise_confidence/'+tp_type+'/'+timepoint)
    } else {
      var get_validate_msg = this.validateAllField()
      //console.log(get_validate_msg)
      if(get_validate_msg == '') {
        var pid = this.props.authReducer.uid
        var get_uri = this.props.location.pathname
        var sep_uri = get_uri.split("/")
        var get_steps = []
        var current_step = 0
        var tp_type = this.props.match.params.tp_type
        var timepoint = this.props.match.params.timepoint
        if(sep_uri[2] == 'followup') {
          axios({
            method: 'post',
            url: connectNode + 'participants/followup/add_womac',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
              pid: pid,
              tp_type: tp_type,
              timepoint: timepoint,
              user: this.state,
            }
          })
          .then((result) => {
            //console.log(result['data'])
            var message = ''
            if(result['data'] == pid) {
              this.props.onLoadLocation('/member/followup/exercise_confidence/'+tp_type+'/'+timepoint, 'WOMAC completed!')
              this.props.history.push('/member/followup/exercise_confidence/'+tp_type+'/'+timepoint)
            } else {
              message = 'Something went wrong. Please try again.'
            }
            if(message !== '') {
              this.setState({
                message: message
              })
              window.scrollTo(0, 0)
            }
          })
        } else {
          axios({
            method: 'post',
            url: connectNode + 'participants/registration/add_womac',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
              pid: pid,
              tp_type: 'b',
              timepoint: 0,
              user: this.state,
            }
          })
          .then((result) => {
            var message = ''
            if(result['data'] == pid) {
              this.props.onLoadLocation('/member/registration/utilization', 'WOMAC completed!')
              this.props.history.push('/member/registration/utilization')
            } else {
              message = 'Something went wrong. Please try again.'
            }
            if(message !== '') {
              this.setState({
                message: message
              })
              window.scrollTo(0, 0)
            }
          })
        }
      } else {
        this.setState({
          message: get_validate_msg,
        })
        window.scrollTo(0, 0)
      }
    }
  }

  render() {
    var get_uri = this.props.location.pathname
    var sep_uri = get_uri.split("/")
    var get_steps = []
    var current_step = 0
    if(sep_uri[2] == 'followup') {
      get_steps = followup_assm_steps
      current_step = 1
    } else {
      get_steps = baseline_assm_steps
      current_step = 2
    }
    const lang = Cookies.get('lang_')
    const choices = lang == 'es' ? womac_pain_scales_es : womac_pain_scales
    const choices2 = lang == 'es' ? womac_joint_stifness_scales_es : womac_joint_stifness_scales
    const choices3 = lang == 'es' ? womac_physical_function_scales_es : womac_physical_function_scales
    const ques1 = lang == 'es' ? womac_pain_questions_es : womac_pain_questions
    const ques2 = lang == 'es' ? womac_joint_stifness_questions_es : womac_joint_stifness_questions
    const ques3 = lang == 'es' ? womac_physical_function_questions_es : womac_physical_function_questions
    return (
    <div className="participant1">
        <div className="headerPage3_1">
        {sep_uri[2] != 'followup' && 'New Participant Questionnaires' }
        {sep_uri[2] == 'followup' && 'Assessments: ' + sep_uri[5] }
        {sep_uri[2] == 'followup' && sep_uri[4] == 'w' && ' weeks' }
        {sep_uri[2] == 'followup' && sep_uri[4] == 'm' && ' months' }
        </div>
        <div className="small-12 columns stepper">
          <Stepper alternativeLabel nonLinear activeStep={current_step}>
            {get_steps.map(each => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={'st'+each.value} {...stepProps}>
                  <StepLabel {...labelProps}>{each.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { (this.state.message !== '' || this.state.compTxt !== '') &&
          <div className="row margin-bottom1">
            <div className={(this.state.message !== '' && 'small-12 columns warning_msg6')
            || (this.state.compTxt !== '' && 'small-12 columns warning_msg7')} >
              <div className="float_left1">
              {this.state.message !== '' && <WarningIcon />}
              {this.state.compTxt !== '' && <CheckCircleOutlineIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.state.message || this.state.compTxt }</div>
            </div>
          </div>}
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns headerPage3_2">
              WOMAC: Osteoarthritis Index
            </div>
            <div className="small-12 columns sep_line3">&nbsp;</div>
            <div className="small-12 columns">
              {(lang=='en' && 'The following questions concern the amount of pain you are currently experiencing in your hips and/or knees.  For each situation, please indicate the amount of pain you recently experienced using the following scale: None, Mild, Moderate, Severe, Extreme.')
              || lang=='es' && 'Las siguientes preguntas son referentes al dolor que siente actualmente, en las caderas y/o en las rodillas.  Para cada situación, favor de indicar cuanto dolor ha sentido últimamente usando la siguiente escala: Nada, Leve, Moderado, Severo, Extremo. CIRCULE UN NÚMERO SOLAMENTE. Pregunta: ¿Cuanto dolor tiene?'}

            </div>
            <div className="small-12 columns sep_line4  show-for-medium">&nbsp;</div>
            <div className="small-12 columns">
              <div className="row show-for-medium" >
                <div className="small-12 medium-7 columns">&nbsp;</div>
                {choices.map(each =>
                <div className="small-12 medium-1 columns scale_header1">{each.label}</div>)}
              </div>
            { ques1.map(eachQ =>
              <div className="row" key={'p'+eachQ.value}>
                <div className="small-12 columns sep_line1">&nbsp;</div>
                <div className="small-12 medium-7 columns">{eachQ.value + '. ' + eachQ.label}</div>
                {choices.map(each =>
                <div className="small-12 medium-1 columns align_center radio" key={'ps'+eachQ.value+each.value}>
                  <input type="radio" name={'pain_'+eachQ.value} value={each.value}
                  disabled={this.state.completed_status && 'disabled'}
                  checked={this.state['pain_'+eachQ.value] == each.value && 'checked'}
                  onChange={(e) => this.handleChange2(e, 'pain_'+eachQ.value)}/>
                  <span className="show-for-small-only">{each.label}</span>
                </div>)}
              </div>)}
            </div>
            <div className="small-12 columns sep_line3">&nbsp;</div>
            <div className="small-12 columns">
              {(lang=='en' && 'The following questions concern the amount of joint stiffness (not pain) you are currently experiencing in your hips and/or knees. Stiffness is a sensation of restriction or slowness in the ease with which you move your joints.')
              || (lang=='es' && 'Las siguientes preguntas son referentes a la cantidad de rigidez o endurecimiento (no dolor) en las coyunturas, que siente actualmente en sus rodillas o caderas.  Rigidez o endurecimiento es la sensación de restricción o lentitud en que mueve sus coyunturas.  CIRCULE UN NÚMERO SOLAMENTE.')}
            </div>
            <div className="small-12 columns sep_line4 show-for-medium">&nbsp;</div>
            <div className="small-12 columns">
              <div className="row show-for-medium" >
                <div className="small-12 medium-7 columns">&nbsp;</div>
                {choices2.map(each =>
                <div className="small-12 medium-1 columns scale_header1">{each.label}</div>)}
              </div>
            { ques2.map(eachQ =>
              <div className="row" key={'s'+eachQ.value}>
                <div className="small-12 columns sep_line1">&nbsp;</div>
                <div className="small-12 medium-7 columns">{eachQ.value + '. ' + eachQ.label}</div>
                {choices.map(each =>
                <div className="small-12 medium-1 columns align_center" key={'sq'+eachQ.value+each.value}>
                  <input type="radio" name={'joint_'+eachQ.value} value={each.value}
                  disabled={this.state.completed_status && 'disabled'}
                  checked={this.state['joint_'+eachQ.value] == each.value && 'checked'}
                  onChange={(e) => this.handleChange2(e, 'joint_'+eachQ.value)}/>
                  <span className="show-for-small-only">{each.label}</span>
                </div>)}
              </div>)}
            </div>
            <div className="small-12 columns sep_line3">&nbsp;</div>
            <div className="small-12 columns">
            {(lang=='en' && 'The following questions concern your physical function. By this we mean your ability to move around and to look after yourself.  For each of the following activities, please indicate the degree of difficulty your are currently experiencing due to arthritis in your hips and/or knees.')
            || (lang=='es' && 'Las siguientes preguntas se refieren a sus funciones físicas.  Se refieren a su habilidad de moverse y cuidarse  por si mismo.  Para cada actividad,  favor de indicar el grado de dificultad que tiene actualmente debido a la artritis en sus caderas y/o rodillas.  CIRCULE UN  NÚMERO SOLAMENTE')}
            <br />{(lang=='en' && 'What degree of difficulty do you have with...') || (lang=='es' && 'PREGUNTA:    ¿Que grado de dificultad tiene con…?')}
            </div>
            <div className="small-12 columns sep_line4 show-for-medium">&nbsp;</div>
            <div className="small-12 columns">
              <div className="row show-for-medium" >
                <div className="small-12 medium-7 columns">&nbsp;</div>
                {choices3.map(each =>
                <div className="small-12 medium-1 columns scale_header1">{each.label}</div>)}
              </div>
            { ques3.map(eachQ =>
              <div className="row" key={'pf'+eachQ.value}>
                <div className="small-12 columns sep_line1">&nbsp;</div>
                <div className="small-12 medium-7 columns">{eachQ.value + '. ' + eachQ.label}</div>
                {choices.map(each =>
                <div className="small-12 medium-1 columns align_center" key={'pfc'+eachQ.value+each.value}>
                  <input type="radio" name={'physical_'+eachQ.value} value={each.value}
                  disabled={this.state.completed_status && 'disabled'}
                  checked={this.state['physical_'+eachQ.value] == each.value && 'checked'}
                  onChange={(e) => this.handleChange2(e, 'physical_'+eachQ.value)}/>
                  <span className="show-for-small-only">{each.label}</span>
                </div>)}
              </div>)}
            </div>
          </div>
        </div>

        <div className="small-12 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
             {!this.state.completed_status && 'Submit'}
             {this.state.completed_status && 'Next'}
          </Button>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Womac)
