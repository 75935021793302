export const exercise_confidence_questions = [
  {
    label: 'On a scale of 1 to 10, how confident are you that you can do exercises for flexibility and range of motion 3 to 4 times per week? A few examples of flexibility exercises include stretching exercises, Tai Chi, and yoga.',
    value: 1,
  }, {
    label: 'On a scale of 1 to 10, how confident are you that you can do exercises for muscle strength 3 to 4 times per week? Examples of exercises for muscle strength include using weights, elastic exercise bands, or weight machines.',
    value: 2,
  }, {
    label: 'On a scale of 1-10, how confident are you that you can do exercises such as aerobics, walking, biking or swimming – physical activity that makes your heart beat faster and makes you breathe hard or make you sweat?',
    value: 3,
  }, {
    label: 'On a scale of 1 to 10, how confident are you that you can exercise without causing yourself pain or other symptoms?',
    value: 4,
  },
]

export const exercise_confidence_questions_es = [
  {
    label: '¿En una escala del 1 al 10, que tan  seguro(a) está que puede hacer ejercicios de flexibilidad y de movimiento de 3 a 4 veces por semana? Ejemplos: estiramiento, Tai Chi, y yoga.',
    value: 1,
  }, {
    label: '¿En una escala del 1 al 10, que tan  seguro(a) está que puede hacer ejercicios para fuerza muscular de 3 a 4 veces por semana? Ejemplos de ejercicios para fuerza muscular incluyen usando pesas, bandas elásticas o maquinas de pesas.',
    value: 2,
  }, {
    label: '¿En una escala del 1 al 10, que tan  seguro(a) está que puede hacer ejercicios como los aeróbicos, caminando, bicicleta, o natación-actividad física que hace que su corazón palpite más rápido y que respire más rápido o que sude?',
    value: 3,
  }, {
    label: '¿En una escala del 1 al 10, que tan  seguro(a) está que puede hacer ejercicio sin que le cause dolor u otros síntomas?',
    value: 4,
  },
]

export const exercise_confidence_scale = [
  {
    label: 'Not at all Confident',
    value: 1,
  }, {
    label: '',
    value: 2,
  }, {
    label: '',
    value: 3,
  }, {
    label: '',
    value: 4,
  }, {
    label: '',
    value: 5,
  }, {
    label: '',
    value: 6,
  }, {
    label: '',
    value: 7,
  }, {
    label: '',
    value: 8,
  }, {
    label: '',
    value: 9,
  }, {
    label: 'Totally Confident',
    value: 10,
  },
]

export const exercise_confidence_scale_es = [
  {
    label: 'Nada Seguro(a)',
    value: 1,
  }, {
    label: '',
    value: 2,
  }, {
    label: '',
    value: 3,
  }, {
    label: '',
    value: 4,
  }, {
    label: '',
    value: 5,
  }, {
    label: '',
    value: 6,
  }, {
    label: '',
    value: 7,
  }, {
    label: '',
    value: 8,
  }, {
    label: '',
    value: 9,
  }, {
    label: 'Totalmente Seguro(a)',
    value: 10,
  },
]

export const promis_pf_questions2 = [
  {
    label: 'Does your health now limit you in doing two hours of physical labor?',
    value: 5,
  }, {
    label: 'Does your health now limit you in doing moderate work around the house like vacuuming, sweeping floors or carrying in groceries?',
    value: 6,
  }, {
    label: 'Does your health now limit you in lifting or carrying groceries?',
    value: 7,
  }, {
    label: 'Does your health now limit you in doing heavy work around the house like scrubbing floors, or lifting or moving heavy furniture?',
    value: 8,
  },
]

export const promis_pf_scale2 = [
  {
    label: 'Not at all',
    value: 5,
  }, {
    label: 'Very little',
    value: 4,
  }, {
    label: 'Somewhat',
    value: 3,
  }, {
    label: 'Quite a lot',
    value: 2,
  }, {
    label: 'Cannot do',
    value: 1,
  },
]
