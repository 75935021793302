import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import { yes_no, english_answers } from '../../../../variables/registration.js'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'
import { WarningMessages } from '../../../../shared/warning_messages'

class Screening extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        warningMessages: [],
        q1: '',
        q2: '',
        q3: 0,
        q4: 0,
        q5: 0,
        q6: 0,
        q7: 0,
        eligible: 0,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/participant/check_completed_screen_questionnaire',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
      if(result['data'].q1 > 0) {
        this.setState({
          q1: result['data'].q1,
          q2: result['data'].q2,
          q3: result['data'].q3,
          q4: result['data'].q4,
          q5: result['data'].q5,
          q6: result['data'].q6,
          q7: result['data'].q7,
          eligible: result['data'].eligible,
          completed_status: true,
        })
      }
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, this.checkAnswersForWarning)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(!validator.isFloat(this.state.q1) || !validator.isFloat(this.state.q2)) {
      this.setState({
        message: 'Please fill only number in question 1 and 2',
      })
      window.scrollTo(0, 0)
    } else if(this.state.q1 < 55 || this.state.q1 > 110 ) {
      this.setState({
        message: 'Please correct question 1',
      })
      window.scrollTo(0, 0)
    } else if(this.state.q2 > 9999 ) {
      this.setState({
        message: 'Please correct question 2',
      })
      window.scrollTo(0, 0)
    } else {
      if(this.state.q1.trim() !== '' && this.state.q2.trim() !== ''
        && this.state.q3 != 0 && this.state.q4 != 0 && this.state.q5 != 0
        && this.state.q6 != 0 && this.state.q7 != 0 && this.state.eligible != 0) {
          var pid = this.props.match.params.pid
          axios({
            method: 'post',
            url: connectNode + 'admin/participant/add_screen_questionnaire',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
              uid: this.props.authReducer.uid,
              pid: pid,
              user: this.state,
            }
          })
          .then((result) => {
            var message = ''
            if(result['data'] == pid) {
              var next_tab = '4'
              if(this.state.eligible == 1) {
                next_tab = '2'
              }
              this.props.onUpdateTab(next_tab, 'Thank you for submitting screening questions!')
            } else {
              message = 'Something went wrong. Please try again.'
            }
            if(message !== '') {
              this.setState({
                message: message
              })
              window.scrollTo(0, 0)
            }
          })
      } else {
        this.setState({
          message: 'Please fill all information',
        })
        window.scrollTo(0, 0)
      }
    }
  }

  checkAnswersForWarning = () => {
    const {q1, q2, q3, q4, q5, q6, q7} = this.state;
    const yesValue = "1";
    const noValue = "2";
    const poorValue = "1";
    const warnings =[
      {isWarning: q1 < 55, warningMessage: "You must be 55 or older to participate in this study" },
      {isWarning: q2 >= 100, warningMessage: "You must be exercising less than 100 minutes/week to participate in this study." },
      {isWarning: q3 === yesValue, warningMessage: "You must not be participating in an exercise program to join this study." },
      {isWarning: q4 === noValue, warningMessage: "You must be willing to attend some in-person exercise classes at or near the church to participate in this study." },
      {isWarning: q5 === noValue, warningMessage: "You must be willing to attend three classes a week to participate in this study." },
      {isWarning: q6 === poorValue || q7 === poorValue, warningMessage: "You must be able to read and write English fairly well to participate in this study." }]
    this.setState({warningMessages: warnings.filter(x => x.isWarning).map(x => x.warningMessage)});
  }

  render() {
    return (
    <div className="">
        <div className="headerPage3_1">
          Preliminary Screening Questions
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { this.state.message !== '' &&
            <WarningMessages messages={[ this.state.message ]} />
          }

          <WarningMessages messages={this.state.warningMessages} yellow/>

          <div className="row space_bottom2 top_space1">
            <div className="small-12 columns">
              <div className="small-12 columns bold_txt2">1.	What is your current age?</div>
              <div className="small-12 columns float_left top_space1">
                <TextField onChange={this.handleChange} variant="outlined"
                disabled={this.state.completed_status && 'disabled'}
                type="text" value={this.state.q1} className="w_50p"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key) || event.key < 0) {
                    event.preventDefault();
                  }
                }}
                InputProps={{ inputProps: { name: "q1" }}} />
              </div>
            </div>
          </div>

          <div className="row space_bottom2">
            <div className="small-12 columns">
              <div className="small-12 columns bold_txt2">2.	How much time on average do you spend on exercising?</div>
              <div className="small-12 columns float_left top_space1">
                <div className="width_auto">
                  <TextField onChange={this.handleChange} variant="outlined"
                  disabled={this.state.completed_status && 'disabled'}
                  type="text" value={this.state.q2} className="w_50p"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key) || event.key < 0) {
                      event.preventDefault();
                    }
                  }}
                  InputProps={{ inputProps: { name: "q2" }}} />
                </div>
                <div className="top_space1 width_auto space_left1">min/week</div>
              </div>
            </div>
          </div>

          <div className="row space_bottom2">
            <div className="small-12 columns">
              <div className="small-12 columns bold_txt2">3.	Are you currently in an exercise program?</div>
              <div className="small-12 columns float_left">
                {yes_no.map(each =>
                <div className="small-12 medium-2 columns float_left" key={'ce'+each.value}>
                  <input type="radio" name="q3" value={each.value}
                  disabled={this.state.completed_status && 'disabled'}
                  checked={this.state.q3 == each.value && 'checked'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span>{each.label}</span>
                </div>)}
              </div>
            </div>
          </div>

          <div className="row space_bottom2">
            <div className="small-12 columns">
              <div className="small-12 columns bold_txt2">4.	Are you able to attend some in-person exercise classes at or near the church?</div>
              <div className="small-12 columns float_left">
                {yes_no.map(each =>
                <div className="small-12 medium-2 columns float_left" key={'aa'+each.value}>
                  <input type="radio" name="q4" value={each.value}
                  disabled={this.state.completed_status && 'disabled'}
                  checked={this.state.q4 == each.value && 'checked'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span>{each.label}</span>
                </div>)}
              </div>
            </div>
          </div>

          <div className="row space_bottom2">
            <div className="small-12 columns">
              <div className="small-12 columns bold_txt2">5.	Are you willing to attend 3 exercise classes per week (either in-person or remote)?</div>
              <div className="small-12 columns float_left">
                {yes_no.map(each =>
                <div className="small-12 medium-2 columns float_left" key={'wa'+each.value}>
                  <input type="radio" name="q5" value={each.value}
                  disabled={this.state.completed_status && 'disabled'}
                  checked={this.state.q5 == each.value && 'checked'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span>{each.label}</span>
                </div>)}
              </div>
            </div>
          </div>

          <div className="row space_bottom2">
            <div className="small-12 columns">
              <div className="small-12 columns bold_txt2">6. How well do you read English?</div>
              <div className="small-12 columns float_left">
                {english_answers.map(each =>
                <div className="small-12 medium-2 columns float_left" key={'wa'+each.value}>
                  <input type="radio" name="q6" value={each.value}
                  disabled={this.state.completed_status && 'disabled'}
                  checked={this.state.q6 == each.value && 'checked'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span>{each.label}</span>
                </div>)}
              </div>
            </div>
          </div>

          <div className="row space_bottom2">
            <div className="small-12 columns">
              <div className="small-12 columns bold_txt2">7. How well do you write English?</div>
              <div className="small-12 columns float_left">
                {english_answers.map(each =>
                <div className="small-12 medium-2 columns float_left" key={'wa'+each.value}>
                  <input type="radio" name="q7" value={each.value}
                  disabled={this.state.completed_status && 'disabled'}
                  checked={this.state.q7 == each.value && 'checked'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span>{each.label}</span>
                </div>)}
              </div>
            </div>
          </div>
          <div className="small-12 columns sep_line1">&nbsp;</div>
          <div className="row space_bottom2">
            <div className="small-12 columns">
              <div className="small-12 columns bold_txt2">Pass screening?</div>
              <div className="small-12 columns float_left">
                {yes_no.map(each =>
                <div className="small-12 medium-2 columns float_left" key={'wa'+each.value}>
                  <input type="radio" name="eligible" value={each.value}
                  disabled={this.state.completed_status && 'disabled'}
                  checked={this.state.eligible == each.value && 'checked'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span>{each.label}</span>
                </div>)}
              </div>
            </div>
          </div>

        </div>
        {!this.state.completed_status &&
        <div className="small-12 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Submit
          </Button>
        </div>}
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Screening)
