import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'

class ExerciseVideos extends Component {
  constructor() {
    super()
  }

  render() {
    //console.log(this.state)
    return (
    <div className="assm_page1">
        <div className="headerPage1">
          Exercise Videos
        </div>
        <div className="small-12 medium-6 columns box_assm2">
          <div className="title_assm1">Youtube Channel</div>
          <div className="">
            <ul>
              <li><a href="">Exercise A</a></li>
              <li><a href="">Exercise B</a></li>
              <li><a href="">Exercise C</a></li>
              <li><a href="">Exercise D</a></li>
              <li><a href="">Exercise E</a></li>
              <li><a href="">Exercise G</a></li>
            </ul>
          </div>
        </div>
        <div className="small-12 medium-6 columns box_assm1">
          <div className="title_assm1">Download</div>
          <div className="">
            <ul>
              <li><a href="">Exercise A</a></li>
              <li><a href="">Exercise B</a></li>
              <li><a href="">Exercise C</a></li>
              <li><a href="">Exercise D</a></li>
              <li><a href="">Exercise E</a></li>
              <li><a href="">Exercise G</a></li>
            </ul>
          </div>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseVideos)
