import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'
import PatientHead from "../participant_head"
import { baseline_assm_steps, tp_type } from '../../../../variables/baseline_assm.js'
import { followup_assm_steps } from '../../../../variables/followup_assm.js'
import { womac_pain_questions, womac_pain_scales, womac_joint_stifness_questions,
         womac_joint_stifness_scales, womac_physical_function_questions,
         womac_physical_function_scales} from '../../../../variables/baseline_womac.js'

class Womac extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        compTxt: '',
        timepoint: 0,
        pain_1: 0,
        pain_2: 0,
        pain_3: 0,
        pain_4: 0,
        pain_5: 0,
        joint_1: 0,
        joint_2: 0,
        physical_1: 0,
        physical_2: 0,
        physical_3: 0,
        physical_4: 0,
        physical_5: 0,
        physical_6: 0,
        physical_7: 0,
        physical_8: 0,
        physical_9: 0,
        physical_10: 0,
        physical_11: 0,
        physical_12: 0,
        physical_13: 0,
        physical_14: 0,
        physical_15: 0,
        physical_16: 0,
        physical_17: 0,
        created: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/register_participant/get_womac',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        type_tp: this.props.match.params.type_tp,
        timepoint: this.props.match.params.timepoint,
      }
    })
    .then((result) => {
      console.log(result['data'])
      if(result['data'].uid > 0) {
        this.setState({
          type_tp: this.props.match.params.type_tp,
          timepoint: this.props.match.params.timepoint,
          pain_1: result['data'].pain_1,
          pain_2: result['data'].pain_2,
          pain_3: result['data'].pain_3,
          pain_4: result['data'].pain_4,
          pain_5: result['data'].pain_5,
          joint_1: result['data'].joint_1,
          joint_2: result['data'].joint_2,
          physical_1: result['data'].physical_1,
          physical_2: result['data'].physical_2,
          physical_3: result['data'].physical_3,
          physical_4: result['data'].physical_4,
          physical_5: result['data'].physical_5,
          physical_6: result['data'].physical_6,
          physical_7: result['data'].physical_7,
          physical_8: result['data'].physical_8,
          physical_9: result['data'].physical_9,
          physical_10: result['data'].physical_10,
          physical_11: result['data'].physical_11,
          physical_12: result['data'].physical_12,
          physical_13: result['data'].physical_13,
          physical_14: result['data'].physical_14,
          physical_15: result['data'].physical_15,
          physical_16: result['data'].physical_16,
          physical_17: result['data'].physical_17,
          created: result['data'].created,
        })
      }
    })
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value,
    })
  }

  render() {
    return (
    <div className="participant1">
        <div className="medium-6 columns headerPage3_1">
        WOMAC: Osteoarthritis Index [{this.findLabelByValue(tp_type, this.props.match.params.type_tp)}]
        </div>
        <div className="medium-6 columns align-right">
        Completed: {this.state.created}
        </div>
        <div className="small-12 columns bottom_space2">
          <PatientHead />
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns">
              The following questions concern the amount of pain you are currently
              experiencing in your hips and/or knees.  For each situation, please
              indicate the amount of pain you recently experienced using the
              following scale: None, Mild, Moderate, Severe, Extreme.
            </div>
            <div className="small-12 columns sep_line4">&nbsp;</div>
            <div className="small-12 columns">
            { womac_pain_questions.map(eachQ =>
              <div className="row" key={'p'+eachQ.value}>
                {eachQ.value > 1 && <div className="small-12 columns sep_line1">&nbsp;</div>}
                <div className="small-12 medium-7 columns">{eachQ.value + '. ' + eachQ.label}</div>
                <div className="small-12 medium-5 columns">{this.findLabelByValue(womac_pain_scales, this.state['pain_'+eachQ.value])}</div>
              </div>)}
            </div>
            <div className="small-12 columns sep_line3">&nbsp;</div>
            <div className="small-12 columns">
              The following questions concern the amount of joint stiffness (not pain)
              you are currently experiencing in your hips and/or knees. Stiffness is a
              sensation of restriction or slowness in the ease with which you move your joints.
            </div>
            <div className="small-12 columns sep_line4">&nbsp;</div>
            <div className="small-12 columns">
            { womac_joint_stifness_questions.map(eachQ =>
              <div className="row" key={'s'+eachQ.value}>
                {eachQ.value > 1 && <div className="small-12 columns sep_line1">&nbsp;</div>}
                <div className="small-12 medium-9 columns">{eachQ.value + '. ' + eachQ.label}</div>
                <div className="small-12 medium-3 columns">{this.findLabelByValue(womac_pain_scales, this.state['joint_'+eachQ.value])}</div>
              </div>)}
            </div>
            <div className="small-12 columns sep_line3">&nbsp;</div>
            <div className="small-12 columns">
            The following questions concern your physical function. By this we mean your ability
            to move around and to look after yourself.  For each of the following activities,
            please indicate the degree of difficulty your are currently experiencing due to
            arthritis in your hips and/or knees.
            <br />What degree of difficulty do you have with...
            </div>
            <div className="small-12 columns sep_line4">&nbsp;</div>
            <div className="small-12 columns">
            { womac_physical_function_questions.map(eachQ =>
              <div className="row" key={'pf'+eachQ.value}>
                {eachQ.value > 1 && <div className="small-12 columns sep_line1">&nbsp;</div>}
                <div className="small-12 medium-7 columns">{eachQ.value + '. ' + eachQ.label}</div>
                <div className="small-12 medium-5 columns">{this.findLabelByValue(womac_pain_scales, this.state['physical_'+eachQ.value])}</div>
              </div>)}
            </div>
          </div>
        </div>
        <div className="small-12 columns back_button1">
          <a href={'/admin/participant_chart/'+this.props.match.params.pid+'/2/1'}>Back</a>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Womac)
