import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
//import '../../../styles/assets/css/pages/dashboard.css'
import '../../../styles/assets/css/pages/graph.css'
import '../../../styles/assets/css/pages/dashboard.css'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { NavLink } from "react-router-dom"
import { FiSettings } from 'react-icons/fi'
import { VictoryChart, VictoryLine, VictoryTheme, VictoryAxis, VictoryScatter, VictoryGroup } from 'victory'
import ShareGraph from './share_graph'

class ViewSharedGraph extends Component {
  constructor() {
    super()
    this.state = {
      checked: false,
      getData:[],
      scale: 0,
      getData2:[],
      scale2: 0,
      getDataList: [],
    }
  }

  componentDidMount() {
    this.getDataList()
  }

  componentWillReceiveProps(nextProps) {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'participants/activity/get_view_shared_to_graph',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
      }
    })
    .then((result) => {
        var genDataList = []
        var getGen = {}
        result['data'].map(participant => {
          getGen = {}
          getGen = this.genEachData(participant)
          genDataList.push(getGen)
        })
        this.setState({
          getDataList: genDataList,
        })
        /*var buff = []
        var cnt = 0
        result['data'][0].map(each => {
          cnt += each.cnt
          buff.push({
            x: this.convertDate(each.FirstDayOfWeek) + '-' + this.convertDate(each.LastDayOfWeek),
            y: cnt,
          })
        })
        var buff2 = []
        var getMax = 0
        result['data'][1].map(each => {
          if(each.sum_mins > getMax) {
            getMax = each.sum_mins
          }
          buff2.push({
            x: this.convertDate(each.FirstDayOfWeek) + '-' + this.convertDate(each.LastDayOfWeek),
            y: each.sum_mins,
          })
        })
        this.setState({
          getData: buff,
          scale: cnt,
          getData2: buff2,
          scale2: getMax,
        })*/
        //console.log(buff)
    })
  }

  genEachData(participant) {
    var genData = {}
    var buff = []
    var getMax = 0
    genData.name = participant[0].fullname
    participant.map(each => {
      if(each.sum_mins > getMax) {
        getMax = each.sum_mins
      }
      buff.push({
        x: this.convertDate(each.FirstDayOfWeek) + '-' + this.convertDate(each.LastDayOfWeek),
        y: each.sum_mins,
      })
      genData.getData2 = buff
      genData.scale2 = getMax
    })
    return genData
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  genArr1(start, num) {
    var a = []
    for(var i=start; i<=num; i++) { a[i] = i }
    return a
  }

  convertDate(getDate) {
    var buff = getDate.split("-")
    return buff[1]+'/'+buff[2] //+'/'+buff[0]
  }

  getXaxis() {
    var length = 0
    if(this.state.graph_type == 1) {
      length = this.state.tracker_day.length
    } else if(this.state.graph_type == 2) {
      length = this.state.tracker_week.length
    }
    return length
  }

  handleUpdateStatus = () => {
    document.getElementById('warning_box').style.display = 'block'
  }

  closeWarningBox = (e) => {
    document.getElementById('warning_box').style.display = 'none'
  }

  handleGotoView = () => {
    window.location = '/member/shared_graph'
  }

  render() {
    console.log(this.state.getDataList)
    return (
    <div className="box_calendar">
      <div className="box_header1">
        <div className="small-12 medium-12 columns headerPage1">
        Shared Aerobic Minutes per Week
        </div>
      </div>
      <div className="">
      {this.state.getDataList.map(each =>
      <>
      <div className="small-12 columns box_header1">{each.name}</div>
      <div className="small-12 columns axis-title">Aerobic minutes per week</div>
      <div className="small-12 columns box_space_top1 pt_graph">
        <div className="row">
          <div className="small-12 columns">
          <VictoryChart theme={VictoryTheme.material}
            padding={{ top: 5, bottom: 30, left: 15, right: 15 }}
            domainPadding={{x : [20, 20], y: each.scale2/5}} height={90} >
            <VictoryAxis crossAxis
              tickValues={this.genArr1(0, 19)}
              style={{
                axis: {stroke: "#cccccc", fontSize: 5},
                axisLabel: {fontSize: 5, padding: 10},
                ticks: {stroke: "#cccccc", size: 1},
                tickLabels: {fontSize: 5, padding: 10, angle: 45},
              }}
            />
          <VictoryAxis dependentAxis crossAxis
              tickValues={each.scale2}
              tickCount={5}
              style={{
                axis: {stroke: "#cccccc", fontSize: 5},
                axisLabel: {fontSize: 5, padding: 0},
                ticks: {stroke: "#cccccc", size: 1},
                tickLabels: {fontSize: 5, padding: 5},
              }}
            />
            <VictoryGroup data={each.getData2} color="#ffac29" >
          <VictoryLine
            domain={{x: [0, 7], y: [0, 6]}}
            style={{
              data: { stroke: "#ffac29", strokeWidth: 0.5 },
              parent: { border: "1px solid #cccccc"}
            }}
            data={each.getData2}
          />
          <VictoryScatter size={1.5} symbol="dot"/>
          </VictoryGroup>
          </VictoryChart>
          </div>
        </div>
      </div>
      </>
      )}
      </div>
    </div>
  )}
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ViewSharedGraph)
