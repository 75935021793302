import React from "react"
import Login from "../components/auth/login"
import Home from "../components/public/home"
import About from "../components/public/about"
import Signup from "../components/members/signup"

const PUBLIC_ROUTES = [
  {   path: "/",
      key: "login",
      title: "Login",
      exact: true,
      requireAuth: false,
      component: () => <Login />,
      showtitle: 'true',
  },
  {   path: "/home",
      key: "home",
      title: "Home",
      exact: true,
      requireAuth: false,
      component: () => <Home />,
      showtitle: 'true',
  },
  {   path: "/login",
      key: "login",
      title: "Login",
      exact: true,
      requireAuth: false,
      component: () => <Login />,
      showtitle: 'true',
  },
  {   path: "/about",
      key: "about",
      title: "About",
      exact: true,
      requireAuth: false,
      component: () => <About />,
      showtitle: 'true',
  },
  {   path: "/member/signup/:pid",
      key: "signup",
      title: "Signup",
      exact: true,
      requireAuth: false,
      component: () => <Signup />,
      showtitle: 'true',
      //ficon: 'fi-home',
  },

];

export default PUBLIC_ROUTES;
