import axios from "axios"
import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'
import { connectNode } from '../../utils/global.js'
const { JWT_CRYPT_KEY } = require("../../const/authKey")

// Authentication service //
class AuthService {
  login(username, password) {
    //return (dispatch) => {
    return axios.post(connectNode + 'auth/validate', {
        username: username,
        password: password
      } , {
        'Content-Type': 'application/x-www-form-urlencoded',
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          }
        /*withCredentials: true,
        credentials: 'same-origin',*/
      }).then(response => {
            //console.log('User-pre: ' + localStorage.getItem("accessUser"))
            //if (response.status === 200) {
            var timeoutTimestamp = new Date().getTime() + 145 * 60 * 1000
            var cookieTimeout = new Date(timeoutTimestamp);
            if (typeof response.data.token !== 'undefined') {
              Cookies.set('cha_', response.data.user.uid, {
                expires: cookieTimeout, // 1/48,
              })
              var enc = CryptoJS.AES.encrypt(response.data.token, JWT_CRYPT_KEY).toString()
              Cookies.set('frha', enc, {
                expires: cookieTimeout,
              })
              //console.log('response =>')
              //console.log(response.data)
              var payload = {
                uid: response.data.user.uid,
                username: response.data.user.username,
                role: response.data.user.role,
                sub_role: response.data.user.sub_role,
                p_st: response.data.user.p_status, // participants_registration.status
                r_step: response.data.user.status, // patients_registration.baseline_step
                reg_status: response.data.status, // participants_registration.status
                token: enc,
                loggedIn: true,
                expires: response.data.user.expires,
              }
              return payload
            } else {
              //console.log(response)
              if(response.data.message == 'Lock') {
                return -2
              } else if(response.data.message == 'Attempts lock') {
                return -3
              } else if(response.data.message == 'Disable') {
                return -4
              } else {
                return -1
              }
            }
            //return null
        }).catch(error => {
            console.log(error);
        })
    //}
  }

  logout() {
    localStorage.removeItem("accessUser")
    Cookies.remove('cha_')
    Cookies.remove('frha')
    //Cookies.remove('cto')
    Cookies.remove('connect.sid')
  }

  register(username, email, password) {
    return axios.post(connectNode + "signup", {
      username,
      email,
      password
    });
  }

  isAuthenticated() {
    return axios.post(connectNode + 'users/profile', {
      //uid: username,
      fr: CryptoJS.AES.decrypt(Cookies.get('frha')).toString(CryptoJS.enc.Utf8)
    } , {
      'Content-Type': 'application/x-www-form-urlencoded',
      //withCredentials: true,
    }).then(response => {
          if (typeof response.data.uid > 0) {
            return true
          }
          return false
      }).catch(error => {
          console.log(error);
      })
  }
}
export default new AuthService();
