import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
//import '../../../styles/assets/css/pages/dashboard.css'
import '../../../styles/assets/css/pages/graph.css'
import Button from '@material-ui/core/Button'
import { NavLink } from "react-router-dom"
import { FiSettings } from 'react-icons/fi'
import { VictoryChart, VictoryLine, VictoryTheme, VictoryAxis, VictoryScatter, VictoryGroup } from 'victory'
import ShareGraph from './share_graph'
import Cookies from 'js-cookie'

class Graph extends Component {
  constructor() {
    super()
    this.state = {
      checked: false,
      getData:[],
      scale: 0,
      getData2:[],
      scale2: 0,
    }
  }

  componentDidMount() {
    this.getDataList()
  }

  componentWillReceiveProps(nextProps) {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'participants/activity/get_exercise_log_by_week',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
      }
    })
    .then((result) => {
        console.log(result['data'])
        var buff = []
        var cnt = 0
        result['data'][0].map(each => {
          cnt += each.cnt
          buff.push({
            x: this.convertDate(each.FirstDayOfWeek) + '-' + this.convertDate(each.LastDayOfWeek),
            y: cnt,
          })
        })
        var buff2 = []
        var getMax = 0
        result['data'][1].map(each => {
          if(each.sum_mins > getMax) {
            getMax = each.sum_mins
          }
          buff2.push({
            x: this.convertDate(each.FirstDayOfWeek) + '-' + this.convertDate(each.LastDayOfWeek),
            y: each.sum_mins,
          })
        })
        this.setState({
          getData: buff,
          scale: cnt,
          getData2: buff2,
          scale2: getMax,
        })
        //console.log(buff)
    })
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  genArr1(start, num) {
    var a = []
    for(var i=start; i<=num; i++) { a[i] = i }
    return a
  }

  convertDate(getDate) {
    var buff = getDate.split("-")
    return buff[1]+'/'+buff[2] //+'/'+buff[0]
  }

  getXaxis() {
    var length = 0
    if(this.state.graph_type == 1) {
      length = this.state.tracker_day.length
    } else if(this.state.graph_type == 2) {
      length = this.state.tracker_week.length
    }
    return length
  }

  handleUpdateStatus = () => {
    document.getElementById('warning_box').style.display = 'block'
  }

  closeWarningBox = (e) => {
    document.getElementById('warning_box').style.display = 'none'
  }

  handleGotoView = () => {
    window.location = '/member/shared_graph'
  }

  render() {
    const lang = Cookies.get('lang_')
    return (
    <div className="box_calendar">
      <div className="box_header1">
        <div className="small-12 medium-12 columns">
        {(lang=='en' && 'Graph') || lang=='es' && 'Grafica'}
        </div>
      </div>
      <div className="">
      <div className="small-12 columns axis-title">
        {(lang=='en' && 'Completed exercise logs') || lang=='es' && 'Registro de ejercicio completados'}
      </div>
      <div className="small-12 columns box_space_top1 pt_graph">
        <div className="row">
          <div className="small-12 columns">
            <VictoryChart theme={VictoryTheme.material}
              padding={{ top: 5, bottom: 30, left: 15, right: 15 }}
              domainPadding={{y: this.state.scale/5}} height={90} >
              <VictoryAxis crossAxis
                tickValues={this.genArr1(0, 19)}
                style={{
                  axis: {stroke: "#cccccc", fontSize: 5},
                  axisLabel: {fontSize: 5, padding: 10},
                  ticks: {stroke: "#cccccc", size: 1},
                  tickLabels: {fontSize: 5, padding: 10, angle: 45},
                }}
              />
              {/*label="Completed exercise logs"*/}
            <VictoryAxis dependentAxis crossAxis
                tickValues={this.state.scale}
                tickCount={5}
                style={{
                  axis: {stroke: "#cccccc", fontSize: 5},
                  axisLabel: {fontSize: 5, padding: 0},
                  ticks: {stroke: "#cccccc", size: 1},
                  tickLabels: {fontSize: 5, padding: 5}
                }}
              />
              <VictoryGroup data={this.state.getData} color="#ffac29" >
            <VictoryLine
              domain={{x: [0, 7], y: [0, 6]}}
              style={{
                data: { stroke: "#ffac29", strokeWidth: 0.5 },
                parent: { border: "1px solid #cccccc"}
              }}
              data={this.state.getData}
            />
            <VictoryScatter size={1.5} symbol="dot"/>
            </VictoryGroup>
            </VictoryChart>
          </div>
        </div>
      </div>

      <div className="small-12 medium-6 columns axis-title">
        {(lang=='en' && 'Aerobic minutes per week') || lang=='es' && 'Minutos aeróbicos por semana'}
      </div>
      <div className="small-12 medium-6 columns text_align_right1">
      <Button variant="contained" color="primary" className="margin_right_1"
       onClick={()=>this.handleUpdateStatus()} type="button">{(lang=='en' && 'Share') || (lang=='es' && 'COMPARTIR')}</Button>
       &nbsp;
      <Button variant="contained" color="primary" className="margin_right_1"
       onClick={()=>this.handleGotoView()} type="button">View Shared Graph</Button>
      </div>
      <div className="small-12 columns box_space_top1 pt_graph">
        <div className="row">
          <div className="small-12 columns">
            <VictoryChart theme={VictoryTheme.material}
              padding={{ top: 5, bottom: 30, left: 15, right: 15 }}
              domainPadding={{y: this.state.scale2/5}} height={90} >
              <VictoryAxis crossAxis
                tickValues={this.genArr1(0, 19)}
                style={{
                  axis: {stroke: "#cccccc", fontSize: 5},
                  axisLabel: {fontSize: 5, padding: 10},
                  ticks: {stroke: "#cccccc", size: 1},
                  tickLabels: {fontSize: 5, padding: 10, angle: 45},
                }}
              />
              {/*label="Completed exercise logs"*/}
            <VictoryAxis dependentAxis crossAxis
                tickValues={this.state.scale2}
                tickCount={5}
                style={{
                  axis: {stroke: "#cccccc", fontSize: 5},
                  axisLabel: {fontSize: 5, padding: 0},
                  ticks: {stroke: "#cccccc", size: 1},
                  tickLabels: {fontSize: 5, padding: 5}
                }}
              />
              <VictoryGroup data={this.state.getData2} color="#ffac29" >
            <VictoryLine
              domain={{x: [0, 7], y: [0, 6]}}
              style={{
                data: { stroke: "#ffac29", strokeWidth: 0.5 },
                parent: { border: "1px solid #cccccc"}
              }}
              data={this.state.getData2}
            />
            <VictoryScatter size={1.5} symbol="dot"/>
            </VictoryGroup>
            </VictoryChart>
          </div>
        </div>
      </div>
      </div>
      <div className="warning_box width_100p no_margin_left" id="warning_box">
      <div className="row">
        <div className="small-12 columns">
          <ShareGraph />
        </div>
      </div>
    </div>
    </div>
  )}
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Graph)
