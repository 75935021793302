import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import { exercise_log_mins, exercise_log_intensity, exercise_log_rep, exercise_log_sets,
  exercise_log_exertion_scale, frm_yes_no} from '../../variables/participant_contract.js'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'

class Event extends Component {
  constructor() {
    super()
    this.quill = require('react-quill')
    this.state = {
        completed: false,
        status_submit: true,
        message: '',
        group: 0,
        name: '',
        class_date: '',
        start_time: '',
        end_time: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    var pid = this.props.authReducer.uid
    var cid = this.props.match.params.cid
    axios({
      method: 'post',
      url: connectNode + 'admin/events/get_class_info',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        cid: cid,
        pid: pid,
      }
    })
    .then((result) => {
      console.log(result['data'])
      this.setState({
        group: result['data'].group,
        name: result['data'].class_name,
        class_date: result['data'].class_date,
        start_time: result['data'].class_start_time,
        end_time: result['data'].class_end_time,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value,
    })
  }

  validateAllField = () => {
    var txt_warning = ''
    var arr = ['name', 'class_date', 'start_time', 'end_time']
    arr.map(each => {
      if(this.state[each].trim() == '') {
        txt_warning = 'Please fill all information'
        return ''
      }
    })
    return txt_warning
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var get_validate_msg = this.validateAllField()
    //console.log(get_validate_msg)
    if(get_validate_msg == '') {
      var uid = this.props.authReducer.uid
      axios({
        method: 'post',
        url: connectNode + 'admin/events/update_event_data',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: uid,
          cid: this.props.match.params.cid,
          group: this.state,
        }
      })
      .then((result) => {
        var message = ''
        if(result['data'] == uid) {
          this.setState({
            message: 'Change completed!',
            status_submit: true,
            completed: true,
          })
          window.scrollTo(0, 0)
        }
      })
    } else {
      this.setState({
        message: get_validate_msg,
        status_submit: false,
      })
      window.scrollTo(0, 0)
    }
  }

  render() {
    return (
    <div className="assm_page1">
        <div className="headerPage1">
          Class [Group {this.state.group}]
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
        <div className="small-12 columns">
          { (this.state.message !== '') &&
          <div className="row margin-bottom1">
            <div className={(!this.state.status_submit && 'small-12 columns warning_msg6')
            || (this.state.status_submit && 'small-12 columns warning_msg7')} >
              <div className="float_left1">
              {!this.state.status_submit && <WarningIcon />}
              {this.state.status_submit && <CheckCircleOutlineIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>
          </div>}
          </div>

          <div className="small-12 medium-2 columns title1">Class name</div>
          <div className="small-12 medium-10 columns top_space1">
            <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
            type="text" value={this.state.name} className="w_100per right_space1"
            InputProps={{ name:'name' }} />
          </div>

          <div className="small-12 medium-2 columns title1">Date</div>
          <div className="small-12 medium-10 columns top_space1">
          <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
          type="date" value={this.state.class_date} className="right_space1"
          InputProps={{ name:'class_date' }} />
          </div>

          <div className="small-12 medium-2 columns title1">Start time</div>
          <div className="small-12 medium-10 columns top_space1">
            <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
            type="text" value={this.state.start_time} className="right_space1"
            InputProps={{ name:'start_time' }} />
          </div>

          <div className="small-12 medium-2 columns title1">End time</div>
          <div className="small-12 medium-10 columns top_space1">
            <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
            type="text" value={this.state.end_time} className="right_space1"
            InputProps={{ name:'end_time' }} />
          </div>

        </div>
        <div className="small-6 columns back_button1 float_left">
          <a href="/admin/events/event_list">Back</a>
        </div>
        <div className="small-6 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Submit
          </Button>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Event)
