import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../actions/location'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import ReactHtmlParser from 'react-html-parser'
import GetXLSX from '../../functions/get_xlsx.jsx'

class ReportList extends Component {
  constructor() {
    super()
  }



  render() {
    return (
    <div className="assm_page1">
      <div className="small-12 columns">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Reports
          </div>
          <div className="small-12 columns box_assm1">
            <div className="row">
              <div className="small-10 columns blue1">
                <ul>
                  <li><a href="/admin/report/exercise_log">Exercise log</a></li>
                </ul>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ReportList)
