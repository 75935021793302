export const fall_history_questions = [
  {
    label: 'In the past <b>two years</b>, have you fallen with or without an injury? Please select the correct response. A fall is defined as an unexpected event in which you have come to rest on the ground, floor or lower level',
    value: 1,
  }, {
    label: 'How many?',
    value: 2,
  }, {
    label: 'Did you have any injuries or serious problems from any falls occurring in the last <b>6 months</b>? Please select the correct response.',
    value: 3,
  }, {
    label: 'How many in the <b>last year</b>?',
    value: 4,
  }, {
    label: 'Did you have any injuries or serious problems from any falls occurring in the <b>last year</b>? Please select the correct response.',
    value: 5,
  },
]

export const fall_history_questions_es = [
  {
    label: '¿En los últimos dos años, se ha caído con o sin lesiones? Por favor seleccione la respuesta correcta. Una caída es definida como un evento inesperado en el cual usted ha terminado en el suelo, piso, o en un nivel bajo.',
    value: 1,
  }, {
    label: '¿Cuántas veces?',
    value: 2,
  }, {
    label: '¿Tuvo alguna lesión o problemas serios debido a la caída(s) en los últimos 6 meses?',
    value: 3,
  }, {
    label: '¿Cuántas caídas tuvo en el último año?',
    value: 4,
  }, {
    label: 'g.	¿Tuvo alguna lesión o problemas serios debido a la caída(s) en el último año?',
    value: 5,
  },
]

export const fall_history_questions_due_to = [
  {
    label: 'Trip, slip or bump',
    sub: 'a',
    value: 1,
  }, {
    label: 'Faint',
    sub: 'b',
    value: 2,
  }, {
    label: 'Sudden drop',
    sub: 'c',
    value: 3,
  }, {
    label: 'Dizzy, room spinning',
    sub: 'd',
    value: 4,
  }, {
    label: 'Just lost balance',
    sub: 'e',
    value: 5,
  },
]

export const fall_history_questions_due_to_es = [
  {
    label: 'Tropiezo, deslizo',
    sub: 'a',
    value: 1,
  }, {
    label: 'Desmayo',
    sub: 'b',
    value: 2,
  }, {
    label: 'Caída súbita',
    sub: 'c',
    value: 3,
  }, {
    label: 'Mareo, habitación dando vueltas',
    sub: 'd',
    value: 4,
  }, {
    label: 'Simple perdida de balance',
    sub: 'e',
    value: 5,
  },
]
