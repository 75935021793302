import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import '../../../styles/assets/css/pages/form1.css'
import { loadLocationWithMessage } from '../../../../../actions/location'
import GetPDF from '../../../../functions/get_pdf.jsx'

class FilePDF extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          title: '',
          pdf: '',
      }
  }

  componentDidMount() {
    var title = ''
  }

  render() {
    return (
      <div className="patient_contract_pdf box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
          Uploaded File
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <GetPDF path="admin_uploaded_file" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
}

const mapDispatchToProps = (dispatch) => ({

})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(FilePDF)
