export const long_term_goal1 = [
  {
    label: 'Goal 1...',
    value: 1,
  }, {
    label: 'Goal 2...',
    value: 2,
  }, {
    label: 'Other',
    value: 10,
  }
]

export const plan_goal1 = [
  {
    label: 'Goal 1...',
    value: 1,
  }, {
    label: 'Goal 2...',
    value: 2,
  }, {
    label: 'Other',
    value: 10,
  }
]

export const commitment_plan_frequency = [
  {
    label: 'Frequency',
    value: 0,
  }, {
    label: 'frequency 1...',
    value: 1,
  }, {
    label: 'frequency 2...',
    value: 2,
  }
]

export const commitment_plan_duration = [
  {
    label: 'Duration',
    value: 0,
  }, {
    label: 'duration 1...',
    value: 1,
  }, {
    label: 'duration 2...',
    value: 2,
  }
]

export const plan_activity = [
  {
    label: 'Type of Activity',
    value: 0,
  }, {
    label: 'activity 1...',
    value: 1,
  }, {
    label: 'activity 2...',
    value: 2,
  }
]

export const exercise_log_mins = [
   {
     label: '10',
     value: 10,
   }, {
     label: '20',
     value: 20,
   }
]

export const exercise_log_intensity = [
   {
     label: 'Intensity 1',
     value: 1,
   }, {
     label: 'Intensity 2',
     value: 2,
   }
]

export const frm_yes_no = [
   {
     label: 'Yes',
     value: 1,
   }, {
     label: 'No',
     value: 2,
   }
]

export const exercise_log_rep = [
   {
     label: '1',
     value: 1,
   }, {
     label: '2',
     value: 2,
   }
]

export const exercise_log_sets = [
   {
     label: '1',
     value: 1,
   }, {
     label: '2',
     value: 2,
   }
]

export const exercise_log_exertion_scale = [
   {
     label: 'Nothing at all',
     value: 0,
   }, {
     label: 'Very, very weak',
     value: 0.5,
   }, {
     label: 'Very weak',
     value: 1,
   }, {
     label: 'Weak',
     value: 2,
   }, {
     label: 'Moderate',
     value: 3,
   }, {
     label: 'Some what Strong',
     value: 4,
   }, {
     label: 'Strong',
     value: 5,
   }, {
     label: '',
     value: 6,
   }, {
     label: 'Very Strong',
     value: 7,
   }, {
     label: '',
     value: 8,
   }, {
     label: '',
     value: 9,
   }, {
     label: 'Very, very Strong',
     value: 10,
   }
]

export const exercise_log_exertion_scale_es = [
   {
     label: 'Para nada',
     value: 0,
   }, {
     label: 'muy muy poco',
     value: 0.5,
   }, {
     label: 'muy poco',
     value: 1,
   }, {
     label: 'poco',
     value: 2,
   }, {
     label: 'moderadamente',
     value: 3,
   }, {
     label: 'Un poco fuerte',
     value: 4,
   }, {
     label: 'Fuerte',
     value: 5,
   }, {
     label: '',
     value: 6,
   }, {
     label: 'Muy fuerte',
     value: 7,
   }, {
     label: '',
     value: 8,
   }, {
     label: '',
     value: 9,
   }, {
     label: 'Muy muy fuerte',
     value: 10,
   }
]

export const revisit_goal1 = [
  {
    label: 'Goal 1...',
    value: 1,
  }, {
    label: 'Goal 2...',
    value: 2,
  }, {
    label: 'Other',
    value: 10,
  }
]
