import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import { tics_questions, tics_questions_22q, tics_q9_words } from '../../../../variables/registration.js'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'
import { SelectLine } from '../../../../shared/select_line'
import { RadioButtonsLine } from '../../../../shared/radio_buttons_line'

//import Tics1 from './tics_1'

class Tics extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        message: '',
        compTxt: '',
        answers: tics_questions.map(() => -1),
        answers12: tics_q9_words.map(() => -1),
        total: 0,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/participant/check_completed_screen_tics',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid, uid: this.props.authReducer.uid }
    })
    .then((result) => {
        if(result['data'].answers !== undefined)
          this.setState({
            answers: result['data'].answers,
            completed_status: result['data'].answers !== undefined
          })
    })
  }

  validateAllField = () => {
    var conc = this.state.answers //.concat(this.state.answers12)
    var answer = conc.findIndex((x) => x === -1) === -1
    return answer
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.validateAllField()) {
        var pid = this.props.match.params.pid
        axios({
          method: 'post',
          url: connectNode + 'admin/participant/add_screen_tics',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            pid: pid,
            user: this.state,
          }
        })
        .then((result) => {
          var message = ''
          var compTxt = ''
          if(result['data'] == pid) {
            this.props.onUpdateTab('4', 'Thank you for submitting TICS!')
            //compTxt = 'Thank you for submitting TICS!'
            //this.getData();
          } else {
            message = 'Something went wrong. Please try again.'
          }
          if(message !== '' || compTxt !== '') {
            this.setState({
              message: message,
              compTxt: compTxt,
            })
            window.scrollTo(0, 0)
          }
        })
    } else {
      this.setState({
        message: 'Please answer all questions.',
      })
      window.scrollTo(0, 0)
    }
  }

  get_value_section = (type, count, index) => {
    const isDisabled = this.state.completed_status === true;
    switch(type) {
      case 'dropdown':
        const itemsMap = Array.from(Array(count + 1).keys()).map(each => ({ id: each, name: each }));
        itemsMap.unshift({ id: -1, name: ' ' });
        return <SelectLine value={this.state.answers[index]}
              name={`answer_${index}`} onChange={(e) => {this.setState({answers: this.state.answers.map((x, xi) => (xi === index ? e.target.value : x))})}} menuItems={itemsMap} disabled={isDisabled} wide noClass noHorizontalPadding />
      case 'radioButton':
        const radioMap = Array.from(Array(count + 1).keys()).map((each) => ({ name: each, value: each, checked: this.state.answers[index] === each }));
        return <RadioButtonsLine items={radioMap} index={index} onChange={(_, i) => this.setState({answers: this.state.answers.map((x, xi) => (xi === index ? i : x))})} disabled={isDisabled} wide noHorizontalPadding />
      default:
        return <div></div>
    }
  }

  handleChangeQ12 = (e, index) => {
    //console.log(index)
    var getVal = this.state.answers12
    getVal[index] = Number(e.target.value)
    this.setState({
      answers12: getVal,
    })
  }

  calculateTotal = () => {
    var conc = this.state.answers //.concat(this.state.answers12)
    var answers = conc.reduce((pv, cv) => cv !== -1 ? pv += cv : pv, 0)
    return answers
  }

  render() {
    console.log(this.state)
    var score_number = []
    for(var i=0; i<=10; i++) { score_number.push(i) }
    const isDisabled = this.state.completed_status === true
    const radioMap = Array.from(Array(2).keys()).map((each) => ({ name: each, value: each }));
    console.log(radioMap)
    return (
    <div className="">
        <div className="headerPage3_1">
          TELEPHONE INTERVIEW FOR COGNITIVE STATUS
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
        { (this.state.message !== '' || this.state.compTxt !== '') &&
        <div className="small-12 columns">
          <div className="row margin-bottom1">
            <div className={(this.state.message !== '' && 'small-12 columns warning_msg6') ||
            ((this.state.compTxt !== '') && 'small-12 columns warning_msg7')} >
              <div className="float_left1">
              {this.state.message !== '' && <WarningIcon />}
              {(this.state.compTxt !== '') && <CheckCircleOutlineIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.state.message || this.state.compTxt }</div>
            </div>
          </div>
        </div>}
        <div className="row">
          <div className="small-12 columns">
            <b>Direction: </b><br />
            1. Explain exam to subject (or patient's caregiver)<br />
            2. Get address<br />
            3. Be sure distractions are minimal (e.g., no T.V. or radio on, remove pens and pencils from reach).<br />
            4. Be sure sources of orientation (e.g. newspapers, calenders) are not in subject's view.<br />
            5. Caregivers may offer reassurance, but not assisstance.<br />
            6. Single repetitions permitted, except for items 5 and 8.
          </div>
          <div className="small-12 columns sep_line1">&nbsp;</div>
        </div>

        {tics_questions.map((each, index) =>
        <div className="row top_space1" key={each.value}>
          <div className="small-12 columns">
            <div className="small-12 medium-5 columns bold_txt2">
            {each.value < 20 && each.value + '. '}
            {each.value == 71 && '7. '}
            {each.value == 91 && '9. '}
            {each.value == 111 && '11. '}
            {each.value == 121 && '12. '}
            {each.label} &nbsp;
            </div>
            <div className="small-12 medium-5 columns">{each.criteria}</div>
            <div className="small-12 medium-2 columns">
              {this.get_value_section(each.type, each.count, index)}
            </div>
          </div>
          {(each.value < 20 || each.value == 74 || each.value == 92 || each.value == 112 || each.value == 1210) && <div className="small-12 columns sep_line1">&nbsp;</div>}
        </div>)}

        {/*<div className="row">
          <div className="small-12 columns">
            <div className="small-12 columns bold_txt2 ">12. {tics_questions_22q[21].label}</div>
            <div className="small-12 columns bottom_space1">
              (Check each correct response)
            </div>
            <div className="small-12 columns bottom_space1 tics_q12">
            {tics_q9_words.map((each, index) =>
              <div className="small-12 medium-6 columns each_q12">
                <div className="q12_label">{each.label}</div>
                <div>
                <FormControl variant="outlined" disabled={isDisabled} wide noHorizontalPadding>
                  {radioMap.map((item, i) =>
                    <div key={"q12_"+index+i} className="each_ans_q12">
                      <div>{item.value}</div>
                      <input type="radio" name={"q12_" + index} value={item.value}
                        disabled={isDisabled} className="q12_radio"
                        checked={item.value == this.state.answers12[index]}
                        onChange={(e) => this.handleChangeQ12(e, index)} />
                    </div>)}
                </FormControl>
                </div>
              </div>
            )}</div>
            <div className="small-12 columns bottom_space1">
            Score = 1 point for each correct response. No penalty for repetitions or intrusions.
            Plurals are considered correct. Record total number of correct responses.
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
          </div>
        </div>*/}

        <div className="">
          <div className="small-12 medium-10 columns text_align_right1 bold">Total</div>
          <div className="small-12 medium-2 columns align-center bold">
          {this.calculateTotal()}
          </div>
          <div className="small-12 columns sep_line1">&nbsp;</div>
        </div>

        <div className="small-12 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Next
          </Button>
        </div>
      </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Tics)
