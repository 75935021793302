import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, Link} from "react-router-dom"
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import MessageIcon from '@material-ui/icons/Message'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import SendIcon from '@material-ui/icons/Send'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import socketIO from 'socket.io-client'
import socket from './chat'
import InfiniteScroll from "react-infinite-scroller"

class Messages extends Component {
  constructor() {
    super()
    this.state = {
      im: [],
      rows: 20,
      message: '',
      text: '',
    }
  }

  componentDidMount() {
    this.getDataList()
    socket.on('messageResponse', msg => {
      //console.log(msg)
      if(msg[0].im_group == this.props.im_group
        || msg[0].im_group == this.state.im_group
        || msg[0].im_group == this.props.match.params.cid) {
          this.setIM(msg)
      }
    })
  }

  componentDidUpdate(prevProps,prevState) {
    console.log(prevProps.im_group + ' :: ' + this.props.im_group)
    if(prevProps.im_group != this.props.im_group) {
      console.log('>> '+this.props.im_group)
      if(this.props.im_group == 0) {
        this.setState({
          im: [],
        })
      } else {
        this.getDataList()
      }
    }
  }

  setIM = (msg) => {
    this.setState({
      im: msg, //[...this.state.im, msg],
    })
    document.getElementById("last_chat").scrollIntoView(false) // { behavior: "smooth", block: "end", inline: "nearest" }
  }

  fetchMoreData = () => {
    if(this.state.im[0].id > this.props.first_chat_rows) {
      setTimeout(() => {
        this.getData(this.state.im[0].id)
      }, 1700)
    }
  }

  getData = (lastID) => {
    var cid = 0
    if(this.props.im_group) {
      cid = this.props.im_group
    } else if(this.state.im_group > 0) {
      cid = this.state.im_group
    } else {
      cid = this.props.match.params.cid
    }
    axios({
      method: 'post',
      url: connectNode + 'participants/messenger/get_conversation_by_group',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
        im_group: cid,
        rows: this.state.rows,
        lastID: lastID,
      }
    })
    .then((result) => {
      this.setState({
        im: result['data'].concat(this.state.im),
      });
    })
  }

  getDataList = () => {
    var cid = 0
    //console.log(this.props.im_group + ' , ' + this.state.im_group + ' , ' + this.props.match.params.cid)
    if(this.props.im_group) {
      cid = this.props.im_group
    } else if(this.state.im_group > 0) {
      cid = this.state.im_group
    } else {
      cid = this.props.match.params.cid
    }
    axios({
      method: 'post',
      url: connectNode + 'participants/messenger/get_conversation_by_group',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
        im_group: cid,
        rows: this.state.rows,
        //lastID: this.props.first_chat_rows,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].length > 0) {
        if(this.props.match.params.cid) {
          this.setState({
            im: result['data'].concat(this.state.im),
            first_chat_rows: this.props.first_chat_rows,
          })
        } else {
          this.setState({
            im: result['data'],
          })
        }
        document.getElementById("last_chat").scrollIntoView(false)
      }
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      message: '',
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    //console.log(this.state)
    if(this.props.im_group == 0 && this.props.p_group.length == 0) {
      this.setState({
        message: "Please add the receivers",
      })
      window.scrollTo(0, 0)
    } else if (this.state.text.trim() == '' || this.state.text.trim() == undefined) {
      var message = ''
      if(this.state.text === '') {
        message = 'Please fill the message'
      }
      this.setState({
        message: message,
      })
      window.scrollTo(0, 0)
    } else {
      if (this.props.match.params.cid > 0 || this.props.im_group > 0
          || this.state.im_group > 0) {
        var cid = 0
        if(this.props.im_group) {
          cid = this.props.im_group
        } else if(this.state.im_group > 0) {
          cid = this.state.im_group
        } else {
          cid = this.props.match.params.cid
        }
        //console.log(connectNode + 'participants/messenger/')
        /*const socket = socketIO.connect('http://localhost:3009',
                        {path: '/hail_study_backend/participants/messenger/chat/socket.io',
                         transports : ['websocket']})*/
        //console.log(socket.id)
        socket.emit('chat_message', {
          cid: cid,
          uid: this.props.authReducer.uid,
          text: this.state.text,
          //clientId: `${Math.random()}`,
          socketID: socket.id,
        });
      } else if(this.props.im_group == 0) {
        var pid = this.props.authReducer.uid
        axios({
          method: 'post',
          url: connectNode + 'participants/messenger/new_message',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            pid: pid,
            p_group: this.props.p_group,
            text: this.state.text,
            im_group: 0 // If not existing conversation //
          }
        })
        .then((result) => {
          this.setState({
            im: result['data'],
            im_group: result['data'][0].im_group,
          })
        })
      }
    }

    this.setState({ text: '' })

    /*if(this.state.text.trim() !== '') {
      var pid = this.props.authReducer.uid
      axios({
        method: 'post',
        url: connectNode + 'participants/messenger/new_message',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: pid,
          p_group: [],
          text: this.state.text,
          im_group: this.state.im.length > 0 ? this.state.im[0].im_group : 0 // If not existing conversation //
        }
      })
      .then((result) => {
        this.setState({
          im: result['data'],
        })
      })
    }

    if(this.state.p_group.length == 0) {
      this.setState({
        message: "Please add the receivers",
      })
      window.scrollTo(0, 0)
    } else {
      var message = ''
      if(this.state.text === '') {
        message = 'Please fill the message'
      }
      this.setState({
        message: message,
      })
      window.scrollTo(0, 0)
    }*/
  }

  render() {
    return (
    <div className="getintouch">
      { this.state.message !== '' &&
      <div className="row margin-bottom1">
        <div className="small-12 columns warning_chat_msg1">
          <div className="float_left1"><WarningIcon /></div>
          <div className="txt_warning_msg1">{ this.state.message }</div>
        </div>
      </div>}
      {this.state.im.length > 0 &&
      <div className="row conversation">
        <div className="conversation_wrapper" >
          <InfiniteScroll
            pageStart={0}
            loadMore={(e) => this.fetchMoreData()}
            hasMore={true}
            loader={
              this.state.im[0].id > this.props.first_chat_rows &&
              <div className="chat_loader" key={0}>
                Loading ...
              </div>
            }
            isReverse={true}
            useWindow={false} >
            {this.state.im.map((each, i) =>
            <div className={(each.uid!=this.props.authReducer.uid && "small-12 columns chat1") ||
                            (each.uid==this.props.authReducer.uid && "small-12 columns chat2")}>
              <div className="row">
                <div className="small-12 columns conv_title1">{each.fullname + " | " + each.submit_datetime}</div>
              </div>
              <div id={i == this.state.im.length-1 && "last_chat"}
                  className={(each.uid!=this.props.authReducer.uid && "chat_content1") ||
                             (each.uid==this.props.authReducer.uid && "chat_content2")}>{each.message}</div>
            </div>)}
          </InfiniteScroll>
        </div>
        {/*<div className="conversation_wrapper">
        {this.state.im.map((each, i) =>
        <div className={(each.uid!=this.props.authReducer.uid && "small-12 columns chat1") ||
                        (each.uid==this.props.authReducer.uid && "small-12 columns chat2")}>
          <div className="row">
            <div className="small-12 columns conv_title1">{each.fullname + " | " + each.submit_datetime}</div>
          </div>
          <div id={i == this.state.im.length-1 && "last_chat"}
              className={(each.uid!=this.props.authReducer.uid && "chat_content1") ||
                         (each.uid==this.props.authReducer.uid && "chat_content2")}>{each.message}</div>
        </div>)}
        </div>*/}
      </div>}
      {this.props.im_group > 0 &&
      <div className="row">
        <div className="small-12 columns sep_line3">&nbsp;</div>
      </div>}
      {(this.props.match.params.cid > 0 || this.props.p_group.length > 0) &&
      <div className="row top_space1">
        <div className="small-10 columns">
        <TextField variant="outlined" type="text" className="chat_txt1"
          placeholder="Enter your message here..." maxLength={500}
          onChange={this.handleChange} value={this.state.text}
          InputProps={{ inputProps: { name: "text" }}} name="text" />
        </div>
        <div className="small-2 columns no-padding-left send_chat1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Send
          </Button>
        </div>
      </div>}
    </div>
  )}
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Messages)
