import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../utils/global'
import { loadLocationWithMessage } from '../../actions/location'
//import imageSignature from '../../../../impact_bd_server/uploads/signatures/patient_contract/signature_57_8.png'

class GetXLSX extends Component {
  constructor(props) {
    super(props)
    this.state = {
      get_file: '',
    }
  }

  componentDidMount() {
    //this.getData()
  }

  getData = () => {
    var pid = this.props.match.params.pid

    var path_get_file = ''
    if(this.props.path == 'get_patient_information_xlsx') {
      path_get_file = 'patient/enroll/get_patient_information_xlsx'
    }

    // get xlsx //
    axios({
      method: 'post',
      url: connectNode + path_get_file,
      headers: {
        'Accept': 'application/json',
        //'Content-Type': 'image/png',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      //responseType: 'base64',
      data: {
        pid: pid,
      },

    })
    .then((result) => {
      const url = window.URL.createObjectURL(new Blob(result.data))
      const link = document.createElement('a')
      link.href = url;
      link.setAttribute('download', 'patient_information_'+pid+'.xlsx')
      document.body.append(link)
      link.click()
    })
  }

  convertBase64ToBlob = (base64Image) => {
    // Split into two parts
    const parts = base64Image.split(';base64,');

    // Hold the content type
    const imageType = parts[0].split(':')[1];

    // Decode Base64 string
    const decodedData = window.atob(parts[1]);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: imageType });
  }

  downloadFile = () => {
    var pid = this.props.match.params.pid
    var path_get_file = ''
    if(this.props.path == 'nia_croms') {
      path_get_file = 'admin/nia/nia_crom1'
    }
    axios({
      method: 'post',
      url: connectNode + path_get_file,
      responseType: 'blob',
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
        var patient = result.data
        let url = window.URL.createObjectURL(new Blob([result.data]))
        let link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'nia_crom_'+Date.now()+'.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      }
    )
  }

  render() {
    return (
      <a onClick={this.downloadFile}>Download NIA CROMS</a>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(GetXLSX)
