import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'
import TextField from '@material-ui/core/TextField'
import ReactHtmlParser from 'react-html-parser'
import { baseline_assm_steps, tp_type } from '../../../../variables/baseline_assm.js'
import { utilization_questions } from '../../../../variables/baseline_utilization.js'
import PatientHead from "../participant_head"

class Utilization extends Component {
  constructor() {
    super()
    this.state = {
        changable: false,
        q1: 0,
        q2a: 0,
        q2b: 0,
        q3a: 0,
        q3b: 0,
        q3c: 0,
        q4a: 0,
        q4b: '',
        q5: 0,
        q6a: 0,
        q6b: '',
        q7a: 0,
        q7b: '',
        created: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/register_participant/get_utilization',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        type_tp: this.props.match.params.type_tp,
        timepoint: this.props.match.params.timepoint,
      }
    })
    .then((result) => {
      console.log(result['data'])
      if(result['data'].uid > 0) {
        this.setState({
          type_tp: this.props.match.params.type_tp,
          timepoint: this.props.match.params.timepoint,
          q1: result['data'].q1,
          q2a: result['data'].q2a,
          q2b: result['data'].q2b,
          q3a: result['data'].q3a,
          q3b: result['data'].q3b,
          q3c: result['data'].q3c,
          q4a: result['data'].q4a,
          q4b: result['data'].q4b,
          q5: result['data'].q5,
          q6a: result['data'].q6a,
          q6b: result['data'].q6b,
          q7a: result['data'].q7a,
          q7b: result['data'].q7b,
          changable: false,
          created: result['data'].created,
        })
      }
    })
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
    <div className="participant1">
        <div className="medium-6 columns headerPage3_1">
          Health Care Utilization [{this.findLabelByValue(tp_type, this.props.match.params.type_tp)}]
        </div>
        <div className="medium-6 columns align-right">
        Completed: {this.state.created}
        </div>
        <div className="small-12 columns bottom_space2">
          <PatientHead />
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns"><b>Visits to Physicians</b></div>
            <div className="small-12 columns top_space1">
            {utilization_questions[0].value}. {ReactHtmlParser(utilization_questions[0].label)}
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">How many visits?</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="number" value={this.state.q1} className="tf_num1"
                disabled={!this.state.changable && 'disabled'}
                InputProps={{ name:'q1', maxLength: 3, inputProps: { min: 0, max: 24 } }} />
                </div>
              </div>
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns"><b>Visits to Mental Health Providers</b></div>
            <div className="small-12 columns top_space1">
            {utilization_questions[1].value}. {ReactHtmlParser(utilization_questions[1].label)}
            </div>
            <div className="small-12 columns">How many visits?</div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">a. Psychiatrist</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="number" value={this.state.q2a} className="tf_num1"
                disabled={!this.state.changable && 'disabled'}
                InputProps={{ inputProps: { name: 'q2a', maxLength: 3 }}} />
                </div>
              </div>
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">b. Psychologist (or other mental health counselor)</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="number" value={this.state.q2b} className="tf_num1"
                disabled={!this.state.changable && 'disabled'}
                InputProps={{ inputProps: { name: 'q2b', maxLength: 3 }}} />
                </div>
              </div>
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns"><b>Visits to Other Providers</b></div>
            <div className="small-12 columns top_space1">
            {utilization_questions[2].value}. {ReactHtmlParser(utilization_questions[2].label)}
            </div>
            <div className="small-12 columns top_space1">How many visits?</div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">a. Nurse practitioner or physician’s assistant</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="number" value={this.state.q3a} className="tf_num1"
                disabled={!this.state.changable && 'disabled'}
                InputProps={{ inputProps: { name: 'q3a', maxLength: 3 }}} />
                </div>
              </div>
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">b. Home health nurse</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="number" value={this.state.q3b} className="tf_num1"
                disabled={!this.state.changable && 'disabled'}
                InputProps={{ inputProps: { name: 'q3b', maxLength: 3 }}} />
                </div>
              </div>
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">c. Physical, occupational, or respiratory therapist</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="number" value={this.state.q3c} className="tf_num1"
                disabled={!this.state.changable && 'disabled'}
                InputProps={{ inputProps: { name: 'q3c', maxLength: 3 }}} />
                </div>
              </div>
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns"><b>Visits to Emergency Room</b></div>
            <div className="small-12 columns top_space1">
            {utilization_questions[3].value}. {ReactHtmlParser(utilization_questions[3].label)}
            </div>
            <div className="small-12 columns top_space1">How many visits?</div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">a. How many times?</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="number" value={this.state.q4a} className="tf_num1"
                disabled={!this.state.changable && 'disabled'}
                InputProps={{ inputProps: { name: 'q4a', maxLength: 3 }}} />
                (Please put in “0” if you have had no visits.)
                </div>
              </div>
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">b. Reason for emergency room visit(s):</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.q4b} className="tf_txt1"
                disabled={!this.state.changable && 'disabled'}
                InputProps={{ inputProps: { name: 'q4b', maxLength: 199 }}} />
                </div>
              </div>
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns"><b>Number of Hospital Stays</b></div>
            <div className="small-12 columns top_space1">
            {utilization_questions[4].value}. {ReactHtmlParser(utilization_questions[4].label)}
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">a. How many times?</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="number" value={this.state.q5} className="tf_num1"
                disabled={!this.state.changable && 'disabled'}
                InputProps={{ inputProps: { name: 'q5', maxLength: 3 }}} />
                (Please put in “0” if you have had no visits.)
                </div>
              </div>
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns"><b>Nights in Hospital</b></div>
            <div className="small-12 columns top_space1">
            {utilization_questions[5].value}. {ReactHtmlParser(utilization_questions[5].label)}
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">a. How many nights?</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="number" value={this.state.q6a} className="tf_num1"
                disabled={!this.state.changable && 'disabled'}
                InputProps={{ inputProps: { name: 'q6a', maxLength: 3 }}} />
                (Please put in “0” if you have had no staying in a hospital overnight.)
                </div>
              </div>
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">b. Reason for hospitalization(s):</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.q6b} className="tf_txt1"
                disabled={!this.state.changable && 'disabled'}
                InputProps={{ inputProps: { name: 'q6b', maxLength: 199 }}} />
                </div>
              </div>
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns"><b>Outpatient Surgeries</b></div>
            <div className="small-12 columns top_space1">
            {utilization_questions[6].value}. {ReactHtmlParser(utilization_questions[6].label)}
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">a. How many surgeries?</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="number" value={this.state.q7a} className="tf_num1"
                disabled={!this.state.changable && 'disabled'}
                InputProps={{ inputProps: { name: 'q7a', maxLength: 3 }}} />
                (Please put in “0” if you have had no surgery.)
                </div>
              </div>
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">b. What kind of outpatient surgery did you have?</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.q7b} className="tf_txt1"
                disabled={!this.state.changable && 'disabled'}
                InputProps={{ inputProps: { name: 'q7b', maxLength: 199 }}} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="small-12 columns back_button1">
          <a href={'/admin/participant_chart/'+this.props.match.params.pid+'/2/1'}>Back</a>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Utilization)
