export const womac_pain_questions = [
  {
    label: 'Walking on a flat surface',
    value: 1,
  }, {
    label: 'Going up or down stairs',
    value: 2,
  }, {
    label: 'At night while  in bed',
    value: 3,
  }, {
    label: 'Sitting or lying',
    value: 4,
  }, {
    label: 'Standing upright',
    value: 5,
  },
]

export const womac_pain_questions_es = [
  {
    label: 'Caminando en suelo plano',
    value: 1,
  }, {
    label: 'Subiendo o bajando escalones',
    value: 2,
  }, {
    label: 'Acostado durante la noche',
    value: 3,
  }, {
    label: 'Sentado o acostado',
    value: 4,
  }, {
    label: 'De pie',
    value: 5,
  },
]

export const womac_pain_scales = [
  {
    label: 'None',
    value: 1,
  }, {
    label: 'Mild',
    value: 2,
  }, {
    label: 'Moderate',
    value: 3,
  }, {
    label: 'Severe',
    value: 4,
  }, {
    label: 'Extreme',
    value: 5,
  },
]

export const womac_pain_scales_es = [
  {
    label: 'Nada',
    value: 1,
  }, {
    label: 'Leve',
    value: 2,
  }, {
    label: 'Moderado',
    value: 3,
  }, {
    label: 'Severo',
    value: 4,
  }, {
    label: 'Extremo',
    value: 5,
  },
]

export const womac_joint_stifness_questions = [
  {
    label: 'How severe is your stiffness after first waking in the morning?',
    value: 1,
  }, {
    label: 'How severe is your stiffness after sitting, lying, or resting later in the day?',
    value: 2,
  },
]

export const womac_joint_stifness_questions_es = [
  {
    label: '¿Que tan severo es su rigidez o endurecimiento al empezar el día en la mañana?',
    value: 1,
  }, {
    label: '¿Que tan severo es su rigidez o endurecimiento después de estar sentado, acostado o reposando, al terminar el día?',
    value: 2,
  },
]

export const womac_joint_stifness_scales = [
  {
    label: 'None',
    value: 1,
  }, {
    label: 'Mild',
    value: 2,
  }, {
    label: 'Moderate',
    value: 3,
  }, {
    label: 'Severe',
    value: 4,
  }, {
    label: 'Extreme',
    value: 5,
  },
]

export const womac_joint_stifness_scales_es = [
  {
    label: 'Nada',
    value: 1,
  }, {
    label: 'Leve',
    value: 2,
  }, {
    label: 'Moderado',
    value: 3,
  }, {
    label: 'Severo',
    value: 4,
  }, {
    label: 'Extremo',
    value: 5,
  },
]

export const womac_physical_function_questions = [
  {
    label: 'Descending stairs (i.e. walking down)',
    value: 1,
  }, {
    label: 'Ascending stairs (i.e. walking up)',
    value: 2,
  }, {
    label: 'Rising from sitting',
    value: 3,
  }, {
    label: 'Standing',
    value: 4,
  }, {
    label: 'Bending to the floor',
    value: 5,
  }, {
    label: 'Walking on a flat surface',
    value: 6,
  }, {
    label: 'Getting in/out of a car',
    value: 7,
  }, {
    label: 'Going shopping',
    value: 8,
  }, {
    label: 'Putting on socks/stockings',
    value: 9,
  }, {
    label: 'Rising from bed',
    value: 10,
  }, {
    label: 'Taking off socks/stockings',
    value: 11,
  }, {
    label: 'Lying in bed',
    value: 12,
  }, {
    label: 'Getting in/out of the bathtub',
    value: 13,
  }, {
    label: 'Sitting',
    value: 14,
  }, {
    label: 'Getting on/off the toilet',
    value: 15,
  }, {
    label: 'Heavy domestic duties',
    value: 16,
  }, {
    label: 'Light domestic duties',
    value: 17,
  },
]

export const womac_physical_function_questions_es = [
  {
    label: 'Bajando escalones (ej., caminando hacia abajo)',
    value: 1,
  }, {
    label: 'Subiendo escalones (ej., caminando hacia arriba)',
    value: 2,
  }, {
    label: 'Pararse después de estar sentado(a)',
    value: 3,
  }, {
    label: 'De Pie',
    value: 4,
  }, {
    label: 'Doblarse hacia el piso',
    value: 5,
  }, {
    label: 'Caminando en suelo plano',
    value: 6,
  }, {
    label: 'Subiéndose o bajándose de un carro',
    value: 7,
  }, {
    label: 'Ir de compras',
    value: 8,
  }, {
    label: 'Poniéndose calcetines o medias',
    value: 9,
  }, {
    label: 'Levantándose de la cama',
    value: 10,
  }, {
    label: 'Quitándose los calcetines o medias',
    value: 11,
  }, {
    label: 'Acostado(a) en la cama',
    value: 12,
  }, {
    label: 'Entrando o saliendo de la bañera',
    value: 13,
  }, {
    label: 'Sentado(a)',
    value: 14,
  }, {
    label: 'Sentándose o parándose de la taza',
    value: 15,
  }, {
    label: 'Trabajos domésticos pesados',
    value: 16,
  }, {
    label: 'Trabajos domésticos livianos',
    value: 17,
  },
]

export const womac_physical_function_scales = [
  {
    label: 'None',
    value: 1,
  }, {
    label: 'Mild',
    value: 2,
  }, {
    label: 'Moderate',
    value: 3,
  }, {
    label: 'Severe',
    value: 4,
  }, {
    label: 'Extreme',
    value: 5,
  },
]

export const womac_physical_function_scales_es = [
  {
    label: 'Nada',
    value: 1,
  }, {
    label: 'Leve',
    value: 2,
  }, {
    label: 'Moderado',
    value: 3,
  }, {
    label: 'Severo',
    value: 4,
  }, {
    label: 'Extremo',
    value: 5,
  },
]
