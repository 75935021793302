import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import Cookies from 'js-cookie'

class Calendar extends Component {
  constructor() {
    super()
    this.state = {
      txt_month: '',
      txt_prev_month: '',
      txt_next_month: '',
      gen_calendar: [],
      existed: []
    }
  }

  componentDidMount() {
    const curr = new Date()
    this.generateCalendar(curr.getMonth() + 1, curr.getFullYear())
  }

  getData = (month, year) => {
    var pid = this.props.authReducer.uid
    axios({
      method: 'post',
      url: connectNode + 'participants/activity/get_exercise_log_month',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: pid,
        month: month,
        year: year,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].length > 0) {
        this.setState({
          existed: result['data']
        })
      }
    })
  }

  weekCount = (year, month_number) => {
    var firstOfMonth = new Date(year, month_number-1, 1)
    var lastOfMonth = new Date(year, month_number, 0)
    var used = firstOfMonth.getDay() + lastOfMonth.getDate()
    return Math.ceil( used / 7)
  }

  generateCalendar = (month, year) => {
    //console.log('=> ' + month + ' , ' + year)
    var prev_month_m = month - 1
    var prev_month_y = year
    if(prev_month_m <= 0) {
      prev_month_m = 12
      prev_month_y -= 1
    }

    var next_month_m = month + 1
    var next_month_y = year
    if(next_month_m > 12) {
      next_month_m = 1
      next_month_y += 1
    }

    const curr = new Date(year, month-1, 1)

    const st_weekDay = curr.getDay()
    //var weekOfMonth = Math.ceil((new Date(year, month + 1, 0).getDate() + new Date(year, month, 1).getDay() - 7) / 7)
    var weekOfMonth = this.weekCount(year, month)
    var daysInMonth = new Date(year, month, 0).getDate()
    var gen_calendar = [[0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0]]

    var cnt_date = 1
    for(var j = st_weekDay; j < 7; j++) {
      gen_calendar[0][j] = cnt_date
      cnt_date++
    }

    for(var i = 1; i <= weekOfMonth; i++) {
      for(var j = 0; j < 7; j++) {
        if(cnt_date <= daysInMonth) {
          gen_calendar[i][j] = cnt_date
          cnt_date++
        }
      }
    }

    this.getData(month, year)

    this.setState({
      month: month,
      txt_month: this.genTxtMonth(month),
      txt_year: year,
      txt_prev_month_m: this.genTxtMonth(prev_month_m),
      prev_month_m: prev_month_m,
      prev_month_y: prev_month_y,
      txt_next_month_m: this.genTxtMonth(next_month_m),
      next_month_m: next_month_m,
      next_month_y: next_month_y,
      gen_calendar: gen_calendar,
    })
  }

  genTxtMonth = (month) => {
    const lang = Cookies.get('lang_')
    var month_of_year = [ '', "January", "February", "March",
      "April", "May", "June", "July", "August",
      "September", "October", "November", "December"]
    if(lang=='es') {
      month_of_year = [ '', "Enero", "Febrero", "Marzo",
        "Abril", "Mayo", "Junio", "Julio", "Agosto",
        "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    }
    return month_of_year[month]
  }

  render() {
    var curr = new Date()
    var curr_date = curr.getDate()
    var curr_month = curr.getMonth() + 1
    var curr_year = curr.getFullYear()
    const lang = Cookies.get('lang_')
    return (
      <div className="box_calendar">
        <div className="box_header1">
          <div className="small-12 medium-6 columns">
          {(lang=='en' && 'Exercise Log') || lang=='es' && 'Registro de Ejercicio'}: {this.state.txt_month + ' ' + this.state.txt_year}
          </div>
          <div className="small-12 medium-6 columns text_explain1">
            <span className="incomplete_log2">{(lang=='en' && 'Yellow: incomplete') || lang=='es' && 'Amarillo: Incompleto'}</span> ,&nbsp;
            <span className="finished_log2">{(lang=='en' && 'Green: completed') || lang=='es' && 'Verde: Completo'}</span>
          </div>
        </div>
        <div className="">
          <table className="calendar_table">
          <thead>
            <tr className="">
              <th>{(lang=='en' && 'Sun') || lang=='es' && 'Dom'}</th>
              <th>{(lang=='en' && 'Mon') || lang=='es' && 'Lun'}</th>
              <th>{(lang=='en' && 'Tue') || lang=='es' && 'Mar'}</th>
              <th>{(lang=='en' && 'Wed') || lang=='es' && 'Mier'}</th>
              <th>{(lang=='en' && 'Thu') || lang=='es' && 'Jue'}</th>
              <th>{(lang=='en' && 'Fri') || lang=='es' && 'Vier'}</th>
              <th>{(lang=='en' && 'Sat') || lang=='es' && 'Sab'}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.gen_calendar &&
              this.state.gen_calendar.map(week =>
              <tr key={'w'+week}>
                {week.map((day, index) =>
                  <td key={'c'+week+day+index} className={this.state.existed.includes(day+0) && 'finished_log'} >
                    {((day <= curr_date && this.state.month == curr_month && this.state.txt_year == curr_year) ||
                     (this.state.month < curr_month && this.state.txt_year == curr_year) ||
                     this.state.txt_year < curr_year) &&
                    <a href={'/member/exercise_log/'+this.state.month+'/'+day+'/'+this.state.txt_year}>
                      {(day > 0 && day) || (day == 0 && '')}</a>}
                      {((day > curr_date && this.state.month == curr_month && this.state.txt_year == curr_year) ||
                       (this.state.month > curr_month && this.state.txt_year == curr_year) ||
                       this.state.txt_year > curr_year) && (day > 0 && day) || (day == 0 && '')}
                  </td>
                )}
              </tr>
            )}
          </tbody>
          </table>
        </div>
        <div className="row change_month">
          <div className="small-6 columns align-left">
            <a href="!#" onClick={(e) => {e.preventDefault(); this.generateCalendar(this.state.prev_month_m, this.state.prev_month_y)}}>
            {'<< ' + this.state.txt_prev_month_m}</a>
          </div>
          <div className="small-6 columns align-right">
            <a href="!#" onClick={(e) => {e.preventDefault(); this.generateCalendar(this.state.next_month_m, this.state.next_month_y)}}>
            {this.state.txt_next_month_m + ' >>'}</a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
