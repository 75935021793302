import React from "react";
import WarningIcon from '@material-ui/icons/Warning'


export const WarningMessages = (props) => {
  return props.messages && props.messages.length > 0 ? props.messages.map((x, index) =>
  (<div key={`warning_${index}`} style={props.yellow ? {color: "#eed202"} : undefined} className="row margin-bottom1">
    <div style={props.yellow ? {color: "#eed202", borderColor: "#eed202"} : undefined} className="small-12 columns warning_msg6">
      <div className="float_left1"><WarningIcon style={props.yellow ? {color: "#eed202"} : undefined} /></div>
      <div className="txt_warning_msg1">{x}</div>
    </div>
  </div>)) : <div></div>
};