import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
//import '../../../styles/assets/css/pages/dashboard.css'
import '../../../styles/assets/css/pages/graph.css'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { NavLink } from "react-router-dom"
import { FiSettings } from 'react-icons/fi'
import { VictoryChart, VictoryLine, VictoryTheme, VictoryAxis, VictoryScatter, VictoryGroup } from 'victory'

class Graph2 extends Component {
  constructor() {
    super()
    this.state = {
      checked: false,
      getData:[],
      scale: 0,
    }
  }

  componentDidMount() {
    this.getDataList()
  }

  componentWillReceiveProps(nextProps) {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'participants/activity/get_exercise_log_by_week',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
      }
    })
    .then((result) => {
        var buff = []
        var cnt = 0
        result['data'].map(each => {
          cnt += each.cnt
          buff.push({
            x: this.convertDate(each.FirstDayOfWeek) + '-' + this.convertDate(each.LastDayOfWeek),
            y: cnt,
          })
        })
        this.setState({
          getData: buff,
          scale: cnt,
        })
        //console.log(buff)
    })
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  genArr1(start, num) {
    var a = []
    for(var i=start; i<=num; i++) { a[i] = i }
    return a
  }

  convertDate(getDate) {
    var buff = getDate.split("-")
    return buff[1]+'/'+buff[2] //+'/'+buff[0]
  }

  getXaxis() {
    var length = 0
    if(this.state.graph_type == 1) {
      length = this.state.tracker_day.length
    } else if(this.state.graph_type == 2) {
      length = this.state.tracker_week.length
    }
    return length
  }

  render() {
    return (
    <div className="box_calendar">
      <div className="box_header1">
        <div className="small-12 medium-12 columns">
        Graph
        </div>
      </div>
      <div className="">
      <div className="small-12 columns axis-title">
        Completed exercise logs
      </div>
      <div className="small-12 columns box_space_top1 pt_graph">
        <div className="row">
          <div className="small-12 columns">
            <VictoryChart theme={VictoryTheme.material}
              padding={{ top: 5, bottom: 15, left: 15, right: 15 }}
              domainPadding={{y: this.state.scale/5}} height={90} >
              <VictoryAxis crossAxis
                tickValues={this.genArr1(0, 19)}
                style={{
                  axis: {stroke: "#cccccc", fontSize: 5},
                  axisLabel: {fontSize: 5, padding: 10},
                  ticks: {stroke: "#cccccc", size: 1},
                  tickLabels: {fontSize: 5, padding: 2, angle: 0},
                }}
              />
              {/*label="Completed exercise logs"*/}
            <VictoryAxis dependentAxis crossAxis
                tickValues={this.state.scale}
                tickCount={5}
                style={{
                  axis: {stroke: "#cccccc", fontSize: 5},
                  axisLabel: {fontSize: 5, padding: 0},
                  ticks: {stroke: "#cccccc", size: 1},
                  tickLabels: {fontSize: 5, padding: 5}
                }}
              />
              <VictoryGroup data={this.state.getData} color="#ffac29" >
            <VictoryLine
              domain={{x: [0, 7], y: [0, 6]}}
              style={{
                data: { stroke: "#ffac29", strokeWidth: 0.5 },
                parent: { border: "1px solid #cccccc"}
              }}
              data={this.state.getData}
            />
            <VictoryScatter size={1.5} symbol="dot"/>
            </VictoryGroup>
            </VictoryChart>
          </div>
        </div>
      </div>
      </div>
    </div>
  )}
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Graph2)
