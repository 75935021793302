import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/dashboard.css'
import BannerDashboard from './banner'
import Greeting from './greeting'
import OnlineClasses from './onlineClasses'
import Calendar from './calendar'
import FocusBlog from './focus_blog'
import Graph from './graph'
import Graph2 from './graph2'
import axios from 'axios'
import { connectNode } from '../../../utils/global'

class Dashboard extends Component {
  constructor() {
    super()
    this.state = {
      update_graph: 0,
      p_status: 0,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/focus_blog/get_focus_blog',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid }
    })
    .then((result) => {
      var g_status = 2
      if(result['data']) {
        g_status = result['data'].status
      }
      this.setState({
        p_status: g_status,
      })
    })
  }

  render() {
    return (
    <div className="participant_dashboard">
      <div className="small-12 columns db_nopadding_medium">
        <BannerDashboard />
      </div>
      <div className="small-12 columns db_nopadding_medium" data-equalizer="foo" >
        <div className={(this.state.p_status == 2 && "small-12 medium-6 columns box_greeting")
                    || (this.state.p_status == 1 && "small-12 medium-4 columns box_greeting2")}
             data-equalizer-watch="foo">
            <Greeting />
        </div>
        {this.state.p_status == 1 &&
        <div className="small-12 medium-4 columns box_greeting2 space_mid_1" data-equalizer-watch="foo">
          <FocusBlog />
        </div>}
        <div className={(this.state.p_status == 2 && "small-12 medium-6 columns box_greeting")
                    || (this.state.p_status == 1 && "small-12 medium-4 columns box_greeting2")}
             data-equalizer-watch="foo">
            <OnlineClasses />
        </div>
      </div>
      <div className="small-12 columns db_nopadding_medium">
        <Calendar />
      </div>
      <div className="small-12 columns db_nopadding_medium">
        <Graph />
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
