import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import { exercise_log_mins, exercise_log_rep, exercise_log_sets,
  exercise_log_exertion_scale, frm_yes_no} from '../../../../variables/participant_contract.js'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'
import { ControlSection } from '../../../../shared/control_section'
import { TextFieldLine } from '../../../../shared/text_field_line'
import { SelectLine } from '../../../../shared/select_line'
import { FormSection } from '../../../../shared/form_section'
import { Line } from '../../../../shared/line'

class ExerciseLogDetail extends Component {
  constructor() {
    super()
    this.quill = require('react-quill')
    this.state = {
        completed: false,
        status_submit: true,
        message: '',
        flexibility_mins: 0,
        flexibility_mins_values: [],
        aerobic_activity: '',
        aerobic_activity_values: [],
        aerobic_activity_other: '',
        aerobic_mins: 0,
        aerobic_intensity: 0,
        aerobic_intensity_other: '',
        strength_upper_body: 0,
        strength_upper_body_reps: 0,
        strength_upper_body_sets: 0,
        strength_upper_body_band: 0,
        strength_lower_body: 0,
        strength_lower_body_reps: 0,
        strength_lower_body_sets: 0,
        strength_lower_body_band: 0,
        exertion_scale: -1,
        exercise_log_intensity: []
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    var pid = this.props.match.params.pid
    var get_date = new Date(this.props.match.params.year, this.props.match.params.month, this.props.match.params.day)
    console.log(pid+': '+("0" + get_date.getMonth()).slice(-2) + '/' + ("0" + get_date.getDate()).slice(-2) + '/' + this.props.match.params.year)
    axios({
      method: 'post',
      url: connectNode + 'participants/activity/get_exercise_log',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: pid,
        date_log: ("0" + get_date.getMonth()).slice(-2) + '/' + ("0" + get_date.getDate()).slice(-2) + '/' + this.props.match.params.year,
      }
    })
    .then((result) => {
      console.log(result['data'])
      this.setState({
        exercise_log_intensity: result['data'].exercise_log_intensity,
        aerobic_activity_values: result['data'].aerobic_activity_values,
        flexibility_mins_values: result['data'].mins_exercise
      })
      if(result['data'].uid == pid) {
        this.setState({
            flexibility_mins: result['data'].flexibility_mins,
            aerobic_activity: result['data'].aerobic_activity,
            aerobic_activity_other: result['data'].aerobic_activity_other,
            aerobic_mins: result['data'].aerobic_mins,
            aerobic_intensity: result['data'].aerobic_intensity,
            aerobic_intensity_other: result['data'].aerobic_intensity_other,
            strength_upper_body: result['data'].strength_upper_body,
            strength_upper_body_reps: result['data'].strength_upper_body_reps,
            strength_upper_body_sets: result['data'].strength_upper_body_sets,
            strength_upper_body_band: result['data'].strength_upper_body_band,
            strength_lower_body: result['data'].strength_lower_body,
            strength_lower_body_reps: result['data'].strength_lower_body_reps,
            strength_lower_body_sets: result['data'].strength_lower_body_sets,
            strength_lower_body_band: result['data'].strength_lower_body_band,
            exertion_scale: result['data'].exertion_scale,
            completed: true,
          })
      }
    })
  }

  render() {
    const isDisabled = this.state.completed;
    const aerobicActivityMap = this.state.aerobic_activity_values.map(each => ({ id: each.vid, name: each.name }));
    const aerobicIntensityMap = this.state.exercise_log_intensity.map(each => ({ id: each.vid, name: each.name }));
    const minutesMap = Array.from(Array(101).keys()).map(each => ({ id: each, name: each })); // this.state.flexibility_mins_values.map(each => ({ id: each.vid, name: each.name }));
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const repsMap = Array.from(Array(31).keys()).map(each => ({ id: each, name: each }));
    const setsMap = Array.from(Array(21).keys()).map(each => ({ id: each, name: each }));
    repsMap.shift();
    setsMap.shift();

    var get_date = new Date(this.props.match.params.month+'/'+this.props.match.params.day+'/'+this.props.match.params.year)
    return (
    <div className="assm_page1">
        <div className="headerPage1">
          Exercise Log: {weekday[get_date.getDay()]} {("0"+this.props.match.params.month).slice(-2)}/{("0" + this.props.match.params.day).slice(-2)}/{this.props.match.params.year}
        </div>
        <div className="small-12 columns box_assm1 more_padding1 display_flex">
          <ControlSection label="Stretching / Flexibility">
            <SelectLine label="Minutes" value={this.state.flexibility_mins}
              name="flexibility_mins" onChange={this.handleChange} menuItems={minutesMap} disabled={isDisabled} />
          </ControlSection>

          <ControlSection label="Aerobic" withLine>
            <SelectLine label="Activity" value={this.state.aerobic_activity} disabled={isDisabled}
              name="aerobic_activity" onChange={this.handleChange} menuItems={aerobicActivityMap} />
            {this.state.aerobic_activity == 99 &&
              <TextFieldLine label="Activity Other" onChange={this.handleChange}
                value={this.state.aerobic_activity_other} disabled={isDisabled}
                name="aerobic_activity_other" maxLength={198} />}
            <SelectLine label="Minutes" value={this.state.aerobic_mins} disabled={isDisabled}
              name="aerobic_mins" onChange={this.handleChange} menuItems={minutesMap} />
            <SelectLine label="Intensity" value={this.state.aerobic_intensity} disabled={isDisabled}
                name="aerobic_intensity" onChange={this.handleChange} menuItems={aerobicIntensityMap} />
          </ControlSection>

          <div className="bold space_bottom1">Strength</div>
          <div className="row">
            <div className="medium-1 columns show-for-medium">&nbsp;</div>
            <div className="small-12 medium-7 columns box_assm3 float_left">
              <div className="small-12 medium-3 columns title1">Upper Body</div>
              <div className="small-12 medium-9 columns top_space1">
              {frm_yes_no.map(each =>
                <div className="small-6 columns float_left" key={'sub'+each.value}>
                  <input type="radio" name="strength_upper_body" value={each.value}
                  disabled={this.state.completed && 'disabled'}
                  checked={this.state.strength_upper_body == each.value && 'checked'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span>{each.label}</span>
                </div>)}
              </div>

              {this.state.strength_upper_body == 1 &&
              <div>
                <div className="small-12 columns sep_line4">&nbsp;</div>
                <div style={{marginTop: '1.3rem'}} className="small-3 medium-3 columns title1">Sets</div>
                <div style={{width: '35%'}} className="small-9 medium-9 columns top_space1">
                  <SelectLine value={this.state.strength_upper_body_sets}
                        name="strength_upper_body_sets" onChange={this.handleChange} menuItems={setsMap} wide noClass noHorizontalPadding disabled={this.state.completed}/>
                </div>
                <div className="small-12 columns sep_line4">&nbsp;</div>
                <div style={{marginTop: '1.3rem'}} className="small-3 medium-3 columns title1">Reps</div>
                <div style={{width: '35%'}} className="small-9 medium-9 columns top_space1">
                  <SelectLine value={this.state.strength_upper_body_reps}
                        name="strength_upper_body_reps" onChange={this.handleChange} menuItems={repsMap} wide noClass noHorizontalPadding disabled={this.state.completed} />
                </div>
                <div className="small-12 columns sep_line4">&nbsp;</div>
                <div className="small-12 medium-3 columns title1 top_space1">Band</div>
                <div className="small-12 medium-9 columns top_space1">
                {frm_yes_no.map(each =>
                  <div className="small-6 columns float_left" key={'subb'+each.value}>
                    <input type="radio" name="strength_upper_body_band" value={each.value}
                    disabled={this.state.completed && 'disabled'}
                    checked={this.state.strength_upper_body_band == each.value && 'checked'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span>{each.label}</span>
                  </div>)}
                </div>
              </div>}

            </div>
          </div>

          <div className="row top_space1">
            <div className="medium-1 columns show-for-medium">&nbsp;</div>
            <div className="small-12 medium-7 columns box_assm3 float_left">
              <div className="small-12 medium-3 columns title1">Lower Body</div>
              <div className="small-12 medium-9 columns top_space1">
              {frm_yes_no.map(each =>
                <div className="small-6 columns float_left" key={'slb'+each.value}>
                  <input type="radio" name="strength_lower_body" value={each.value}
                  disabled={this.state.completed && 'disabled'}
                  checked={this.state.strength_lower_body == each.value && 'checked'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span>{each.label}</span>
                </div>)}
              </div>
              {this.state.strength_lower_body == 1 &&
              <div>
                <div className="small-12 columns sep_line4">&nbsp;</div>
                <div style={{marginTop: '1.3rem'}} className="small-3 medium-3 columns title1">Sets</div>
                <div style={{width: '35%'}} className="small-9 medium-9 columns top_space1">
                  <SelectLine value={this.state.strength_lower_body_sets}
                        name="strength_lower_body_sets" onChange={this.handleChange} menuItems={setsMap} wide noClass noHorizontalPadding disabled={this.state.completed}/>
                </div>
                <div className="small-12 columns sep_line4">&nbsp;</div>
                <div style={{marginTop: '1.3rem'}} className="small-3 medium-3 columns title1">Reps</div>
                <div style={{width: '35%'}} className="small-9 medium-9 columns top_space1">
                  <SelectLine value={this.state.strength_lower_body_reps}
                        name="strength_lower_body_reps" onChange={this.handleChange} menuItems={repsMap} wide noClass noHorizontalPadding disabled={this.state.completed}/>
                </div>
                <div className="small-12 columns sep_line4">&nbsp;</div>
                <div className="small-12 medium-3 columns title1 top_space1">Band</div>
                <div className="small-12 medium-9 columns top_space1">
                {frm_yes_no.map(each =>
                  <div className="small-6 columns float_left" key={'slbb'+each.value}>
                    <input type="radio" name="strength_lower_body_band" value={each.value}
                    disabled={this.state.completed && 'disabled'}
                    checked={this.state.strength_lower_body_band == each.value && 'checked'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span>{each.label}</span>
                  </div>)}
                </div>
            </div>}
            </div>
          </div>

          <div className="small-12 columns sep_line3">&nbsp;</div>

          <div className="small-12 columns title2">Perceived Rate of Exertion Scale</div>
          <div className="small-12 columns top_space1 radio_scale1">
            {exercise_log_exertion_scale.map(each =>
            <div className="small-12 medium-1 columns float_left label_scale1" key={'eles'+each.value}>
              <input type="radio" name="exertion_scale" value={each.value}
              disabled={this.state.completed && 'disabled'}
              checked={this.state.exertion_scale == each.value && 'checked'}
              onChange={(e) => this.handleChange(e)}/>
              <span >{each.label}</span>
            </div>)}
          </div>

        </div>
        <div className="small-6 columns back_button1 float_left">
          <a href={"/admin/participant_chart/"+this.props.match.params.pid+"/4/1"}>Back</a>
        </div>
        {!this.state.completed &&
        <div className="small-6 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Submit
          </Button>
        </div>}
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ExerciseLogDetail)
