import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, Link} from "react-router-dom"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Pagination from '../../functions/pagination'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import '../../../styles/assets/css/admin/template.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import { yes_no } from '../../variables/registration.js'
import { filter_participant_status } from '../../variables/admin_participant.js'

class ParticipantList extends Component {
  constructor() {
      super();
      //var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
      this.state = {
          dataItems: [], //exampleItems,
          pageOfItems: [],
          show_filter_status: 0,
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/register_participant/get_participant_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        show_filter_status: this.state.show_filter_status,
      }
    })
    .then((result) => {
      if(result['data'].length > 0) {
        this.setState({
          dataItems: result['data'],
        })
      } else {
        this.setState({
          dataItems: [],
        })
      }
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    }, () => {
      this.getDataList()
    })
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    //console.log(this.state)
    return (
    <div className="assm_page1">
      <div className="row">
        <div className="small-7 columns headerPage1">
          Participants
        </div>
        <div className="small-5 columns space_button1">
          <FormControl variant="outlined" className="filter_p_status">
            <Select value={this.state.show_filter_status} variant="outlined" label=""
              onChange={(e) => this.handleChange2(e, 'show_filter_status')}
              value={this.state.show_filter_status} >
            {filter_participant_status.map(each =>
              <MenuItem key={'apt'+each.value} value={each.value}>{each.label}</MenuItem>
            )}
            </Select>
          </FormControl>
          <NavLink to='/admin/add_participant' className="menu_list_active new_bttn no_margin_top">
            New Participant
          </NavLink>
        </div>
      </div>
      <div className="row">
          <div className="small-12 columns ">
            { typeof this.props.locationReducer.txt_message !== 'undefined' &&
              this.props.locationReducer.txt_message !== '' &&
              <div className="warning_msg3">
                <div className="float_left1"><CheckCircleOutlineIcon /></div>
                <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
              </div>
            }
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Consented</th>
                    <th>Eligible</th>
                    <th>Group</th>
                    <th>Status</th>
                    <th>Created</th>
                  </tr>
                </thead>
                <tbody>
                {this.state && this.state.dataItems && this.state.pageOfItems.map(item =>
                <tr key={item.uid}>
                  <td>{item.uid}</td>
                  <td>
                  <Link to={'/admin/participant_chart/'+item.uid+'/1/1'} >
                    {item.fullname}
                  </Link></td>
                  <td>{this.findLabelByValue(yes_no, item.consent)}</td>
                  <td>{this.findLabelByValue(yes_no, item.eligible)}</td>
                  <td>{item.group}</td>
                  <td>{item.pt_status_name}</td>
                  <td>{item.created}</td>
                </tr>)}

              {this.state && this.state.dataItems &&
                <tr className="pagination_block">
                  <td colSpan="9">
                    <Pagination items={this.state.dataItems} pageSize={20} onChangePage={this.onChangePage} />
                  </td>
                </tr>
              }
              </tbody>
              </table>
            </div>
            <div>
            <b>Notes</b><br />
            - Initial contact: Contacted about study<br />
            - Consented<br />
            - Active: After finishing registration
            </div>
          </div>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantList)
