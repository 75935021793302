import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import '../../../styles/assets/css/pages/form1.css'
import { loadLocationWithMessage } from '../../../actions/location'
import GetPDF from '../../functions/get_pdf.jsx'

class ParticipantContractPDF extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          title: '',
          pdf: '',
          type_id: 0,
      }
  }

  componentDidMount() {
    var type_id = this.getTypeID()
    var title = ''
    if(type_id == 1) {
      title = 'Commitment to Class and Practice Sessions'
    } else if(type_id == 2) {
      title = 'Individual plan for maintaining an active lifestyle'
    } else if(type_id == 3) {
      title = 'Revisiting your goals from the beginning of the program'
    }
    this.setState({
      title: title,
      type_id: type_id,
    })
  }

  getTypeID = () => {
    var type_id = 0
    if(this.props.match.params.type_id > 0) {
      type_id = this.props.match.params.type_id
    }
    if(this.props.type_id != null) {
      type_id = this.props.type_id
    }
    return type_id
  }

  render() {
    var type_id = this.getTypeID()
    return (
      <div className="patient_contract_pdf box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
          {this.state.title}
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <GetPDF path="patient_contract_pdf" type_id={type_id} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
}

const mapDispatchToProps = (dispatch) => ({

})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ParticipantContractPDF)
