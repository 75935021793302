export const utilization_questions = [
  {
    label: 'During the <b>past 6 months</b>, did you visit any physician other than a psychiatrist? (Please put in “0” if you have had no visits. Please do not include visits while in a hospital.).',
    value: 1,
  }, {
    label: 'During the <b>past 6 months</b>, did you visit any of the following health professionals? (Please put in “0” if you have had no visits. Please do not include visits while in a hospital.).',
    value: 2,
  }, {
    label: 'During the <b>past 6 months</b>, did you visit any of the following health professionals? (Please put in “0” if you have had no visits. Please do not include visits while in a hospital.).',
    value: 3,
  }, {
    label: 'How many times did you visit the emergency room in the <b>past 6 months</b>?',
    value: 4,
  }, {
    label: 'How many different times did you stay in a hospital overnight or longer in the <b>past 6 months</b>?',
    value: 5,
  }, {
    label: '. How many total nights did you stay in a hospital overnight in the <b>past 6 months</b>?',
    value: 6,
  }, {
    label: 'In the <b>past 6 months</b>, how many times did you have outpatient surgery (surgery where you did not stay overnight in the hospital?',
    value: 7,
  },
]

export const utilization_questions_es = [
  {
    label: '¿Durante los últimos 6 meses, visitó un doctor que no fuese un psiquiatra? (Por favor ponga “0” si no tuvo visitas. Por favor no incluya visitas mientras estaba en el hospital.)',
    value: 1,
  }, {
    label: '¿Durante los últimos 6 meses, visito alguno de los siguientes profesionales de la salud? (Por favor ponga “0” si no tuvo visitas. Por favor no incluya visitas mientras estaba en el hospital.)',
    value: 2,
  }, {
    label: '¿Durante los últimos 6 meses, visito alguno de los siguientes profesionales de la salud? Por favor ponga “0” si no tuvo visitas. Por favor no incluya visitas mientras estaba en el hospital.)',
    value: 3,
  }, {
    label: '¿Cuántas veces visitó la sala de emergencias en los últimos 6 meses?',
    value: 4,
  }, {
    label: '¿Cuántas veces se quedó en el hospital durante la noche o más en los últimos 6 meses?',
    value: 5,
  }, {
    label: '¿En los últimos 6 meses, cuantas veces tuvo operaciones ambulatorias (operaciones donde no se quedó en el hospital durante la noche)?',
    value: 6,
  }, {
    label: '¿Qué tipo de operación ambulatoria tuvo? ',
    value: 7,
  },
]

export const utilization_none_how_many = [
  {
    label: 'None',
    value: 1,
  }, {
    label: 'How many times?',
    value: 2,
  },
]
