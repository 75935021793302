import React, { Component } from 'react'
import { connect } from 'react-redux'
import AuthService from '../../services/auth/auth.service.js'
import { logoutUser } from '../../actions/auth'
import { Link } from "react-router-dom"

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      route: '',
      message: '',
    }
  }

  onReloadPages = () => {
    this.props.onLoadPages()
  }

  render() {
    var url_origin = window.location.origin
    return (
      <div className="footer">
        <div className="wrapper row">
          <div className="small-12 medium-5 columns align-left footer_link">
            <a>ABOUT</a> &nbsp; | &nbsp; <a>CONTACT</a>
          </div>
          <div className="small-12 medium-7 columns align-right copyright">
            Copyright © 2022, Partners HealthCare System, Inc.  All rights reserved.
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { uid: state.authReducer.uid }
}

export default connect(mapStateToProps)(Footer)
