import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import '../../../styles/assets/css/pages/assistants.css'
import Cookies from 'js-cookie'

class Assistants extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
    }
  }

  render() {
    const lang = Cookies.get('lang_')
    return (
    <div className="assm_page1">
        <div className="headerPage1">
          {(lang=='en' && 'Do you need help?') || lang=='es' && 'Necesitas ayuda?'}
        </div>
        <div className="small-12 columns box_assm1">
          <div className="row">
            <div className="small-12 columns top_space1">
              <div className="blue1">
                {(lang=='en' && 'Louisa Sylvia, Ph.D. and Ana-Maria Vranceanu, Ph.D. are the persons in charge of this research study.')
                || lang=='es' && 'Louisa Sylvia, Ph.D. es la persona en cargo de este estudio.'}
              </div>
              <ul className="help_step1_button top_space1 icon_grey1">
                <li>{(lang=='en' && 'You can reach Dr. Sylvia at 617-643-4804 Mondays through Fridays, 9 am – 5 pm.') || lang=='es' && 'Usted puede comunicarse con Dr. Sylvia llamando al 617-643-4804 de Lunes a Viernes, 9 am – 5 pm.'}</li>
                <li>{(lang=='en' && 'If you need help using this website or have any logistical questions, please call Antonietta Alvarez Hernandez (617-634-4806 / AALVAREZHERNANDEZ@mgh.harvard.edu) or Sofia Montinola (617-634-4806 / amontinola@mgh.harvard.edu).') || lang=='es' && 'Si necesita ayuda utilizando este sitio web o tiene preguntas logísticas, por favor llame a Antonietta Alvarez Hernandez al 617-634-4806 / AALVAREZHERNANDEZ@mgh.harvard.edu o a Sofia Montinola (617-634-4806 / amontinola@mgh.harvard.edu).'}</li>
                <li><a href="../HAIL_Study_Cheat_Sheet.pdf" target="_blank">{(lang=='en' && 'HAIL study cheat sheet') || lang=='es' && 'Estudio HAIL hoja de referencia y ayuda'}</a></li>
              </ul>
            </div>

          </div>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Assistants)
