import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/list1.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'

import ExerciseLog from "./exercise/exercise_log"

class TabExercise extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          tab_status: '1',
          uid: 0,
          pid: 0,
          doctor: 0,
          type: 0,
          date: '',
          time: '',
          duration: 0,
          patient_status: 0,
      }
  }

  componentDidMount() {
    if(this.props.subTab > 0) {
      this.setState({
        tab_status: this.props.subTab,
      })
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tab_status: newValue,
    })
  }

  handleUpdateTab = (step, txt) => {
    this.setState({
      tab_status: step,
      message: txt,
    })
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="">
        <div className="row">
          <div className="small-12 columns tab_data1 tab_patient">
            <TabContext value={this.state.tab_status}>
              <AppBar position="static">
                <TabList onChange={this.handleTabChange} aria-label="simple tabs example">
                  <Tab label="Exercise Log" value="1" />
                </TabList>
              </AppBar>
              <TabPanel value="1" index={1} ><ExerciseLog onUpdateTab={this.handleUpdateTab} /></TabPanel>
            </TabContext>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TabExercise)
