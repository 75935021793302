export const who5_questions = [
  {
    label: 'I have felt cheerful and in good spirits',
    value: 1,
  }, {
    label: 'I have felt calm and relaxed',
    value: 2,
  }, {
    label: 'I have felt active and vigorous',
    value: 3,
  }, {
    label: 'I woke up feeling fresh and rested',
    value: 4,
  }, {
    label: 'My daily life has been filled with things that interest me',
    value: 5,
  },
]

export const who5_questions_es = [
  {
    label: 'Mehe sentido alegre y de buen humor',
    value: 1,
  }, {
    label: 'Mehe sentido tranquilo y relajado',
    value: 2,
  }, {
    label: 'Mehe sentido activo y enérgico',
    value: 3,
  }, {
    label: 'Mehe despertado fresco y descandado',
    value: 4,
  }, {
    label: 'Mi vida cotidiana ha estado llena de cosas que me interesan',
    value: 5,
  },
]

export const who5_scale = [
  {
    label: 'All of the time',
    value: 5,
  }, {
    label: 'Most of the time',
    value: 4,
  }, {
    label: 'More than half of the time',
    value: 3,
  }, {
    label: 'Less than half of the time',
    value: 2,
  }, {
    label: 'Some of the time',
    value: 1,
  }, {
    label: 'At no time',
    value: 0,
  },
]

export const who5_scale_es = [
  {
    label: 'Todo el tiempo',
    value: 5,
  }, {
    label: 'La mayor parte del tiempo',
    value: 4,
  }, {
    label: 'Más de la mitad del tiempo',
    value: 3,
  }, {
    label: 'Menos de la mitad del tiempo',
    value: 2,
  }, {
    label: 'De vez en cuando',
    value: 1,
  }, {
    label: 'Nunca',
    value: 0,
  },
]
