import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { NavLink} from "react-router-dom"
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import { join_class_status } from '../../variables/admin_participant.js'

class EventParticipantList extends Component {
  constructor() {
    super()
    this.state = {
      status: false,
      status_submit: true,
      completed_status: false,
      message: '',
      compTxt: '',
      group: 0,
      name: '',
      class_date: '',
      start_time: '',
      end_time: '',
      participants: [],
      cnt_exist: 0,
    }
  }

  componentDidMount() {
    this.getDataList(1)
  }

  getDataList = (gid) => {
    var pid = this.props.authReducer.uid
    var cid = this.props.match.params.cid
    var gid = this.props.match.params.gid
    axios({
      method: 'post',
      url: connectNode + 'admin/events/get_class_participants_info',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        cid: cid,
        gid: gid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      var completed_status = false
      if(result['data'][0].c_status > 0) {
        completed_status = true
      }
      var cnt_exist = 0
      if(result['data'][0].fullname) {
        cnt_exist = 1
      }
      this.setState({
        group: result['data'][0].gid,
        name: result['data'][0].class_name,
        class_date: result['data'][0].class_date,
        start_time: result['data'][0].class_start_time,
        end_time: result['data'][0].class_end_time,
        participants: result['data'],
        completed_status: completed_status,
        cnt_exist: cnt_exist,
      })
    })

  }

  onChangeAnswer = (e, pid, val ) => {
    var get_val = this.state.participants
    var get_index = -1
    get_val.map((each, index) => {
      if(each.uid == pid) {
        get_index = index
      }
    })
    get_val[get_index].c_status = val
    this.setState({
      participants: get_val,
    })
  }

  validateAllField = () => {
    var chk = true
    var txt_warning = ''
    this.state.participants.map(each => {
      if(each.c_status == -1) {
        chk = false
        txt_warning = 'Please select all answers'
        return ''
      }
    })
    return txt_warning
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var get_validate_msg = this.validateAllField()
    if(get_validate_msg == '') {
      axios({
        method: 'post',
        url: connectNode + 'admin/events/add_class_status',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          cid: this.props.match.params.cid,
          gid: this.props.match.params.gid,
          uid: this.props.authReducer.uid,
          participants: this.state.participants,
        }
      })
      .then((result) => {
        //console.log(result['data'])
        var message = ''
        if(result['data'] == this.props.match.params.cid) {
          this.setState({
            compTxt: 'Thank you for submitting information!',
            message: '',
            status_submit: true,
            completed_status: true,
          })
          window.scrollTo(0, 0)
        } else {
          message = 'Something went wrong. Please try again.'
        }
        if(message !== '') {
          this.setState({
            message: message
          })
          window.scrollTo(0, 0)
        }
      })
    } else {
      this.setState({
        message: get_validate_msg,
      })
      window.scrollTo(0, 0)
    }

  }

  render() {
    //console.log(this.state)
    return (
    <div className="assm_page1">
      <div className="row">
        <div className="small-12 medium-6 columns headerPage1">
          Class Attendance
        </div>
        <div className="small-12 medium-6 columns header3">
          Group {this.state.group} | {this.state.class_date},&nbsp;
          {this.state.start_time} - {this.state.end_time}
        </div>
      </div>
      <div className="row">
          <div className="small-12 columns ">
            { (this.state.message !== '' || this.state.compTxt !== '') &&
            <div className="margin-bottom1">
              <div className={(this.state.message !== '' && 'small-12 columns warning_msg6')
              || (this.state.compTxt !== '' && 'small-12 columns warning_msg7')} >
                <div className="float_left1">
                {this.state.message !== '' && <WarningIcon />}
                {this.state.compTxt !== '' && <CheckCircleOutlineIcon />}
                </div>
                <div className="txt_warning_msg1">{ this.state.message || this.state.compTxt }</div>
              </div>
            </div>}
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>Participant name</th>
                    <th>Attendance</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.cnt_exist > 0 && this.state.participants.map((each, index) =>
                  <tr>
                    <td>{each.fullname}</td>
                    <td>
                    {join_class_status.map(choice =>
                    <div className="small-12 medium-4 columns">
                      <input type="radio" name={'q'+index} value={each.value}
                      disabled={this.state.completed_status && 'disabled'}
                      checked={each.c_status == choice.value && 'checked'}
                      onChange={(e) => this.onChangeAnswer(e, each.uid, choice.value)}/>
                      <span>{choice.label}</span>
                    </div>
                    )}
                    </td>
                  </tr>
                )}

              </tbody>
              </table>
            </div>
          </div>
          {!this.state.completed_status &&
          <div className="small-12 columns text_align_right1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
             Submit
          </Button>
          </div>}
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(EventParticipantList)
