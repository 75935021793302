import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../actions/location'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import ReactHtmlParser from 'react-html-parser'
import GetXLSX from '../../functions/get_xlsx.jsx'

class NiaCroms extends Component {
  constructor() {
    super()
  }

  downloadFile = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/download_patient_information',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
        var patient = result['data']
      }
    )
  }

  render() {
    return (
    <div className="participant1">
        <div className="small-12 columns">
          <div className="row">
            <div className="small-12 columns thanks1">
              <GetXLSX path="nia_croms" />
            </div>
          </div>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(NiaCroms)
