import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import { yes_no, english_answers } from '../../../../variables/registration.js'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'

class Consent extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        eligible: 0,
        consent: 0,
        consent_reason: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/participant/check_completed_screen_eligible',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
      if(result['data'].consent > 0) {
        this.setState({
          eligible: result['data'].eligible,
          consent: result['data'].consent,
          consent_reason: result['data'].consent_reason,
          completed_status: true,
        })
      }
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.consent > 0 ) {
        var pid = this.props.match.params.pid
        axios({
          method: 'post',
          url: connectNode + 'admin/participant/add_screen_consent',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            pid: pid,
            user: this.state,
          }
        })
        .then((result) => {
          var message = ''
          if(result['data'] == pid) {
            this.props.onUpdateTab('5', 'Update consent completed!')
          } else {
            message = 'Something went wrong. Please try again.'
          }
          if(message !== '') {
            this.setState({
              message: message
            })
            window.scrollTo(0, 0)
          }
        })
    } else {
      this.setState({
        message: 'Please select answer',
      })
      window.scrollTo(0, 0)
    }
  }

  render() {
    return (
    <div className="">
        <div className="headerPage3_1">
          Consent
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { this.state.message !== '' &&
          <div className="row margin-bottom1">
            <div className="small-12 columns warning_msg6">
              <div className="float_left1"><WarningIcon /></div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>
          </div>}

          <div className="row space_bottom2">
            <div className="small-12 columns">
              <div className="small-12 columns bold_txt2">Consent?</div>
              <div className="small-12 columns float_left">
                {yes_no.map(each =>
                <div className="small-12 columns float_left" key={'ce'+each.value}>
                  <input type="radio" name="consent" value={each.value}
                  disabled={this.state.completed_status && 'disabled'}
                  checked={this.state.consent == each.value && 'checked'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span>{each.label}</span>
                </div>)}
                {this.state.consent == 2 &&
                <div className="small-12 columns top_space1">
                <TextField onChange={this.handleChange} variant="outlined"
                disabled={this.state.completed_status && 'disabled'}
                type="text" value={this.state.consent_reason} className="w_80per"
                InputProps={{ inputProps: { name: "consent_reason", maxLength: 250, placeholder: 'Reason' }}} />
                </div>}
              </div>
            </div>
          </div>
        </div>
        {!this.state.completed_status &&
        <div className="small-12 columns  top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Submit
          </Button>
        </div>}
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Consent)
