export const gen_m = [
  {
    label: 'January',
    value: 1,
  }, {
    label: 'February',
    value: 2,
  }, {
    label: 'March',
    value: 3,
  }, {
    label: 'April',
    value: 4,
  }, {
    label: 'May',
    value: 5,
  }, {
    label: 'June',
    value: 6,
  }, {
    label: 'July',
    value: 7,
  }, {
    label: 'August',
    value: 8,
  }, {
    label: 'September',
    value: 9,
  }, {
    label: 'October',
    value: 10,
  }, {
    label: 'November',
    value: 11,
  }, {
    label: 'December',
    value: 12,
  },
]

export const yes_no = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  },
]

export const group_list = [
  {
    label: 'Group 1',
    value: 1,
  }, {
    label: 'Group 2',
    value: 2,
  },
]

export const english_answers = [
  {
    label: 'Poor',
    value: 1,
  }, {
    label: 'Fair',
    value: 2,
  }, {
    label: 'Good',
    value: 3,
  }, {
    label: 'Excellent',
    value: 4,
  },
]

export const par_q_questions = [
  {
    label: 'Has your doctor ever said that you have a heart condition and that you should only perform physical activity recommended by a doctor?',
    value: 1,
  }, {
    label: 'Do you feel pain in your chest when you perform physical activity?',
    value: 2,
  }, {
    label: 'In the past month, have you had chest pain when you were not performing any physical activity?',
    value: 3,
  }, {
    label: 'Do you lose your balance because of dizziness or do you ever lose consciousness?',
    value: 4,
  }, {
    label: 'Do you have a bone or joint problem that could be made worse by a change in your physical activity?',
    value: 5,
  }, {
    label: 'Do you have high blood pressure or a preexisting heart condition that is not currently being monitored and managed by your doctor?',
    value: 6,
  }, {
    label: 'Do you know of any other reason why you should not engage in physical activity?',
    value: 7,
  },
]

export const parq_steps = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
]

export const correct_incorrect = [
  {
    label: 'Correct',
    value: 1,
  }, {
    label: 'Incorrect',
    value: 2,
  },
]

export const tics_questions_22q = [
  {
    label: 'Please tell me your full name',
    value: 1,
  }, {
    label: 'What is the year we are in?',
    value: 2,
  }, {
    label: 'What season is it?',
    value: 3,
  }, {
    label: 'What month are we in?',
    value: 4,
  }, {
    label: 'What is today’s date?',
    value: 5,
  }, {
    label: 'What day of the week is today?',
    value: 6,
  }, {
    label: 'What is your home address?',
    value: 7,
  }, {
    label: 'Count backwards from 20 to 1.',
    value: 8,
  }, {
    label: 'I am going to read a list of 10 words. Please listen carefully and try to remember them. When I am done, tell me as many words as you can, in any order.  Ready?  The words are:',
    value: 9,
  }, {
    label: 'Now I’d like you to subtract 7 from 100. Then keep subtracting 7 from each answer until I tell you to stop. What is 100 take away 7?',
    value: 10,
  }, {
    label: 'What do people usually use to cut paper?',
    sub: '(Score = 1 point for scissors or shears)',
    sc_type: 1,
    value: 11,
  }, {
    label: 'How many things are in a dozen?',
    sub: '(Score = 1 point for 12)',
    sc_type: 1,
    value: 12,
  }, {
    label: 'What do you call the prickly green plant that lives in the desert?',
    sub: '(Score = 1 point for cactus only)',
    sc_type: 1,
    value: 13,
  }, {
    label: 'What animal does wool come from?',
    sub: '(Score = 1 point for sheep or lamb only)',
    sc_type: 1,
    value: 14,
  }, {
    label: "Say this, ‘No ifs, ands or buts’.",
    sub: '(Score = 1 point for completely correct repetition on the first trial. Repeat only if poorly presented)',
    sc_type: 1,
    value: 15,
  }, {
    label: "Say this, ‘Methodist Episcopal’.",
    sub: '(Score = 1 point for completely correct repetition on the first trial. Repeat only if poorly presented)',
    sc_type: 1,
    value: 16,
  }, {
    label: 'Who is the President of the United States right now?',
    sub: '(Score = 1 point for correct first and last name only)',
    sc_type: 1,
    value: 17,
  }, {
    label: 'Who is the Vice President?',
    sub: '(Score = 1 point for correct first and last name only)',
    sc_type: 1,
    value: 18,
  }, {
    label: 'With your finger, tap 5 times on the part of the phone you speak into.',
    sub: '(Score = 2 points if 5 taps are heard; 1 point if participant taps more or less than 5 times)',
    sc_type: 2,
    value: 19,
  }, {
    label: "I’m going to give you a word and I want you to give me its opposite. For example, the opposite of hot is cold. What is the opposite of ‘west’?",
    sub: '(Score = 1 point for east)',
    sc_type: 1,
    value: 20,
  }, {
    label: "What is the opposite of ‘generous’?",
    sub: '(Score = 1 point for selfish, greedy, stingy, tight, cheap, mean, meager, skimpy or other good antonym)',
    sc_type: 1,
    value: 21,
  }, {
    label: "Earlier I read a long list of words to you. Please tell me all of the words that you can remember from that list.",
    value: 22,
  },
]

export const tics_questions = [
  {
    label: 'Please tell me your full name?',
    criteria: '1 pt. for first name, 1 pt. for last name.',
    value: 1,
    type: 'radioButton',
    count: 2
  }, {
    label: "What is today's date?",
    criteria: '1 pt. each for month, date, year, day of week, and season. If incomplete, ask specifics (e.g., "What is the month?", "What season are we in?").',
    value: 2,
    type: 'dropdown',
    count: 5
  }, {
    label: 'Where are you right now?',
    criteria: '1 pt. each for house number, street, city, state, zip. If incomplete, ask specifics (e.g., "What street are you on right now?")',
    value: 3,
    type: 'dropdown',
    count: 5
  }, {
    label: 'Count backwards from 20 to 1.',
    criteria: '2 pts. if completely correct on the first trial; 1 pt. if completely correct on second trial; 0pts. for anything else.',
    value: 4,
    type: 'radioButton',
    count: 2
  }, {
    label: 'I am going to read a list of ten words. Please listen carefully and try to remember them. When I am done, tell me as many words as you can, in any order.  Ready?  The words are: cabin, pipe, elephant, chest, silk, theatre, watch, whip, pillow, giant. Now tell me all the words you can remember.',
    criteria: '1 pt. for each correct response. No penalty for repetitions or intrusions.',
    value: 5,
    type: 'dropdown',
    count: 10
  }, {
    label: 'One hundred minus 7 equals what? And 7 from that?, etc.',
    criteria: 'Stop at 5 serial subtractions. 1 pt. for each correct subtraction. Do not inform the subject of incorrect responses, but allow subtractions to be made from his/her last response (e.g. "93-85-78-71-65" would get 3 points)',
    value: 6,
    type: 'dropdown',
    count: 5
  }, {
    label: 'What do people usually use to cut paper?',
    criteria: '1 pt. for "scissor" or "shears" only',
    value: 71,
    type: 'radioButton',
    count: 1
  }, {
    label: 'How many things are in a dozen?',
    criteria: '1 pt. for "12"',
    value: 72,
    type: 'radioButton',
    count: 1
  }, {
    label: 'What do you call the prickly green plant that lives in the desert?',
    criteria: '1 pt. for "cactus" only',
    value: 73,
    type: 'radioButton',
    count: 1
  }, {
    label: 'What animal does wool come from?',
    criteria: '1 pt. for "sheep" or "lamb" only',
    value: 74,
    type: 'radioButton',
    count: 1
  }, {
    label: 'Say this, ‘No ifs, ands or buts’. Say this, ‘Methodist Episcopal’.',
    criteria: '1 pt. for each complete repetition on the first trial. Repeat only if poorly presented',
    value: 8,
    type: 'radioButton',
    count: 2
  }, {
    label: 'Who is the President of the United States right now?',
    criteria: '1 pt. for correct first and last name',
    value: 91,
    type: 'radioButton',
    count: 1
  }, {
    label: 'Who is the Vice President?',
    criteria: '1 pt. for correct first and last name',
    value: 92,
    type: 'radioButton',
    count: 1
  }, {
    label: 'With your finger, tap 5 times on the part of the phone you speak into.',
    criteria: '2 pt. if 5 taps are heard; 1 point if subject taps more or less than 5 times',
    value: 10,
    type: 'radioButton',
    count: 2
  }, {
    label: 'I’m going to give you a word and I want you to give me its opposite. For example, the opposite of hot is cold. What is the opposite of ‘west’?',
    criteria: '1 pt. for "east"',
    value: 111,
    type: 'radioButton',
    count: 1
  }, {
    label: "What is the opposite of ‘generous’?",
    criteria: '1 pt. for "selfish", "greedy", "stingy", "tight", "cheap", "mean", "meager", "skimpy" or other good antonym',
    value: 112,
    type: 'radioButton',
    count: 1
  }, {
    label: 'Earlier I read a long list of words to you. Please tell me all of the words that you can remember from that list.',
    criteria: 'Cabin',
    value: 121,
    type: 'radioButton',
    count: 1
  }, {
    label: '(Check each correct response)',
    criteria: 'Theatre',
    value: 122,
    type: 'radioButton',
    count: 1
  }, {
    label: '',
    criteria: 'Pipe',
    value: 123,
    type: 'radioButton',
    count: 1
  }, {
    label: '',
    criteria: 'Watch',
    value: 124,
    type: 'radioButton',
    count: 1
  }, {
    label: '',
    criteria: 'Elephant',
    value: 125,
    type: 'radioButton',
    count: 1
  }, {
    label: '',
    criteria: 'Whip',
    value: 126,
    type: 'radioButton',
    count: 1
  }, {
    label: '',
    criteria: 'Chest',
    value: 127,
    type: 'radioButton',
    count: 1
  }, {
    label: '',
    criteria: 'Pillow',
    value: 128,
    type: 'radioButton',
    count: 1
  }, {
    label: '',
    criteria: 'Silk',
    value: 129,
    type: 'radioButton',
    count: 1
  }, {
    label: '',
    criteria: 'Giant',
    value: 1210,
    type: 'radioButton',
    count: 1
  },
]

export const tics_q1 = [
  {
    label: 'First name',
    value: 1,
  }, {
    label: 'Last name',
    value: 2,
  },
]

export const tics_q7 = [
  {
    label: 'House number',
    value: 1,
  }, {
    label: 'Street Name',
    value: 2,
  }, {
    label: 'City',
    value: 3,
  }, {
    label: 'State',
    value: 4,
  }, {
    label: 'Zip',
    value: 5,
  },
]

export const tics_q8_score = [
  {
    label: '0',
    value: 0,
  }, {
    label: '1',
    value: 1,
  }, {
    label: '2',
    value: 2,
  },
]

export const tics_q9_words = [
  {
    label: 'Cabin',
    value: 1,
  }, {
    label: 'Theatre',
    value: 2,
  }, {
    label: 'Pipe',
    value: 3,
  }, {
    label: 'Watch',
    value: 4,
  }, {
    label: 'Elephant',
    value: 5,
  }, {
    label: 'Whip',
    value: 6,
  }, {
    label: 'Chest',
    value: 7,
  }, {
    label: 'Pillow',
    value: 8,
  }, {
    label: 'Silk',
    value: 9,
  }, {
    label: 'Giant',
    value: 10,
  },
]

export const tics_q10_numbers = [
  {
    label: '93',
    value: 93,
  }, {
    label: '86',
    value: 86,
  }, {
    label: '79',
    value: 79,
  }, {
    label: '72',
    value: 72,
  }, {
    label: '65',
    value: 65,
  },
]

export const file_category = [
  {
    label: 'Redcap Consent Form',
    value: 1,
  }, {
    label: 'PCP Consent',
    value: 2,
  }, {
    label: 'ROI',
    value: 3,
  }, {
    label: 'Others',
    value: 4,
  },
]
