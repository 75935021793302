import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import '../../../styles/assets/css/pages/assm_eval.css'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import "react-datepicker/dist/react-datepicker.css"
import 'react-quill/dist/quill.snow.css'
import Cookies from 'js-cookie'

import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { SelectLine } from '../../shared/select_line'
import { TextFieldLine } from '../../shared/text_field_line'

class FrmIndividualPlan extends Component {
  constructor() {
    super()
    this.quill = require('react-quill')
    this.state = {
        completed: false,
        status_submit: false,
        message: '',
        contract_plan_id: 0,
        participant_name: '',
        contract_date: new Date(), //.toJSON().slice(0,10).replace(/-/g,'/'),
        instructor_name: '',
        goal1: '',
        goal1_other: '',
        goal2: '',
        goal2_other: '',
        goal3: '',
        goal3_other: '',
        goal4: '',
        goal4_other: '',
        goal5: '',
        goal5_other: '',
        intermediate_steps: '',

        exercise_times_week: 0,
        exercise_mins_each: 0,
        commitment_type_activity: '',
        commitment_type_activity_other: '',
        type_activity_values: [],
        type_activity_other: '',
        frequency_values: [],
        commitment_alone_group: '',
        commitment_where: '',
        commitment_day_donot_feel_well: '',
        flexibility_frequency: '',
        flexibility_duration: '',
        flexibility_location: '',
        aerobic_frequency: '',
        aerobic_duration: '',
        aerobic_location: '',
        strength_frequency: '',
        strength_duration: '',
        strength_location: '',
        signature: '',
        supplemental_activities: [{ id:1, type_activity: '', type_activity_other: '', frequency: '', duration: '', location: ''}],
        cookieTimeout: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    var pid = this.props.authReducer.uid
    if(this.props.match.params.pid > 0) {
      pid = this.props.match.params.pid
    }
    axios({
      method: 'post',
      url: connectNode + 'participants/contract/get_contract_plan_maintaining',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: pid,
      }
    })
    .then((result) => {
      this.setState({
        type_activity_values: result['data'].type_activity_values,
        frequency_values: result['data'].frequency_values
      })
      if(result['data'].uid == pid) {
        this.setSessionData(result['data'])
        /*this.setState({
          completed: true,
          participant_name: result['data'].participant_name,
          //contract_date: result['data'].contract_date,
          instructor_name: result['data'].instructor_name,
          goal1: result['data'].goal1,
          goal1_other: result['data'].goal1_other,
          goal2: result['data'].goal2,
          goal2_other: result['data'].goal2_other,
          goal3: result['data'].goal3,
          goal3_other: result['data'].goal3_other,
          goal4: result['data'].goal4,
          goal4_other: result['data'].goal4_other,
          goal5: result['data'].goal5,
          goal5_other: result['data'].goal5_other,
          intermediate_steps: result['data'].intermediate_steps,

          exercise_times_week: result['data'].exercise_times_week,
          exercise_mins_each: result['data'].exercise_mins_each,
          commitment_type_activity: result['data'].commitment_type_activity,
          commitment_type_activity_other: result['data'].commitment_type_activity_other,
          commitment_alone_group: result['data'].commitment_alone_group,
          commitment_where: result['data'].commitment_where,
          commitment_day_donot_feel_well: result['data'].commitment_day_donot_feel_well,
          flexibility_frequency: result['data'].flexibility_frequency,
          flexibility_duration: result['data'].flexibility_duration,
          flexibility_location: result['data'].flexibility_location,
          aerobic_frequency: result['data'].aerobic_frequency,
          aerobic_duration: result['data'].aerobic_duration,
          aerobic_location: result['data'].aerobic_location,
          strength_frequency: result['data'].strength_frequency,
          strength_duration: result['data'].strength_duration,
          strength_location: result['data'].strength_location,

          week_mins: result['data'].week_mins,
          what_hope_program: result['data'].what_hope_program,
          why_decide_program: result['data'].why_decide_program,
          version: result['data'].version,
          contract_plan_id: result['data'].id,
        })*/
      } else {
        this.setSessionData([])
      }
    }).then((data) => {
       // Supplemental Activities //
       axios({
         method: 'post',
         url: connectNode + 'participants/contract/get_contract_plan_maintaining_supplemental',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json;charset=UTF-8'
         },
         data: {
           pid: pid,
           contract_plan_id: this.state.contract_plan_id,
         }
       })
       .then((result) => {
         if(result['data'].length > 0) {
          this.setState({
            supplemental_activities: result['data']
          })
        }
       })
    })
  }

  setSessionData = (getData) => {
    var participant_name = ''
    var instructor_name = ''
    var intermediate_steps = ''
    var goal1 = ''
    var goal2 = ''
    var goal3 = ''
    var goal4 = ''
    var goal5 = ''
    var exercise_times_week = 0
    var exercise_mins_each = 0
    var commitment_type_activity = ''
    var commitment_type_activity_other = ''
    var commitment_alone_group = ''
    var commitment_where = ''
    var commitment_day_donot_feel_well = ''
    var flexibility_frequency = ''
    var flexibility_duration = ''
    var flexibility_location = ''
    var aerobic_frequency = ''
    var aerobic_duration = ''
    var aerobic_location = ''
    var strength_frequency = ''
    var strength_location = ''
    var strength_duration = ''
    var supplemental_activities = [{ id:1, type_activity: '', type_activity_other: '', frequency: '', duration: '', location: ''}]
    var version = 0
    var completed = false

    if(Cookies.get('participant_name')) {
      participant_name = JSON.parse(Cookies.get('participant_name'))
    }
    if(Cookies.get('instructor_name')) {
      instructor_name = JSON.parse(Cookies.get('instructor_name'))
    }
    if(Cookies.get('intermediate_steps')) {
      intermediate_steps = JSON.parse(Cookies.get('intermediate_steps'))
    }
    if(Cookies.get('2_goal1')) {
      goal1 = JSON.parse(Cookies.get('2_goal1'))
    }
    if(Cookies.get('2_goal2')) {
      goal2 = JSON.parse(Cookies.get('goal2'))
    }
    if(Cookies.get('2_goal3')) {
      goal3 = JSON.parse(Cookies.get('goal3'))
    }
    if(Cookies.get('2_goal4')) {
      goal4 = JSON.parse(Cookies.get('goal4'))
    }
    if(Cookies.get('2_goal5')) {
      goal5 = JSON.parse(Cookies.get('goal5'))
    }
    if(Cookies.get('exercise_times_week')) {
      exercise_times_week = JSON.parse(Cookies.get('exercise_times_week'))
    }
    if(Cookies.get('exercise_mins_each')) {
      exercise_mins_each = JSON.parse(Cookies.get('exercise_mins_each'))
    }
    if(Cookies.get('commitment_type_activity')) {
      commitment_type_activity = JSON.parse(Cookies.get('commitment_type_activity'))
    }
    if(Cookies.get('commitment_type_activity_other')) {
      commitment_type_activity_other = JSON.parse(Cookies.get('commitment_type_activity_other'))
    }
    if(Cookies.get('commitment_alone_group')) {
      commitment_alone_group = JSON.parse(Cookies.get('commitment_alone_group'))
    }
    if(Cookies.get('commitment_where')) {
      commitment_where = JSON.parse(Cookies.get('commitment_where'))
    }
    if(Cookies.get('commitment_day_donot_feel_well')) {
      commitment_day_donot_feel_well = JSON.parse(Cookies.get('commitment_day_donot_feel_well'))
    }
    if(Cookies.get('flexibility_frequency')) {
      flexibility_frequency = JSON.parse(Cookies.get('flexibility_frequency'))
    }
    if(Cookies.get('flexibility_duration')) {
      flexibility_duration = JSON.parse(Cookies.get('flexibility_duration'))
    }
    if(Cookies.get('flexibility_location')) {
      flexibility_location = JSON.parse(Cookies.get('flexibility_location'))
    }
    if(Cookies.get('aerobic_frequency')) {
      aerobic_frequency = JSON.parse(Cookies.get('aerobic_frequency'))
    }
    if(Cookies.get('aerobic_duration')) {
      aerobic_duration = JSON.parse(Cookies.get('aerobic_duration'))
    }
    if(Cookies.get('aerobic_location')) {
      aerobic_location = JSON.parse(Cookies.get('aerobic_location'))
    }
    if(Cookies.get('strength_frequency')) {
      strength_frequency = JSON.parse(Cookies.get('strength_frequency'))
    }
    if(Cookies.get('strength_duration')) {
      strength_duration = JSON.parse(Cookies.get('strength_duration'))
    }
    if(Cookies.get('strength_location')) {
      strength_location = JSON.parse(Cookies.get('strength_location'))
    }
    if(Cookies.get('supplemental_activities')) {
      supplemental_activities = JSON.parse(Cookies.get('supplemental_activities'))
    }

    if(getData['participant_name'] != undefined) {
      participant_name = getData['participant_name']
      instructor_name = getData['instructor_name']
      intermediate_steps = getData['intermediate_steps']
      goal1 = getData['goal1']
      goal2 = getData['goal2']
      goal3 = getData['goal3']
      goal4 = getData['goal4']
      goal5 = getData['goal5']
      exercise_times_week = getData['exercise_times_week']
      exercise_mins_each = getData['exercise_mins_each']
      commitment_type_activity = getData['commitment_type_activity']
      commitment_type_activity_other = getData['commitment_type_activity_other']
      commitment_alone_group = getData['commitment_alone_group']
      commitment_where = getData['commitment_where']
      commitment_day_donot_feel_well = getData['commitment_day_donot_feel_well']
      flexibility_frequency = getData['flexibility_frequency']
      flexibility_duration = getData['flexibility_duration']
      flexibility_location = getData['flexibility_location']
      aerobic_frequency = getData['aerobic_frequency']
      aerobic_duration = getData['aerobic_duration']
      aerobic_location = getData['aerobic_location']
      strength_frequency = getData['strength_frequency']
      strength_location = getData['strength_location']
      strength_duration = getData['strength_duration']
      supplemental_activities = getData['supplemental_activities']
      version = getData['version']
      completed = true
    }

    this.setState({
      completed: completed,
      participant_name: participant_name,
      instructor_name: instructor_name,
      goal1: goal1,
      goal2: goal2,
      goal3: goal3,
      goal4: goal4,
      goal5: goal5,
      intermediate_steps: intermediate_steps,
      exercise_times_week: exercise_times_week,
      exercise_mins_each: exercise_mins_each,
      commitment_type_activity: commitment_type_activity,
      commitment_type_activity_other: commitment_type_activity_other,
      commitment_alone_group: commitment_alone_group,
      commitment_where: commitment_where,
      commitment_day_donot_feel_well: commitment_day_donot_feel_well,
      flexibility_frequency: flexibility_frequency,
      flexibility_duration: flexibility_duration,
      flexibility_location: flexibility_location,
      aerobic_frequency: aerobic_frequency,
      aerobic_duration: aerobic_duration,
      aerobic_location: aerobic_location,
      strength_frequency: strength_frequency,
      strength_duration: strength_duration,
      strength_location: strength_location,
      supplemental_activities: supplemental_activities,
      version: version,
      contract_plan_id: getData['id'],
    })
  }

  removeCookie = () => {
    Cookies.remove('participant_name')
    Cookies.remove('instructor_name')
    Cookies.remove('intermediate_steps')
    Cookies.remove('2_goal1')
    Cookies.remove('2_goal2')
    Cookies.remove('2_goal3')
    Cookies.remove('2_goal4')
    Cookies.remove('2_goal5')
    Cookies.remove('exercise_times_week')
    Cookies.remove('exercise_mins_each')
    Cookies.remove('commitment_type_activity')
    Cookies.remove('commitment_type_activity_other')
    Cookies.remove('commitment_alone_group')
    Cookies.remove('commitment_where')
    Cookies.remove('commitment_day_donot_feel_well')
    Cookies.remove('flexibility_frequency')
    Cookies.remove('flexibility_duration')
    Cookies.remove('flexibility_location')
    Cookies.remove('aerobic_frequency')
    Cookies.remove('aerobic_duration')
    Cookies.remove('aerobic_location')
    Cookies.remove('strength_frequency')
    Cookies.remove('strength_duration')
    Cookies.remove('strength_location')
    Cookies.remove('supplemental_activities')
  }

  handleChange = (e) => {
    var getStateVal = e.target.value
    this.setState({
      [e.target.name]: getStateVal,
    })
    Cookies.set(e.target.name, JSON.stringify(getStateVal), {
      expires: this.state.cookieTimeout,
    })
  }

  handleChange2 = (e) => {
    var getStateVal = e.target.value
    this.setState({
      [e.target.name]: getStateVal,
    })
    Cookies.set("2_"+e.target.name, JSON.stringify(getStateVal), {
      expires: this.state.cookieTimeout,
    })
  }

  handleChange4_1 = (contents) => {
    this.setState({
      intermediate_steps: contents,
    })
    Cookies.set('intermediate_steps', JSON.stringify(contents), {
      expires: this.state.cookieTimeout,
    })
  }

  handleChange4_2 = (contents) => {
    this.setState({
      commitment_type_activity: contents.target.value,
    })
    Cookies.set('commitment_type_activity', JSON.stringify(contents), {
      expires: this.state.cookieTimeout,
    })
  }

  handleChange4_2_other = (contents) => {
    this.setState({
      commitment_type_activity_other: contents.target.value,
    })
    Cookies.set('commitment_type_activity_other', JSON.stringify(contents), {
      expires: this.state.cookieTimeout,
    })
  }

  handleChange4_3 = (contents) => {
    this.setState({
      commitment_alone_group: contents,
    })
    Cookies.set('commitment_alone_group', JSON.stringify(contents), {
      expires: this.state.cookieTimeout,
    })
  }

  handleChange4_4 = (contents) => {
    this.setState({
      commitment_where: contents,
    })
    Cookies.set('commitment_where', JSON.stringify(contents), {
      expires: this.state.cookieTimeout,
    })
  }

  handleChange4_5 = (contents) => {
    this.setState({
      commitment_day_donot_feel_well: contents,
    })
    Cookies.set('commitment_day_donot_feel_well', JSON.stringify(contents), {
      expires: this.state.cookieTimeout,
    })
  }

  handleChange5 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = e.target.value
    this.setState({
      [name]: getStateVal
    })
    Cookies.set(name, JSON.stringify(getStateVal), {
      expires: this.state.cookieTimeout,
    })
  }

  appendInputActivity = (name, fields) => {
    var newInput = fields[fields.length-1]
    var newInputID = newInput.id + 1
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
          id: newInputID, type_activity: '', type_activity_other: '', frequency: '', duration: '', location: '',
        }])
      }
    ))
  }

  removeInputSet1 = (name, id, fields) => {
    var getIndex = fields.findIndex(obj => obj.id === id)
    if (getIndex > 0) {
      fields.splice(getIndex, 1);
    }
    this.setState({ [name]: fields })
  }

  validateAllField = () => {
    var txt_warning = ''
    const lang = Cookies.get('lang_')
    var arr = ['participant_name', 'instructor_name', 'intermediate_steps',
               'commitment_type_activity', 'commitment_alone_group', 'commitment_where',
               'commitment_day_donot_feel_well', 'flexibility_location', 'aerobic_location',
               'strength_location', 'goal1', 'goal2', 'goal3',
               'flexibility_frequency', 'flexibility_duration', 'aerobic_frequency',
               'aerobic_duration', 'strength_frequency', 'strength_duration'];
    arr.forEach(each => {
      //console.log(each + ': ' + this.state[each])
       if(((typeof this.state[each] === 'string') && this.state[each].trim() === '') || this.state[each] === '') {
         if(lang=='es') {
           txt_warning = 'Por favor llene toda la informacion y seleccione al menos tres metas generales'
         } else {
           txt_warning = 'Please fill all information and select at least three overall goals'
         }
       }
    })

    if(this.state.exercise_times_week === 0 || this.state.exercise_mins_each === 0) {
      if(lang=='es') {
        txt_warning = 'Por favor llene todo el Contrato Negociado de Adherencia'
      } else {
        txt_warning = 'Please fill commitment plan all information'
      }
    }

    //this.state.supplemental_activities.forEach(each => {
    //  if(each.type_activity === 0 || each.frequency === 0 || each.duration === 0 || each.location.trim() === '') {
    //    txt_warning = 'Please fill and select supplemental activities answers';
    //  }
    //})

    //console.log(this.state.supplemental_activities);
    return txt_warning
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const lang = Cookies.get('lang_')
    var get_validate_msg = '' //this.validateAllField()
    if(get_validate_msg == '') {
      var pid = this.props.authReducer.uid
      if(this.props.match.params.pid > 0) {
        pid = this.props.match.params.pid
      }
      axios({
        method: 'post',
        url: connectNode + 'participants/contract/add_contract_plan_maintaining',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: pid,
          user: this.state,
        }
      })
      .then((result) => {
        var message = ''
        if(result['data'] == pid) {
          if(this.state.version > 0) {
            if(lang=='es') {
              message = '¡Gracias por actualizar el plan individual para manter un estilo de vida activo!'
            } else {
              message = 'Thank you for updating individual plan for maintaining an active lifestyle!'
            }
          } else {
            if(lang=='es') {
              message = '¡Gracias por enviar el plan individual para manter un estilo de vida activo!'
            } else {
              message = 'Thank you for submitting individual plan for maintaining an active lifestyle!'
            }
          }
          this.setState({
            message: message,
            status_submit: true,
            completed: true,
          })
          this.removeCookie()
          window.scrollTo(0, 0)
        }
      })
    } else {
      this.setState({
        message: get_validate_msg,
      })
      window.scrollTo(0, 0)
    }
  }

  render() {
    const ReactQuill = this.quill;
    const getDate = new Date(this.state.contract_date)
    const lang = Cookies.get('lang_')
    const activityMap = (lang == 'es') ? this.state.type_activity_values.map(each => ({ id: each.vid, name: each.name_es })) : this.state.type_activity_values.map(each => ({ id: each.vid, name: each.name }))
    const frequencyMap = (lang == 'es') ? this.state.frequency_values.map(each => ({ id: each.vid, name: each.name_es })) : this.state.frequency_values.map(each => ({ id: each.vid, name: each.name }))
    const minutesMap = [];
    for (let i = 10; i <= 60; i = i + 10)
      minutesMap.push({ id: i, name: i })

    return (
    <div className="assm_page1">
        <div className="headerPage1">
          {(lang=='en' && 'Individual plan for maintaining an active lifestyle') || (lang=='es' && 'PLAN INDIVIDUAL PARA MANTENER UN ESTILO DE VIDA ACTIVO')} {this.state.completed && ' [Edit]'}
        </div>
        <div className="small-12 columns box_assm1 more_padding1 ip_box1">
          { (this.state.message !== '') &&
          <div className="row bottom_space1">
            <div className={(!this.state.status_submit && 'small-12 columns warning_msg6')
            || (this.state.status_submit && 'small-12 columns warning_msg7')} >
              <div className="float_left1">
              {!this.state.status_submit && <WarningIcon />}
              {this.state.status_submit && <CheckCircleOutlineIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>
          </div>}
          <div className="row">
            <div className="small-12 medium-3 columns text_align_right1 text_align_left_m1 no_padding_right top_space2 bottom_space2">
              {(lang=='en' && 'Participant Name') || (lang=='es' && 'Nombre de participante')}
            </div>
            <div className="small-12 medium-9 columns">
              <TextField onChange={this.handleChange} variant="outlined" className="txt_size1"
                    type="text" value={this.state.participant_name}
                    InputProps={{ inputProps: { name: "participant_name", maxLength: 50 }}} />
            </div>
          </div>
          <div className="row top_space1">
            <div className="small-12 medium-3 columns text_align_right1 text_align_left_m1 no_padding_right top_space2">
              {(lang=='en' && 'Name of Instructor') || (lang=='es' && 'Nombre del instructor')}
            </div>
            <div className="small-12 medium-9 columns top_space2">
              <TextField onChange={this.handleChange} variant="outlined" className="txt_size1"
                    type="text" value={this.state.instructor_name}
                    InputProps={{ inputProps: { name: "instructor_name", maxLength: 50 }}} />
            </div>
          </div>
          <div className="row top_space1">
            <div className="small-4 medium-3 columns text_align_right1 text_align_left_m1 no_padding_right">
              {this.state.completed && 'Update '}{(lang=='en' && 'Date') || (lang=='es' && 'Fecha')}
            </div>
            <div className="small-8 medium-9 columns no_bold">{((getDate.getMonth() > 8) ? (getDate.getMonth() + 1) : ('0' + (getDate.getMonth() + 1))) + '/' + ((getDate.getDate() > 9) ? getDate.getDate() : ('0' + getDate.getDate())) + '/' + getDate.getFullYear()}</div>
          </div>
        </div>
        <div className="small-12 columns box_assm1 more_padding1 top_space1">
          <div className="row">
            <div className="small-12 columns header1">
              {(lang=='en' && 'INDIVIDUAL PLAN FOR MAINTAINING AN ACTIVE LIFESTYLE') || (lang=='es' && 'PLAN INDIVIDUAL PARA MANTENER UN ESTILO DE VIDA ACTIVO')}
            </div>
            <div className="small-12 columns">
              {(lang=='en' && 'Now that you have learned about ways to manage your arthritis, the benefits that come from exercise, and how to exercise safely and at your own level, you need to think about what your goals are after Fit and Strong! ends. Here are some topics to consider when developing your individual goals and commitment plan for continuing to lead an active lifestyle.')
              || (lang=='es' && 'Ahora que ha aprendido formas de cómo controlar su artritis, los beneficios que vienen con el ejercicio, y cómo ejercitarse sin percances y a su propio nivel, usted tiene que pensar en cuáles son sus metas después de que acabe el programa de ¡Fuerte y en Forma! Aquí están algunos temas que debe considerar cuando esté desarrollando sus metas individuales y plan de compromiso para continuar con un estilo de vida activo.')}
            </div>
            <div className="small-12 columns top_space1">
              {(lang=='en' && 'Review the class goals you identified at the beginning of the program (Session 1), and see what progress you have made toward those goals.') || (lang=='es' && 'Repase las metas de la clase que identificó al principio del programa, y vea el progreso que ha hecho hacia estas metas.')}
            </div>
            <div className="small-12 columns li_color1">
              <ul>
                <li>
                  {(lang=='en' && 'If you have made progress toward these goals, how can you maintain your functioning at the current level?') || (lang=='es' && 'Si ha progresado hacia estas metas, ¿cómo puede mantener su funcionamiento con el mismo el nivel en el que está actualmente?')}
                </li>
                <li>
                  {(lang=='en' && 'Are there goals that need to be modified so that you can realistically reach them?') || (lang=='es' && '¿Hay metas que tiene que modificar para que las pueda alcanzar realísticamente?')}
                </li>
                <li>
                  {(lang=='en' && 'Are there other goals you have for managing your physical health? Can you think of smaller, intermediate steps that you can set as goals on the way to reaching a larger goal?') || (lang=='es' && '¿Usted tiene otras metas para controlar su salud física? ¿Puede pensar en pasos más pequeños o intermedios que puede usar como metas en el transcurso de alcanzar una meta más grande?')}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="small-12 columns box_assm1 more_padding1 top_space1">
          <div className="row">
            <div className="small-12 columns header1">
              {(lang=='en' && 'MY PLAN FOR SUSTAINING AN ACTIVE LIFESTYLE') || (lang=='es' && 'MI PLAN PARA MANTENER UN ESTILO DE VIDA ACTIVO')}
            </div>
            <div className="small-12 columns">
              <span className="bold">{(lang=='en' && 'Goal(s)') || (lang=='es' && 'META(S)')}:</span> {(lang=='en' && 'You must select at least three overall goals.') || (lang=='es' && 'Debe selccionar al menos tres metas generales.')}
            </div>
            <div className="long_term_goal space_bottom1 top_space1">
              <ol>
                <li>
                  <TextField onChange={(e) => this.handleChange2(e)} variant="outlined"
                    type="text" value={this.state.goal1}
                    name="goal1" className="tf_other1 "
                    InputProps={{ inputProps: { name: "goal1", maxLength: 249 }}} />
                </li>
                <li>
                <TextField onChange={(e) => this.handleChange2(e)} variant="outlined"
                  type="text" value={this.state.goal2}
                  name="goal2" className="tf_other1 "
                  InputProps={{ inputProps: { name: "goal2", maxLength: 249 }}} />
                </li>
                <li>
                <TextField onChange={(e) => this.handleChange2(e)} variant="outlined"
                  type="text" value={this.state.goal3} name="goal3"
                  className="tf_other1 "
                  InputProps={{ inputProps: { name: "goal3", maxLength: 249 }}} />
                </li>
                <li>
                <TextField onChange={(e) => this.handleChange2(e)} variant="outlined"
                  type="text" value={this.state.goal4} name="goal4"
                  className="tf_other1 "
                  InputProps={{ inputProps: { name: "goal4", maxLength: 249 }}} />
                </li>
                <li>
                <TextField onChange={(e) => this.handleChange2(e)} variant="outlined"
                  type="text" value={this.state.goal5} name="goal5"
                  className="tf_other1 "
                  InputProps={{ inputProps: { name: "goal5", maxLength: 249 }}} />
                </li>
              </ol>
            </div>
            <div className="long_term_goal">
              <div>{(lang=='en' && 'What are intermediate steps I could take to reach these goals?') || (lang=='es' && '¿Cuáles son los pasos intermedios que puedo tomar para alcanzar estas metas?')}</div>
              <div>
                <ReactQuill name="intermediate_steps" modules={{toolbar: false}}
                      theme='snow' value={this.state.intermediate_steps}
                      onChange={this.handleChange4_1}
                      style={{height: '80px'}} />
              </div>
            </div>
          </div>
        </div>
        <div className="small-12 columns box_assm1 more_padding1 top_space1 ip_box2">
          <div className="small-12 columns header1">
            {(lang=='en' && 'COMMITMENT PLAN') || (lang=='es' && 'PLAN DE COMPROMISO')}
          </div>
          <div className="">
            <span className="bold">{(lang=='en' && 'I will exercise') || (lang=='es' && 'Voy a hacer ejercicio')}
            <TextField onChange={this.handleChange} variant="outlined"
              className="front_back_space2 w_3rem"
                type="text" value={this.state.exercise_times_week}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                InputProps={{ inputProps: { name: "exercise_times_week", maxLength: 3 }}} />
            {(lang=='en' && 'times a week for about') || (lang=='es' && 'veces a la semana por')}
            <TextField onChange={this.handleChange} variant="outlined"
            className="front_back_space2 w_3rem"
                type="text" value={this.state.exercise_mins_each}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                InputProps={{ inputProps: { name: "exercise_mins_each", maxLength: 3 }}} />
            {(lang=='en' && 'minutes each time.') || (lang=='es' && 'minutos cada vez.')}</span>
            <br />{(lang=='en' && 'Recommend a minimum of 3 times a week for 60 minutes') || (lang=='es' && 'Se recomienda un mínimo de 3 veces por semana por 60 minutos.')}
          </div>
          <div className="long_term_goal top_space1">
            <ul>
              <li>
                <div>{(lang=='en' && 'What types of activity will I do?') || (lang=='es' && '¿Qué tipos de actividad voy a hacer?')}</div>
                {/* {this.state.commitment_type_activity == 99 &&
                  <SelectLine value={this.state.commitment_type_activity}
                    name="commitment_type_activity" onChange={this.handleChange4_2} menuItems={activityMap} noClass wide noHorizontalPadding/>
                  </div>}<div>
                  */}

                <div>
                  <TextField onChange={this.handleChange} variant="outlined"
                  className="front_back_space2 w_3rem"
                    type="text" value={this.state.commitment_type_activity}
                    InputProps={{ inputProps: { name: "commitment_type_activity", maxLength: 250 }}} />
                </div>
              </li>
              <li>
                <div>{(lang=='en' && 'Will I exercise alone or with another person or group?') || (lang=='es' && '¿Voy a hacer ejercicio solo o con otra persona o en grupo?')}</div>
                <div>
                  <ReactQuill name="commitment_alone_group" modules={{toolbar: false}}
                        theme='snow' value={this.state.commitment_alone_group}
                        onChange={this.handleChange4_3} style={{height: '80px'}} />
                </div>
              </li>
              <li>
                <div>{(lang=='en' && 'Where will I exercise?') || (lang=='es' && '¿En dónde haré ejercicio?')}</div>
                <div>
                  <ReactQuill name="commitment_where" modules={{toolbar: false}}
                        theme='snow' value={this.state.commitment_where}
                        onChange={this.handleChange4_4} style={{height: '80px'}} />
                </div>
              </li>
              <li>
                <div>{(lang=='en' && 'What will I do on the days I don’t feel like exercising?') || (lang=='es' && '¿Qué haré los días que no sienta ganas de hacer ejercicio?')}</div>
                <div>
                  <ReactQuill name="commitment_day_donot_feel_well" modules={{toolbar: false}}
                        theme='snow' value={this.state.commitment_day_donot_feel_well}
                        onChange={this.handleChange4_5} style={{height: '80px'}} />
                </div>
              </li>
            </ul>
          </div>
          <div className="long_term_goal top_space1">
            <div className="small-12 columns bold">
              {(lang=='en' && 'Flexibility') || (lang=='es' && 'Flexibilidad')}
            </div>
            <div className="small-12 medium-3 columns no_padding_right">
              <div className="fieldset_1">{(lang=='en' && 'Frequency') || (lang=='es' && 'Frecuencia')}</div>
              <div className="tf_other1">
                <SelectLine value={this.state.flexibility_frequency}
                   name="flexibility_frequency" onChange={this.handleChange} menuItems={frequencyMap} noClass wide noPadding/>
              </div>
            </div>
            <div className="small-12 medium-3 columns no_padding_left_right">
              <div className="fieldset_1">{(lang=='en' && 'Duration') || (lang=='es' && 'Duración')}</div>
              <div className="tf_other1">
               <SelectLine value={this.state.flexibility_duration}
                 name="flexibility_duration" onChange={this.handleChange} menuItems={minutesMap} noClass wide noPadding />
              </div>
            </div>
            <div className="small-12 medium-6 columns no_padding_left_right">
              <div className="fieldset_1">{(lang=='en' && 'Location') || (lang=='es' && 'Lugar')}</div>
              <TextField style={{marginTop: "0.35rem"}} onChange={this.handleChange} variant="outlined"
                type="text" value={this.state.flexibility_location} className=""
                InputProps={{ inputProps: { name: "flexibility_location", maxLength: 100, placeholder: 'Location' }}} />
            </div>
          </div>
          <div className="long_term_goal top_space1">
            <div className="small-12 columns bold">
              {(lang=='en' && 'Aerobic Conditioning') || (lang=='es' && 'Condición aeróbica')}
            </div>
            <div className="small-12 medium-3 columns no_padding_right">
              <div className="fieldset_1">{(lang=='en' && 'Frequency') || (lang=='es' && 'Frecuencia')}</div>
              <div className="tf_other1">
                <SelectLine value={this.state.aerobic_frequency}
                   name="aerobic_frequency" onChange={this.handleChange} menuItems={frequencyMap} noClass wide noPadding/>
              </div>
            </div>
            <div className="small-12 medium-3 columns no_padding_left_right">
              <div className="fieldset_1">{(lang=='en' && 'Duration') || (lang=='es' && 'Duración')}</div>
              <div className="tf_other1">
               <SelectLine value={this.state.aerobic_duration}
                name="aerobic_duration" onChange={this.handleChange} menuItems={minutesMap} noClass wide noPadding />
              </div>
            </div>
            <div className="small-12 medium-6 columns no_padding_left_right">
              <div className="fieldset_1">{(lang=='en' && 'Location') || (lang=='es' && 'Lugar')}</div>
              <TextField style={{marginTop: "0.35rem"}} onChange={this.handleChange} variant="outlined"
                type="text" value={this.state.aerobic_location} className=""
                InputProps={{ inputProps: { name: "aerobic_location", maxLength: 100, placeholder: 'Location' }}} />
            </div>
          </div>
          <div className="long_term_goal top_space1">
            <div className="small-12 columns bold">
              {(lang=='en' && 'Strength Training') || (lang=='es' && 'Entrenamiento de fortalecimiento')}
            </div>
            <div className="small-12 medium-3 columns no_padding_right">
               <div className="fieldset_1">{(lang=='en' && 'Frequency') || (lang=='es' && 'Frecuencia')}</div>
               <div className="tf_other1">
                <SelectLine value={this.state.strength_frequency}
                   name="strength_frequency" onChange={this.handleChange} menuItems={frequencyMap} noClass wide noPadding/>
              </div>
            </div>
            <div className="small-12 medium-3 columns no_padding_left_right">
              <div className="fieldset_1">{(lang=='en' && 'Duration') || (lang=='es' && 'Duración')}</div>
              <div className="tf_other1">
                <SelectLine value={this.state.strength_duration}
                  name="strength_duration" onChange={this.handleChange} menuItems={minutesMap} noClass wide noPadding />
              </div>
            </div>
            <div className="small-12 medium-6 columns no_padding_left_right">
              <div className="fieldset_1">{(lang=='en' && 'Location') || (lang=='es' && 'Lugar')}</div>
              <TextField style={{marginTop: "0.35rem"}} onChange={this.handleChange} variant="outlined"
                type="text" value={this.state.strength_location} className=""
                InputProps={{ inputProps: { name: "strength_location", maxLength: 100, placeholder: 'Location' }}} />
            </div>
          </div>
        </div>
        <div className="small-12 columns box_assm1 more_padding1 top_space1 multiple_activities">
          <div className="small-12 columns space_bottom1">
            <span className="bold">{(lang=='en' && 'Supplemental Activities') || (lang=='es' && 'Actividades suplementarias')}</span>&nbsp;
            - {(lang=='en' && 'These are some additional activities that you could include as part of your exercise routine that are separate from what is listed previously. Please indicate which of these activities you would like to include.')
            || (lang=='es' && 'Estas son algunas actividades adicionales que usted podrá incluir como parte de su rutina de ejercicios que son distintas de lo que se habló anteriormente. Por favor, indique cuáles de estas actividades le gustaría incluir.')}
          </div>
          <div className="row">
            <div className="small-12 columns sep_line1">&nbsp;</div>
          </div>
          {this.state.supplemental_activities && this.state.supplemental_activities.map((val, index) =>
            <div key={"m_"+val.id}>
              <div className="row">
                <div className="small-12 medium-3 columns">
                  <div className="fieldset_2">{(lang=='en' && 'Activity') || (lang=='es' && 'Tipo de actividad')}</div>
                  <SelectLine value={this.state.supplemental_activities[index].type_activity}
                    name={"supplemental_activities_" + index} onChange={(e) => this.handleChange5(e, 'supplemental_activities', index, 'type_activity')} menuItems={activityMap} noClass wide noPadding/>
                </div>
                <div className="small-12 medium-3 columns no_padding_left">
                   <div className="fieldset_2">{(lang=='en' && 'Frequency') || (lang=='es' && 'Frecuencia')}</div>
                   <SelectLine value={this.state.supplemental_activities[index].frequency}
                    name={"frequency_" + index} onChange={(e) => this.handleChange5(e, 'supplemental_activities', index, 'frequency')} menuItems={frequencyMap} noClass wide noPadding/>
                </div>
                <div className="small-12 medium-2 columns no_padding_left">
                  <div className="fieldset_2">{(lang=='en' && 'Duration') || (lang=='es' && 'Duración')}</div>
                  <SelectLine value={this.state.supplemental_activities[index].duration}
                    name="supplemental_activities" onChange={(e) => this.handleChange5(e, 'supplemental_activities', index, 'duration')} menuItems={minutesMap} noClass wide noPadding />
                </div>
                <div className="small-12 medium-3 columns no_padding_left_right">
                  <div className="fieldset_2">{(lang=='en' && 'Location') || (lang=='es' && 'Lugar')}</div>
                  <TextField onChange={(e) => this.handleChange5(e, 'supplemental_activities', index, 'location')}
                    variant="outlined" type="text" value={this.state.supplemental_activities[index].location} className="w_100p"
                    InputProps={{ inputProps: { maxLength: 100, placeholder: 'Location' }}} />
                </div>
                <div className="small-12 medium-1 columns">
                {val.id > 1 && <CancelIcon className="icon_cancel" onClick={ () => this.removeInputSet1('supplemental_activities', val.id, this.state.supplemental_activities) } />}
                {val.id === 1 && <div className="icon_cancel_none">&nbsp;</div>}
                </div>
                <div className="small-12 columns sep_line1">&nbsp;</div>
              </div>
              {this.state.supplemental_activities[index].type_activity == 99 &&
                <div className="row">
                  <div className="columns">
                    <TextFieldLine onChange={(e) => this.handleChange5(e, 'supplemental_activities', index, 'type_activity_other')}
                      noPadding wide
                      value={this.state.supplemental_activities[index].type_activity_other}
                      name={"frequency_other_" + index}
                      max={200} />
                  </div>
                  <div className="small-12 columns sep_line1">&nbsp;</div>
                </div>
              }
            </div>
          )}
          <div className="small-12 medium-3 columns text_align_right1">
            <button type="button" className="addInput1"
               onClick={ () => this.appendInputActivity('supplemental_activities', this.state.supplemental_activities) }>
                {(lang=='en' && 'Add an activity') || (lang=='es' && 'Añade una actividad')}
            </button>
          </div>
        </div>
        <div className="small-12 columns long_term_goal">
          <div className="small-12 medium-5 columns float_right1 top_space3">
          <div className="small-3 medium-3 columns space_bottom1 text_align_right1 text_mb_align_left1 no_padding_right">{(lang=='en' && 'Name') || (lang=='es' && 'Nombre')}</div>
          <div className="small-9 medium-9 columns space_bottom1">
          <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
              type="text" value={this.state.signature}
              name="signature" className="front_back_space1"
              InputProps={{ inputProps: { name: "signature", maxLength: 198 }}} />
          </div>
          </div>
        </div>
        <div className="small-6 columns back_button1">
        {this.props.match.params.pid > 0 &&
          <a href={"/admin/participant_chart/"+this.props.match.params.pid+"/3/1"}>Back</a>}
        {this.props.authReducer.uid >= 10 &&
          <a href="/member/participant_contract">Back</a>}
        </div>
        <div className="small-6 columns text_align_right1 top_space1">
        <Button variant="contained" color="primary"
           onClick={this.handleSubmit} type="submit">
            {!this.state.completed && 'Submit'}
            {this.state.completed && 'Update'}
        </Button>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(FrmIndividualPlan)
