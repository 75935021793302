import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
//import axios from 'axios'
//import { connectNode } from '../../utils/global.js'
import AuthService from '../../services/auth/auth.service.js'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { FaRegUserCircle } from 'react-icons/fa'
//import { loadAuthUser } from '../../actions/auth'
import { loadLocationWithMessage } from '../../actions/location'

class Login extends Component {
  constructor() {
      super();
      this.state = {
        username: '',
        password: '',
        loading: false,
        message: ''
      }
      this.handleSubmit = this.handleSubmit.bind(this)
      this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    })
  }

  handleNext = (forgot_password) => {
    window.location.href = "/"+forgot_password
  }

  handleSubmit(event) {
      event.preventDefault()
      AuthService.login(this.state.username, this.state.password)
      .then((response) => {
        //console.log('------')
        //console.log(response)
        if(response !== -1 && response !== -2 && response !== -3) {
          var path_location = window.location.pathname
          this.props.onLoadPages(response)
          if(path_location === '/login' || path_location === '/') { // '/' for haven't no Home page
            // check registration_step //
            path_location = '/member/dashboard'
            if(response.role == 2) {
              path_location = '/admin/participant_list'
            } else if(response.role == 3) {
              path_location = '/admin/group_setting'
            }

            if(response.role == 5) {
              if(response.r_step == 1 || response.r_step == null) {
                path_location = '/member/registration/demographic'
              } else if (response.reg_status > 1) {
                path_location = '/member/dashboard'
              }
            }

          }
          this.props.onLoadLocation(path_location)
          this.props.history.push(path_location)
        } else {
          var txt_err = ''
          if(response == -2) {
            txt_err = 'You have not changed your password in more than 180 days. Please click on Forgot Password to create a new one'
          } else if(response == -3) {
            txt_err = 'Too many failed attempts. We sent the link to reset your passwork to your email. If you didn\'t receive email, please click Forgot Password.'
          } else {
            txt_err = 'Password mismatch. Please try again. '
          }
          this.setState({
              username: '',
              password: '',
              message: txt_err,
          })
        }
      }).catch( error => {
        //console.log(error)
      })
  }

  render() {
    return (
      <div className="row" id="wrapper-login">
        <div className="small-12 columns welcome_txt">
          Welcome to HAIL!
        </div>
        <div className="small-12 columns">
          <div className="logo"><FaRegUserCircle /></div>
        </div>
        <div className="small-12 columns">
          { this.state.message !== '' &&
            <div className="warning_msg9">
              <div className="float_left1"><WarningIcon /></div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>
          }
          { (this.props.txt_message !== '' && this.props.txt_message !== undefined
              && this.state.message === '' && this.props.route !== 'login_s') &&
            <div className="warning_msg8">
              <div className="float_left1"><CheckCircleOutlineIcon /></div>
              <div className="txt_warning_msg1">{ this.props.txt_message }</div>
            </div>
          }
          { this.props.route === 'login_s' &&
            <div className="warning_msg9">
              <div className="float_left1"><WarningIcon /></div>
              <div className="txt_warning_msg1">Your session has expired. Please log in again to continue.</div>
            </div>
          }
          <form id="login" onSubmit={this.handleSubmit}>
            <div>
              <input className="form-input"
                  type="text"
                  id="username"
                  name="username"
                  placeholder="Username"
                  value={this.state.username}
                  onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <input className="form-input"
                  placeholder="Password"
                  type="password"
                  name="password"
                  id="password"
                  value={this.state.password}
                  onChange={this.handleChange} />
            </div>
            <div className="form-group ">
              <button onClick={this.handleSubmit} type="submit">Login</button>
            </div>
            <div className="form-group top_space1">
              <button onClick={(e) => this.handleNext('forgot_password')}>Forgot Password</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    txt_message: state.locationReducer.txt_message,
    route: state.locationReducer.route,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Login)
//export default Login
