import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'

import { baseline_assm_steps } from '../../variables/baseline_assm.js'
import { motivation_scale, motivation_scale_es} from '../../variables/baseline_motivation.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Cookies from 'js-cookie'

class Motivation extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        compTxt: '',
        q1: -1,
    }
  }

  componentDidMount() {
    this.getRegistraionProcess()
  }

  getRegistraionProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'participants/registration/get_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid }
    })
    .then((result) => {
        if(result['data'] !== 'motivation') {
          this.props.history.push('/member/registration/'+result['data'].reg_step)
        }
      }
    )
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value,
    })
  }

  validateAllField = () => {
    var chk = true
    var txt_warning = ''
    var arr = ['q1']
    const lang = Cookies.get('lang_')
    arr.map(each => {
      if(this.state[each] == -1) {
        chk = false
        if(lang=='es') {
          txt_warning = 'Por favor entre una respuesta para todas las preguntas'
        } else {
          txt_warning = 'Please enter a response to all questions.'
        }
        return ''
      }
    })

    return txt_warning
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var get_validate_msg = this.validateAllField()
    //console.log(get_validate_msg)
    if(get_validate_msg == '') {
      var pid = this.props.authReducer.uid
      axios({
        method: 'post',
        url: connectNode + 'participants/registration/add_motivation',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: pid,
          timepoint: 0,
          user: this.state,
        }
      })
      .then((result) => {
        var message = ''
        if(result['data'] == pid) {
          this.props.onLoadLocation('/member/registration/completed', 'Thank you for submitting your motivation!')
          //this.props.history.push('/member/registration/completed')
          window.location.href = '/member/registration/completed'
        } else {
          message = 'Something went wrong. Please try again.'
        }
        if(message !== '') {
          this.setState({
            message: message
          })
          window.scrollTo(0, 0)
        }
      })
    } else {
      this.setState({
        message: get_validate_msg,
      })
      window.scrollTo(0, 0)
    }
  }

  render() {
    const lang = Cookies.get('lang_')
    const choices = lang == 'es' ? motivation_scale_es : motivation_scale
    return (
    <div className="participant1 pt_motivation">
        <div className="headerPage3_1">
          New Participant Questionnaires
        </div>
        <div className="small-12 columns stepper">
          <Stepper alternativeLabel nonLinear activeStep={11}>
            {baseline_assm_steps.map(each => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={'st'+each.value} {...stepProps}>
                  <StepLabel {...labelProps}>{each.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { (this.state.message !== '' || this.state.compTxt !== '') &&
          <div className="row margin-bottom1">
            <div className={(this.state.message !== '' && 'small-12 columns warning_msg6')
            || (this.state.compTxt !== '' && 'small-12 columns warning_msg7')} >
              <div className="float_left1">
              {this.state.message !== '' && <WarningIcon />}
              {this.state.compTxt !== '' && <CheckCircleOutlineIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.state.message || this.state.compTxt }</div>
            </div>
          </div>}
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns headerPage3_2">
              In the past week...
            </div>
            <div className="small-12 columns box_border2">
              <b>Instructions: </b>
              {(lang=='en' && 'On a scale of 0 to 10, where 0 is “I have no thoughts about exercising” and 10 is “I have started to exercise or I exercise regularly, click on the circle that best shows where you are right now in your feelings about exercising.')
              || (lang=='es' && 'En una escala del 0 al 10, donde 0 es “No tengo pensamientos sobre hacer ejercicio” y 10 es “He comenzado a ejercitar o ejercito regularmente”, cliquee en el circulo que mejor muestra dónde sus sentimientos en este momento acerca de hacer ejercicio.')}
            </div>
            <div className="small-12 columns box_border2">
            { choices.map(each =>
              <div className="row" key={'sc'+each.value}>

                <div className="small-12 columns " key={'qs'+each.value}>
                  <input type="radio" name={'q1'} value={each.value}
                  disabled={this.state.completed_status && 'disabled'}
                  checked={this.state.q1 == each.value && 'checked'}
                  onChange={(e) => this.handleChange2(e, 'q1')}/>
                  <span>{each.value} {each.label.length > 0 && '-'} {each.label}</span>
                </div>
                {each.value != 10 &&
                <div className="small-12 columns">
                  <ArrowDownwardIcon className="arrow1" />
                </div>}
              </div>)}
            </div>
          </div>
        </div>
        {!this.state.completed_status &&
        <div className="small-12 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Submit
          </Button>
        </div>}
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Motivation)
