import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import Button from '@material-ui/core/Button'
import moment from 'moment'
import { par_q_questions, yes_no } from '../../../../variables/registration.js'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'

class ParQ extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        q1: '',
        q2: '',
        q3: 0,
        q4: 0,
        q5: 0,
        q6: 0,
        q7: 0,
        eligible: 0,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/participant/check_completed_screen_parq',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].q1 > 0) {
        this.setState({
          q1: result['data'].q1,
          q2: result['data'].q2,
          q3: result['data'].q3,
          q4: result['data'].q4,
          q5: result['data'].q5,
          q6: result['data'].q6,
          q7: result['data'].q7,
          eligible: result['data'].eligible,
          completed_status: true,
        })
      } /*else {
        this.setState({
          eligible: result['data'].eligible,
          completed_status: true,
        })
      }*/
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.q1 != 0 && this.state.q2!= 0 && this.state.q3 != 0
      && this.state.q4 != 0 && this.state.q5 != 0
      && this.state.q6 != 0 && this.state.q7 ) {
        var pid = this.props.match.params.pid
        axios({
          method: 'post',
          url: connectNode + 'admin/participant/add_screen_parq',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            pid: pid,
            user: this.state,
          }
        })
        .then((result) => {
          var message = ''
          if(result['data'] == pid) {
            this.props.onUpdateTab('3', 'Thank you for submitting PAR-Q!')
          } else {
            message = 'Something went wrong. Please try again.'
          }
          if(message !== '') {
            this.setState({
              message: message
            })
            window.scrollTo(0, 0)
          }
        })
    } else {
      this.setState({
        message: 'Please fill all information',
      })
      window.scrollTo(0, 0)
    }
  }

  render() {
    //console.log(this.state)
    return (
    <div className="">
        <div className="headerPage3_1">
          PHYSICAL ACTIVITY READINESS QUESTIONNAIRE (PAR-Q)
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { (this.state.message !== '' || this.props.compTxt !== '') &&
          <div className="row margin-bottom1">
            <div className={(this.state.message !== '' && 'small-12 columns warning_msg6') || (this.props.compTxt !== '' && 'small-12 columns warning_msg7')} >
              <div className="float_left1">
              {this.state.message !== '' && <WarningIcon />}
              {this.props.compTxt !== '' && <CheckCircleOutlineIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.state.message || this.props.compTxt }</div>
            </div>
          </div>}

          <div className="small-12 columns">
          {par_q_questions.map(eachQ =>
            <div className="row space_bottom2">
              <div className="small-12 columns bold_txt2">{eachQ.value+'. '+eachQ.label}</div>
              <div className="small-12 columns float_left">
              {yes_no.map(each =>
              <div className="small-12 medium-2 columns float_left" key={'q'+eachQ.value+each.value}>
                <input type="radio" name={'q'+eachQ.value} value={each.value}
                disabled={(this.state.eligible === 2 || this.state.completed_status) && 'disabled'}
                checked={this.state["q"+eachQ.value] == each.value && 'checked'}
                onChange={(e) => this.handleChange(e)}/>
                <span>{each.label}</span>
              </div>)}
              </div>
            </div>)}
          </div>

        </div>
        {!this.state.completed_status &&
        <div className="small-12 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Submit
          </Button>
        </div>}
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ParQ)
