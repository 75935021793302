export const promis_pf_questions1 = [
  {
    label: 'Are you able to do chores such as vacuuming or yard work?',
    value: 1,
  }, {
    label: 'Are you able to up and down stairs at a normal pace?',
    value: 2,
  }, {
    label: 'Are you able to go for a walk of at least 15 minutes?',
    value: 3,
  }, {
    label: 'Are you able to run errands and shop?',
    value: 4,
  },
]

export const promis_pf_questions1_es = [
  {
    label: '¿Puede realizar tareas, como pasar la aspiradora o trabajar en el jardín?',
    value: 1,
  }, {
    label: '¿Puede subir y bajar escaleras a un paso normal?',
    value: 2,
  }, {
    label: '¿Puede salir a caminar durante 15 minutos por lo menos?',
    value: 3,
  }, {
    label: '¿Puede hacer mandados (recados) y compras?',
    value: 4,
  },
]

export const promis_pf_scale1 = [
  {
    label: 'Without any difficulty',
    value: 5,
  }, {
    label: 'With a little difficulty',
    value: 4,
  }, {
    label: 'With some difficulty',
    value: 3,
  }, {
    label: 'With much difficulty',
    value: 2,
  }, {
    label: 'Unable to do',
    value: 1,
  },
]

export const promis_pf_scale1_es = [
  {
    label: 'Sin dificultad',
    value: 5,
  }, {
    label: 'Con poca dificultad',
    value: 4,
  }, {
    label: 'Con alguna dificultad',
    value: 3,
  }, {
    label: 'Con mucha dificultad',
    value: 2,
  }, {
    label: 'No puedo hacerlo',
    value: 1,
  },
]

export const promis_pf_questions2 = [
  {
    label: 'Does your health now limit you in doing two hours of physical labor?',
    value: 5,
  }, {
    label: 'Does your health now limit you in doing moderate work around the house like vacuuming, sweeping floors or carrying in groceries?',
    value: 6,
  }, {
    label: 'Does your health now limit you in lifting or carrying groceries?',
    value: 7,
  }, {
    label: 'Does your health now limit you in doing heavy work around the house like scrubbing floors, or lifting or moving heavy furniture?',
    value: 8,
  },
]

export const promis_pf_questions2_es = [
  {
    label: '¿Limita su salud en este momento su capacidad para realizar dos horas de trabajo físico?',
    value: 5,
  }, {
    label: '¿Limita su salud en este momento su capacidad para realizar trabajos moderados en el hogar, como pasar la aspiradora, barrer el piso (suelo) o entrar a la casa las compras del mercado?',
    value: 6,
  }, {
    label: '¿Limita su salud en este momento su capacidad para levantar las bolsas del mercado o hacer mercado?',
    value: 7,
  }, {
    label: '¿Limita su salud en este momento su capacidad para hacer trabajos fuertes en el hogar como fregar el suelo, levantar o mover muebles pesados?',
    value: 8,
  },
]

export const promis_pf_scale2 = [
  {
    label: 'Not at all',
    value: 5,
  }, {
    label: 'Very little',
    value: 4,
  }, {
    label: 'Somewhat',
    value: 3,
  }, {
    label: 'Quite a lot',
    value: 2,
  }, {
    label: 'Cannot do',
    value: 1,
  },
]

export const promis_pf_scale2_es = [
  {
    label: 'Nada',
    value: 5,
  }, {
    label: 'Poco',
    value: 4,
  }, {
    label: 'Algo',
    value: 3,
  }, {
    label: 'Mucho',
    value: 2,
  }, {
    label: 'No puedo hacerlo',
    value: 1,
  },
]
