import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import '../../../styles/assets/css/pages/assm_eval.css'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import Edit from '@material-ui/icons/Edit'
import Cookies from 'js-cookie'

class ParticipantContract extends Component {
  constructor() {
    super()
  }

  render() {
    const lang = Cookies.get('lang_')
    var pid = 0
    var prefix_link = '/member'
    if(this.props.match.params.pid > 0) {
      pid = this.props.match.params.pid
      prefix_link = '/admin/participant_contract/'+pid
    }
    return (
    <div className="assm_page1">
        <div className="headerPage1">
          Participant's Contracts
        </div>
        <div className="small-12 columns box_assm1">
          <div className="row">
            <div className="small-10 columns blue1">
              {(lang=='en' && 'Commitment to class and practice sessions' || lang=='es' && 'EL COMPROMISO A LA CLASE Y A LAS SESIONES DE PRÁCTICA')}
            </div>
            <div className="small-1 columns">
              <a href={prefix_link+"/frm_commitment"} className="">Edit</a>
            </div>
            {pid == 0 &&
            <div className="small-1 columns">
              <a href={'/member/participant_contract_pdf/1'} className="icon_link1"><PictureAsPdfIcon /></a>
            </div>}
            <div className="small-12 columns sep_line1">&nbsp;</div>
          </div>
          <div className="row">
            <div className="small-10 columns blue1">
              {(lang=='en' && 'Individual plan for maintaining an active lifestyle' || lang=='es' && 'PLAN INDIVIDUAL PARA MANTENER UN ESTILO DE VIDA ACTIVO')}
            </div>
            <div className="small-1 columns">
              <a href={prefix_link+"/frm_individual_plan"} className="">Edit</a>
            </div>
            {pid == 0 &&
            <div className="small-1 columns">
              <a href={'/member/participant_contract_pdf/2'} className="icon_link1"><PictureAsPdfIcon /></a>
            </div>}
            <div className="small-12 columns sep_line1">&nbsp;</div>
          </div>
          <div className="row">
            <div className="small-10 columns blue1">
              {(lang=='en' && 'Revisiting your goals from the beginning of the program' || lang=='es' && 'REVISANDO LAS METAS DEL PROGRAMA DESDE EL COMIENZO')}
            </div>
            <div className="small-1 columns">
              <a href={prefix_link+"/frm_visiting"} className="">Edit</a>
            </div>
            {pid == 0 &&
            <div className="small-1 columns">
              <a href={'/member/participant_contract_pdf/3'} className="icon_link1"><PictureAsPdfIcon /></a>
            </div>}
            {pid > 0 && <div className="small-1 columns">&nbsp;</div>}
          </div>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ParticipantContract)
//export default connect(mapStateToProps, mapDispatchToProps)(ParticipantContract)
