import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, Link} from "react-router-dom"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Pagination from '../../functions/pagination'
import '../../../styles/assets/css/admin/template.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import Person from '@material-ui/icons/Person'
import ReactHtmlParser from 'react-html-parser'

class TopicList extends Component {
  constructor() {
      super();
      //var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
      this.state = {
          dataItems: [], //exampleItems,
          pageOfItems: [],
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/forum/topic_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid }
    })
    .then((result) => {
      if(result['data'].length > 0) {
        this.setState({
          dataItems: result['data'],
        })
      }
    })
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
    <div className="assm_page1 forum_page">
      <div className="row">
        <div className="small-6 medium-9 columns headerPage1">
          Forum
        </div>
        <div className="small-6 medium-3 columns">
          <NavLink to='/admin/forum/new_forum' className="menu_list_active new_bttn">
            New Topic
          </NavLink>
        </div>
      </div>
      <div className="row ">
      { typeof this.props.locationReducer.txt_message !== 'undefined' &&
        this.props.locationReducer.txt_message !== '' &&
        <div className="small-12 columns ">
          <div className="warning_msg3 warning_msg3_1">
            <div className="float_left1"><CheckCircleOutlineIcon /></div>
            <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
          </div>
        </div>
      }
        <div className="small-12 columns ">
          <div className="forum_list">
            {this.state && this.state.dataItems && this.state.pageOfItems.map(item =>
            <div key={item.id} className="each_topic" >
              <div className="topic_by">
                <Person /> {item.fullname}, {item.created_datetime},
                Group: {item.group_id}, Comments: {item.cnt_comments}
              </div>
              <div>
                <Link to={'/admin/forum/topic/'+item.id} >{ReactHtmlParser(item.title)}</Link>
              </div>
            </div>)}

            {this.state && this.state.dataItems &&
              <div>
                  <Pagination items={this.state.dataItems} pageSize={20} onChangePage={this.onChangePage} />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(TopicList)
