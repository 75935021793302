export const baseline_assm_steps = [
  {
    label: 'Demographics',
    value: 1,
  }, {
    label: 'Geri-AIMS',
    value: 2,
  }, {
    label: 'WOMAC Osteoarthritis Index',
    value: 3,
  }, {
    label: 'Healthcare Utilization',
    value: 4,
  }, {
    label: 'PROMIS Physical Function',
    value: 5,
  }, {
    label: 'Fall History',
    value: 6,
  }, {
    label: 'Exercise Confidence',
    value: 7,
  }, {
    label: 'WHO-5',
    value: 8,
  }, {
    label: 'PHQ-9',
    value: 9,
  }, {
    label: 'GAD-7',
    value: 10,
  }, {
    label: '11.	PROMIS Social Isolation',
    value: 11,
  }, {
    label: 'Motivation to Change',
    value: 12,
  },
]

export const yes_no = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  },
]

export const yes_no_es = [
  {
    label: 'Si',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  },
]

export const yes_no_prefer = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  }, {
    label: 'Prefer not to answer',
    value: 3,
  },
]

export const yes_no_prefer_es = [
  {
    label: 'Si',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  }, {
    label: 'Prefiero no responder',
    value: 3,
  },
]

export const tp_type = [
  {
    label: 'Baseline',
    value: 'b',
  }, {
    label: '8 weeks',
    value: 'w',
  }, {
    label: '3 months',
    value: 'm',
  },
]
