import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import ReactHtmlParser from 'react-html-parser'
import Cookies from 'js-cookie'

class AssmEval extends Component {
  constructor() {
    super()
    this.state = {
      week8: {},
      month3: {},
      timeline_8w: '',
      timeline_3m: '',
    }
  }

  componentDidMount() {
    this.getProcess()
  }

  getProcess = () => {
    // get timeline //
    axios({
      method: 'post',
      url: connectNode + 'participants/followup/get_followup_timeline',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].length > 0) {
        var get_data_w = ''
        var get_data_m = ''
        result['data'].map(each => {
          this.setState({
            timeline_8w: each.date_8weeks,
            timeline_3m: each.date_3months,
          })
        })
      }
    })
    // get assm //
    axios({
      method: 'post',
      url: connectNode + 'participants/followup/get_followup_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].length > 0) {
        var get_data_w = {}
        var get_data_m = {}
        result['data'].map(each => {
          if(each.tp_type == 'w') {
            get_data_w = each
          } else if(each.tp_type == 'm') {
            get_data_m = each
          }
        })
        this.setState({
          week8: get_data_w,
          month3: get_data_m,
        })
      }
    })
  }

  genAvailableCompletedIcon = (timepoint, assm) => {
    const getData = this.state[timepoint]
    var genIcon = ''
    const lang = Cookies.get('lang_')
    if(getData[assm+'_status'] == 2) {
      if(lang=='es') {
        genIcon = '<span class="butt_completed">Completado</span>'
      } else {
        genIcon = '<span class="butt_completed">Completed</span>'
      }
    } else {
      if(lang=='es') {
        genIcon = '<span class="butt_available">Disponible</span>'
      } else {
        genIcon = '<span class="butt_available">Available</span>'
      }
    }
    return genIcon
  }

  render() {
    var today = new Date().getTime()

    var w8_date = this.state.timeline_8w.split("-")
    var ts_w8_date = new Date( w8_date[0], w8_date[1] - 1, w8_date[2]).getTime()

    var m3_date = this.state.timeline_3m.split("-")
    var ts_m3_date = new Date( m3_date[0], m3_date[1] - 1, m3_date[2]).getTime()

    const lang = Cookies.get('lang_')
    return (
    <div className="assm_page1">
        <div className="headerPage1">
          {(lang=='en' && 'Assessments & Evaluation') || lang=='es' && 'Encuestas e Evaluaciones'}
        </div>
        <div className="small-12 columns box_assm1">
          {today > ts_w8_date &&
          <div className="small-12 medium-6 columns">
            <div className="small-12 columns header2 sep_line1">8 {(lang=='en' && 'Weeks') || lang=='es' && 'Semanas'}</div>
            <div className="small-12 columns">
              <ul>
                <li>
                  <a href="/member/followup/promis_pf/w/8">PROMIS Physical Function</a> &nbsp;
                  {ReactHtmlParser(this.genAvailableCompletedIcon('week8', 'promis_pf'))}
                </li>
                <li>
                  <a href="/member/followup/womac/w/8">WOMAC</a> &nbsp;
                  {ReactHtmlParser(this.genAvailableCompletedIcon('week8', 'womac'))}
                </li>
                <li>
                  <a href="/member/followup/exercise_confidence/w/8">Exercise Confidence</a> &nbsp;
                  {ReactHtmlParser(this.genAvailableCompletedIcon('week8', 'exercise_confidence'))}
                </li>
                <li>
                  <a href="/member/followup/who5/w/8">WHO-5</a> &nbsp;
                  {ReactHtmlParser(this.genAvailableCompletedIcon('week8', 'who5'))}
                </li>
                <li>
                  <a href="/member/followup/phq9/w/8">PHQ-9</a> &nbsp;
                  {ReactHtmlParser(this.genAvailableCompletedIcon('week8', 'phq9'))}
                </li>
                <li>
                  <a href="/member/followup/gad7/w/8">GAD-7</a> &nbsp;
                  {ReactHtmlParser(this.genAvailableCompletedIcon('week8', 'gad7'))}
                </li>
                <li>
                  <a href="/member/followup/promis_si/w/8">PROMIS Social Isolation</a> &nbsp;
                  {ReactHtmlParser(this.genAvailableCompletedIcon('week8', 'promis_si'))}
                </li>
                <li>
                  <a href="/member/followup/csq8_program/w/8">CSQ-8_Program</a> &nbsp;
                  {ReactHtmlParser(this.genAvailableCompletedIcon('week8', 'csq8_program'))}
                </li>
                <li>
                  <a href="/member/followup/csq8_platform/w/8">CSQ-8_Platform</a> &nbsp;
                  {ReactHtmlParser(this.genAvailableCompletedIcon('week8', 'csq8_platform'))}
                </li>
              </ul>
            </div>
          </div>}
          {today > ts_m3_date &&
          <div className="small-12 medium-6 columns">
            <div className="small-12 columns header2 sep_line1">3 {(lang=='en' && 'Months') || lang=='es' && 'Meses'}</div>
            <div className="small-12 columns">
            <ul>
              <li>
                <a href="/member/followup/promis_pf/m/3">PROMIS Physical Function</a> &nbsp;
                {ReactHtmlParser(this.genAvailableCompletedIcon('month3', 'promis_pf'))}
              </li>
              <li>
                <a href="/member/followup/womac/m/3">WOMAC</a> &nbsp;
                {ReactHtmlParser(this.genAvailableCompletedIcon('month3', 'womac'))}
              </li>
              <li>
                <a href="/member/followup/exercise_confidence/m/3">Exercise Confidence</a> &nbsp;
                {ReactHtmlParser(this.genAvailableCompletedIcon('month3', 'exercise_confidence'))}
              </li>
              <li>
                <a href="/member/followup/who5/m/3">WHO-5</a> &nbsp;
                {ReactHtmlParser(this.genAvailableCompletedIcon('month3', 'who5'))}
              </li>
              <li>
                <a href="/member/followup/phq9/m/3">PHQ-9</a> &nbsp;
                {ReactHtmlParser(this.genAvailableCompletedIcon('month3', 'phq9'))}
              </li>
              <li>
                <a href="/member/followup/gad7/m/3">GAD-7</a> &nbsp;
                {ReactHtmlParser(this.genAvailableCompletedIcon('month3', 'gad7'))}
              </li>
              <li>
                <a href="/member/followup/promis_si/m/3">PROMIS Social Isolation</a> &nbsp;
                {ReactHtmlParser(this.genAvailableCompletedIcon('month3', 'promis_si'))}
              </li>
              <li>
                <a href="/member/followup/csq8_program/m/3">CSQ-8_Program</a> &nbsp;
                {ReactHtmlParser(this.genAvailableCompletedIcon('month3', 'csq8_program'))}
              </li>
              <li>
                <a href="/member/followup/csq8_platform/m/3">CSQ-8_Platform</a> &nbsp;
                {ReactHtmlParser(this.genAvailableCompletedIcon('month3', 'csq8_platform'))}
              </li>
            </ul>
            </div>
          </div>}
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AssmEval)
