import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux'
import AuthService from '../../services/auth/auth.service.js'
import { logoutUser } from '../../actions/auth'
import axios from 'axios'
import { connectNode } from '../../utils/global'
import { AppBar, Toolbar } from '@material-ui/core'
import ListAltIcon from '@material-ui/icons/ListAlt';
import DashboardIcon from '@material-ui/icons/Dashboard'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import MenuBook from '@material-ui/icons/MenuBook'
import DescriptionIcon from '@material-ui/icons/Description'
import VideoLibrary from '@material-ui/icons/VideoLibrary'
import ForumIcon from '@material-ui/icons/Forum'
import Help from '@material-ui/icons/Help'
import StorageIcon from '@material-ui/icons/Storage'
import Face from '@material-ui/icons/Face'
import { CgMail } from 'react-icons/cg'
//import BookIcon from '@material-ui/icons/Book'
import AssessmentIcon from '@material-ui/icons/Assessment'
import SettingsIcon from '@material-ui/icons/Settings'
import { NavDrawer } from "./NavDrawer"
import Cookies from 'js-cookie'

class NavBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpened: false,
      reg_status: 0,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'participants/registration/get_registration_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.uid }
    })
    .then((result) => {
      this.setState({
        reg_status: result['data'].status,
      })
    })
  }

  toggleDrawer = booleanValue => () => {
    this.setState({
      drawerOpened: booleanValue
    })
  }

  onReloadLogoutPages = () => {
    //console.log('Navbar onReloadPages')
    this.props.onLoadLogoutPages()
  }

  onReloadPages = (route) => {
    //console.log('Navbar onReloadPages')
    this.props.onLoadPages(route)
  }

  handleLogout() {
    AuthService.logout()
    this.onReloadLogoutPages()
  }

  handlePage(route) {
    this.onReloadPages(route)
  }

  render() {
    const lang = Cookies.get('lang_')
    var url_origin = window.location.origin
    return (
      <div className="main_menu">
      <AppBar position="static">
        <Toolbar>
          <div className="show-for-medium">
            { this.props.role == 2 &&
              <div className="menu_list">
              <NavLink to='/admin/participant_list' activeClassName="menu_list_active">
                <ListAltIcon />
                <div className="menu_list_name">PARTICIPANTS</div>
              </NavLink>
            </div> }
            { this.props.role >= 1 && this.props.role <= 3 &&
              <div className="menu_list">
              <NavLink to='/admin/group_setting' activeClassName="menu_list_active">
                <DashboardIcon />
                <div className="menu_list_name">GROUP SETTINGS</div>
              </NavLink>
            </div> }
            { this.props.role >= 1 && this.props.role <= 3 &&
              <div className="menu_list">
              <NavLink to='/admin/events/event_list' activeClassName="menu_list_active">
                <CalendarTodayIcon />
                <div className="menu_list_name">CLASSES</div>
              </NavLink>
            </div> }
            { this.props.role >= 1 && this.props.role <= 3 &&
              <div className="menu_list">
              <NavLink to='/admin/focus_blog/focus_blog/1' activeClassName="menu_list_active">
                <Face />
                <div className="menu_list_name">SPOTLIGHT</div>
              </NavLink>
            </div> }
            { this.props.role == 2 &&
            <div className="menu_list">
              <NavLink to="/admin/forum/topic_list" activeClassName="menu_list_active">
                <ForumIcon />
                <div className="menu_list_name">GET IN TOUCH</div>
              </NavLink>
            </div>}
            { this.props.role == 2 &&
              <div className="menu_list">
              <NavLink to='/admin/nia_croms' activeClassName="menu_list_active">
                <AssessmentIcon />
                <div className="menu_list_name">NIA CROMS</div>
              </NavLink>
            </div> }
            { this.props.role == 2 &&
              <div className="menu_list">
              <NavLink to='/admin/report_list' activeClassName="menu_list_active">
                <StorageIcon />
                <div className="menu_list_name">REPORT</div>
              </NavLink>
            </div> }
            { this.props.role == 5 && (this.props.r_step > 1 || this.state.reg_status > 1) &&
            <div className="menu_list">
              <NavLink to='/member/dashboard' activeClassName="menu_list_active" onClick={this.handlePage('dashboard')}>
                <DashboardIcon />
                <div className="menu_list_name">{(lang=='en' && 'DASHBOARD') || (lang=='es' && 'Tablero')}</div>
              </NavLink>
            </div>}
            { this.props.role == 5 && (this.props.r_step > 1 || this.state.reg_status > 1) &&
            <div className="menu_list">
              <NavLink to='/member/assessment_evaluation' activeClassName="menu_list_active">
                <AssessmentIcon />
                <div className="menu_list_name">{(lang=='en' && 'PARTICIPANT QUESTIONNAIRES') || (lang=='es' && 'Cuestionarios del participante')}</div>
              </NavLink>
            </div>}
            { this.props.role == 5 && (this.props.r_step > 1 || this.state.reg_status > 1) &&
            <div className="menu_list">
              <NavLink to='/member/participant_manual' activeClassName="menu_list_active">
                <MenuBook />
                <div className="menu_list_name">{(lang=='en' && 'PARTICIPANT MANUAL') || (lang=='es' && 'Manual del participante')}</div>
              </NavLink>
            </div>}
            { this.props.role == 5 && (this.props.r_step > 1 || this.state.reg_status > 1) &&
            <div className="menu_list">
              <NavLink to="/member/participant_contract" activeClassName="menu_list_active">
                <DescriptionIcon />
                <div className="menu_list_name">{(lang=='en' && 'PARTICIPANT CONTRACTS') || (lang=='es' && 'Contratos del participante')}</div>
              </NavLink>
            </div>}
            { this.props.role == 5 && (this.props.r_step > 1 || this.state.reg_status > 1) &&
            <div className="menu_list">
              <NavLink to="/member/recorded_classes" activeClassName="menu_list_active">
                <VideoLibrary />
                <div className="menu_list_name">{(lang=='en' && 'RECORDED CLASSES') || (lang=='es' && 'Clases grabadas')}</div>
              </NavLink>
            </div>}
            { this.props.role == 5 && (this.props.r_step > 1 || this.state.reg_status > 1) &&
            <div className="menu_list">
              <NavLink to="/member/getintouch/getintouch" activeClassName="menu_list_active">
                <ForumIcon />
                <div className="menu_list_name">{(lang=='en' && 'GET IN TOUCH') || (lang=='es' && 'Ponte en Contacto')}</div>
              </NavLink>
            </div>}
            { this.props.role == 5 && (this.props.r_step > 1 || this.state.reg_status > 1) &&
            <div className="menu_list">
              <NavLink to="/member/help" activeClassName="menu_list_active">
                <Help />
                <div className="menu_list_name">{(lang=='en' && 'HELP') || (lang=='es' && 'Ayuda')}</div>
              </NavLink>
            </div>}
            <div className="menu_list margin-bttom-1">
              <NavLink to="/user/settings" activeClassName="menu_list_active">
                <SettingsIcon />
                <div className="menu_list_name">{(lang=='en' && 'SETTINGS') || (lang=='es' && 'Configuraciones')}</div>
              </NavLink>
            </div>
          </div>
        </Toolbar>
      </AppBar>

        <NavDrawer
          drawerOpened={this.state.drawerOpened}
          toggleDrawer={this.toggleDrawer}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('NavBar STATE: ', state)
  return {
    uid: state.authReducer.uid,
    role: state.authReducer.role,
    p_st: state.authReducer.p_st,
    r_step: state.authReducer.r_step,
    //reg_status: state.authReducer.reg_status,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoadLogoutPages() {
    //console.log('NavBar mapDispatchToProps')
    dispatch(logoutUser())
  },
  onLoadPages(route) {
    //console.log('NavBar updateLocation: '+route)
    //dispatch(updateLocation(route))
    dispatch({
      type: 'UPDATE_LOCATION',
      payload: {route: route},
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
