import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, Link} from "react-router-dom"
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import moment from 'moment'
import validator from 'validator'
import FaceIcon from '@material-ui/icons/Face'
import MessageIcon from '@material-ui/icons/Message'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'

class ChatList extends Component {
  constructor() {
    super()
    this.state = {
      nameList: [],
    }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'participants/messenger/chat_group_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid }
    })
    .then((result) => {
      console.log(result['data'])
      if(result['data'].length > 0) {
        this.setState({
          nameList: result['data'],
        })
      }
    })
  }

  render() {
    return (
    <div className="small-12 columns chat_name_list_padding">
      <div className="conversation_list_wrapper">
        {this.state.nameList.map(each =>
        <div className="row mod_each_name1">
          <a href={"/member/getintouch/message_list/"+each.id}>
          <div className="small-3 columns padding-left2">
            <FaceIcon />
          </div>
          <div className="small-9 columns padding-left1">
            {each.conversation_names}
            {each.last_msg_timestamp > each.last_read_timestamp &&
            <FiberManualRecordIcon className="icon_unread" />
            }
          </div>
          </a>
        </div>)}
      </div>
    </div>
  )}
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ChatList)
