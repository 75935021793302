export const followup_assm_steps = [
  {
    label: 'PROMIS Physical Function',
    value: 1,
  }, {
    label: 'WOMAC',
    value: 2,
  }, {
    label: 'Exercise Confidence',
    value: 3,
  }, {
    label: 'WHO-5',
    value: 4,
  }, {
    label: 'PHQ-9',
    value: 5,
  }, {
    label: 'GAD-7',
    value: 6,
  }, {
    label: 'PROMIS Social Isolation',
    value: 7,
  }, {
    label: 'CSQ-8',
    value: 8,
  },
]
