import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import WarningIcon from '@material-ui/icons/Warning'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import GetImage from '../../functions/get_image.jsx'
import ReactHtmlParser from 'react-html-parser'

class FocusBlog extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        message: '',
        uid: '',
        p_name: '',
        p_content: '',
        p_status: 0,
        photo_name: '',
        //img: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/focus_blog/get_focus_blog',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid }
    })
    .then((result) => {
      this.setState({
        uid: this.props.authReducer.uid,
        p_name: result['data'].name,
        p_content: result['data'].content,
        photo_name: result['data'].file_name,
        p_status: result['data'].status,
      })
    })
  }

  render() {
    console.log(this.state.p_content)
    return (
    <div className="assm_page1 admin_page focus_blog">
        <div className="headerPage1">
          Spotlight on . . .
        </div>
        <div className="small-12 columns box_assm1 more_padding1">

          <div className="row top_space1">
            <div className="small-12 columns center_photo">
              <GetImage path="get_focus_blog_img" />
            </div>
          </div>

          <div className="row top_space1">
            <div className="small-12 columns bold top_space1">
              {this.state.p_name}
            </div>
          </div>

          <div className="row top_space1">
            <div className="small-12 columns top_space1 content_focus_blog">
              {ReactHtmlParser(this.state.p_content)}
            </div>
          </div>

        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(FocusBlog)
