import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import Cookies from 'js-cookie'

class ParticipantsManual extends Component {
  constructor() {
    super()
  }

  render() {
    const lang = Cookies.get('lang_')
    return (
    <div className="assm_page1">
        <div className="headerPage1">
          Participant Manual
        </div>
        <div className="small-12 columns box_assm1">
          <div className="title_assm1">Session Topics</div>
          <div className="">
            <ul>
              <li><a href={(lang=='en' && "../manual/Session1.pdf") || (lang=='es' && "../manual/Sesion1_es.pdf")} >
              {(lang=='en' && "Session 1: Introduction to Fit & Strong!") ||
              (lang=='es' && "Sesión1: Introducción para los participantes de ¡Fuerte y en Forma!")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session2.pdf") || (lang=='es' && "../manual/Sesion2_es.pdf")} >
              {(lang=='en' && "Session 2: Getting Started") ||
              (lang=='es' && "Sesión 2: Primeros Pasos")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session3.pdf") || (lang=='es' && "../manual/Sesion3_es.pdf")} >
              {(lang=='en' && "Session 3: What to Wear & Foot Care") ||
              (lang=='es' && "Sesión 3: Qué Ropa Ponerse")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session4.pdf") || (lang=='es' && "../manual/Sesion4_es.pdf")} >
              {(lang=='en' && "Session 4: Osteoarthritis") ||
              (lang=='es' && "Sesión 4: Osteoartritis")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session5.pdf") || (lang=='es' && "../manual/Sesion5_es.pdf")} >
              {(lang=='en' && "Session 5: Managing Osteoarthritis") ||
              (lang=='es' && "Sesión 5: Manejando la osteoartritis por medio del ejercicio")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session6.pdf") || (lang=='es' && "../manual/Sesion6_es.pdf")} >
              {(lang=='en' && "Session 6: Pain & Osteoarthritis") ||
              (lang=='es' && "Sesión 6: Dolor y Osteoartritis")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session7.pdf") || (lang=='es' && "../manual/Sesion7_es.pdf")} >
              {(lang=='en' && "Session 7: Warm-Up Exercises") ||
              (lang=='es' && "Sesión 7: Ejercicios de Calentamiento")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session8.pdf") || (lang=='es' && "../manual/Sesion8_es.pdf")} >
              {(lang=='en' && "Session 8: Stretching") ||
              (lang=='es' && "Sesión 8: Ejercicios de estiramiento")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session9.pdf") || (lang=='es' && "../manual/Sesion9_es.pdf")} >
              {(lang=='en' && "Session 9: Aerobic Exercise") ||
              (lang=='es' && "Sesión 9: Ejercicios aeróbicos")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session10.pdf") || (lang=='es' && "../manual/Sesion10_es.pdf")} >
              {(lang=='en' && "Session 10: Walking") ||
              (lang=='es' && "Sesión 10: Caminar")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session11.pdf") || (lang=='es' && "../manual/Sesion11_es.pdf")} >
              {(lang=='en' && "Session 11: Strengthening Exercises") ||
              (lang=='es' && "Sesión 11: Ejercicios de fortalecimiento")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session12.pdf") || (lang=='es' && "../manual/Sesion12_es.pdf")} >
              {(lang=='en' && "Session 12: Resistance Training to Improve or Maintain Strength") ||
              (lang=='es' && "Sesión 12: Entrenamiento de resistencia para mejorar o mantener la fuerza")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session13.pdf") || (lang=='es' && "../manual/Sesion13_es.pdf")} >
              {(lang=='en' && "Session 13: Cool-Down Exercises") ||
              (lang=='es' && "Sesión 13: Ejercicios de enfriamiento")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session14.pdf") || (lang=='es' && "../manual/Sesion14_es.pdf")} >
              {(lang=='en' && "Session 14: Posture & Bone Health") ||
              (lang=='es' && "Sesión 14: La postura y la salud de los huesos")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session15.pdf") || (lang=='es' && "../manual/Sesion15_es.pdf")} >
              {(lang=='en' && "Session 15: Fall Prevention") ||
              (lang=='es' && "Sesión 15: Prevención de Caídas")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session16.pdf") || (lang=='es' && "../manual/Sesion16_es.pdf")} >
              {(lang=='en' && "Session 16: Negotiated Adherence Contract") ||
              (lang=='es' && "Sesión 16: Contrato Negociado de Adherencia")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session17.pdf") || (lang=='es' && "../manual/Sesion17_es.pdf")} >
              {(lang=='en' && "Session 17: Other Ways to Do Exercises") ||
              (lang=='es' && "Sesión 17: Otras Formas de Hacer Ejercicio")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session18.pdf") || (lang=='es' && "../manual/Sesion18_es.pdf")} >
              {(lang=='en' && "Session 18: Lifestyle Changes") ||
              (lang=='es' && "Sesión 18: Cambios de Estilo de Vida")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session19.pdf") || (lang=='es' && "../manual/Sesion19_es.pdf")} >
              {(lang=='en' && "Session 19: Exercise: A World of Options") ||
              (lang=='es' && "Sesión 19: Ejercicio: Un Mundo de Opciones")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session20.pdf") || (lang=='es' && "../manual/Sesion20_es.pdf")} >
              {(lang=='en' && "Session 20: Getting Past Barriers") ||
              (lang=='es' && "Sesión 20: Superando las barreras")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session21.pdf") || (lang=='es' && "../manual/Sesion21_es.pdf")} >
              {(lang=='en' && "Session 21: Diet & Exercise") ||
              (lang=='es' && "Sesión 21: Dieta & Ejercicio")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session22.pdf") || (lang=='es' && "../manual/Sesion22_es.pdf")} >
              {(lang=='en' && "Session 22: Stress Management") ||
              (lang=='es' && "Sesión 22: El manejo del estrés")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session23.pdf") || (lang=='es' && "../manual/Sesion23_es.pdf")} >
              {(lang=='en' && "Session 23: Maintaining an Active Lifestyle") ||
              (lang=='es' && "Sesión 23: Manteniendo un Estilo de Vida Activo")}</a></li>
              <li><a href={(lang=='en' && "../manual/Session24.pdf") || (lang=='es' && "../manual/Sesion24_es.pdf")} >
              {(lang=='en' && "Session 24: Putting It All Together Complete Exercise Routine") ||
              (lang=='es' && "Sesión 24: Juntando Todo lo Aprendido")}</a></li>
            </ul>
          </div>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsManual)
