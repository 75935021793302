import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'

import { baseline_assm_steps } from '../../variables/baseline_assm.js'
import { followup_assm_steps } from '../../variables/followup_assm.js'
import { csq8_questions1, csq8_scale1, csq8_scale2, csq8_scale3,
         csq8_scale4, csq8_scale5, csq8_scale6, csq8_scale7,
         csq8_scale8, csq8_questions1_es, csq8_scale1_es, csq8_scale2_es,
         csq8_scale3_es, csq8_scale4_es, csq8_scale5_es, csq8_scale6_es,
         csq8_scale7_es, csq8_scale8_es  } from '../../variables/baseline_csq8.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Cookies from 'js-cookie'

class Csq8 extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        compTxt: '',
        timepoint: 0,
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
        q5: 0,
        q6: 0,
        q7: 0,
        q8: 0,
    }
  }

  componentDidMount() {
    this.getProcess()
  }

  getProcess = () => {
    var timepoint = 0
    if(this.props.match.params.timepoint > 0) {
      timepoint = this.props.match.params.timepoint
    }
    var get_uri = this.props.location.pathname
    var sep_uri = get_uri.split("/")
    //console.log(sep_uri[2])
    if(sep_uri[2] == 'followup') {
      axios({
        method: 'post',
        url: connectNode + 'participants/followup/get_followup_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: this.props.authReducer.uid,
          assm: 'csq8',
          tp_type: this.props.match.params.tp_type,
          timepoint: timepoint,
        }
      })
      .then((result) => {
        //console.log(result['data'])
        if(result['data'].assm_val.id > 0) {
          const get_val = result['data'].assm_val
          this.setState({
            completed_status: true,
            timepoint: get_val.timepoint,
            q1: get_val.q1,
            q2: get_val.q2,
            q3: get_val.q3,
            q4: get_val.q4,
            q5: get_val.q5,
            q6: get_val.q6,
            q7: get_val.q7,
            q8: get_val.q8,
          })
        }
      })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value,
    })
  }

  validateAllField = () => {
    var chk = true
    var txt_warning = ''
    var arr = ['q1', 'q2', 'q3', 'q4', 'q5', 'q6', 'q7', 'q8']
    arr.map(each => {
      if(this.state[each] == 0) {
        chk = false
        const lang = Cookies.get('lang_')
        if(lang=='es') {
          txt_warning = 'Por favor entre una respuesta para todas las preguntas'
        } else {
          txt_warning = 'Please enter a response to all questions.'
        }
        return ''
      }
    })

    return txt_warning
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var tp_type = this.props.match.params.tp_type
    var timepoint = this.props.match.params.timepoint
    if(this.state.completed_status) {
      this.props.onLoadLocation('/member/followup/completed/'+tp_type+'/'+timepoint, '')
      this.props.history.push('/member/followup/completed/'+tp_type+'/'+timepoint)
    } else {
      var get_validate_msg = this.validateAllField()
      //console.log(get_validate_msg)
      if(get_validate_msg == '') {
        var pid = this.props.authReducer.uid
        var get_uri = this.props.location.pathname
        var sep_uri = get_uri.split("/")
        var get_steps = []
        var current_step = 0
        if(sep_uri[2] == 'followup') {
          axios({
            method: 'post',
            url: connectNode + 'participants/followup/add_csq8',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
              pid: pid,
              tp_type: tp_type,
              timepoint: timepoint,
              user: this.state,
            }
          })
          .then((result) => {
            var message = ''
            if(result['data'] == pid) {
              this.props.onLoadLocation('/member/followup/completed/'+tp_type+'/'+timepoint, 'Thank you for submitting your CSQ-8!')
              this.props.history.push('/member/followup/completed/'+tp_type+'/'+timepoint)
            } else {
              message = 'Something went wrong. Please try again.'
            }
            if(message !== '') {
              this.setState({
                message: message
              })
              window.scrollTo(0, 0)
            }
          })
        } else {
          axios({
            method: 'post',
            url: connectNode + 'participants/registration/add_csq8',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
              pid: pid,
              timepoint: 0,
              user: this.state,
            }
          })
          .then((result) => {
            var message = ''
            if(result['data'] == pid) {
              this.props.onLoadLocation('/member/registration/completed', 'PROMIS Physical Function completed!')
              this.props.history.push('/member/registration/completed')
            } else {
              message = 'Something went wrong. Please try again.'
            }
            if(message !== '') {
              this.setState({
                message: message
              })
              window.scrollTo(0, 0)
            }
          })
        }
      } else {
        this.setState({
          message: get_validate_msg,
        })
        window.scrollTo(0, 0)
      }
    }
  }

  render() {
    var get_uri = this.props.location.pathname
    var sep_uri = get_uri.split("/")
    var get_steps = []
    var current_step = 0
    if(sep_uri[2] == 'followup') {
      get_steps = followup_assm_steps
      current_step = 7
    }

    const lang = Cookies.get('lang_')
    const ques1 = lang == 'es' ? csq8_questions1_es : csq8_questions1
    const csq_scale = lang == 'es' ? [csq8_scale1_es, csq8_scale2, csq8_scale3_es,
                     csq8_scale4_es, csq8_scale5_es, csq8_scale6_es, csq8_scale7_es,
                     csq8_scale8_es] : [csq8_scale1, csq8_scale2, csq8_scale3,
                     csq8_scale4, csq8_scale5, csq8_scale6, csq8_scale7, csq8_scale8]
    return (
    <div className="participant1">
        <div className="headerPage3_1">
        {sep_uri[2] != 'followup' && 'New Participant Questionnaires' }
        {sep_uri[2] == 'followup' && 'Assessments: ' + sep_uri[5] }
        {sep_uri[2] == 'followup' && sep_uri[4] == 'w' && ' weeks' }
        {sep_uri[2] == 'followup' && sep_uri[4] == 'm' && ' months' }
        </div>
        <div className="small-12 columns stepper">
          <Stepper alternativeLabel nonLinear activeStep={current_step}>
            {get_steps.map(each => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={'st'+each.value} {...stepProps}>
                  <StepLabel {...labelProps}>{each.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { (this.state.message !== '' || this.state.compTxt !== '') &&
          <div className="row margin-bottom1">
            <div className={(this.state.message !== '' && 'small-12 columns warning_msg6')
            || (this.state.compTxt !== '' && 'small-12 columns warning_msg7')} >
              <div className="float_left1">
              {this.state.message !== '' && <WarningIcon />}
              {this.state.compTxt !== '' && <CheckCircleOutlineIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.state.message || this.state.compTxt }</div>
            </div>
          </div>}
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns headerPage3_2">
              {(lang=='en' && 'Client Satisfaction Questionnaire') || (lang=='es' && 'Cuestionario de Satisfacción Client Satisfaction Questionnaire')}
            </div>
            <div className="small-12 columns">
              {(lang=='en' && 'Please help us improve our online platform for exercise by answering some questions about it. We are interested in your honest opinions, whether they are positive or negative. Please answer all of the questions. We also welcome your comments and suggestions. Thank you very much.') || (lang=='es' && 'Por favor para mejorar nuestros servicios nos gustaría hacerle algunas preguntas sobre la atención que usted ha recibido. Nos interesa conocer su verdadera opinión, sea esta positiva o negativa. Por favor responda a todas las cuestiones que le planteamos. También le agradeceríamos que al final del cuestionario aportase sus comentarios y sugerencias.')}
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns">
            { ques1.map((eachQ, index) =>
              <div className="row" key={'q'+eachQ.value}>
                <div className="small-12 columns">{eachQ.value + '. ' + eachQ.label}</div>
                {csq_scale[index].map(each =>
                <div>
                  <div className="show-for-medium medium-1 columns">&nbsp;</div>
                  <div className="small-12 medium-11 columns top_space2" key={'qs'+eachQ.value+each.value}>
                    <input type="radio" name={'q'+eachQ.value} value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state['q'+eachQ.value] == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q'+eachQ.value)}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
                {eachQ.value < 8 && <div className="small-12 columns sep_line1">&nbsp;</div>}
              </div>)}
            </div>
          </div>
        </div>
        <div className="small-12 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
             {!this.state.completed_status && 'Submit'}
             {this.state.completed_status && 'Next'}
          </Button>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Csq8)
