import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'

import { baseline_assm_steps } from '../../variables/baseline_assm.js'
import { utilization_questions, utilization_questions_es } from '../../variables/baseline_utilization.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import ReactHtmlParser from 'react-html-parser'
import Cookies from 'js-cookie'

class Utilization extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        compTxt: '',
        q1: '',
        q2a: '',
        q2b: '',
        q3a: '',
        q3b: '',
        q3c: '',
        q4a: '',
        q4b: '',
        q5: '',
        q6a: '',
        q6b: '',
        q7a: '',
        q7b: '',
    }
  }

  componentDidMount() {
    this.getRegistraionProcess()
  }

  getRegistraionProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'participants/registration/get_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid }
    })
    .then((result) => {
        if(result['data'] !== 'demographic') {
          this.props.history.push('/member/registration/'+result['data'].reg_step)
        } else {
          window.scrollTo(0, 0)
        }
      }
    )
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value,
    })
  }

  validateAllField = () => {
    var chk = true
    var chk_list = true
    var txt_warning = ''
    var arr = ['q1', 'q2a', 'q2b', 'q3a', 'q3b', 'q3c', 'q4a', 'q5', 'q6a', 'q7a']
    const lang = Cookies.get('lang_')
    arr.map(each => {
      //console.log(each + ': ' + this.state[each])
      if(this.state[each] < 0 || this.state[each] > 999) {
        chk = false
        if(lang=='es') {
          txt_warning = 'Por favor entre una respuesta para todas las preguntas'
        } else {
          txt_warning = 'Please enter a response to all questions.'
        }
        return ''
      }
    })

    if(chk) {
      if(this.state.q4a > 0 && this.state.q4b.trim() == '') {
        if(lang=='es') {
          txt_warning = 'Por favor escriba las razones de la(s) visitas a la sala de emergencia'
        } else {
          txt_warning = 'Please fill reason for emergency room visit(s)'
        }
      } else {
        if(this.state.q6a > 0 && this.state.q6b.trim() == '') {
          if(lang=='es') {
            txt_warning = 'Razones de la(s) hospitalización(es)'
          } else {
            txt_warning = 'Please fill reason for hospitalization(s)'
          }
        } else {
          if(this.state.q7a > 0 && this.state.q7b.trim() == '') {
            if(lang=='es') {
              txt_warning = 'Por favor escriba qué tipo de cirugía ambulatoria tuvo'
            } else {
              txt_warning = 'Please fill what kind of outpatient surgery did you have'
            }
          }
        }
      }
    }

    return txt_warning
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var get_validate_msg = this.validateAllField()
    //console.log(get_validate_msg)
    if(get_validate_msg == '') {
      var pid = this.props.authReducer.uid
      axios({
        method: 'post',
        url: connectNode + 'participants/registration/add_utilization',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: pid,
          timepoint: 0,
          user: this.state,
        }
      })
      .then((result) => {
        var message = ''
        if(result['data'] == pid) {
          this.props.onLoadLocation('/member/registration/geri_aims', 'Thank you for submitting your background questions!')
          this.props.history.push('/member/registration/geri_aims')
        } else {
          message = 'Something went wrong. Please try again.'
        }
        if(message !== '') {
          this.setState({
            message: message
          })
          window.scrollTo(0, 0)
        }
      })
    } else {
      this.setState({
        message: get_validate_msg,
      })
      window.scrollTo(0, 0)
    }
  }

  render() {
    const lang = Cookies.get('lang_')
    const ques1 = lang == 'es' ? utilization_questions_es : utilization_questions
    return (
    <div className="participant1">
        <div className="headerPage3_1">
          New Participant Questionnaires
        </div>
        <div className="small-12 columns stepper">
          <Stepper alternativeLabel nonLinear activeStep={3}>
            {baseline_assm_steps.map(each => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={'st'+each.value} {...stepProps}>
                  <StepLabel {...labelProps}>{each.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { (this.state.message !== '' || this.state.compTxt !== '') &&
          <div className="row margin-bottom1">
            <div className={(this.state.message !== '' && 'small-12 columns warning_msg6')
            || (this.state.compTxt !== '' && 'small-12 columns warning_msg7')} >
              <div className="float_left1">
              {this.state.message !== '' && <WarningIcon />}
              {this.state.compTxt !== '' && <CheckCircleOutlineIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.state.message || this.state.compTxt }</div>
            </div>
          </div>}
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns headerPage3_2">
              {(lang=='en' && 'Health Care Utilization') || (lang=='es' && 'Utilización de Cuidado de Salud')}
            </div>
            <div className="small-12 columns require1">All questions must be answered before clicking Submit</div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns"><b>{(lang=='en' && 'Visits to Physicians') || (lang=='es' && 'Visitas al Médico')}</b></div>
            <div className="small-12 columns top_space1">
            {ques1[0].value}. {ReactHtmlParser(ques1[0].label)}
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">{(lang=='en' && 'How many visits?') || (lang=='es' && 'How many visits?')}</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.q1} className="tf_num1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                InputProps={{ name:'q1', inputProps: { maxLength: 3, min: 0, max: 24 } }} />
                </div>
              </div>
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns"><b>Visits to Mental Health Providers</b></div>
            <div className="small-12 columns top_space1">
            {ques1[1].value}. {ReactHtmlParser(ques1[1].label)}
            </div>
            <div className="small-12 columns">How many visits?</div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">a. {(lang=='en' && 'Psychiatrist') || (lang=='es' && 'Psiquiatra')}</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.q2a} className="tf_num1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                InputProps={{ inputProps: { name: 'q2a', maxLength: 3 }}} />
                </div>
              </div>
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">b. {(lang=='en' && 'Psychologist (or other mental health counselor)') || (lang=='es' && 'b.	Psicólogo (u otro consejero de la salud mental)')}</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.q2b} className="tf_num1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                InputProps={{ inputProps: { name: 'q2b', maxLength: 3 }}} />
                </div>
              </div>
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns"><b>{(lang=='en' && 'Visits to Other Providers') || (lang=='es' && 'Visitas al Cuarto de Emergencia')}</b></div>
            <div className="small-12 columns top_space1">
            {ques1[2].value}. {ReactHtmlParser(ques1[2].label)}
            </div>
            <div className="small-12 columns top_space1">{(lang=='en' && 'How many visits?') || (lang=='es' && '¿Cuántas visitas?')}</div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">a. {(lang=='en' && 'Nurse practitioner or physician’s assistant') || (lang=='es' && 'Enfermera(o) practicante u asociado medico')}</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.q3a} className="tf_num1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                InputProps={{ inputProps: { name: 'q3a', maxLength: 3 }}} />
                </div>
              </div>
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">b. {(lang=='en' && 'Home health nurse') || (lang=='es' && 'Enfermera(o) del hogar')}</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.q3b} className="tf_num1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                InputProps={{ inputProps: { name: 'q3b', maxLength: 3 }}} />
                </div>
              </div>
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">c. {(lang=='en' && 'Physical, occupational, or respiratory therapist') || (lang=='es' && 'Terapeuta físico, ocupacional, o respiratorio')}</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.q3c} className="tf_num1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                InputProps={{ inputProps: { name: 'q3c', maxLength: 3 }}} />
                </div>
              </div>
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns"><b>{(lang=='en' && 'Visits to Emergency Room') || (lang=='es' && 'Número de Estancias Hospitalizadas')}</b></div>
            <div className="small-12 columns top_space1">
            {ques1[3].value}. {ReactHtmlParser(ques1[3].label)}
            </div>
            <div className="small-12 columns top_space1">How many visits?</div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">a. {(lang=='en' && 'How many times?') || (lang=='es' && '# de visitas a la sala de emergencias ')}</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.q4a} className="tf_num1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                InputProps={{ inputProps: { name: 'q4a', maxLength: 3 }}} />
                (Please put in “0” if you have had no visits.)
                </div>
              </div>
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">b. {(lang=='en' && 'Reason for emergency room visit(s)') || (lang=='es' && 'Razón de las visitas a la sala de emergencias')}:</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.q4b} className="tf_txt1"
                InputProps={{ inputProps: { name: 'q4b', maxLength: 199 }}} />
                </div>
              </div>
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns"><b>{(lang=='en' && 'Number of Hospital Stays') || (lang=='es' && 'Noches en Hospital')}</b></div>
            <div className="small-12 columns top_space1">
            {ques1[4].value}. {ReactHtmlParser(ques1[4].label)}
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">a. {(lang=='en' && 'How many times?') || (lang=='es' && '# Estadías en el hospital')}</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.q5} className="tf_num1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                InputProps={{ inputProps: { name: 'q5', maxLength: 3 }}} />
                (Please put in “0” if you have had no visits.)
                </div>
              </div>
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns"><b>Nights in Hospital</b></div>
            <div className="small-12 columns top_space1">
            {ques1[5].value}. {ReactHtmlParser(ques1[5].label)}
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">a. {(lang=='en' && 'How many nights?') || (lang=='es' && '# de operaciones ambulatorias')}</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.q6a} className="tf_num1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                InputProps={{ inputProps: { name: 'q6a', maxLength: 3 }}} />
                </div>
                <div className="float_left1">
                (Please put in “0” if you have had no staying in a hospital overnight.)
                </div>
              </div>
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">b. Reason for hospitalization(s):</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.q6b} className="tf_txt1"
                InputProps={{ inputProps: { name: 'q6b', maxLength: 199 }}} />
                </div>
              </div>
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns"><b>Outpatient Surgeries</b></div>
            <div className="small-12 columns top_space1">
            {ques1[6].value}. {ReactHtmlParser(ques1[6].label)}
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">a. How many surgeries?</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.q7a} className="tf_num1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                InputProps={{ inputProps: { name: 'q7a', maxLength: 3 }}} />
                (Please put in “0” if you have had no surgery.)
                </div>
              </div>
            </div>
            <div className="small-12 columns top_space1">
              <div className="small-12 columns">
                <div className="float_left1">b. What kind of outpatient surgery did you have?</div>
                <div className="float_left1">
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.q7b} className="tf_txt1"
                InputProps={{ inputProps: { name: 'q7b', maxLength: 199 }}} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {!this.state.completed_status &&
        <div className="small-12 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Submit
          </Button>
        </div>}
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Utilization)
