import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'
import Cookies from 'js-cookie'
import validator from 'validator'
import { withRouter } from "react-router-dom"
//import { Redirect } from 'react-router'
import { connectNode } from '../../utils/global'
import { loadLocationWithMessage } from '../../actions/location'
import WarningIcon from '@material-ui/icons/Warning'

class Signup extends Component {
  constructor() {
    super();
    this.state = {
      status_submit: true,
      email: '',
      //mrn: '',
      username: '',
      password: '',
      repassword: '',
      loading: false,
      message: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    var get_uri = this.props.location.pathname
    var sep_uri = get_uri.split("/")
    if(sep_uri.length > 3) {
      if(this.props.authReducer.uid != null) {
        this.props.history.push('/dashboard')
      } else {
        var pid = this.props.match.params.pid
        this.getPatientStatus()
      }
    } else {
      if(this.props.authReducer.uid != undefined) {
        this.props.history.push('/dashboard')
      } else {
        this.props.history.push('/login')
      }
    }

  }

  getPatientStatus = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/registration/get_screen_questionaire_complete',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.match.params.pid }
    })
    .then((result) => {
      if(result['data'].username) {
        this.props.history.push('/login')
      }
    })
  }

  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    })
  }

  handleChangePassword(event) {
    var password = event.target.value
    if(password.length > 0) {
      document.getElementById('password_policy').style.display = 'block'
    } else {
      document.getElementById('password_policy').style.display = 'none'
    }

    if (password.match(/[a-z]+/)) {
      //console.log('--lower---')
      document.getElementById('lower_case').style.color = '#1a8f00'
    } else {
      document.getElementById('lower_case').style.color = '#cf0000'
    }

    if (password.match(/[A-Z]+/)) {
      document.getElementById('upper_case').style.color = '#1a8f00'
    } else {
      document.getElementById('upper_case').style.color = '#cf0000'
    }
    if (password.match(/[0-9]+/)) {
      document.getElementById('one_number').style.color = '#1a8f00'
    } else {
      document.getElementById('one_number').style.color = '#cf0000'
    }

    if (password.length >= 8) {
      document.getElementById('eight_char').style.color = '#1a8f00'
    } else {
      document.getElementById('eight_char').style.color = '#cf0000'
    }

    this.setState({
        [event.target.name]: event.target.value
    })
  }

  validatePasswordStrength(password) {
    var strength = 0
    if(password.length < 8 || !password.match(/[a-z]+/) ||
      !password.match(/[A-Z]+/) || !password.match(/[0-9]+/)) {
      strength = 2
    }
    return strength
  }

  handleSubmit(event) {
    event.preventDefault()
    var msg = ''
    if(this.state.email === ''
      || this.state.username === '' || this.state.password === ''
      || this.state.repassword === '' ) {
        msg = 'Please enter all information.'
    } else {
      if(this.state.password !== this.state.repassword) {
        msg = 'Please enter the same password.'
      } else {
        if(this.validatePasswordStrength(this.state.password) === 2) {
          msg = 'Please enter the strength password.'
        } else {
          if (!validator.isEmail(this.state.email)) {
            msg = "Please enter valid email address."
          }
        }
      }
    }

    if(msg !== '') {
      this.setState({ message: msg })
      window.scrollTo(0, 0)
    } else {
      this.setState({
        status_submit: false,
        message: '',
      })
      // check match email and mrn //
      // update new account //
      axios({
        method: 'post',
        url: connectNode + 'user/update_new_user',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          user: this.state,
          uid: this.props.match.params.pid,
          //pre_questionaire: pre_questionaire,
        }
      })
      .then((result) => {
          //console.log('Callback: '+result['data'])
          if(result['data'] === 9) {
            this.props.onLoadLocation('/member/registration/demographic', 'You have successfully updated your information!')
            this.props.history.push('/member/registration/demographic')
          } else if(result['data'] === -1) {
            this.setState({
              status_submit: true,
              message: 'Your email is not registered in our system.'
            })
          } else if(result['data'] === -2) {
            this.setState({
              status_submit: true,
              message: 'Sorry, this username has already been used. Please enter a different username.'
            })
          } else if(result['data'] === -3 || result['data'] === -5) {
            this.setState({
              status_submit: true,
              message: 'Something went wrong with updating data. Please try again.'
            })
          } else if(result['data'] === -4) {
            this.setState({
              status_submit: true,
              message: 'You already created your account.'
            })
          } else {
            this.setState({
              status_submit: true,
              message: 'Something went wrong. Please try again.'
            })
          }
          if(result['data'] != 9) {
            window.scrollTo(0, 0)
          }
        }
      )
    }
  }

  render() {
    return (
      <div className="row" id="wrapper-signup">
        <div className="small-12 columns">
          <div className="title">Create HAIL Study Account</div>
        </div>
        <div className="small-12 columns">
          { this.state.message !== '' &&
            <div className="warning_msg6">
              <div className="float_left1"><WarningIcon /></div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>
          }
          <form >
            <div className="field_name">Email</div>
            <div>
              <input className="form-input"
                  type="email"
                  id="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
              />
            </div>
            <div className="field_name">Username</div>
            <div>
              <input className="form-input"
                  type="text"
                  id="username"
                  name="username"
                  value={this.state.username}
                  onChange={this.handleChange}
              />
            </div>
            <div className="password_policy_display">
              Your username can be any name or word that you
              can remember easily (for example, susang).
            </div>
            <div className="field_name">Password</div>
            <div className="form-group">
              <input className="form-input"
                  type="password"
                  name="password"
                  id="password"
                  value={this.state.password}
                  onChange={(e) => this.handleChangePassword(e)}
              />
            </div>
            <div className="password_policy" id="password_policy">
              <div className="bold">Password strength:</div>
              <div className=""><ul>
              <li id="lower_case">One lowercase character</li>
              <li id="upper_case">One uppercase character</li>
              <li id="one_number">One number</li>
              <li id="eight_char">8 characters minimum</li>
              </ul></div>
            </div>
            <div className="field_name">Re-Password</div>
            <div className="form-group">
              <input className="form-input"
                  type="password"
                  name="repassword"
                  id="repassword"
                  value={this.state.repassword}
                  onChange={this.handleChange}
              />
            </div>

            <div className="form-group ">
            {this.state.status_submit &&
              <button onClick={this.handleSubmit} type="submit">Sign Up</button>}
            {!this.state.status_submit &&
              <button onClick={this.handleSubmit} disabled type="submit">Sign Up</button>}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Signup)
