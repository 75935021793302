import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/header1.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import GetXLSX from '../../../functions/get_xlsx.jsx'

class ParticipantChart extends Component {
  constructor() {
      super();
      this.state = {
          name: '',
          mrn: '',
          registered: '',
          dob: '',
          age: '',
          clinician: '',
          clinician_intake: '',
          navigator: '',
          pt_status_name: '',
          status: '',
          address: '',
          username: '',
      }
  }

  componentDidMount() {
    this.getHeader()
  }

  getHeader = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/participant/get_header1',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
      // this.props.authReducer.uid
    })
    .then((result) => {
        var participant = result['data']
        this.setState({
          name: participant.fullname,
          registered: participant.registered_datetime,
          dob: participant.dob,
          status: participant.status_txt,
          status_id: participant.status,
          best_phone: participant.best_phone,
          phone: participant.phone,
          email: participant.email,
          pt_status_name: participant.pt_status_name,
          address: participant.address,
          username: participant.username,
        })
      }
    )
  }

  formatDate =  (date) => {
    if(date) {
      var [ month, day, year ] = date.split('-')

      if (month < 10)
          month = '0' + month;
      if (day < 10)
          day = '0' + day;

      return [month, day, year].join('/')
    }
  }

  downloadFile = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/download_patient_information',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
        var patient = result['data']
      }
    )
  }

  render() {
    return (
      <div className="row">
        <div className="small-12 columns participant_header">
          <div className="row bottom_space2">
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Name: </span>
              {this.state.name} &nbsp;
            </div>
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Date of Birth: </span>
              {this.formatDate(this.state.dob)}
            </div>
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Registered: </span> {this.state.registered}
            </div>
          </div>
          <div className="row bottom_space2">
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Email: </span>
              {this.state.email}
            </div>
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Phone: </span>
              {this.state.phone}
            </div>
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Status: </span> {this.state.pt_status_name} &nbsp;
              <a href={'/admin/participant_chart/edit_status/'+this.props.match.params.pid}>Edit</a>
            </div>
          </div>
          <div className="row">
            <div className="small-12 medium-8 columns bottom-space1 float_left">
              <span className="bold_txt1">Address: </span>
              {this.state.address}
            </div>
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Username: </span> {this.state.username} &nbsp;
              <a href={'/admin/participant_chart/change_password/'+this.props.match.params.pid}>Change password</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ParticipantChart)
