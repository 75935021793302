export const gad7_questions1 = [
  {
    label: 'Feeling nervous, anxious, or on edge',
    value: 1,
  }, {
    label: 'Not being able to stop or control worrying',
    value: 2,
  }, {
    label: 'Worrying too much about different things',
    value: 3,
  }, {
    label: 'Trouble relaxing',
    value: 4,
  }, {
    label: "Being so restless that it's hard to sit still",
    value: 5,
  }, {
    label: 'Becoming easily annoyed or irritable',
    value: 6,
  }, {
    label: 'Feeling afraid as if something awful might happen',
    value: 7,
  },
]

export const gad7_questions1_es = [
  {
    label: 'Sentirse nervioso/a, intranquilo/a o con los nervios de punta',
    value: 1,
  }, {
    label: 'No poder dejar de preocuparse o no poder controlar la preocupación',
    value: 2,
  }, {
    label: 'Preocuparse demasiado por diferentes cosas',
    value: 3,
  }, {
    label: 'Dificultad para relajarse',
    value: 4,
  }, {
    label: "Estar tan inquieto/a que es difícil permanecer sentado/a tranquilamente",
    value: 5,
  }, {
    label: 'Molestarse o ponerse irritable fácilmente',
    value: 6,
  }, {
    label: 'Sentir miedo como si algo terrible pudiera pasar',
    value: 7,
  },
]

export const gad7_scale1 = [
  {
    label: 'Not at all sure',
    value: 0,
  }, {
    label: 'Several days',
    value: 1,
  }, {
    label: 'Over half the days',
    value: 2,
  }, {
    label: 'Nearly every day',
    value: 3,
  },
]

export const gad7_scale1_es = [
  {
    label: 'Nunca',
    value: 0,
  }, {
    label: 'Varios días',
    value: 1,
  }, {
    label: 'Más de la mitad de los días',
    value: 2,
  }, {
    label: 'Casi todos los días',
    value: 3,
  },
]

export const gad7_questions2 = [
  {
    label: 'If you checked off any problems, how difficult have these made it for you to do your work, take care of things at home, or get along with other people?',
    value: 8,
  },
]

export const gad7_questions2_es = [
  {
    label: 'Si ha marcado cualquier problema, ¿qué tan difícil ha hecho que usted haga su trabajo, se haga cargo de sus cosas en el hogar, o se lleve bien con otras personas?',
    value: 8,
  },
]

export const gad7_scale2 = [
  {
    label: 'Not difficult at all',
    value: 1,
  }, {
    label: 'Somewhat difficult',
    value: 2,
  }, {
    label: 'Very difficult',
    value: 3,
  },  {
    label: 'Extremely difficult',
    value: 4,
  },
]

export const gad7_scale2_es = [
  {
    label: 'Para nada difícil',
    value: 1,
  }, {
    label: 'Un poco difícil ',
    value: 2,
  }, {
    label: 'Muy difícil ',
    value: 3,
  },  {
    label: 'Extremadamente difícil',
    value: 4,
  },
]
