import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import WarningIcon from '@material-ui/icons/Warning'
import Person from '@material-ui/icons/Person'
import PersonOutline from '@material-ui/icons/PersonOutline'
import PersonPin from '@material-ui/icons/PersonPin'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import ReactHtmlParser from 'react-html-parser'

class Topic extends Component {
  constructor() {
    super()
    this.quill = require('react-quill')
    this.state = {
        status: false,
        status_submit: true,
        message: '',
        topic: [],
        comments: [],
        comment: '',
        group: 0,
        study: 1,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    // get topic //
    axios({
      method: 'post',
      url: connectNode + 'participants/forum/get_topic_info',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
        tid: this.props.match.params.tid,
      }
    })
    .then((result) => {
      this.setState({
        topic: result['data'],
      })
    })
    // get comments //
    this.getComments()
  }

  getComments = () => {
    // get comments //
    axios({
      method: 'post',
      url: connectNode + 'participants/forum/comment_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
        tid: this.props.match.params.tid,
      }
    })
    .then((result) => {
      this.setState({
        comments: result['data'],
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.comment.trim() !== '') {
      var pid = this.props.authReducer.uid
        axios({
          method: 'post',
          url: connectNode + 'participants/forum/new_comment',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            pid: pid,
            comment: this.state.comment,
            tid: this.props.match.params.tid,
            gid: this.state.topic.group_id,
          }
        })
        .then((result) => {
          var message = ''
          var status_submit = true
          if(result['data'] == pid) {
            message = 'Thank you for the comment!'
            this.getComments()
          } else {
            message = 'Something went wrong. Please try again.'
            status_submit = false
          }
          if(message !== '') {
            this.setState({
              message: message,
              status_submit: status_submit,
              comment: '',
            })
            window.scrollTo(0, 0)
          }
        })
    } else {
      var message = ''
      if(this.state.comment === '') {
        message = 'Please fill the comment.'
      }
      this.setState({
        message: message,
        status_submit: false
      })
      window.scrollTo(0, 0)
    }
  }

  render() {
    return (
    <div className="assm_page1 admin_page">
        <div className="headerPage1">
          Topic
        </div>
        <div className="small-12 columns">
          { this.state.message !== '' &&
          <div className="row">
            {!this.state.status_submit &&
            <div className="small-12 columns warning_msg6">
              <div className="float_left1"><WarningIcon /></div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>}
            {this.state.status_submit &&
            <div className="small-12 columns warning_msg3">
              <div className="float_left1"><CheckCircleOutlineIcon /></div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>}
          </div>}

          <div className="row forum_list">
            <div className="small-12 columns each_topic">
              <div className="topic_by">
                <Person /> {this.state.topic.fullname}, {this.state.topic.created_datetime}
              </div>
              <div className="title_txt">
                {ReactHtmlParser(this.state.topic.title)}
              </div>
            </div>
          </div>
        </div>

        <div className="comment_header">
          Comments
        </div>

        <div className="comment_list">
          {this.state.comments && this.state.comments.map(item =>
          <div key={item.id} className="each_topic" >
            <div className="topic_by">
              <PersonPin /> {item.fullname}, {item.created_datetime}
            </div>
            <div>{item.comment}</div>
          </div>)}
        </div>

        <div className="row top_space1">
          <div className="small-12 columns bold">Add a comment</div>
          <div className="small-12 columns top_space1">
            <TextareaAutosize onChange={this.handleChange} variant="outlined" className="w_100per"
            minRows={3} value={this.state.comment} name="comment" maxLength={450}
            placeholder="Add a comment"
            InputProps={{ inputProps: { name: "comment" }}} />
          </div>
        </div>
        <div className="small-6 columns back_button1">
          <a href="/member/forum/topic_list">Back to Forum</a>
        </div>
        <div className="small-6 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Submit
          </Button>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Topic)
