import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/getintouch.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from 'react-select'
import moment from 'moment'
import { gen_m } from '../../variables/registration.js'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import CancelIcon from '@material-ui/icons/Cancel'
import Messages from './messages'

class NewMessage extends Component {
  constructor() {
    super()
    this.quill = require('react-quill')
    this.state = {
        status: false,
        status_submit: true,
        message: '',
        get_participant: 0,
        participant_list: [],
        text: '',
        p_group: [],
        im: [],
        im_group: 0,
        first_row: 0,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'participants/messenger/get_participant_list_by_group',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid }
    })
    .then((result) => {
      this.setState({
        participant_list: result['data'],
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSelectParticipant = (e) => {
    if(e.value > 0) {
      this.setState({
        get_participant: e.value,
      })
    }
  }

  addList = () => {
    if(this.state.get_participant == 0) {
      this.setState({
        message: 'Please select a receiver',
      }, () => setTimeout( () => this.setState({ message: '' }) ,2000))
      window.scrollTo(0, 0)
    } else {
      var getVal = this.state.get_participant
      var getGroup = this.state.p_group
      var getName = this.findLabelByValue(this.state.participant_list, getVal)
      const found = getGroup.some(el => el.id === getVal)
      if(found) {
        this.setState({
          message: 'This patient has already added to the list.',
        }, () => setTimeout( () => this.setState({ message: '' }) ,2000))
        window.scrollTo(0, 0)
      } else {
        getGroup.push(
        { id: this.state.get_participant,
          name: getName,
        })
        // check existing conversation //
        this.checkExistingConversation(getGroup)
      }
    }
  }

  checkExistingConversation = (getGroup) => {
    console.log('---')
    console.log(getGroup)
    axios({
      method: 'post',
      url: connectNode + 'participants/messenger/get_im_group_first_row', // get_conversations_by_users get_im_group_by_users
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { p_group: getGroup, uid: this.props.authReducer.uid, }
    })
    .then((result) => {
      console.log('im_group: '+result['data'][0])
      if(result['data'][0] > 0) {
        this.setState({
          im_group: result['data'][0], //[0].im_group,
          first_row: result['data'][1],
        })
      } else {
        this.setState({
          //im_group: result['data'],
          //im: [],
          im_group: 0,
        })
      }
      //document.getElementById("last_chat").scrollIntoView(false)
    })
    // close //

  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  removeInputSet1 = (name, id, fields) => {
    var getIndex = fields.findIndex(obj => obj.id === id)
    //console.log(getIndex)
    fields.splice(getIndex, 1)
    this.setState({ [name]: fields })
    if(this.state.participant_list.length > 0) {
      this.checkExistingConversation(this.state.p_group)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.text.trim() !== '') {
      var pid = this.props.authReducer.uid
      axios({
        method: 'post',
        url: connectNode + 'participants/messenger/new_message',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: pid,
          p_group: this.state.p_group,
          text: this.state.text,
          im_group: this.state.im.length > 0 ? this.state.im[0].im_group : 0 // If not existing conversation //
        }
      })
      .then((result) => {
        this.setState({
          im: result['data'],
        })
      })
    } if(this.state.p_group.length == 0) {
      this.setState({
        message: "Please add the receivers",
      }, () => setTimeout( () => this.setState({ message: '' }) ,2000))
      window.scrollTo(0, 0)
    } else {
      var message = ''
      if(this.state.text === '') {
        message = 'Please fill the message'
      }
      this.setState({
        message: message,
      }, () => setTimeout( () => this.setState({ message: '' }) ,2000))
      window.scrollTo(0, 0)
    }
  }

  render() {
    return (
    <div className="assm_page1 admin_page">
        <div className="headerPage1">
          New Instant Message
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { this.state.message !== '' &&
          <div className="row margin-bottom1">
            <div className="small-12 columns warning_msg6">
              <div className="float_left1"><WarningIcon /></div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>
          </div>}

          <div className="row">
            <div className="small-1 columns bold">To</div>
            <div className="small-8 columns">
            <Select options={this.state.participant_list} placeholder="Type in name and choose from list..."
                     onChange={this.handleSelectParticipant} id="get_patient" />
            </div>
            <div className="small-3 columns">
            <button type="button" className="addInput_select" id="patient_name"
             onClick={() => this.addList()} >
              Add Another Person
            </button>
            </div>
          </div>
          <div className="row">
          {this.state.p_group.length > 0 &&
            <div className="medium-12 columns bold">&nbsp;</div>}
            <div className="small-12 medium-11 columns">
              <div className="group_name_list">
                <ul>
                {this.state.p_group.map(each =>
                    <li key={'k'+each.id}>{each.name}
                    <CancelIcon onClick={ () => this.removeInputSet1('p_group', each.id, this.state.p_group) } /></li>
                )}</ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns sep_line3">&nbsp;</div>
          </div>
          {this.state.p_group.length > 0 &&
          <Messages  im_group={this.state.im_group} first_chat_rows={this.state.first_row}
                    p_group={this.state.p_group} />}

          {/*this.state.im.length > 0 &&
            <div className="row conversation">
            <div className="conversation_wrapper">
            {this.state.im.map((each, i) =>
            <div>
            {each.uid != this.props.authReducer.uid &&
            <div className="small-3 columns">&nbsp;</div>}
            <div className={(each.uid!=this.props.authReducer.uid && "small-9 columns chat1") ||
                            (each.uid==this.props.authReducer.uid && "small-9 columns chat2")}>
              <div className="row">
                <div className="small-12 columns conv_title1">{each.fullname + " | " + each.submit_datetime}</div>
              </div>
              <div id={i == this.state.im.length-1 && "last_chat"}
                  className={(each.uid!=this.props.authReducer.uid && "chat_content1") ||
                             (each.uid==this.props.authReducer.uid && "chat_content2")}>{each.message}</div>
            </div>
            {each.uid == this.props.authReducer.uid &&
            <div className="small-3 columns">&nbsp;</div>}
            </div>
            )}
          </div>
          </div>
          <div className="row">
            <div className="small-12 columns sep_line3">&nbsp;</div>
          </div>
          <div className="row">
            <div className="small-12 columns top_space1">
              <TextareaAutosize onChange={this.handleChange} variant="outlined"
              minRows={2} defaultValue={this.state.text} maxLength={500}
              placeholder="Enter your message"
              InputProps={{ inputProps: { name: "text" }}} name="text" />
            </div>
          </div>
          <div className="small-6 columns text_align_right1 top_space1">
            <Button variant="contained" color="primary"
               onClick={this.handleSubmit} type="submit">
                Send
            </Button>
          </div>*/}
        </div>
        <div className="small-12 columns back_button1">
          <a href="/member/getintouch/message_list">Back to Messages</a>
        </div>
    </div>
  )}
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(NewMessage)
