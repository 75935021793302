import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import ReactPlayer from 'react-player'

class VideoPlayer extends Component {
  constructor() {
    super()
    this.state = {
      title: '',
      file: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    //console.log(this.props.match.params.gid + ', ' + this.props.match.params.vid)
    var title = ''
    var file = ''
    if(this.props.match.params.gid == '1') {
      if(this.props.match.params.vid == 'c111422') {
        title = 'Exercise Class from 11/14/2022'
        file = 'c111422.mp4'
      } else if(this.props.match.params.vid == 'c112322') {
        title = 'Exercise Class from 11/23/2022'
        file = 'c112322.mp4'
      } else if(this.props.match.params.vid == 'c120522') {
        title = 'Exercise Class from 12/05/2022'
        file = 'c120522.mp4'
      } else if(this.props.match.params.vid == 'c121222') {
        title = 'Exercise Class from 12/12/2022'
        file = 'c121222.mp4'
      } else if(this.props.match.params.vid == 'c0323') {
        title = 'Exercise Class from 03/2023'
        file = 'c0323.mp4'
      }
    }
    this.setState({
      title: title,
      file: file,
    })
  }

  render() {
    return (
    <div className="assm_page1">
        <div className="headerPage1">{this.state.title}</div>
        <div className="small-12 columns box_assm1">
          <div className="small-12 columns">
            <ReactPlayer playing url={'/videos/' + this.state.file} width='100%'
            height='100%' controls = {true}/>
          </div>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(VideoPlayer)
