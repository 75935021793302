import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, Link} from "react-router-dom"
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from 'react-select'
import moment from 'moment'
import { gen_m } from '../../variables/registration.js'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import MessageIcon from '@material-ui/icons/Message'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import SendIcon from '@material-ui/icons/Send'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import Messages from './messages'

class Conversation extends Component {
  constructor() {
    super()
    this.state = {
      chat_id: 0,
      im: [],
      name_list: '',
      first_chat_rows: 0,
    }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    console.log('- conversation -')
    axios({
      method: 'post',
      url: connectNode + 'participants/messenger/chat_group_list_by_im',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
        im_group: this.props.match.params.cid,
      }
    })
    .then((result) => {
      this.setState({
        name_list: result['data'][0],
        first_chat_rows: result['data'][1],
      })
    })
  }

  /*handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  /*handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.text.trim() !== '') {
      var pid = this.props.authReducer.uid
      axios({
        method: 'post',
        url: connectNode + 'participants/messenger/new_message',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: pid,
          p_group: [],
          text: this.state.text,
          im_group: this.state.im.length > 0 ? this.state.im[0].im_group : 0 // If not existing conversation //
        }
      })
      .then((result) => {
        this.setState({
          im: result['data'],
        })
      })
    } if(this.state.p_group.length == 0) {
      this.setState({
        message: "Please add the receivers",
      })
      window.scrollTo(0, 0)
    } else {
      var message = ''
      if(this.state.text === '') {
        message = 'Please fill the message'
      }
      this.setState({
        message: message,
      })
      window.scrollTo(0, 0)
    }
  }*/

  render() {
    //console.log(this.state)
    return (
    <div className="getintouch">
      {this.props.chat_user_id > 0 &&
      <div>
      <div className="row">
        <div className="small-11 columns title_conversation">
          {this.state.name_list}
        </div>
      </div>
      <Messages first_chat_rows={this.state.first_chat_rows}/>
      </div> }
      {this.props.chat_user_id == 0 &&
        <div className="row ">
          <div className="small-12 columns"><MessageIcon /></div>
          <div className="small-12 columns align_center title1">
            To send a instant message to another participant, <br />click on New Instant Message on the top right corner.

          </div>
          <div className="small-12 columns align_center top_space1 bottom_space1">

          </div>
        </div>}
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Conversation)
