import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'
import PatientHead from "../participant_head"
import { baseline_assm_steps, yes_no, tp_type } from '../../../../variables/baseline_assm.js'
import { geri_questions } from '../../../../variables/baseline_geri.js'

class GeriAims extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        compTxt: '',
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
        q5: 0,
        q6: 0,
        q7: 0,
        q8: 0,
        q9: 0,
        q10: 0,
        q11: 0,
        q12: 0,
        q13: 0,
        q14: 0,
        q15: 0,
        q16: 0,
        q17: 0,
        q18: 0,
        q19: 0,
        q19_specify: '',
        created: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/register_participant/get_geri_aims',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        type_tp: this.props.match.params.type_tp,
        timepoint: this.props.match.params.timepoint,
      }
    })
    .then((result) => {
      if(result['data'].uid > 0) {
        this.setState({
          type_tp: this.props.match.params.type_tp,
          timepoint: this.props.match.params.timepoint,
          q1: result['data'].q1,
          q2: result['data'].q2,
          q3: result['data'].q3,
          q4: result['data'].q4,
          q5: result['data'].q5,
          q6: result['data'].q6,
          q7: result['data'].q7,
          q8: result['data'].q8,
          q9: result['data'].q9,
          q10: result['data'].q10,
          q11: result['data'].q11,
          q12: result['data'].q12,
          q13: result['data'].q13,
          q14: result['data'].q14,
          q15: result['data'].q15,
          q16: result['data'].q16,
          q17: result['data'].q17,
          q18: result['data'].q18,
          q19: result['data'].q19,
          q19_specify: result['data'].q19_specify,
          created: result['data'].created,
        })
      }
    })
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    var total_score = 0
    geri_questions.map(eachQ => {
      total_score += this.state['q'+eachQ.value]
    })
    return (
    <div className="participant1">
        <div className="small-12 medium-6 headerPage3_1 columns">
          Chronic Conditions: Geri-AIMS [{this.findLabelByValue(tp_type, this.props.match.params.type_tp)}]
        </div>
        <div className="medium-6 columns align-right">
        Completed: {this.state.created}
        </div>
        <div className="small-12 columns bottom_space2">
          <PatientHead />
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns">
              Is your health currently affected by any of the following medical problems
              (i.e., do any of the conditions below impact your stress and/or ability
              to do things)?
            </div>
            <div className="small-12 columns">
            { geri_questions.map(eachQ =>
              <div className="row" key={'q'+eachQ.value}>
                <div className="small-12 columns sep_line1">&nbsp;</div>
                <div className="small-12 medium-10 columns">{eachQ.value + '. ' + eachQ.label}</div>
                <div className="small-12 medium-2 columns">
                  {this.findLabelByValue(yes_no, this.state['q'+eachQ.value])}
                  {eachQ.value == 19 && this.state.q19 == '1' && ' ['+this.state.q19_specify+']'}
                </div>
              </div>
            )}
            {/* <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="small-12 columns bold">Total score: {total_score}</div> */}
            </div>
          </div>
        </div>
        <div className="small-12 columns back_button1">
          <a href={'/admin/participant_chart/'+this.props.match.params.pid+'/2/1'}>Back</a>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(GeriAims)
