import React, { Component } from 'react'
import { VscFeedback } from 'react-icons/vsc'
import { FiPhone } from 'react-icons/fi'
import { RiMessage2Line } from 'react-icons/ri'
import Equalizer from 'react-equalizer'
import Image from '../../images/banner1.png'
import Image2 from '../../images/Dauten_logo.png'
import Image3 from '../../images/mood_network_logo.png'

class Home extends Component {

  componentDidMount() { }

  render() {
    return (
      <div className="home">
        <div className="banner">
          <img src={Image} alt="home_banner" />
        </div>
        <div className="home_note">
          <div className="wrapper row">
            <Equalizer>
              <div className="small-12 medium-6 columns note1">
                <div className="inner_note">
                  <div className="align-center"><VscFeedback /></div>
                  <div className="title align-center">Join Us</div>
                  <div className="content align-center">
                    Although we’ve come up with a simple system to get you all set up, we understand you may need additional support or perhaps need something even more advanced to accelerate your biz.
                  </div>
                  <div className="link_button align-center">
                    <button type="submit">Join Us</button>
                  </div>
                </div>
              </div>
              <div className="small-12 medium-6 columns note2">
                <div className="inner_note">
                  <div className="align-center"><FiPhone /></div>
                  <div className="title align-center">Contact Us</div>
                  <div className="content align-center">
                    Although we’ve come up with a simple system to get you all set up, we understand you may need additional support or perhaps need something even more advanced to accelerate your biz.
                  </div>
                  <div className="link_button align-center">
                    <button type="submit">Contact Us</button>
                  </div>
                  </div>
              </div>
            </Equalizer>
          </div>
        </div>
        <div className="note_from_us">
          <div className="wrapper row">
            <div className="small-12 columns">
              <div className="align-center"><RiMessage2Line /></div>
              <div className="title align-center">A note from us!</div>
              <div className="content">
                Although we’ve come up with a simple system to get you all set up, we understand you may need additional support or perhaps need something even more advanced to accelerate your biz. Although we’ve come up with a simple system to get you all set up, we understand you may need additional support or perhaps need something even more advanced to accelerate your biz.
              </div>
            </div>
          </div>
        </div>
        <div className="related_logo">
          <div className="wrapper row">
            <div className="small-12 medium-6 columns content_related_logo dauten_logo">
              <img src={Image2} alt="dauten_logo" />
            </div>
            <div className="small-12 medium-6 columns content_related_logo mn_logo">
              <img src={Image3} alt="moodnetwork_logo" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
