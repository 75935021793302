import React from "react";

export const ControlSection = (props) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="bold space_bottom1">{props.label}</div>
      {props.children}
      {props.withLine && <div className="small-12 columns sep_line3">&nbsp;</div>}
    </div>
  );
};