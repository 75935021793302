export const motivation_scale = [
  {
    label: 'I have no thoughts about exercising.',
    value: 0,
  }, {
    label: '',
    value: 1,
  }, {
    label: 'I think I need to consider exercising someday.',
    value: 2,
  }, {
    label: '',
    value: 3,
  }, {
    label: '',
    value: 4,
  }, {
    label: 'I think I should start exercising, but I am not quite ready.',
    value: 5,
  }, {
    label: '',
    value: 6,
  }, {
    label: '',
    value: 7,
  }, {
    label: 'I am making plans to exercise',
    value: 8,
  }, {
    label: '',
    value: 9,
  }, {
    label: 'I have started to exercise / I exercise regularly',
    value: 10,
  },
]

export const motivation_scale_es = [
  {
    label: 'No pienso en hacer ejercicio',
    value: 0,
  }, {
    label: '',
    value: 1,
  }, {
    label: 'Pienso que debo considerar hacer ejercicio algún día.',
    value: 2,
  }, {
    label: '',
    value: 3,
  }, {
    label: '',
    value: 4,
  }, {
    label: 'Pienso que debo comenzar a hacer ejercicio, pero no estoy completamente listo(a).',
    value: 5,
  }, {
    label: '',
    value: 6,
  }, {
    label: '',
    value: 7,
  }, {
    label: 'Estoy haciendo planes para hacer ejercicio ',
    value: 8,
  }, {
    label: '',
    value: 9,
  }, {
    label: 'He comenzado a hacer ejercicio / Hago ejercicio regularmente. ',
    value: 10,
  },
]
