import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'
import PatientHead from "../participant_head"
import { baseline_assm_steps, yes_no, tp_type } from '../../../../variables/baseline_assm.js'
import { dem_questions, dem_ans_q1, dem_ans_q2, dem_ans_q3, dem_ans_q4,
         dem_ans_q5, dem_ans_q6, dem_ans_q7, dem_ans_q8, dem_ans_q9,
         dem_med_category } from '../../../../variables/baseline_demographic.js'

class Demographic extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        compTxt: '',
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
        q5: 0,
        q6: 0,
        q7: 0,
        q7_other: '',
        q8: 0,
        q9: 0,
        med1a: 0,
        med_list: [],
        created: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/register_participant/get_demographic',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    }).then((result) => {
      // Medication List //
      axios({
        method: 'post',
        url: connectNode + 'admin/register_participant/get_demographic_med_list',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { pid: this.props.match.params.pid }
      })
      .then((result_med) => {
        if(result['data'].uid > 0) {
          this.setState({
            type_tp: this.props.match.params.type_tp,
            q1: result['data'].q1,
            q2: result['data'].q2,
            q3: result['data'].q3,
            q4: result['data'].q4,
            q5: result['data'].q5,
            q6: result['data'].q6,
            q7: result['data'].q7,
            q7_other: result['data'].q7_other,
            q8: result['data'].q8,
            q9: result['data'].q9,
            med1a: result['data'].take_medication,
            med_list: result_med['data'],
            created: result['data'].created,
          })
        }
      })
    })
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    var choice_list = [dem_ans_q1, dem_ans_q2, dem_ans_q3, dem_ans_q4,
                       dem_ans_q5, dem_ans_q6, dem_ans_q7, dem_ans_q8, dem_ans_q9]
    return (
    <div className="participant1">
        <div className="medium-6 columns headerPage3_1">
          Background Questions [{this.findLabelByValue(tp_type, this.props.match.params.type_tp)}]
        </div>
        <div className="medium-6 columns align-right">
        Completed: {this.state.created}
        </div>
        <div className="small-12 columns bottom_space2">
          <PatientHead />
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns">
              <b>Tell us about yourself:</b> Please answer the following
              questions based on your present situation.
            </div>
            <div className="small-12 columns">
            { dem_questions.map(each =>
              <div className="row" key={'q'+each.value}>
                <div className="small-12 columns sep_line1">&nbsp;</div>
                <div className="small-12 medium-6 columns">{each.value + '. ' + each.label}</div>
                <div className="small-12 medium-6 columns">
                {this.findLabelByValue(choice_list[each.value-1], this.state['q'+each.value])}
                {each.value == 7 && this.state.q7 == 6 && ': ' + this.state.q7_other}
                </div>
              </div>
            )}
            </div>
            <div className="small-12 columns med_part">
              <div className="row">
                <div className="small-12 columns sep_line1">&nbsp;</div>
                <div className="small-12 columns bottom_space1"><b>Medications</b></div>
                <div className="small-12 medium-6 columns">
                  Do you take any prescription medications?
                </div>
                <div className="small-12 medium-6 columns">
                {this.findLabelByValue(yes_no, this.state.med1a)}
                </div>
                {this.state.med1a == 1 &&
                <div>
                  <div className="small-12 columns sep_line1">&nbsp;</div>
                  <div className="small-12 columns bottom_space1">
                  Please list the medications that you take:
                  </div>
                  <div className="small-12 columns box_border6">
                    <div className="small-12 medium-4 columns">Category</div>
                    <div className="small-12 medium-4 columns">Medication name</div>
                    <div className="small-12 medium-4 columns">Dosage (mg/day)</div>
                    {this.state.med_list.map(each =>
                    <div key={'ml'+each.id}>
                      <div className="small-12 columns sep_line2">&nbsp;</div>
                      <div className="small-12 medium-4 columns">{this.findLabelByValue(dem_med_category, each.category)}</div>
                      <div className="small-12 medium-4 columns">{each.med_name}</div>
                      <div className="small-12 medium-4 columns">{each.dosage}</div>
                    </div>)}
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
        <div className="small-12 columns back_button1">
          <a href={'/admin/participant_chart/'+this.props.match.params.pid+'/2/1'}>Back</a>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Demographic)
