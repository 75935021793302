import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import '../../../styles/assets/css/pages/assm_eval.css'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import moment from 'moment'
import { long_term_goal1 } from '../../variables/participant_contract.js'
import { SelectLine } from '../../shared/select_line'
import 'react-quill/dist/quill.snow.css'
import Cookies from 'js-cookie'

import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'

class FrmCommitment extends Component {
  constructor() {
    super()
    this.quill = require('react-quill')
    this.state = {
      completed: false,
      status_submit: false,
      message: '',
      participant_name: '',
      week_times: 0,
      week_mins: 0,
      goal1: '',
      goal1_other: '',
      goal2: '',
      goal2_other: '',
      goal3: '',
      goal3_other: '',
      goal4: '',
      goal4_other: '',
      goal5: '',
      goal5_other: '',
      what_hope_program: '',
      why_decide_program: '',
      version: 0,
      signature: '',
      cookieTimeout: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    var pid = this.props.authReducer.uid
    if(this.props.match.params.pid > 0) {
      pid = this.props.match.params.pid
    }
    axios({
      method: 'post',
      url: connectNode + 'participants/contract/get_contract_commitment',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: pid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].uid == pid) {
        this.setSessionData(result['data'])
        /*this.setState({
          completed: true,
          participant_name: result['data'].participant_name,
          week_times: result['data'].week_times,
          week_mins: result['data'].week_mins,
          goal1: result['data'].goal1,
          goal1_other: result['data'].goal1_other,
          goal2: result['data'].goal2,
          goal2_other: result['data'].goal2_other,
          goal3: result['data'].goal3,
          goal3_other: result['data'].goal3_other,
          goal4: result['data'].goal4,
          goal4_other: result['data'].goal4_other,
          goal5: result['data'].goal5,
          goal5_other: result['data'].goal5_other,
          what_hope_program: result['data'].what_hope_program,
          why_decide_program: result['data'].why_decide_program,
          version: result['data'].version,
        })*/
      } else {
        this.setSessionData([])
      }
    })

  }

  setSessionData = (getData) => {
    var participant_name = ''
    var week_times = 0
    var week_mins = 0
    var goal1 = ''
    var goal2 = ''
    var goal3 = ''
    var goal4 = ''
    var goal5 = ''
    var what_hope_program = ''
    var why_decide_program = ''
    var version = 0
    var completed = false

    if(Cookies.get('participant_name')) {
      participant_name = JSON.parse(Cookies.get('participant_name'))
    }
    if(Cookies.get('week_times')) {
      week_times = JSON.parse(Cookies.get('week_times'))
    }
    if(Cookies.get('week_mins')) {
      week_mins = JSON.parse(Cookies.get('week_mins'))
    }
    if(Cookies.get('goal1')) {
      goal1 = JSON.parse(Cookies.get('goal1'))
    }
    if(Cookies.get('goal2')) {
      goal2 = JSON.parse(Cookies.get('goal2'))
    }
    if(Cookies.get('goal3')) {
      goal3 = JSON.parse(Cookies.get('goal3'))
    }
    if(Cookies.get('goal4')) {
      goal4 = JSON.parse(Cookies.get('goal4'))
    }
    if(Cookies.get('goal5')) {
      goal5 = JSON.parse(Cookies.get('goal5'))
    }
    if(Cookies.get('what_hope_program')) {
      what_hope_program = JSON.parse(Cookies.get('what_hope_program'))
    }
    if(Cookies.get('why_decide_program')) {
      why_decide_program = JSON.parse(Cookies.get('why_decide_program'))
    }

    if(getData['participant_name'] != undefined) {
      participant_name = getData['participant_name']
      week_times = getData['week_times']
      week_mins = getData['week_mins']
      goal1 = getData['goal1']
      goal2 = getData['goal2']
      goal3 = getData['goal3']
      goal4 = getData['goal4']
      goal5 = getData['goal5']
      what_hope_program = getData['what_hope_program']
      why_decide_program = getData['why_decide_program']
      version = getData['version']
      completed = true
    }

    this.setState({
      participant_name: participant_name,
      week_times: week_times,
      week_mins: week_mins,
      goal1: goal1,
      goal2: goal2,
      goal3: goal3,
      goal4: goal4,
      goal5: goal5,
      what_hope_program: what_hope_program,
      why_decide_program: why_decide_program,
      version: version,
      completed: completed,
    })
  }

  removeCookie = () => {
    Cookies.remove('participant_name')
    Cookies.remove('week_times')
    Cookies.remove('week_mins')
    Cookies.remove('goal1')
    Cookies.remove('goal2')
    Cookies.remove('goal3')
    Cookies.remove('goal4')
    Cookies.remove('goal5')
    Cookies.remove('what_hope_program')
    Cookies.remove('why_decide_program')
  }

  handleChange = (e) => {
    var getStateVal = e.target.value
    this.setState({
      [e.target.name]: getStateVal,
    })
    Cookies.set(e.target.name, JSON.stringify(getStateVal), {
      expires: this.state.cookieTimeout,
    })
  }
  handleChange3_1 = (contents) => {
    this.setState({
      what_hope_program: contents,
    })
    Cookies.set('what_hope_program', JSON.stringify(contents), {
      expires: this.state.cookieTimeout,
    })
  }
  handleChange3_2 = (contents) => {
    this.setState({
      why_decide_program: contents,
    })
    Cookies.set('why_decide_program', JSON.stringify(contents), {
      expires: this.state.cookieTimeout,
    })
  }

  validateAllField = () => {
    var chk = true
    var chk_list = true
    var txt_warning = ''
    var arr = ['week_mins', 'week_times']
    arr.map(each => {
      if(this.state[each] == 0) {
        chk = false
        txt_warning = 'Please answer all questions.'
        //return ''
      }
    })
    arr = ['participant_name', 'what_hope_program', 'why_decide_program',
           'goal1', 'goal2', 'goal3', 'signature']
    arr.map(each => {
      if(this.state[each].trim() == '') {
        chk = false
        txt_warning = 'Please answer all questions and select at least three overall goals.'
      }
    })
    return txt_warning
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var get_validate_msg = '' //this.validateAllField()
    if(get_validate_msg == '') {
      var pid = this.props.authReducer.uid
      if(this.props.match.params.pid > 0) {
        pid = this.props.match.params.pid
      }
      axios({
        method: 'post',
        url: connectNode + 'participants/contract/add_contract_commitment',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: pid,
          user: this.state,
        }
      })
      .then((result) => {
        var message = ''
        if(result['data'] == pid) {
          if(this.state.version > 0) {
            message = 'Thank you for updating your commitment to class and practice sessions!'
          } else {
            message = 'Thank you for submitting your commitment to class and practice sessions!'
          }
          this.setState({
            message: message,
            status_submit: true,
            completed: true,
          })
          this.removeCookie()
          window.scrollTo(0, 0)
        }
      })
    } else {
      this.setState({
        message: get_validate_msg,
      })
      window.scrollTo(0, 0)
    }
  }

  render() {
    const lang = Cookies.get('lang_')
    const ReactQuill = this.quill
    const timesMap = Array.from(Array(8).keys()).map(each => ({ id: each, name: each }));
    const minutesMap = Array.from(Array(301).keys()).map(each => ({ id: each, name: each }));
    timesMap.shift()
    minutesMap.shift()
    return (
    <div className="assm_page1">
        <div className="headerPage1">
          {(lang=='en' && 'Commitment to Class and Practice Sessions') || lang=='es' && 'EL COMPROMISO A LA CLASE Y A LAS SESIONES DE PRÁCTICA'} {this.state.completed && ' [Edit]'}
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { (this.state.message !== '') &&
          <div className="row margin-bottom1">
            <div className={(!this.state.status_submit && 'small-12 columns warning_msg6')
            || (this.state.status_submit && 'small-12 columns warning_msg7')} >
              <div className="float_left1">
              {!this.state.status_submit && <WarningIcon />}
              {this.state.status_submit && <CheckCircleOutlineIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>
          </div>}
          <div className="">
            {(lang=='en' && 'We would like you to set goals for yourself which are feasible. Can you practice some activity from the exercise program at home? What do you think is a manageable commitment in the first few weeks? As we progress through the program you can determine whether or not this commitment is realistic for you more than 80% of the time.')
            || (lang=='es' && 'Nos gustaría que usted haga metas que estén a su alcance. ¿Puede poner en práctica alguna actividad del programa de ejercicio en su casa? ¿Qué piensa usted que es un compromiso manejable durante las primeras semanas? En cuanto vayamos progresando en el programa usted puede determinar si este compromiso es realista para usted más del 80% del tiempo.')}
          </div>
          <div className="dark_li1">
            <ul>
              <li>{(lang=='en' && 'I' || lang=='es' && 'Yo')},
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                    type="text" value={this.state.participant_name}
                    name="participant_name" className="front_back_space1"
                    InputProps={{ inputProps: { name: "participant_name", maxLength: 50 }}} />
                , {(lang=='en' && 'will attend Fit & Strong! three times a week for the next 8 weeks.' || lang=='es' && 'asistiré al programa de ¡Fuerte y en Forma! cada lunes, miércoles, y viernes por las próximas 8 semanas.')}
              </li>
              <li><span className="w_auto">{(lang=='en' && 'I will also exercise' || lang=='es' && 'También haré ejercicio ')}</span>
                  <span className="w90px"><SelectLine value={this.state.week_times}
                  name="week_times" onChange={this.handleChange}
                  menuItems={timesMap} wide noHorizontalPadding/></span>
                  <span className="w_auto">{(lang=='en' && 'times each week for' || lang=='es' && 'veces por semana por')} </span>
                  <span className="w90px">
                    <SelectLine value={this.state.week_mins}
                      name="week_mins" onChange={this.handleChange} menuItems={minutesMap} wide noHorizontalPadding />
                  </span>
                  <span className="w90px">{(lang=='en' && 'minutes' || lang=='es' && 'minutos')}.</span>
              </li>
            </ul>
          </div>
          <div className="float_left1 space_bottom1"><span className="bold">
          {(lang=='en' && 'Long-Term Goals' || lang=='es' && 'Metas a largo plazo')}</span>&nbsp;
          ({(lang=='en' && 'You must select at least three overall goals.' || lang=='es' && 'Debe seleccionar al menos tres metas generales')})</div>
          <div className="long_term_goal space_bottom1">
            <ol>
              <li>
                <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                  type="text" value={this.state.goal1}
                  name="goal1" className="tf_other1 "
                  InputProps={{ inputProps: { name: "goal1", maxLength: 249 }}} />
              </li>
              <li>
              <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.goal2}
                name="goal2" className="tf_other1 "
                InputProps={{ inputProps: { name: "goal2", maxLength: 249 }}} />
              </li>
              <li>
              <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.goal3} name="goal3"
                className="tf_other1 "
                InputProps={{ inputProps: { name: "goal3", maxLength: 249 }}} />
              </li>
              <li>
              <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.goal4} name="goal4"
                className="tf_other1 "
                InputProps={{ inputProps: { name: "goal4", maxLength: 249 }}} />
              </li>
              <li>
              <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.goal5} name="goal5"
                className="tf_other1 "
                InputProps={{ inputProps: { name: "goal5", maxLength: 249 }}} />
              </li>
            </ol>
          </div>
          <div className="long_term_goal">
            <ul>
              <li>
                <div>{(lang=='en' && 'What do I hope to get out of this program?' || lang=='es' && '¿Qué espero obtener de este programa?')}</div>
                <div>
                  <ReactQuill name="what_hope_program" modules={{toolbar: false}}
                  theme='snow' value={this.state.what_hope_program}
                  onChange={this.handleChange3_1} style={{height: '80px'}}
                  disabled={this.state.completed && 'disabled'} />
                </div>
              </li>
              <li>
                <div>{(lang=='en' && 'Why did I decide to do this program?' || lang=='es' && '¿Por qué decidí hacer este programa?')}</div>
                <div>
                  <ReactQuill name="why_decide_program" modules={{toolbar: false}}
                    theme='snow' value={this.state.why_decide_program}
                    onChange={this.handleChange3_2} style={{height: '80px'}} />
                </div>
              </li>
            </ul>
          </div>
          <div className="long_term_goal">
            <div className="small-12 medium-5 columns float_right1 top_space3">
            <div className="small-3 medium-3 columns space_bottom1 text_align_right1 text_mb_align_left1 no_padding_right">{(lang=='en' && 'Name') || (lang=='es' && 'Nombre')}</div>
            <div className="small-9 medium-9 columns space_bottom1">
            <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.signature}
                name="signature" className="front_back_space1"
                InputProps={{ inputProps: { name: "signature", maxLength: 198 }}} />
            </div>
            </div>
          </div>
        </div>

        <div className="small-6 columns back_button1 float_left">
        {this.props.match.params.pid > 0 &&
          <a href={"/admin/participant_chart/"+this.props.match.params.pid+"/3/1"}>Back</a>}
        {this.props.authReducer.uid >= 10 &&
          <a href="/member/participant_contract">Back</a>}
        </div>
        <div className="small-6 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              {!this.state.completed && 'Submit'}
              {this.state.completed && 'Update'}
          </Button>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(FrmCommitment)
