import React, { Component } from 'react'
import { connect } from 'react-redux'
import AuthService from '../../services/auth/auth.service.js'
import { logoutUser } from '../../actions/auth'
//import { connectNode } from '../../utils/global.js'
import { Link, NavLink } from "react-router-dom"
import { loadLocation } from '../../actions/location'
import Cookies from 'js-cookie'

import { IconButton } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from "@material-ui/core/Drawer"
//import NavDrawer from "./NavDrawer"
import Image from '../../images/hail_logo1.png' // fs-logo

import DashboardIcon from '@material-ui/icons/Dashboard'
import BookIcon from '@material-ui/icons/Book'
import AssessmentIcon from '@material-ui/icons/Assessment'
import MenuBook from '@material-ui/icons/MenuBook'
import Help from '@material-ui/icons/Help'
import SettingsIcon from '@material-ui/icons/Settings'
import { CgMail } from 'react-icons/cg'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import VideoLibrary from '@material-ui/icons/VideoLibrary'
import ForumIcon from '@material-ui/icons/Forum'

import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import DescriptionIcon from '@material-ui/icons/Description'
import NavigationIcon from '@material-ui/icons/Navigation'

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      drawerOpened: false,
      login: false,
      language: 'en',
    };
  }

  componentDidMount() {
    const lang = Cookies.get('lang_')
    if(lang != 'en' && lang != 'es') {
      Cookies.set('lang_', 'en', {
        expires: 1,
      })
      this.setState({
        language: 'en',
      })
    } else {
      this.setState({
        language: lang,
      })
    }
  }

  toggleDrawer = booleanValue => () => {
    this.setState({
      drawerOpened: booleanValue
    });
  };

  onReloadPages = () => {
    this.props.onLoadPages()
  }

  handleLogout() {
    AuthService.logout()
    this.onReloadPages()
    this.onReloadLocation('/login')
    document.location.href = '/login'
  }

  onReloadLocation = (route) => {
    this.props.onLoadLocation(route)
  }

  handlePage(route) {
    this.onReloadLocation(route)
  }

  changeLanguage = (language) => {
    Cookies.set('lang_', language, {
      expires: 1,
    })
    this.setState({
      language: language,
    })
    window.location.reload()
  }

  render() {
    var url_origin = window.location.origin
    const lang = Cookies.get('lang_')
    return (
      <div className="header">
        <div className="wrapper row">
          <div className="header_logo">
            <img src={Image} alt="main_logo"/>
          </div>
          <div className="topBarContact show-for-medium">
            <div className="row">
              <div className="small-12 columns align-right">
              {this.props.uid > 0 && lang=='en' && 'Hi, '+this.props.username}
              {this.props.uid > 0 && lang=='es' && 'Hola, '+this.props.username}
              { this.props.uid > 0 && (this.props.role >= 1 && this.props.role <= 2) && (
                <Link to="/admin/participant_list" >PARTICIPANTS</Link>)}
              { this.props.uid > 0 && this.props.role == 5 && this.props.p_st === 6 && (
                <Link to="/dashboard" >DASHBOARD</Link>)}

              { this.props.uid > 0 && (
                <button onClick={(e) => {this.handleLogout('home')}} className="highlight_button2" >
                LOGOUT</button>)}
              { this.props.uid > 0 && (
              <div className="language_bar">
                <div className={"lang_es "+((lang=='es' && 'active')||(lang=='en' && 'inactive'))}>
                  <a onClick={(e)=>this.changeLanguage('es')}>ES</a>
                </div>
                <div className={"lang_en "+((lang=='en' && 'active')||(lang=='es' && 'inactive'))}>
                  <a onClick={(e)=>this.changeLanguage('en')}>EN</a>
                </div>
              </div>) }
              </div>
            </div>
          </div>
          { this.props.uid > 0 &&
            <div className="topBarContact show-for-small-only">
            <IconButton
              color="inherit"
              aria-label="Menu"
              onClick={this.toggleDrawer(true)} >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={this.state.drawerOpened}
              onClose={this.toggleDrawer(false)} >
              <div
                onClick={this.toggleDrawer(false)}
                onKeyDown={this.toggleDrawer(false)} >
                <div className="menu_list">
                  <Link to="/member/dashboard">
                    <DashboardIcon />
                  </Link>
                </div>
                <div className="menu_list">
                  <Link to="/member/assessment_evaluation">
                    <AssessmentIcon />
                  </Link>
                </div>
                <div className="menu_list">
                  <Link to="/member/participant_manual">
                    <MenuBook />
                  </Link>
                </div>
                <div className="menu_list">
                  <Link to="/member/participant_contract">
                    <DescriptionIcon />
                  </Link>
                </div>
                <div className="menu_list">
                  <Link to="/member/recorded_classes">
                    <VideoLibrary />
                  </Link>
                </div>
                <div className="menu_list">
                  <Link to="/member/forum/topic_list">
                    <ForumIcon />
                  </Link>
                </div>
                <div className="menu_list">
                  <Link to="/member/help">
                    <Help />
                  </Link>
                </div>
                <div className="menu_list">
                  <Link to="/user/settings">
                    <SettingsIcon />
                  </Link>
                </div>
                <div className="menu_list">
                  <Link to="/#" onClick={(e) => {this.handleLogout('home')}}>
                    <ExitToAppIcon />
                  </Link>
                </div>
              </div>
            </Drawer>
          </div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.authReducer.uid,
    role: state.authReducer.role,
    p_st: state.authReducer.p_st,
    username: state.authReducer.username,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages() {
    dispatch(logoutUser())
  },
  onLoadLocation(route) {
    dispatch(loadLocation(route))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
