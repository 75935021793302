import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import ReactHtmlParser from 'react-html-parser'
import Cookies from 'js-cookie'

class RecordClasses extends Component {
  constructor() {
    super()
    this.state = {
      week8: {},
      month3: {},
      timeline_8w: '',
      timeline_3m: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'participants/followup/get_followup_timeline',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].length > 0) {
        var group = -1
        result['data'].map(each => {
          group = each.group
        })
        this.setState({
          group: group,
        })
      }
    })
  }

  render() {
    const lang = Cookies.get('lang_')
    return (
    <div className="assm_page1">
        <div className="headerPage1">
          {(lang=='en' && 'Recorded Classes') || lang=='es' && 'Clases Grabadas'}
        </div>
        <div className="small-12 columns box_assm1">
          <div className="small-12 medium-6 columns">
            <div className="small-12 columns">
            {this.state.group == 1 &&
              <ul>
                <li>
                  <a href="/member/video_player/1/c111422">{(lang=='en' && 'Exercise Class from') || lang=='es' && 'Clase de Ejercicio del'} 11/14/2022</a>
                </li>
                <li>
                  <a href="/member/video_player/1/c112322">{(lang=='en' && 'Exercise Class from') || lang=='es' && 'Clase de Ejercicio del'} 11/23/2022</a>
                </li>
                <li>
                  <a href="/member/video_player/1/c120522">{(lang=='en' && 'Exercise Class from') || lang=='es' && 'Clase de Ejercicio del'} 12/05/2022</a>
                </li>
                <li>
                  <a href="/member/video_player/1/c121222">{(lang=='en' && 'Exercise Class from') || lang=='es' && 'Clase de Ejercicio del'} 12/12/2022</a>
                </li>
              </ul>
            }
            {this.state.group == 2 &&
              <ul>
                <li>
                  <a href="/member/video_player/1/c0323">Pre-recorded Class</a>
                </li>
              </ul>
            }
            </div>
          </div>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(RecordClasses)
