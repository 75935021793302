import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import ReactHtmlParser from 'react-html-parser'
import Cookies from 'js-cookie'

class Greeting extends Component {
  constructor() {
    super()
    this.state = {
      week8: {},
      month3: {},
      timeline_8w: '',
      timeline_3m: '',
      ts_w8_date: 999999999999,
      ts_m3_date: 999999999999,
      cnt_completed_last_week: 0,
      group: 0,
      cnt_forum: 0,
      cnt_activity_log: 0,
      large_badges: [],
      big_badges: [],
      small_badges: [],
      unread: 0,
    }
  }

  componentDidMount() {
    this.getProcess()
    this.getExerciseLogData()
  }

  getProcess = () => {
    // get unread messages number //
    axios({
      method: 'post',
      url: connectNode + 'participants/messenger/count_unread_messages',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.authReducer.uid }
    })
    .then((result) => {
      if(result['data'] > 0) {
        this.setState({
          unread: result['data'],
        })
      }
    })

    // get timeline //
    axios({
      method: 'post',
      url: connectNode + 'participants/followup/get_followup_timeline',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].length > 0) {
        var get_data_w = ''
        var get_data_m = ''
        var group = 0
        result['data'].map(each => {
          get_data_w = each.date_8weeks
          get_data_m = each.date_3months
          group = each.group
        })
        var w8_date = get_data_w.split("-")
        var ts_w8_date = new Date( w8_date[0], w8_date[1]-1, w8_date[2]).getTime()

        //console.log('get_data_m: ' + get_data_m)
        var m3_date = get_data_m.split("-")
        var ts_m3_date = new Date( m3_date[0], m3_date[1]-1, m3_date[2]).getTime()

        this.getForumData(group)

        this.setState({
          timeline_8w: get_data_w,
          timeline_3m: get_data_m,
          ts_w8_date: ts_w8_date,
          ts_m3_date: ts_m3_date,
          group: group,
        })
      }
    })
    // get assm //
    axios({
      method: 'post',
      url: connectNode + 'participants/followup/get_followup_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].length > 0) {
        var get_data_w = {}
        var get_data_m = {}
        result['data'].map(each => {
          if(each.tp_type == 'w') {
            get_data_w = each
          } else if(each.tp_type == 'm') {
            get_data_m = each
          }
        })
        this.setState({
          week8: get_data_w,
          month3: get_data_m,
        })
      }
    })

  }

  getForumData = (group) => {
    axios({
      method: 'post',
      url: connectNode + 'admin/forum/get_most_update_forum',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        group: group,
      }
    })
    .then((result) => {
      this.setState({
        cnt_forum: result['data'].length,
      })
    })
  }

  getExerciseLogData = (group) => {
    axios({
      method: 'post',
      url: connectNode + 'participants/activity/get_exercise_this_month',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      const cnt_exercise_log = result['data'].length
      var big_badges = []
      var small_badges = []
      var large_badges = []
      var num_big_badges = 5
      if(cnt_exercise_log >= 25) {
        large_badges = [1]
        num_big_badges = 25
      }
      if(cnt_exercise_log >= 30) {
        big_badges = [1]
        num_big_badges = 30
      } else if(cnt_exercise_log >= 25 && cnt_exercise_log < 30) {
        // nothing //
      } else {
        for (var i = 1; i<=Math.floor(cnt_exercise_log/5); i++) {
            big_badges.push(i)
        }
      }
      for (var i = 1; i<=cnt_exercise_log%num_big_badges; i++) {
          small_badges.push(i)
      }
      this.setState({
        cnt_activity_log: cnt_exercise_log,
        big_badges: big_badges,
        small_badges: small_badges,
        large_badges: large_badges,
      })
    })
  }

  render() {
    var today = new Date().getTime()
    const lang = Cookies.get('lang_')
    return (
    <div className="row">
      <div className="small-12 columns">
        <div className="box_header1">
          <div className="row">
            <div className="small-12 medium-3 columns">{(lang=='en' && 'Hello') || (lang=='es' && 'Hola')},</div>
            <div className="small-12 medium-9 columns text-align-right">
              {this.state.large_badges.map(each => {
                return(<img className="large_star" src="/badge1.png" key={'l'+each} />)
              })}
              {this.state.big_badges.map(each => {
                return(<img className="big_star" src="/badge1.png" key={'l'+each} />)
              })}
              {this.state.small_badges.map(each => {
                return(<img className="small_star" src="/badge1.png" key={'s'+each} />)
              })}
            </div>
          </div>
        </div>
        <div>
          <ul>
            {today > this.state.ts_w8_date && this.state.week8.status != 2 &&
            <li>{(lang=='en' && 'Please complete the 8-week questionnaires') || lang=='es' && 'Por favor complete los cuestionarios de la semana 8'}
                (<a href="./followup/promis_pf/w/8">{(lang=='en' && 'CLICK HERE') || lang=='es' && 'CLIQUEE AQUI'}</a>).</li>}

            {this.state.week8.status == 2 &&
             today < ((this.state.week8.end_timestamp * 1) + (7 * 24 * 60 * 60 * 1000)) &&
             // show only 7 days //
            <li>{(lang=='en' && 'Thank you for completing the 8-week questionnaires!') || lang=='es' && '¡Gracias por completar los cuestionarios de la semana 8! '}</li>}

            {today >= this.state.ts_m3_date && this.state.month3.status != 2 &&
            <li>{(lang=='en' && 'Please complete the final questionnaires') || lang=='es' && 'Por favor complete los cuestionarios finales'}
                (<a href="./followup/promis_pf/m/3">{(lang=='en' && 'CLICK HERE') || lang=='es' && 'CLIQUEE AQUI'}</a>).</li>}

            {this.state.month3.status == 2 &&
             today < ((this.state.month3.end_timestamp * 1) + (7 * 24 * 60 * 60 * 1000)) &&
             // show only 7 days //
            <li>{(lang=='en' && 'Thank you for completing the final questionnaires!') || lang=='es' && '¡Gracias por completar los cuestionarios finales! '}</li>}

            {this.state.cnt_activity_log > 0 &&
            <li>{(lang=='en' && 'Great work! You have done') || lang=='es' && '¡Buen trabajo!  Has completado'} {this.state.cnt_activity_log} {(lang=='en' && 'exercise logs this month.') || lang=='es' && 'registro(s) de ejercicio este mes'}</li>}

            {this.state.unread > 0 &&
              <li>{(lang=='en' && 'You have') || lang=='es' && 'Tienes'} {this.state.unread} {(lang=='en' && 'new') || lang=='es' && 'nuevo(s)'}&nbsp;
              <a href="./getintouch/message_list">{(lang=='en' && 'instant messages.') || lang=='es' && 'mensajes instantáneos '}</a></li>}

            {this.state.cnt_forum > 0 &&
            <li>{(lang=='en' && 'New update on Forum!') || lang=='es' && '¡Nueva actualización en el fórum!'}
                (<a href="./forum/topic_list">{(lang=='en' && 'CLICK HERE') || lang=='es' && 'CLIQUEE AQUI'}</a>).</li>}

            {this.state.group == 1 &&
            <li>{(lang=='en' && 'Sinceree’s Tip') || lang=='es' && 'Tip de Sinceree '}
                (<a href="./forum/topic/23">{(lang=='en' && 'CLICK HERE') || lang=='es' && 'CLIQUEE AQUI'}</a>).</li>}
          </ul>
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Greeting)
