import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/getintouch.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../actions/location'
import ForumIcon from '@material-ui/icons/Forum'
import MessageIcon from '@material-ui/icons/Message'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import Cookies from 'js-cookie'

class GetInTouch extends Component {
  constructor() {
    super()
    this.state = {
      unread: 0,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'participants/messenger/count_unread_messages',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.authReducer.uid }
    })
    .then((result) => {
      if(result['data'] > 0) {
        this.setState({
          unread: result['data'],
        })
      }
    })
  }

  render() {
    const lang = Cookies.get('lang_')
    return (
    <div className="assm_page1 getintouch">
        <div className="headerPage1">
          Get in Touch
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          <div className="row space-bottom1">
            <div className="small-12 medium-1 columns ">&nbsp;</div>
            <div className="small-12 medium-5 columns icon_title1">
            <a href="/member/forum/topic_list"><ForumIcon /><br />Forum</a>
            <div><a href="" className="link_language1">
            {lang=='en' &&
              <span>Click<a href="/member/forum/topic_list"><span className="highlight1">here</span></a>
              to see the forum.</span> }
            {lang=='es' &&
              <span>Cliquee<a href="/member/forum/topic_list"><span className="highlight1">aquí</span></a>
              para ver el fórum</span> }
             </a>
            </div>
            </div>
            <div className="small-12 medium-5 columns icon_title1">
            <a href="/member/getintouch/message_list">
            <MessageIcon />
            {this.state.unread > 0 &&
              <div className="number_notification1">{this.state.unread}</div>}
            <br />Instant Messages</a>
            <div><a href="" className="link_language1">
            {this.state.unread > 0 &&
            <div>You have {this.state.unread} new instant messages.</div>}
            {lang=='en' &&
              <span>Click<a href="/member/getintouch/message_list"><span className="highlight1">here</span></a>
              to see your messages.</span>}
            {lang=='es' &&
              <span>Cliquee<a href="/member/getintouch/message_list"><span className="highlight1">aquí</span></a>
              para ver sus mensajes.</span>}
              </a>
            </div>
            </div>
            <div className="small-12 medium-1 columns ">&nbsp;</div>
          </div>

        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(GetInTouch)
