import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/list1.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'

import Baseline from "./assessments/baseline"
import Week8 from "./assessments/week8"
import Month3 from "./assessments/month3"
import ParticipantContractPDF from "../../../members/participant_contract/participant_contract_pdf"
import ParticipantContract from "../../../members/participant_contract/participant_contract"

class TabContract extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          tab_status: '1',
          uid: 0,
          pid: 0,
          doctor: 0,
          type: 0,
          date: '',
          time: '',
          duration: 0,
          patient_status: 0,
          commitment: 0,
          individual: 0,
          revisiting: 0,
      }
  }

  componentDidMount() {
    if(this.props.subTab > 0) {
      this.setState({
        tab_status: this.props.subTab,
      })
      this.getData()
    }
  }

  getData = () => {
    var pid = this.props.match.params.pid
    axios({
      method: 'post',
      url: connectNode + 'participants/contract/checkContractExist',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: pid,
      }
    })
    .then((result) => {
      this.setState({
        commitment: result['data'].commitment_id,
        individual: result['data'].maintain_id,
        revisiting: result['data'].revisit_id,
      })
    })
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tab_status: newValue,
    })
  }

  handleUpdateTab = (step, txt) => {
    this.setState({
      tab_status: step,
      message: txt,
    })
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="">
        <div className="row">
          <div className="small-12 columns tab_data1 tab_patient">
            <TabContext value={this.state.tab_status}>
              <AppBar position="static">
                <TabList onChange={this.handleTabChange} aria-label="simple tabs example">
                  <Tab label="Forms" value="1" />
                  <Tab label="Commitment to class" value="2" />
                  <Tab label="Individual plan" value="3" />
                  <Tab label="Revisiting your goals" value="4" />
                </TabList>
              </AppBar>
              <TabPanel value="1" index={1} >
                <ParticipantContract onUpdateTab={this.handleUpdateTab} type_id="1" />
              </TabPanel>
              <TabPanel value="2" index={2} >
                {this.state.commitment > 0 &&
                <ParticipantContractPDF onUpdateTab={this.handleUpdateTab} type_id="1" />}
                {this.state.commitment == null && 'Participant has not completed yet'}
              </TabPanel>
              <TabPanel value="3" index={3} >
                {this.state.individual > 0 &&
                <ParticipantContractPDF onUpdateTab={this.handleUpdateTab} type_id="2" />}
                {this.state.individual == null && 'Participant has not completed yet'}
              </TabPanel>
              <TabPanel value="4" index={4} >
                {this.state.revisiting > 0 &&
                <ParticipantContractPDF onUpdateTab={this.handleUpdateTab} type_id="3" />}
                {this.state.revisiting == null && 'Participant has not completed yet'}
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TabContract)
