import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../utils/global'
import { loadLocationWithMessage } from '../../actions/location'

//import imageSignature from '../../../../impact_bd_server/uploads/signatures/patient_contract/signature_57_8.png'

class GetImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      img: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    var path_get_img = ''
    if(this.props.path == 'get_focus_blog_img') {
      path_get_img = 'admin/focus_blog/get_focus_blog_img'
    } else if(this.props.path == 'get_focus_blog_img2') {
      path_get_img = 'admin/focus_blog/get_focus_blog_img2'
    }

    // get image //
    axios({
      method: 'post',
      url: connectNode + path_get_img,
      headers: {
        'Accept': 'application/json',
        //'Content-Type': 'image/png',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
        gid: this.props.match.params.gid
      }
    })
    .then((result) => {
      this.setState({
          img: result['data']
      })
    })
  }

  render() {
    return ( <img src={this.state.img}  /> )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(GetImage)
