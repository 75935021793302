import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import { exercise_log_mins, exercise_log_intensity, exercise_log_rep, exercise_log_sets,
  exercise_log_exertion_scale, frm_yes_no} from '../../variables/participant_contract.js'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'

class Group extends Component {
  constructor() {
    super()
    this.quill = require('react-quill')
    this.state = {
        completed: false,
        status_submit: true,
        message: '',
        group: 0,
        group_name: '',
        zoom_link: '',
        date_8weeks: '',
        date_3months: '',
        inspirational_quote: '',
        inspirational_quote_from: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    var pid = this.props.authReducer.uid
    var gid = this.props.match.params.gid
    axios({
      method: 'post',
      url: connectNode + 'admin/activity/get_class_info_group',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        gid: gid,
      }
    })
    .then((result) => {
      this.setState({
        group: gid,
        group_name: result['data'].name,
        zoom_link: result['data'].zoom_link,
        date_8weeks: result['data'].date_8weeks,
        date_3months: result['data'].date_3months,
        inspirational_quote: result['data'].inspirational_quote,
        inspirational_quote_from: result['data'].inspirational_quote_from,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value,
    })
  }

  validateAllField = () => {
    var txt_warning = ''
    var arr = ['zoom_link', 'date_8weeks', 'date_3months', 'inspirational_quote', 'inspirational_quote_from']
    arr.forEach(each => {
      if(this.state[each].trim() == '') {
        txt_warning = 'Please fill all information'
      }
    })
    return txt_warning
  }

  formatDate =  (date) => {
    var d = new Date(date)
    var month = '' + (d.getMonth() + 1)
    var day = '' + d.getDate()
    var year = d.getFullYear()

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var get_validate_msg = this.validateAllField()
    //console.log(get_validate_msg)
    if(get_validate_msg == '') {
      var uid = this.props.authReducer.uid
      axios({
        method: 'post',
        url: connectNode + 'admin/activity/update_group_data',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: uid,
          group: this.state,
        }
      })
      .then((result) => {
        if(result['data'] == uid) {
          this.setState({
            message: 'Change completed!',
            status_submit: true,
            completed: true,
          })
          window.scrollTo(0, 0)
        }
      })
    } else {
      this.setState({
        message: get_validate_msg,
        status_submit: false,
      })
      window.scrollTo(0, 0)
    }
  }

  render() {
    return (
    <div className="assm_page1">
        <div className="headerPage1">
          Group {this.state.group_name}
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
        <div className="small-12 columns">
          { (this.state.message !== '') &&
          <div className="row margin-bottom1">
            <div className={(!this.state.status_submit && 'small-12 columns warning_msg6')
            || (this.state.status_submit && 'small-12 columns warning_msg7')} >
              <div className="float_left1">
              {!this.state.status_submit && <WarningIcon />}
              {this.state.status_submit && <CheckCircleOutlineIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>
          </div>}
          </div>

          <div className="small-12 medium-2 columns title1">Zoom Link</div>
          <div className="small-12 medium-10 columns top_space1">
            <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
            type="text" value={this.state.zoom_link} className="w_100per right_space1"
            InputProps={{ name:'zoom_link' }} />
          </div>

          <div className="small-12 medium-4 columns title1">8-week questionnaires date</div>
          <div className="small-12 medium-8 columns top_space1">
          <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
          type="date" value={this.state.date_8weeks} className="right_space1"
          InputProps={{ name:'date_8weeks' }} />
          </div>

          <div className="small-12 medium-4 columns title1">Final questionnaires date</div>
          <div className="small-12 medium-8 columns top_space1">
          <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
          type="date" value={this.state.date_3months} className="right_space1"
          InputProps={{ name:'date_3months' }} />
          </div>

          <div className="small-12 medium-4 columns title1">Inspiration Quote</div>
          <div className="small-12 medium-8 columns top_space1">
          <TextField onChange={this.handleChange} variant="outlined"
          type="text" value={this.state.inspirational_quote} className="right_space1 w_100per"
          InputProps={{ inputProps: { name: 'inspirational_quote', maxLength: 120 }}}  />
          </div>

          <div className="small-12 medium-4 columns title1">Inspiration Quote From</div>
          <div className="small-12 medium-8 columns top_space1">
          <TextField onChange={this.handleChange} variant="outlined"
          type="text" value={this.state.inspirational_quote_from} className="right_space1"
          InputProps={{ inputProps: { name: 'inspirational_quote_from', maxLength: 20 }}} />
          </div>

        </div>
        <div className="small-6 columns back_button1 float_left">
          <a href="/admin/group_setting">Back</a>
        </div>
        <div className="small-6 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Submit
          </Button>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Group)
