export const geri_questions = [
  {
    label: 'Arthritis',
    value: 1,
  }, {
    label: 'Hip Replacement',
    value: 2,
  }, {
    label: 'High Blood Pressure',
    value: 3,
  }, {
    label: 'Heart Disease',
    value: 4,
  }, {
    label: 'Mental Illness',
    value: 5,
  }, {
    label: 'Diabetes',
    value: 6,
  }, {
    label: 'Cancer',
    value: 7,
  }, {
    label: 'Alcohol or Drug Abuse',
    value: 8,
  }, {
    label: 'Lung Disease',
    value: 9,
  }, {
    label: 'Kidney Disease',
    value: 10,
  }, {
    label: 'Liver Disease',
    value: 11,
  }, {
    label: 'Stomach Disease',
    value: 12,
  }, {
    label: 'Blood Disease',
    value: 13,
  }, {
    label: 'Stroke or Other Neurologic Problems',
    value: 14,
  }, {
    label: 'Vascular Disease (hardening of the arteries other than coronary arteries/varicose veins, poor circulation)',
    value: 15,
  }, {
    label: 'Vision Problems',
    value: 16,
  }, {
    label: 'Hearing Problems',
    value: 17,
  }, {
    label: 'Thyroid',
    value: 18,
  }, {
    label: 'Other',
    value: 19,
  },
]

export const geri_questions_es = [
  {
    label: 'Artritis',
    value: 1,
  }, {
    label: 'Remplazo De Cadera',
    value: 2,
  }, {
    label: 'Alta Presión',
    value: 3,
  }, {
    label: 'Enfermedad Cardiaca',
    value: 4,
  }, {
    label: 'Enfermedad Mental',
    value: 5,
  }, {
    label: 'Diabetes',
    value: 6,
  }, {
    label: 'Cáncer',
    value: 7,
  }, {
    label: 'Abuso De Alcohol o Drogas',
    value: 8,
  }, {
    label: 'Enfermedad Pulmonaria',
    value: 9,
  }, {
    label: 'Enfermedad de Riñones',
    value: 10,
  }, {
    label: 'Enfermedad Del Hígado',
    value: 11,
  }, {
    label: 'Enfermedad Estomacal',
    value: 12,
  }, {
    label: 'Enfermedad de la Sangre',
    value: 13,
  }, {
    label: 'Embolia u otros Problemas Neurológicos',
    value: 14,
  }, {
    label: 'Enfermedad Vascular (endurecimiento de las arterias a parte de las arterias coronarias/varices, mala circulación)',
    value: 15,
  }, {
    label: 'Problemas de Visión',
    value: 16,
  }, {
    label: 'Problemas Auditorios',
    value: 17,
  }, {
    label: 'Tiroides',
    value: 18,
  }, {
    label: 'Otros',
    value: 19,
  },
]
