import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import { gen_m } from '../../variables/registration.js'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import Cookies from 'js-cookie'

class NewForum extends Component {
  constructor() {
    super()
    this.quill = require('react-quill')
    this.state = {
        status: false,
        status_submit: true,
        message: '',
        topic: '',
        group: 0,
        study: 1,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'participants/forum/get_participant_info',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid }
    })
    .then((result) => {
      this.setState({
        group: result['data'].group,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.topic.trim() !== '') {
      var pid = this.props.authReducer.uid
        axios({
          method: 'post',
          url: connectNode + 'participants/forum/new_forum',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            pid: pid,
            user: this.state,
          }
        })
        .then((result) => {
          var message = ''
          if(result['data'] == pid) {
            this.props.onLoadLocation('topic_list', 'Your topic has created!')
            this.props.history.push('/member/forum/topic_list')
            message = 'Your topic has created!'
          } else {
            message = 'Something went wrong. Please try again.'
          }
          if(message !== '') {
            this.setState({
              message: message
            })
            window.scrollTo(0, 0)
          }
        })
    } else {
      var message = ''
      if(this.state.topic === '') {
        message = 'Please fill the topic.'
      }
      this.setState({
        message: message,
      })
      window.scrollTo(0, 0)
    }
  }

  render() {
    const lang = Cookies.get('lang_')
    return (
    <div className="assm_page1 admin_page">
        <div className="headerPage1">
          {(lang=='en' && 'Add Another Person') || (lang=='es' && 'Nuevo tema')}
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { this.state.message !== '' &&
          <div className="row margin-bottom1">
            <div className="small-12 columns warning_msg6">
              <div className="float_left1"><WarningIcon /></div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>
          </div>}

          <div className="row">
            <div className="small-12 columns bold">
            {(lang=='en' && 'What do you want to ask or share?') || (lang=='es' && '¿Qué quieres preguntar o compartir?')}
            </div>
            <div className="small-12 columns top_space1">
              <TextareaAutosize onChange={this.handleChange} variant="outlined"
              minRows={3} defaultValue={this.state.topic} maxLength={450}
              InputProps={{ inputProps: { name: "topic" }}} name="topic" />
            </div>
          </div>

        </div>
        <div className="small-6 columns back_button1">
          <a href="/member/forum/topic_list">
          {(lang=='en' && 'Back to Forum') || (lang=='es' && 'Vuelve al Foro')}
          </a>
        </div>
        <div className="small-6 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              {(lang=='en' && 'Submit') || (lang=='es' && 'Enviar')}
          </Button>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(NewForum)
