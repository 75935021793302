import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/list1.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
//import Button from '@material-ui/core/Button'

import PatientHead from "./participant_head"
import TabScreening from "./tab_screening"
import TabAssessments from "./tab_assessments"
import TabContract from "./tab_contract"
import TabExercise from "./tab_exercise"

class ParticipantChart extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          main_tab: '1',
          tab_status: '1',
          uid: 0,
          pid: 0,
          doctor: 0,
          type: 0,
          date: '',
          time: '',
          duration: 0,
          patient_status: 0,
      }
  }

  componentDidMount() {
    if(this.props.match.params.maintab > 0) {
      this.setState({
        main_tab: this.props.match.params.maintab,
        tab_status: this.props.match.params.subtab,
      })
    }
  }

  getPatientStatus = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/register_participant/get_participant_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
      //console.log(result)
        this.setState({
          patient_status: result['data'].status,
        })
      }
    )
  }

  handleMainTabChange = (event, newValue) => {
    this.setState({
      main_tab: newValue,
    })
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tab_status: newValue,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: connectNode + 'admin/register_patient/add_new_patient',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: this.state
    })
    .then((result) => {
        //console.log('Callback: '+result['data'])
        if(result['data'] > 0) {
          //this.props.onLoadPages(response)
          this.props.onLoadLocation('patient_list', 'Adding new patient completed!')
          this.props.history.push('/admin/patient_list')
          /*this.setState({
            message: 'Adding new patient completed!'
          })*/
        } else if(result['data'] === -1) {
          this.setState({
            message: 'This email already exist.'
          })
        } else if(result['data'] === -2) {
          this.setState({
            message: 'Adding new patient completed but system couldn\'t send email reminder!'
          })
        } else {
          this.setState({
            message: 'Something went wrong. Please try again.'
          })
        }
      }
    )
  }

  render() {
    //console.log(this.state)
    return (
      <div className="box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Participant
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
            <div className="row warning_msg7">
              <div className="small-12 columns ">
                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>}
            <PatientHead />
            <div className="row">
              <div className="small-12 columns tab_data2 main_tab">
                <TabContext value={this.state.main_tab}>
                  <AppBar position="static" className="main_tab1">
                    <TabList onChange={this.handleMainTabChange} aria-label="">
                      <Tab label="Screening" value="1" />
                      <Tab label="Assessments" value="2" />
                      <Tab label="Contracts" value="3" />
                      <Tab label="Exercise Log" value="4" />
                    </TabList>
                  </AppBar>
                  <TabPanel value="1" index={1} ><TabScreening subTab={this.props.match.params.subtab} /></TabPanel>
                  <TabPanel value="2" index={2} ><TabAssessments subTab={this.props.match.params.subtab} /></TabPanel>
                  <TabPanel value="3" index={3} ><TabContract subTab={this.props.match.params.subtab} /></TabPanel>
                  <TabPanel value="4" index={4} ><TabExercise subTab={this.props.match.params.subtab} /></TabPanel>
                </TabContext>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientDetail STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ParticipantChart)
