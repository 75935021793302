import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../actions/location'
import ReactHtmlParser from 'react-html-parser'

class Completed extends Component {
  constructor() {
    super()
  }

  render() {
    var txt = 'Thank you for completing registration!<br />Your dashboard is now available.'
    if(this.props.match.params.tp_type == 'w') {
      txt = 'Thank you for completed 8 weeks assessments!'
    } else if(this.props.match.params.tp_type == 'm') {
      txt = 'Thank you for completed 3 months assessments!'
    }
    return (
    <div className="participant1">
        <div className="small-12 columns">
          <div className="row">
            <div className="small-12 columns thanks1">{ReactHtmlParser(txt)}</div>
          </div>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Completed)
