import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import WarningIcon from '@material-ui/icons/Warning'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import GetImage from '../../functions/get_image.jsx'
import 'react-quill/dist/quill.snow.css'
import ReactQuill from "react-quill"
import EditorToolbar, { modules, formats } from "./EditorToolbar";

class FocusBlog extends Component {
  constructor() {
    super()
    this.quill = require('react-quill')
    this.state = {
        status: false,
        status_submit: false,
        message: '',
        uid: '',
        p_name: '',
        p_intro: '',
        p_content: '',
        p_photo: '',
        p_status: 0,
        selectedFile: null,
        photo_name: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/focus_blog/get_focus_blog2',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
        gid: this.props.match.params.gid
      }
    })
    .then((result) => {
      this.setState({
        uid: this.props.authReducer.uid,
        p_name: result['data'].name,
        p_intro: result['data'].intro,
        p_content: result['data'].content,
        photo_name: result['data'].file_name,
        p_status: result['data'].status,
        gid: this.props.match.params.gid,
      })
    })
    //this.getImage()
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (contents) => {
    this.setState({
      p_content: contents,
    })
  }

  handleChangeFile = (e) => {
    var file_type = e.target.files[0].name.split(".")
    file_type = file_type[1]
    if(file_type == 'jpg' || file_type == 'jpeg') {
      let img = e.target.files[0];
      this.setState({
        p_photo: URL.createObjectURL(img),
        selectedFile: e.target.files[0],
        uid: this.props.authReducer.uid,
      })
    } else {
      this.setState({
        message: 'Please select only jpg file',
        //p_photo: '',
        uid: this.props.authReducer.uid,
      })
      window.scrollTo(0, 0)
    }

  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.p_name.trim() !== '' && this.state.p_intro.trim() !== '' &&
       this.state.p_content.trim() !== '' && this.state.selectedFile != null) {
         console.log(this.state)
       const formData = new FormData()
       var dataToSubmit = this.state
       formData.append('file', this.state.selectedFile)
       formData.append('udata', JSON.stringify(dataToSubmit))
        axios({
          method: 'post',
          url: connectNode + 'admin/focus_blog/update_focus_blog',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
          },
          //body: this.state, //this.state,
          data: formData, //dataToSubmit,
        })
        .then((result) => {
          var message = ''
          var status_submit = false
          if(result['data'] == this.props.authReducer.uid) {
            message = 'Focus blog updated!'
            status_submit = true
          } else {
            message = 'Something went wrong. Please try again.'
          }
          if(message !== '') {
            this.setState({
              message: message,
              status_submit: status_submit,
            })
            window.scrollTo(0, 0)
            if(status_submit) {
              this.getData()
            }
          }
        })
    } else {
      this.setState({
        message: 'Please fill all information',
      })
      window.scrollTo(0, 0)
    }
  }

  handleStatus = (e) => {
    e.preventDefault()
    var new_status = 0
    if(this.state.p_status == 1) {
      new_status = 2
    } else if(this.state.p_status == 2) {
      new_status = 1
    }
    axios({
      method: 'post',
      url: connectNode + 'admin/focus_blog/change_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        new_status: new_status,
        gid: this.props.match.params.gid,
      }
    })
    .then((result) => {
      var message = ''
      var status_submit = false
      if(result['data'] == new_status) {
        this.setState({
          p_status: new_status,
        })
      } else {
        message = 'Something went wrong. Please try again.'
      }
      if(message !== '') {
        this.setState({
          message: message,
          status_submit: status_submit,
        })
        window.scrollTo(0, 0)
      }
    })
  }

  render() {
    const ReactQuill = this.quill
    const modules = {
        toolbar: {
          container: "#toolbar",
        }
    }
    const formats = [
      'font','size',
      'bold','italic','underline','strike',
      'color','background',
      'script',
      'header','blockquote','code-block',
      'indent','list',
      'direction','align',
      'link','image','video','formula',
    ]
    return (
    <div className="assm_page1 admin_page focus_blog">
        <div className="small-12 medium-6 columns headerPage1">
          Spotlight: Group { this.props.match.params.gid }
        </div>
        <div className="small-12 medium-6 columns text_align_right1">
          <a href="/admin/focus_blog/focus_blog/1">Group 1</a> |&nbsp;
          <a href="/admin/focus_blog/focus_blog/2">Group 2</a>
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { this.state.message !== '' &&
          <div className="row margin-bottom1">
            <div className={(this.state.status_submit && "small-12 columns warning_msg7") ||
                            (!this.state.status_submit && "small-12 columns warning_msg6")}>
              <div className="float_left1"><WarningIcon /></div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>
          </div>}

          <div className="row">
            <div className="small-12 medium-1 columns bold top_space1">Status</div>
            <div className="small-12 medium-11 columns top_space1">
              <Button variant="contained" color="primary"
                 onClick={this.handleStatus} type="submit">
                  {this.state.p_status == 1 && 'Enable'}
                  {this.state.p_status == 2 && 'Disable'}
              </Button>
            </div>
          </div>

          <div className="row top_space1">
            <div className="small-12 medium-1 columns bold top_space1">Name</div>
            <div className="small-12 medium-11 columns top_space1">
              <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
              type="text" value={this.state.p_name} className="w_100per right_space1"
              InputProps={{ name:'p_name', maxLength: 95 }} />
            </div>
          </div>

          <div className="row top_space1">
            <div className="small-12 medium-1 columns bold">Intro</div>
            <div className="small-12 medium-11 columns top_space1">
              <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
              type="text" value={this.state.p_intro} className="w_100per right_space1"
              InputProps={{ name:'p_intro', maxLength: 250 }} />
            </div>
          </div>

          <div className="row top_space1">
            <div className="small-12 medium-1 columns bold">Detail</div>
            <div className="small-12 medium-11 columns top_space1">
              {/* <TextareaAutosize onChange={this.handleChange} variant="outlined"
              minRows={6} defaultValue={this.state.p_content}
              InputProps={{ inputProps: { name: "p_content" }}} name="p_content" /> */}
              <EditorToolbar />
              <ReactQuill name="p_content" modules={{toolbar: false}}
              theme='snow' value={this.state.p_content}
              modules={modules} formats={formats} id="toolbar"
              onChange={this.handleChange2} style={{height: '350px'}} />
            </div>
          </div>

          <div className="row top_space1">
            <div className="small-12 medium-5 columns bold">
              Current Photo<br />
              {!this.state.status_submit && <GetImage path="get_focus_blog_img2" />}
              {this.state.status_submit && this.state.p_photo &&
                <img src={this.state.p_photo} className="thumbnail_focus_blog" />}
            </div>
            <div className="small-12 medium-7 columns bold">
              Upload Photo<br />
              <TextField onChange={this.handleChangeFile} variant="outlined"
                type="file" className="w100pc"
                InputProps={{ inputProps: { name: "selectedFile" }}} />
              <span className="red1">* jpg file only</span><br />
              {!this.state.status_submit && this.state.p_photo && <img src={this.state.p_photo} className="thumbnail_focus_blog" />}
            </div>
          </div>

        </div>
        <div className="small-12 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Update
          </Button>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(FocusBlog)
