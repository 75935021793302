import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import { group_list } from '../../../../variables/registration.js'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'

class Group extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        msg_status: false,
        group: 0,
        first_date: '',
        changable: false,
        eligible: 0,
        group_name: [],
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    // Get Group //
    axios({
      method: 'post',
      url: connectNode + 'admin/participant/study_group_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { }
    })
    .then((result) => {
      this.setState({
        group_name: result['data'],
      })
    })

    axios({
      method: 'post',
      url: connectNode + 'admin/participant/check_study_group',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
      if(result['data'].group > 0) {
        this.setState({
          group: result['data'].group,
          completed_status: true,
          eligible: result['data'].eligible,
        })
      } else {
        var comp_status = false
        if(result['data'].eligible == 2) {
          comp_status = true
        }
        this.setState({
          completed_status: comp_status,
          eligible: result['data'].eligible,
        })
      }
    })
    // Get earliest class //
    /*axios({
      method: 'post',
      url: connectNode + 'admin/participant/check_first_class_start',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
    })
    .then((result) => {
      var today = new Date().getTime()
      var first_date = new Date(result['data'].first_date + ' 00:00:00').getTime()
      var changable = true
      if(first_date < today) {
        changable = false
      }
      this.setState({
        today: today,
        first_date: first_date,
        changable: changable,
      })
    })*/
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.group > 0 ) {
        var pid = this.props.match.params.pid
        axios({
          method: 'post',
          url: connectNode + 'admin/participant/add_group_to_participant',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            pid: pid,
            user: this.state,
          }
        })
        .then((result) => {
          var message = ''
          var msg_status = false
          //console.log(result['data'])
          if(result['data'] == pid) {
            this.props.onUpdateTab('5', '')
            message = 'Assigning group completed!'
            msg_status = true
          } else {
            message = 'Something went wrong. Please try again.'
          }
          if(message !== '') {
            this.setState({
              message: message,
              msg_status: msg_status,
              completed_status: true,
            })
            window.scrollTo(0, 0)
          }
        })
    } else {
      this.setState({
        message: 'Please select answer',
      })
      window.scrollTo(0, 0)
    }
  }

  render() {
    return (
    <div className="">
        <div className="headerPage3_1">
          Group
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
        { this.state.message !== '' &&
        <div className="row margin-bottom1">
          <div className={(!this.state.msg_status && 'small-12 columns warning_msg6')
          || (this.state.msg_status && 'small-12 columns warning_msg7') }>
            <div className="float_left1">
              {!this.state.msg_status && <WarningIcon />}
              {this.state.msg_status && <CheckCircleOutlineIcon />}
            </div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>}

          <div className="row space_bottom2">
            <div className="small-12 columns">
              <div className="small-12 columns bold_txt2">Please assign the group to this participant</div>
              <div className="small-12 columns float_left">
                {this.state.group_name.map(each =>
                <div className="small-12 medium-4 columns float_left" key={'ce'+each.group}>
                  <input type="radio" name="group" value={each.group}
                  disabled={(this.state.completed_status || this.state.eligible == 2 || this.state.eligible == null) && 'disabled'}
                  checked={this.state.group == each.group && 'checked'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span>{each.name}</span>
                </div>)}
              </div>
            </div>
          </div>
        </div>
        {!this.state.completed_status && this.state.eligible == 1 &&
        <div className="small-12 columns  top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Submit
          </Button>
        </div>}
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Group)
