import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import '../../../styles/assets/css/pages/assm_eval.css'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { revisit_goal1 } from '../../variables/participant_contract.js'
import 'react-quill/dist/quill.snow.css'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import Cookies from 'js-cookie'

class FrmRevisiting extends Component {
  constructor() {
    super()
    this.quill = require('react-quill')
    this.state = {
      completed: false,
      status_submit: false,
      message: '',
      goal1: '',
      goal1_other: '',
      goal2: '',
      goal2_other: '',
      goal3: '',
      goal3_other: '',
      goal4: '',
      goal4_other: '',
      goal5: '',
      goal5_other: '',
      what_hope_program: '',
      why_decide_program: '',
      how_program_help: '',
      how_remain_active: '',
      signature: '',
      cookieTimeout: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    var pid = this.props.authReducer.uid
    if(this.props.match.params.pid > 0) {
      pid = this.props.match.params.pid
    }
    axios({
      method: 'post',
      url: connectNode + 'participants/contract/get_contract_revisit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: pid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].uid == pid || result['data'].c_g1 != null) {
        this.setSessionData(result['data'])
      } else {
        this.setSessionData([])
      }
    })
  }

  setSessionData = (getData) => {
    var what_hope_program = ''
    var why_decide_program = ''
    var how_program_help = ''
    var how_remain_active = ''
    var version = 0
    var completed = false

    if(Cookies.get('3_what_hope_program')) {
      what_hope_program = JSON.parse(Cookies.get('3_what_hope_program'))
    }
    if(Cookies.get('3_why_decide_program')) {
      why_decide_program = JSON.parse(Cookies.get('3_why_decide_program'))
    }
    if(Cookies.get('3_how_program_help')) {
      how_program_help = JSON.parse(Cookies.get('3_how_program_help'))
    }
    if(Cookies.get('3_how_remain_active')) {
      how_remain_active = JSON.parse(Cookies.get('3_how_remain_active'))
    }

    if(getData['version'] != undefined) {
      what_hope_program = getData['what_hope_program']
      why_decide_program = getData['why_decide_program']
      how_program_help = getData['how_program_help']
      how_remain_active = getData['how_remain_active']
      version = getData['version']
      completed = true
    }

    this.setState({
      goal1: getData.c_g1,
      goal2: getData.c_g2,
      goal3: getData.c_g3,
      goal4: getData.c_g4,
      goal5: getData.c_g5,
      what_hope_program: what_hope_program,
      why_decide_program: why_decide_program,
      how_program_help: how_program_help,
      how_remain_active: how_remain_active,
      version: version,
      completed: completed,
    })
  }

  removeCookie = () => {
    Cookies.remove('3_what_hope_program')
    Cookies.remove('3_why_decide_program')
    Cookies.remove('3_how_program_help')
    Cookies.remove('3_how_remain_active')
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleChange4_1 = (contents) => {
    this.setState({
      what_hope_program: contents,
    })
    Cookies.set('3_what_hope_program', JSON.stringify(contents), {
      expires: this.state.cookieTimeout,
    })
  }

  handleChange4_2 = (contents) => {
    this.setState({
      why_decide_program: contents,
    })
    Cookies.set('3_why_decide_program', JSON.stringify(contents), {
      expires: this.state.cookieTimeout,
    })
  }

  handleChange4_3 = (contents) => {
    this.setState({
      how_program_help: contents,
    })
    Cookies.set('3_how_program_help', JSON.stringify(contents), {
      expires: this.state.cookieTimeout,
    })
  }

  handleChange4_4 = (contents) => {
    this.setState({
      how_remain_active: contents,
    })
    Cookies.set('3_how_remain_active', JSON.stringify(contents), {
      expires: this.state.cookieTimeout,
    })
  }

  validateAllField = () => {
    var chk = true
    var chk_list = true
    var txt_warning = ''
    var arr = ['what_hope_program', 'why_decide_program', 'how_program_help',
               'how_remain_active', 'signature']
    arr.map(each => {
       if(this.state[each].trim() == '') {
         chk = false
         txt_warning = 'Please answer all questions.'
         return ''
       }
    })

    return txt_warning
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var get_validate_msg = '' //this.validateAllField()
    if(get_validate_msg == '') {
      var pid = this.props.authReducer.uid
      if(this.props.match.params.pid > 0) {
        pid = this.props.match.params.pid
      }
      axios({
        method: 'post',
        url: connectNode + 'participants/contract/add_contract_revisit',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: pid,
          user: this.state,
        }
      })
      .then((result) => {
        var message = ''
        if(result['data'] == pid) {
          if(this.state.version > 0) {
            message = 'Thank you for updating your revisiting your goals from the beginning of the program!'
          } else {
            message = 'Thank you for submitting your revisiting your goals from the beginning of the program!'
          }
          this.setState({
            message: message,
            status_submit: true,
            completed: true,
          })
          this.removeCookie()
          window.scrollTo(0, 0)
        }
      })
    } else {
      this.setState({
        message: get_validate_msg,
      })
      window.scrollTo(0, 0)
    }
  }

  render() {
    const ReactQuill = this.quill
    const lang = Cookies.get('lang_')
    return (
    <div className="assm_page1">
        <div className="headerPage1">
          {(lang=='en' && 'Revisiting Your Goals from the Beginning of the Program' || lang=='es' && 'REVISANDO LAS METAS DEL PROGRAMA DESDE EL COMIENZO')} {this.state.completed && ' [Edit]'}
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { (this.state.message !== '') &&
          <div className="row margin-bottom1">
            <div className={(!this.state.status_submit && 'small-12 columns warning_msg6')
            || (this.state.status_submit && 'small-12 columns warning_msg7')} >
              <div className="float_left1">
              {!this.state.status_submit && <WarningIcon />}
              {this.state.status_submit && <CheckCircleOutlineIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>
          </div>}
          <div className="">
            {(lang=='en' && 'At the beginning of HAIL!, we set goals and discussed reasons for being in the program. Now that we are nearing the end of the class sessions, let’s revisit your goals and reasons for exercising.'
            || lang=='es' && 'Al comienzo de ¡Fuerte y en Forma!, fijamos metas y hablamos de las razones por las cuales estamos en el programa. Ahora que se acerca el final de las sesiones de clase, vamos a volver a ver las metas y las razones de hacer ejercicio.')}
          </div>
          <div className="bold space_bottom1 top_space3">{(lang=='en' && 'What Are Your Long-Term Goals?' || lang=='es' && '¿Cuáles son sus metas a largo plazo?')}</div>
          <div className="long_term_goal space_bottom1">
            <ol>
              {this.state.goal1 != undefined  && <li>{this.state.goal1}</li>}
              {this.state.goal2 != undefined  && <li>{this.state.goal2}</li>}
              {this.state.goal3 != undefined  && <li>{this.state.goal3}</li>}
              {this.state.goal4 != undefined  && <li>{this.state.goal4}</li>}
              {this.state.goal5 != undefined && <li>{this.state.goal5}</li>}
            </ol>
          </div>
          <div className="long_term_goal">
            <ul>
              <li>
                <div>{(lang=='en' && 'What do I hope to get out of this program?' || lang=='es' && '¿Qué pensaba sacar de este programa?')}</div>
                <div>
                  <ReactQuill name="comments" modules={{toolbar: false}}
                        theme='snow' value={this.state.what_hope_program}
                        onChange={this.handleChange4_1}
                        style={{height: '80px'}} />
                </div>
              </li>
              <li>
                <div>{(lang=='en' && 'Why did I decide to do this program?' || lang=='es' && '¿Por qué decidí hacer este programa?')}</div>
                <div>
                  <ReactQuill name="comments" modules={{toolbar: false}}
                        theme='snow' value={this.state.why_decide_program}
                        onChange={this.handleChange4_2}
                        style={{height: '80px'}} />
                </div>
              </li>
              <li>
                <div>{(lang=='en' && 'How has the program helped?' || lang=='es' && '¿Cómo me ha ayudado este programa?')}</div>
                <div>
                  <ReactQuill name="comments" modules={{toolbar: false}}
                        theme='snow' value={this.state.how_program_help}
                        onChange={this.handleChange4_3}
                        style={{height: '80px'}} />
                </div>
              </li>
              <li>
                <div>{(lang=='en' && 'How will I remain active after the program ends?' || lang=='es' && '¿Cómo permaneceré activo después de que el programa termine?')}</div>
                <div>
                  <ReactQuill name="comments" modules={{toolbar: false}}
                        theme='snow' value={this.state.how_remain_active}
                        onChange={this.handleChange4_4}
                        style={{height: '80px'}} />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="small-12 medium-3 columns float_right1 top_space3">
            <div className="small-12 columns float_right1 top_space3 bottom_space2 text_mb_align_left1 no_padding_right">Name</div>
            <div className="small-12 columns space_bottom1">
            <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                type="text" value={this.state.signature}
                name="signature" className="front_back_space1"
                InputProps={{ inputProps: { name: "signature", maxLength: 198 }}} />
            </div>
          </div>
        </div>
        <div className="small-6 columns back_button1">
        {this.props.match.params.pid > 0 &&
          <a href={"/admin/participant_chart/"+this.props.match.params.pid+"/3/1"}>Back</a>}
        {this.props.authReducer.uid >= 10 &&
          <a href="/member/participant_contract">Back</a>}
        </div>
        <div className="small-6 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Submit
          </Button>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(FrmRevisiting)
