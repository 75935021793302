import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom"
//import { Router  } from "react-router-dom"
import { Provider } from 'react-redux'
import App from './components/App.jsx';
//import * as serviceWorker from './utils/serviceWorker'
import configureStore from './stores/configureStore'

import './styles/assets/css/foundation-float.css';
import './styles/assets/css/foundation-icons/foundation-icons.css';
import './styles/assets/css/foundation.css';
import './styles/index.css';
import './styles/assets/css/template.css';

if (typeof window !== 'undefined') {
  ReactDOM.render(
    <Provider store={configureStore()} key="provider">
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  );
}
