import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import validator from 'validator'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import ReactHtmlParser from 'react-html-parser'
import {Link} from "react-router-dom"

class ExerciseLog extends Component {
  constructor() {
    super()
    var today = new Date()
    var sevenDaysAgo = new Date()
    sevenDaysAgo.setDate(today.getDate() - 7)
    this.state = {
      gid: 1,
      data_list: [],
      group: 1,
      from_date: sevenDaysAgo.getFullYear()+'-'+("0" + (sevenDaysAgo.getMonth() + 1)).slice(-2)+'-'+("0" + sevenDaysAgo.getDate()).slice(-2),
      to_date: today.getFullYear()+'-'+("0" + (today.getMonth() + 1)).slice(-2)+'-'+("0" + today.getDate()).slice(-2),
    }
  }

  componentDidMount() {
  }

  onChangeGroup = (e, gid) => {
    this.getDataList(gid)
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var uid = this.props.authReducer.uid
    axios({
      method: 'post',
      url: connectNode + 'admin/reports/exercise_log',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        group: this.state.group,
        from_date: this.state.from_date,
        to_date: this.state.to_date,
      }
    })
    .then((result) => {
      this.setState({ data_list: result['data'] })
    })
  }

  render() {
    return (
    <div className="assm_page1 reports">
      <div className="row">
        <div className="small-12 columns headerPage1">
          Report: Exercise log
        </div>
        <div className="small-12 columns space_bottom2 top_space1">
          From: &nbsp;
          <TextField onChange={(e) => this.handleChange(e)} variant="standard"
          type="date" value={this.state.from_date} className="right_space1"
          InputProps={{ name:'from_date' }} defaultValue={this.state.from_date} />
          to: &nbsp;
          <TextField onChange={(e) => this.handleChange(e)} variant="standard"
          type="date" value={this.state.to_date} className="right_space1"
          InputProps={{ name:'to_date' }} defaultValue={this.state.to_date} />
          &nbsp;
          Group:
          <FormControl variant="outlined" className="w_dd1" >
            <Select value={this.state.gr} variant="standard"
              label="" defaultValue={this.state.group} name="group"
              onChange={(e) => this.handleChange(e)}>
              <MenuItem value='1' >1</MenuItem>
              <MenuItem value='2' >2</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Submit
          </Button>
        </div>
      </div>
      <div className="row">
          <div className="small-12 columns ">
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Completed Exercise Log</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.data_list.map((each, index) =>
                  <tr key={'p'+index}>
                    <td>{each.uid}</td>
                    <td><a href={"/admin/participant_chart/"+each.uid+"/4/1"}>{each.fullname}</a></td>
                    <td>{each.cnt}</td>
                  </tr>
                )}
              </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ExerciseLog)
