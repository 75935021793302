import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import ReactHtmlParser from 'react-html-parser'
import GetImage from '../../functions/get_image.jsx'

class FocusBlog extends Component {
  constructor() {
    super()
    this.state = {
      week8: {},
      month3: {},
      timeline_8w: '',
      timeline_3m: '',
      ts_w8_date: 999999999999,
      ts_m3_date: 999999999999,
      cnt_completed_last_week: 0,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/focus_blog/get_focus_blog',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid }
    })
    .then((result) => {
      this.setState({
        uid: this.props.authReducer.uid,
        p_name: result['data'].name,
        p_intro: result['data'].intro,
        photo_name: result['data'].file_name,
        p_status: result['data'].status,
      })
    })
    //this.getImage()
  }

  render() {
    //console.log(this.state)
    var today = new Date().getTime()
    return (
    <div className="row focus_blog">
      <div className="small-12 columns">
        <div className="box_header1">
          Spotlight on . . .
        </div>
        <div className="blog_photo">
          <GetImage path="get_focus_blog_img" />
        </div>
        <div className="blog_name">{this.state.p_name}</div>
        <div className="blog_intro">{this.state.p_intro}</div>
        <div className="blog_readmore"><a href="/member/focus_blog">Read more...</a></div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(FocusBlog)
