export const dem_questions = [
  {
    label: 'What is your sex (i.e., biological sex at birth)?',
    value: 1,
  }, {
    label: 'What is your gender?',
    value: 2,
  }, {
    label: 'What is your sexual orientation?',
    value: 3,
  }, {
    label: 'What is the highest grade or year of school you completed?',
    value: 4,
  }, {
    label: 'Which of the following best describes your current employment status?',
    value: 5,
  }, {
    label: 'What is your marital status?  Are you:',
    value: 6,
  }, {
    label: 'What is your race?  Are you:',
    value: 7,
  }, {
    label: 'What is your ethnicity?',
    value: 8,
  }, {
    label: 'What is your annual household income from all sources?',
    value: 9,
  }, /*{
    label: 'What is your current weight?',
    value: 10,
  }*/
]

export const dem_questions_es = [
  {
    label: '¿Cuál es su sexo (es decir, sexo biológico al nacer)?',
    value: 1,
  }, {
    label: '¿Cuál es su género?',
    value: 2,
  }, {
    label: '¿Cuál es su orientación sexual?',
    value: 3,
  }, {
    label: '¿Cuál es el grado o año escolar más alto que completó?',
    value: 4,
  }, {
    label: '¿Cuál de las siguientes mejor describe su situación de empleo actual?',
    value: 5,
  }, {
    label: '¿Cuál es su estado marital?',
    value: 6,
  }, {
    label: '¿Cuál es su grupo racial? Es:',
    value: 7,
  }, {
    label: '¿Cuál es su grupo étnico?',
    value: 8,
  }, {
    label: '¿Cuál es su ingreso familiar anual?',
    value: 9,
  },
]

export const dem_ans_q1 = [
  {
    label: 'Female',
    value: 1,
  }, {
    label: 'Male',
    value: 2,
  }, {
    label: 'Intersex',
    value: 3,
  }, {
    label: 'None of these describe me',
    value: 4,
  }, {
    label: 'Prefer not to answer',
    value: 5,
  }, {
    label: 'Not Reported',
    value: 6,
  },
]

export const dem_ans_q1_es = [
  {
    label: 'Femenino',
    value: 1,
  }, {
    label: 'Masculino',
    value: 2,
  }, {
    label: 'Intersex',
    value: 3,
  }, {
    label: 'Ninguno de estos me describe',
    value: 4,
  }, {
    label: 'Prefiero no responder',
    value: 5,
  }, {
    label: 'No reportado',
    value: 6,
  },
]

export const dem_ans_q2 = [
  {
    label: 'Man',
    value: 1,
  }, {
    label: 'Woman',
    value: 2,
  }, {
    label: 'Non-binary',
    value: 3,
  }, {
    label: 'Transgender',
    value: 4,
  }, {
    label: 'None of these describe me',
    value: 5,
  }, {
    label: 'Prefer not to answer',
    value: 6,
  }, {
    label: 'Not Reported',
    value: 7,
  },
]

export const dem_ans_q2_es = [
  {
    label: 'Hombre',
    value: 1,
  }, {
    label: 'Mujer',
    value: 2,
  }, {
    label: 'No-binario',
    value: 3,
  }, {
    label: 'Transgénero',
    value: 4,
  }, {
    label: 'Ninguno de estos me describe',
    value: 5,
  }, {
    label: 'Prefiero no responder ',
    value: 6,
  }, {
    label: 'No reportado',
    value: 7,
  },
]

export const dem_ans_q3 = [
  {
    label: 'Gay',
    value: 1,
  }, {
    label: 'Lesbian',
    value: 2,
  }, {
    label: 'Straight (that is not gay/lesbian, etc)',
    value: 3,
  }, {
    label: 'Bisexual',
    value: 4,
  }, {
    label: 'Prefer not to answer',
    value: 5,
  }, {
    label: 'Not Reported',
    value: 6,
  },
]

export const dem_ans_q3_es = [
  {
    label: 'Gay',
    value: 1,
  }, {
    label: 'Lesbiana',
    value: 2,
  }, {
    label: 'Heterosexual',
    value: 3,
  }, {
    label: 'Bisexual',
    value: 4,
  }, {
    label: 'Prefiero no responder',
    value: 5,
  }, {
    label: 'No reportado',
    value: 6,
  },
]

export const dem_ans_q4 = [
  {
    label: 'Never attended school or only attended kindergarten',
    value: 1,
  }, {
    label: 'Grades 1 through 8 (Elementary)',
    value: 2,
  }, {
    label: 'Grades 9 through 11 (Some high school)',
    value: 3,
  }, {
    label: 'Grade 12 or GED (High school graduate)',
    value: 4,
  }, {
    label: 'College 1 year to 3 years (Some college or technical school)',
    value: 5,
  }, {
    label: 'College 4 years or more (College graduate)',
    value: 6,
  }, {
    label: 'Prefer not to answer',
    value: 7,
  },
]

export const dem_ans_q4_es = [
  {
    label: 'Nunca atendí a la escuela o solo atendí al prescolar',
    value: 1,
  }, {
    label: '1ro a 8vo grado (Elemental) ',
    value: 2,
  }, {
    label: '9no a 11vo grado (Algún Bachiller)',
    value: 3,
  }, {
    label: '12vo grado o GED (Bachiller Graduado)',
    value: 4,
  }, {
    label: '1 – 3 años de Universidad (Alguna Universidad o Colegio Técnico)',
    value: 5,
  }, {
    label: '4 años de Universidad o más (Universitario Graduado)',
    value: 6,
  }, {
    label: 'Prefiero no responder',
    value: 7,
  },
]

export const dem_ans_q5 = [
  {
    label: 'Employed full-time for pay',
    value: 1,
  }, {
    label: 'Employed part-time for pay',
    value: 2,
  }, {
    label: 'Out of work for more than 1 year',
    value: 3,
  }, {
    label: 'Out of work for less than 1 year',
    value: 4,
  }, {
    label: 'A homemaker',
    value: 5,
  }, {
    label: 'Unable to work',
    value: 6,
  }, {
    label: 'Retired',
    value: 7,
  }, {
    label: 'Prefer not to answer',
    value: 8,
  },
]

export const dem_ans_q5_es = [
  {
    label: 'Empleado a full tiempo por paga',
    value: 1,
  }, {
    label: 'Empleado a medio tiempo por paga',
    value: 2,
  }, {
    label: 'Sin trabajo por más de un año',
    value: 3,
  }, {
    label: 'Sin trabajo por menos de un año',
    value: 4,
  }, {
    label: 'Ama de casa',
    value: 5,
  }, {
    label: 'Incapaz de trabajar',
    value: 6,
  }, {
    label: 'Retirado(a)',
    value: 7,
  }, {
    label: 'Prefiero no responder',
    value: 8,
  },
]

export const dem_ans_q6 = [
  {
    label: 'Married',
    value: 1,
  }, {
    label: 'Divorced',
    value: 2,
  }, {
    label: 'Widowed',
    value: 3,
  }, {
    label: 'Separated',
    value: 4,
  }, {
    label: 'Never married',
    value: 5,
  }, {
    label: 'A member of an unmarried couple',
    value: 6,
  }, {
    label: 'Prefer not to answer',
    value: 7,
  },
]

export const dem_ans_q6_es = [
  {
    label: 'Casado(a)',
    value: 1,
  }, {
    label: 'Divorciado(a)',
    value: 2,
  }, {
    label: 'Viudo(a)',
    value: 3,
  }, {
    label: 'Separado(a)',
    value: 4,
  }, {
    label: 'Nunca casado(a)',
    value: 5,
  }, {
    label: 'Miembro de una pareja no casada',
    value: 6,
  }, {
    label: 'Prefiero no responder',
    value: 7,
  },
]

export const dem_ans_q7 = [
  {
    label: 'Native American or American Indian',
    value: 1,
  }, {
    label: 'Black or African American',
    value: 2,
  }, {
    label: 'White (Includes Hispanic or Latino)',
    value: 3,
  }, {
    label: 'Asian or Asian American',
    value: 4,
  }, {
    label: 'Native Hawaiian, Pacific Islander',
    value: 5,
  }, {
    label: 'Other, Specify',
    value: 6,
  }, {
    label: 'Prefer not to answer',
    value: 7,
  },
]

export const dem_ans_q7_es = [
  {
    label: 'Nativo Americano o Indio Americano',
    value: 1,
  }, {
    label: 'Negro o Afro Americano',
    value: 2,
  }, {
    label: 'Blanco (Incluye Hispanos o Latinos)',
    value: 3,
  }, {
    label: 'Asiático o Asiático Americano',
    value: 4,
  }, {
    label: 'Nativo Hawaiano o Isleño del Pacifico',
    value: 5,
  }, {
    label: 'Otro, Por favor especifique',
    value: 6,
  }, {
    label: 'Prefiero no responder',
    value: 7,
  },
]

export const dem_ans_q8 = [
  {
    label: 'Hispanic or Latino',
    value: 1,
  }, {
    label: 'Non-Hispanic or Latino',
    value: 2,
  }, {
    label: 'Unknown',
    value: 3,
  }, {
    label: 'Prefer not to answer',
    value: 4,
  },
]

export const dem_ans_q8_es = [
  {
    label: 'Hispano o Latino',
    value: 1,
  }, {
    label: 'No Hispano o Latino',
    value: 2,
  }, {
    label: 'Desconocido',
    value: 3,
  }, {
    label: 'Prefiero no responder',
    value: 4,
  },
]

export const dem_ans_q9 = [
  {
    label: '$0-$9,999',
    value: 1,
  }, {
    label: '$10,000-$19,999',
    value: 2,
  }, {
    label: '$20,000-$29,999',
    value: 3,
  }, {
    label: '$30,000-$39,999',
    value: 4,
  }, {
    label: '$40,000-$49,999',
    value: 5,
  }, {
    label: '$50,000-$59,999',
    value: 6,
  }, {
    label: '$60,000-$69,999',
    value: 7,
  }, {
    label: '$70,000 or more+',
    value: 8,
  }, {
    label: 'Refused',
    value: 9,
  }, {
    label: 'Prefer not to answer',
    value: 10,
  },
]

export const dem_ans_q9_es = [
  {
    label: '$0-$9,999',
    value: 1,
  }, {
    label: '$10,000-$19,999',
    value: 2,
  }, {
    label: '$20,000-$29,999',
    value: 3,
  }, {
    label: '$30,000-$39,999',
    value: 4,
  }, {
    label: '$40,000-$49,999',
    value: 5,
  }, {
    label: '$50,000-$59,999',
    value: 6,
  }, {
    label: '$60,000-$69,999',
    value: 7,
  }, {
    label: '$70,000 or more+',
    value: 8,
  }, {
    label: 'Refused',
    value: 9,
  }, {
    label: 'Prefiero no responder',
    value: 10,
  },
]

export const dem_med_category = [
  {
    label: 'Antidepressants',
    value: 1,
  }, {
    label: 'Anticonvulsants',
    value: 2,
  }, {
    label: 'Mood Stabilizers',
    value: 3,
  }, {
    label: 'Antipsychotics',
    value: 4,
  }, {
    label: 'Anxiolytics',
    value: 5,
  }, {
    label: 'Anticholinergic',
    value: 6,
  }, {
    label: 'Antihistamine',
    value: 7,
  }, {
    label: 'Sedatives',
    value: 8,
  }, {
    label: 'Other',
    value: 9,
  },
]
