import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'
import PatientHead from "../participant_head"
import { baseline_assm_steps, tp_type } from '../../../../variables/baseline_assm.js'
import { followup_assm_steps } from '../../../../variables/followup_assm.js'
import { phq9_questions1, phq9_questions2, phq9_scale1, phq9_scale2} from '../../../../variables/baseline_phq9.js'

class Phq9 extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        compTxt: '',
        timepoint: 0,
        q1: -1,
        q2: -1,
        q3: -1,
        q4: -1,
        q5: -1,
        q6: -1,
        q7: -1,
        q8: -1,
        q9: -1,
        q10: -1,
        created: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/register_participant/get_phq9',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        type_tp: this.props.match.params.type_tp,
        timepoint: this.props.match.params.timepoint,
      }
    })
    .then((result) => {
      console.log(result['data'])
      if(result['data'].uid > 0) {
        this.setState({
          type_tp: this.props.match.params.type_tp,
          timepoint: this.props.match.params.timepoint,
          q1: result['data'].q1,
          q2: result['data'].q2,
          q3: result['data'].q3,
          q4: result['data'].q4,
          q5: result['data'].q5,
          q6: result['data'].q6,
          q7: result['data'].q7,
          q8: result['data'].q8,
          q9: result['data'].q9,
          q10: result['data'].q10,
          created: result['data'].created,
        })
      }
    })
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
    <div className="participant1">
        <div className="medium-8 columns headerPage3_1">
        The Patient Health Questionnaire (PHQ-9) [{this.findLabelByValue(tp_type, this.props.match.params.type_tp)}]
        </div>
        <div className="medium-4 columns align-right">
        Completed: {this.state.created}
        </div>
        <div className="small-12 columns bottom_space2">
          <PatientHead />
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns sep_line6 show-for-medium">&nbsp;</div>
            <div className="small-12 columns">
              <div className="row show-for-medium" >
                <div className="small-12 columns scale_header2">
                  Over the past 2 weeks, how often have you been bothered by any of the following problems?
                </div>
              </div>
            { phq9_questions1.map(eachQ =>
              <div className="row" key={'q'+eachQ.value}>
                <div className="small-12 columns sep_line1">&nbsp;</div>
                <div className="small-12 medium-8 columns">{eachQ.value + '. ' + eachQ.label}</div>
                <div className="small-12 medium-4 columns">
                {this.findLabelByValue(phq9_scale1, this.state['q'+eachQ.value])}
                </div>
              </div>)}
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 medium-8 columns">
              {phq9_questions2[0].value}. {phq9_questions2[0].label}
            </div>
            <div className="small-12 medium-4 columns">
            {this.findLabelByValue(phq9_scale2, this.state.q10)}
            </div>
          </div>
        </div>
        <div className="small-12 columns back_button1">
          <a href={'/admin/participant_chart/'+this.props.match.params.pid+'/2/1'}>Back</a>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Phq9)
