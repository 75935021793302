import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'
import PatientHead from "../participant_head"
import { baseline_assm_steps, tp_type } from '../../../../variables/baseline_assm.js'
import { motivation_scale} from '../../../../variables/baseline_motivation.js'

class Motivation extends Component {
  constructor() {
    super()
    this.state = {
        status_submit: true,
        completed_status: false,
        q1: -1,
        created: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/register_participant/get_motivation',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        type_tp: this.props.match.params.type_tp,
        timepoint: this.props.match.params.timepoint,
      }
    })
    .then((result) => {
      console.log(result['data'])
      if(result['data'].uid > 0) {
        this.setState({
          type_tp: this.props.match.params.type_tp,
          q1: result['data'].motivation,
          created: result['data'].end_datetime,
        })
      }
    })
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
    <div className="participant1">
        <div className="medium-6 columns headerPage3_1">
          Motivation to Change [{this.findLabelByValue(tp_type, this.props.match.params.type_tp)}]
        </div>
        <div className="medium-6 columns align-right">
        Completed: {this.state.created}
        </div>
        <div className="small-12 columns bottom_space2">
          <PatientHead />
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 medium-8 columns headerPage3_2">
              In the past week...
            </div>
            <div className="small-12 medium-4 columns headerPage3_2">
              {this.state.q1}
            </div>
          </div>
        </div>
        <div className="small-12 columns back_button1">
        <a href={'/admin/participant_chart/'+this.props.match.params.pid+'/2/1'}>Back</a>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Motivation)
