import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import Button from '@material-ui/core/Button'
import Select from 'react-select'
import moment from 'moment'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import Cookies from 'js-cookie'

class ShareExerciseLog extends Component {
  constructor() {
    super()
    this.state = {
      message: '',
      p_group: [],
      warning_type: 0,
      get_participant: 0,
      participant_list: [],
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'participants/messenger/get_participant_list_by_group',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid }
    })
    .then((result) => {
      this.setState({
        participant_list: result['data'],
      })
    })

    axios({
      method: 'post',
      url: connectNode + 'participants/activity/get_shared_exercise_log',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
        date_log: this.props.match.params.month+'/'+this.props.match.params.day+'/'+this.props.match.params.year,
      }
    })
    .then((result) => {
      this.setState({
        p_group: result['data'],
      })
    })
  }

  handleSelectParticipant = (e) => {
    if(e.value > 0) {
      this.setState({
        get_participant: e.value,
      })
    }
  }

  addList = () => {
    if(this.state.get_participant == 0) {
      this.setState({
        message: 'Please select a receiver',
        warning_type: 1,
      }, () => setTimeout( () => this.setState({ message: '' }) ,2000))
      window.scrollTo(0, 0)
    } else {
      var getVal = this.state.get_participant
      var getGroup = this.state.p_group
      var getName = this.findLabelByValue(this.state.participant_list, getVal)
      const found = getGroup.some(el => el.id === getVal)
      if(found) {
        this.setState({
          message: 'This patient has already added to the list.',
          warning_type: 1,
        }, () => setTimeout( () => this.setState({ message: '', warning_type: 0 }) ,2000))
        window.scrollTo(0, 0)
      } else {
        getGroup.push(
        { id: this.state.get_participant,
          name: getName,
        })
        this.setState({ p_group: getGroup, warning_type: 0 })
      }
    }
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  removeInputSet1 = (name, id, fields) => {
    var getIndex = fields.findIndex(obj => obj.id === id)
    fields.splice(getIndex, 1)
    this.setState({ [name]: fields })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.p_group.length > 0) {
      var pid = this.props.authReducer.uid
      axios({
        method: 'post',
        url: connectNode + 'participants/activity/share_exercise_log',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: this.props.authReducer.uid,
          share_to: this.state.p_group,
          date_log: this.props.match.params.month+'/'+this.props.match.params.day+'/'+this.props.match.params.year,
        }
      })
      .then((result) => {
        var message = ''
        if(result['data'].length > 0) {
          this.setState({
            message: 'Thank you for sharing your exercise log!',
            p_group: result['data'],
            warning_type: 2,
          }, () => setTimeout( () => this.setState({ message: '', warning_type: 0 }) ,2000))
          window.scrollTo(0, 0)
        }
      })
    } else {
      this.setState({
        message: 'Please select a receiver',
        warning_type: 1,
      }, () => setTimeout( () => this.setState({ message: '', warning_type: 0, }) ,2000))
      window.scrollTo(0, 0)
    }
  }

  handleUpdateStatus = () => {
    document.getElementById('warning_box').style.display = 'block'
    this.setState({  })
  }

  closeWarningBox = (e) => {
    document.getElementById('warning_box').style.display = 'none'
  }

  render() {
    const lang = Cookies.get('lang_')
    return (
    <div className="assm_page1 share_exercise_log">
      <div className="small-12 columns box_assm1 more_padding1 display_flex">
        { this.state.message !== '' &&
        <div className="row margin-bottom1">
          <div className={(this.state.warning_type == 1 && "small-12 columns warning_msg6")
                    || (this.state.warning_type == 2 && "small-12 columns warning_msg7")}>
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>}
        <div className="row">
          <div className="small-12 medium-2 columns top_space3">Share to:</div>
          <div className="small-12 medium-6 columns no_padding_left_float_left left_footer">
          <Select options={this.state.participant_list} placeholder="Type in name and choose from list..."
                   onChange={this.handleSelectParticipant} id="get_patient" />
          </div>
          <div className="small-4 columns">
          <button type="button" className="addInput_select" id="patient_name"
           onClick={() => this.addList()} >
            Add Another Person
          </button>
          </div>
        </div>
        <div className="small-12 columns sep_line3">&nbsp;</div>
        <div className="snall-12 columns">
          <div className="group_name_list">
            <ul>
            {this.state.p_group.map(each =>
                <li key={'k'+each.pid}>{each.name}
                <CancelIcon onClick={ () => this.removeInputSet1('p_group', each.id, this.state.p_group) } /></li>
            )}</ul>
          </div>
        </div>
        <div className="small-12 columns margin_top_space1">
        <Button variant="contained" color="primary" className="margin_right_1" disableElevation
         onClick={this.handleSubmit} type="button">{(lang=='en' && 'Share') || (lang=='es' && 'COMPARTIR')}</Button>
       <Button variant="contained" color="primary" disableElevation
        onClick={this.closeWarningBox} type="button">{(lang=='en' && 'Close') || (lang=='es' && 'Cerrar')}</Button>
        </div>
      </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ShareExerciseLog)
