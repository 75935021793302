import React from "react";
import { Line } from "./line";
import TextField from "@material-ui/core/TextField";

export const TextFieldLine = (props) => {
  return (
    <Line label={props.label} noPadding={props.noPadding} noHorizontalPadding={props.noHorizontalPadding}>
      <div style={props.wide ? { width: "100%", padding: 0 } : undefined} className="small-12 medium-10 columns top_space1">
        <TextField
          style={props.wide ? { width: "100%" } : undefined}
          className="w_style1 right_space1"
          onChange={(e) => props.onChange(e)}
          variant="outlined"
          type={props.type ?? "text"}
          value={props.value}
          disabled={props.disabled ?? false}
          InputProps={{
            inputProps: {
              name: props.name,
              min: props.min,
              max: props.max,
              maxLength: props.maxLength }
          }}
        />
      </div>
    </Line>
  );
};
