import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import ReactHtmlParser from 'react-html-parser'
import {Link} from "react-router-dom"

class EventList extends Component {
  constructor() {
    super()
    this.state = {
      gid: 1,
      event_list: []
    }
  }

  componentDidMount() {
    this.getDataList(1)
  }

  getDataList = (gid) => {
    axios({
      method: 'post',
      url: connectNode + 'admin/events/get_event_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        gid: gid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      this.setState({
        gid: gid,
        event_list: result['data'],
      })
    })
  }

  onChangeGroup = (e, gid) => {
    this.getDataList(gid)
  }

  render() {
    //console.log(this.state)
    return (
    <div className="assm_page1">
      <div className="row">
        <div className="small-12 medium-6 columns headerPage1">
          Classes: Group {this.state.gid}
        </div>
        <div className="small-12 medium-6 columns text_align_right1">
          <a onClick={(e) => this.onChangeGroup(e, 1)}>Group 1</a> | &nbsp;
          <a onClick={(e) => this.onChangeGroup(e, 2)}>Group 2</a>
        </div>
      </div>
      <div className="row">
          <div className="small-12 columns ">
            { typeof this.props.locationReducer.txt_message !== 'undefined' &&
              this.props.locationReducer.txt_message !== '' &&
              <div className="warning_msg3">
                <div className="float_left1"><CheckCircleOutlineIcon /></div>
                <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
              </div>
            }
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>Session</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Class Attendance</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.event_list.map((each, index) =>
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                    <Link to={"/admin/events/event/"+each.id}>{each.class_name}</Link>
                    </td>
                    <td>{each.class_date}</td>
                    <td>{each.class_start_time} - {each.class_end_time}</td>
                    <td>
                      <Link to={'/admin/events/participant_list/'+this.state.gid+'/'+each.id}>
                        {each.completed != 2 && 'Update'}
                        {each.completed == 2 && 'Completed'}
                      </Link>
                    </td>
                  </tr>
                )}
              </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(EventList)
