import React from "react"
import { Link } from "react-router-dom"
import Drawer from "@material-ui/core/Drawer"
//import { Button } from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard'
import BookIcon from '@material-ui/icons/Book'
import MenuBook from '@material-ui/icons/MenuBook'
import DescriptionIcon from '@material-ui/icons/Description'
import AssessmentIcon from '@material-ui/icons/Assessment'
import Help from '@material-ui/icons/Help'
import SettingsIcon from '@material-ui/icons/Settings'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import VideoLibrary from '@material-ui/icons/VideoLibrary'
import ForumIcon from '@material-ui/icons/Forum'
import { CgMail } from 'react-icons/cg'

export class NavDrawer extends React.Component {
  render() {
    return (
      <Drawer
        anchor="right"
        open={this.props.drawerOpened}
        onClose={this.props.toggleDrawer(false)}
      >
        <div
          onClick={this.props.toggleDrawer(false)}
          onKeyDown={this.props.toggleDrawer(false)}
        >
          <div className="menu_list">
            <Link to="/member/dashboard">
              <DashboardIcon />
            </Link>
          </div>
          <div className="menu_list">
            <Link to="/member/assessment_evaluation">
              <AssessmentIcon />
            </Link>
          </div>
          <div className="menu_list">
            <Link to="/member/participant_manual">
              <MenuBook />
            </Link>
          </div>
          <div className="menu_list">
            <Link to="/member/participant_contract">
              <DescriptionIcon />
            </Link>
          </div>
          <div className="menu_list">
            <Link to="/member/help">
              <Help />
            </Link>
          </div>
          <div className="menu_list">
            <Link to="/member/recorded_classes">
              <VideoLibrary />
            </Link>
          </div>
          <div className="menu_list">
            <Link to="/member/forum/topic_list">
              <ForumIcon />
            </Link>
          </div>
          <div className="menu_list">
            <Link to="/user/setting">
              <SettingsIcon />
            </Link>
          </div>
        </div>
      </Drawer>
    );
  }
}
