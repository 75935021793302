import React from "react";
import { Line } from "./line";
import FormControl from '@material-ui/core/FormControl'

export const RadioButtonsLine = (props) => {
  const flexStyle = { display: 'flex', flexDirection: "row" };
  return (
    <Line label={props.label} noPadding={props.noPadding} noHorizontalPadding={props.noHorizontalPadding}>
        <FormControl style={props.wide ? { ...flexStyle, width: "100%" } : flexStyle} variant="outlined" disabled={props.disabled}>
          {props.items.map((item, index, arr) =>
            <div key={"radio_button_"+index} style ={{display: 'flex', flexDirection: 'column', marginRight: arr.length === index + 1 ? 0 : '0.5rem'}}>
              <div style={{marginLeft: '0.2rem'}}>{item.value}</div>
              <input type="radio" name={item.name + "_" + props.index} value={item.value}
                disabled={props.disabled}
                checked={item.checked}
                onChange={(e) => props.onChange(e, index)} />
            </div>)}
        </FormControl>
    </Line>
  );
};