export const join_class_status = [
  {
    label: 'Full',
    value: 1,
  }, {
    label: 'Partial',
    value: 2,
  }, {
    label: 'Missed',
    value: 3,
  },
]

export const group = [
  {
    label: '1',
    value: 1,
  }, {
    label: '2',
    value: 2,
  }, 
]

export const filter_participant_status = [
  {
    label: 'All status',
    value: 0,
  }, {
    label: 'Initial Contact',
    value: 1,
  }, {
    label: 'Active',
    value: 2,
  }, {
    label: 'Inactive',
    value: 3,
  }, {
    label: 'Withdrawn',
    value: 4,
  }, {
    label: 'Registered',
    value: 5,
  },
]
