import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import Cookies from 'js-cookie'
import { baseline_assm_steps, yes_no_prefer, yes_no_prefer_es } from '../../variables/baseline_assm.js'
import { dem_questions, dem_ans_q1, dem_ans_q2, dem_ans_q3, dem_ans_q4,
         dem_ans_q5, dem_ans_q6, dem_ans_q7, dem_ans_q8, dem_ans_q9,
         dem_questions_es, dem_ans_q1_es, dem_ans_q2_es, dem_ans_q3_es, dem_ans_q4_es,
         dem_ans_q5_es, dem_ans_q6_es, dem_ans_q7_es, dem_ans_q8_es, dem_ans_q9_es,
         dem_med_category } from '../../variables/baseline_demographic.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

class Demographic extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        compTxt: '',
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
        q5: 0,
        q6: 0,
        q7: 0,
        q7_other: '',
        q8: 0,
        q9: 0,
        q10: '',
        q10_not_answer: 0,
        med1a: 0,
        med_list: [{ id:1, category: 0, medication: '', dosage: '' }],
    }
  }

  componentDidMount() {
    this.getRegistraionProcess()
  }

  getRegistraionProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'participants/registration/get_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid }
    })
    .then((result) => {
        if(result['data'] !== 'demographic') {
          this.props.history.push('/member/registration/'+result['data'].reg_step)
        }
      }
    )
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value,
    })
  }

  handleChangeQ10 = (e) => {
    if(e.target.checked) {
      this.setState({
        q10: '',
        q10_not_answer: e.target.value,
      })
    } else {
      this.setState({
        q10_not_answer: 0,
      })
    }

  }

  handleChangeArray1= (e, name, id) => {
    var getStateVal = this.state[name]
    getStateVal[id] = e.target.value
    this.setState({
      [name]: getStateVal
    })
  }

  handleChangeArray2 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = e.target.value
    this.setState({
      [name]: getStateVal
    })
    //console.log(this.state[name])
  }

  appendInputMedication = (name, fields) => {
    var newInput = fields[fields.length-1]
    //console.log(newInput)
    var newInputID = newInput.id + 1
    //console.log(fields)
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
          id: newInputID, category: 0, medication: '', dosage: '',
        }])
      }
    ))
  }

  removeInputSet1 = (name, id, fields) => {
    //console.log(id)
    var getIndex = fields.findIndex(obj => obj.id === id)
    if (getIndex > 0) {
      fields.splice(getIndex, 1);
    }
    this.setState({ [name]: fields })
    //console.log(this.state.med_list)
  }

  validateAllField = () => {
    var chk = true
    var chk_list = true
    var txt_warning = ''
    var arr = ['q1', 'q2', 'q3', 'q4', 'q5', 'q6', 'q7', 'q8', 'q9', 'med1a']
    const lang = Cookies.get('lang_')
    arr.map(each => {
      if(this.state[each] == 0) {
        chk = false
        if(lang=='es') {
          txt_warning = 'Por favor entre una respuesta para todas las preguntas'
        } else {
          txt_warning = 'Please enter a response to all questions.'
        }
        return ''
      }
      if(each == 'q7' && this.state.q7 == 6 && this.state.q7_other.trim() == '') {
        chk = false
        if(lang=='es') {
          txt_warning = 'Por favor especifique su grupo racial'
        } else {
          txt_warning = 'Please specify your race'
        }
        return ''
      }
    })
    if(!this.state.q10 && this.state.q10_not_answer == 0) {
      chk = false
      if(lang=='es') {
        txt_warning = 'Por favor entre su peso actual'
      } else {
        txt_warning = 'Please enter current weight'
      }
    } else if(this.state.med1a == 1) {
      this.state.med_list.map(each => {
        if(each.category == 0 || each.medication.trim() == '' || each.dosage == '') {
          chk_list = false
          return ''
        }
      })
      if(!chk_list) {
        if(lang=='es') {
          txt_warning = 'Por favor llene toda la información acerca de su medicación'
        } else {
          txt_warning = 'Please fill all information about your medication'
        }
      }
    } else {
      if(chk) {
        return ''
      }
    }

    return txt_warning
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var get_validate_msg = this.validateAllField()
    console.log(get_validate_msg)
    if(get_validate_msg == '') {
      var pid = this.props.authReducer.uid
      axios({
        method: 'post',
        url: connectNode + 'participants/registration/add_demographic',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: pid,
          user: this.state,
        }
      })
      .then((result) => {
        var message = ''
        if(result['data'] == pid) {
          this.props.onLoadLocation('/member/registration/geri_aims', 'Thank you for submitting your background questions!')
          this.props.history.push('/member/registration/geri_aims')
        } else {
          message = 'Something went wrong. Please try again.'
        }
        if(message !== '') {
          this.setState({
            message: message
          })
          window.scrollTo(0, 0)
        }
      })
    } else {
      this.setState({
        message: get_validate_msg,
      })
      window.scrollTo(0, 0)
    }
  }

  render() {
    const lang = Cookies.get('lang_')
    const ques1 = lang == 'es' ? dem_questions_es : dem_questions
    const choices1 = lang == 'es' ? yes_no_prefer_es : yes_no_prefer
    const choice_list = lang == 'es' ? [dem_ans_q1_es, dem_ans_q2_es, dem_ans_q3_es, dem_ans_q4_es,
                       dem_ans_q5_es, dem_ans_q6_es, dem_ans_q7_es, dem_ans_q8_es, dem_ans_q9_es] : [dem_ans_q1, dem_ans_q2, dem_ans_q3, dem_ans_q4,
                       dem_ans_q5, dem_ans_q6, dem_ans_q7, dem_ans_q8, dem_ans_q9]
    return (
    <div className="participant1">
        <div className="headerPage3_1">
          New Participant Questionnaires
        </div>
        <div className="small-12 columns stepper">
          <Stepper alternativeLabel nonLinear activeStep={0}>
            {baseline_assm_steps.map(each => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={'st'+each.value} {...stepProps}>
                  <StepLabel {...labelProps}>{each.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { (this.state.message !== '' || this.state.compTxt !== '') &&
          <div className="row margin-bottom1">
            <div className={(this.state.message !== '' && 'small-12 columns warning_msg6')
            || (this.state.compTxt !== '' && 'small-12 columns warning_msg7')} >
              <div className="float_left1">
              {this.state.message !== '' && <WarningIcon />}
              {this.state.compTxt !== '' && <CheckCircleOutlineIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.state.message || this.state.compTxt }</div>
            </div>
          </div>}
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns headerPage3_2">
              Background Questions
            </div>
            <div className="small-12 columns">
              <b>Tell us about yourself:</b> Please answer the following
              questions based on your present situation.
            </div>
            <div className="small-12 columns">
            { ques1.map(each =>
              <div className="row" key={'q'+each.value}>
                <div className="small-12 columns sep_line1">&nbsp;</div>
                <div className="small-12 medium-6 columns">{each.value + '. ' + each.label}</div>
                <div className="small-12 medium-6 columns">
                <FormControl variant="outlined" className="dd_1">
                  <Select value={this.state['q'+each.value]} variant="outlined"
                    label="" defaultValue=""
                    onChange={(e) => this.handleChange2(e, 'q'+each.value)}>
                    { choice_list[each.value-1].map(each_ch =>
                      <MenuItem key={'ans'+each.value+each_ch.value}
                      value={each_ch.value}>{each_ch.label}</MenuItem>)}
                  </Select>
                </FormControl>
                {each.value == 7 && this.state.q7 == 6 &&
                  <div>
                  <TextField onChange={(e) => this.handleChange(e)} variant="outlined"
                  type="text" value={this.state.q7_other} className="top_space1 w100pc"
                  InputProps={{ inputProps: { name: 'q7_other', maxLength: 49, placeholder: 'Please specify'  }}} /></div>
                }
                </div>
              </div>
            )}
              <div className="row" >
                <div className="small-12 columns sep_line1">&nbsp;</div>
                <div className="small-12 medium-6 columns">10. {(lang=='en' && 'What is your current weight?') || (lang=='es' && '10.	¿Cuál es su peso actual?')}</div>
                <div className="small-12 medium-6 columns">
                <div>
                  {this.state.q10_not_answer == 0 &&
                  <div className="small-12 columns bottom_space1">
                    <TextField onChange={(e) => this.handleChange2(e, 'q10')} variant="outlined"
                    type="text" value={this.state.q10} className="w50p"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    InputProps={{ inputProps: { maxLength: 3 }}} /> {(lang=='en' && 'pounds') || (lang=='es' && 'libras (kilos)')}
                  </div>}
                  <div className="small-12 columns">
                    <input type="checkbox" name="q10_not_answer" value='1'
                    onChange={(e) => this.handleChangeQ10(e)}/> &nbsp;
                    <span>{(lang=='en' && 'Prefer not to answer') || (lang=='es' && 'Prefiero no responder')}</span>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div className="small-12 columns med_part">
              <div className="row">
                <div className="small-12 columns sep_line1">&nbsp;</div>
                <div className="small-12 columns bottom_space1"><b>{(lang=='en' && 'Medications') || (lang=='es' && 'Medicaciones')}</b></div>
                <div className="small-12 medium-6 columns">
                  {(lang=='en' && 'Do you take any prescription medications?') || (lang=='es' && '¿Toma usted medicaciones prescritas? ')}
                </div>
                <div className="small-12 medium-6 columns">
                {choices1.map(each =>
                  <div className={(each.value <=2 && 'small-4' || each.value > 2 && 'small-8') + " columns float_left"}
                  key={'wa'+each.value}>
                    <input type="radio" name="med1a" value={each.value}
                    onChange={(e) => this.handleChange(e)}/>
                    <span>{each.label}</span>
                  </div>)}
                </div>
                {this.state.med1a == 1 &&
                <div>
                  <div className="small-12 columns sep_line1">&nbsp;</div>
                  <div className="small-12 columns bottom_space1">
                  Please list the medications that you take:
                  </div>
                  <div className="small-12 columns box_border6">
                    {this.state.med_list.map(each =>
                    <div className="row">
                      {each.id > 1 && <div className="small-12 columns sep_line2">&nbsp;</div>}
                      <div className="small-12 medium-4 columns">
                        <div className="fst1">Category</div>
                        <FormControl variant="outlined" className="dd_1">
                          <Select variant="outlined"
                            label="Category" defaultValue="" value={each.category}
                            onChange={(e) => this.handleChangeArray2(e, 'med_list', each.id-1, 'category')}>
                            { dem_med_category.map(each_m =>
                              <MenuItem key={'mcat'+each_m.value}
                              value={each_m.value}>{each_m.label}</MenuItem>)}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="small-12 medium-3 columns">
                        <div className="fst1">Medication name</div>
                        <TextField onChange={(e) => this.handleChangeArray2(e, 'med_list', each.id-1, 'medication')}
                        variant="outlined" type="text" value={each.medication}
                        className="w_50p" InputProps={{ inputProps: { maxLength: 49 }}} />
                      </div>
                      <div className="small-12 medium-3 columns float_left">
                        <div className="fst1">Dosage (mg/day)</div>
                        <TextField onChange={(e) => this.handleChangeArray2(e, 'med_list', each.id-1, 'dosage')}
                        variant="outlined" type="text" value={each.dosage}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        className="w_50p" InputProps={{ inputProps: { maxLength: 4 }}} />
                      </div>
                      <div className="small-12 medium-2 columns float_left cancelButton">
                        {each.id > 1 &&<CancelIcon onClick={ () => this.removeInputSet1('med_list', each.id, this.state.med_list) } />}
                      </div>
                    </div>
                    )}
                    <div className="small-12 columns sep_line2">&nbsp;</div>
                    <div className="small-12 medium-3 columns">
                      <button type="button" className="addInput1"
                        onClick={ () => this.appendInputMedication('med_list', this.state.med_list) }>
                          Add Medication
                      </button>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
        {!this.state.completed_status &&
        <div className="small-12 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Submit
          </Button>
        </div>}
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Demographic)
