import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'
import PatientHead from "../participant_head"
import { baseline_assm_steps, tp_type } from '../../../../variables/baseline_assm.js'
import { followup_assm_steps } from '../../../../variables/followup_assm.js'
import { exercise_confidence_questions, exercise_confidence_scale } from '../../../../variables/baseline_exercise_confidence.js'

class ExerciseConfidence extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        compTxt: '',
        timepoint: 0,
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
        created: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/register_participant/get_exercise_confidence',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        type_tp: this.props.match.params.type_tp,
        timepoint: this.props.match.params.timepoint,
      }
    })
    .then((result) => {
      console.log(result['data'])
      if(result['data'].uid > 0) {
        this.setState({
          type_tp: this.props.match.params.type_tp,
          timepoint: this.props.match.params.timepoint,
          q1: result['data'].q1,
          q2: result['data'].q2,
          q3: result['data'].q3,
          q4: result['data'].q4,
          created: result['data'].created,
        })
      }
    })
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
    <div className="participant1">
        <div className="medium-6 columns headerPage3_1">
          Exercise Confidence [{this.findLabelByValue(tp_type, this.props.match.params.type_tp)}]
        </div>
        <div className="medium-6 columns align-right">
        Completed: {this.state.created}
        </div>
        <div className="small-12 columns bottom_space2">
          <PatientHead />
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns">
              We would like to know how confident you are in doing certain activities. We want you to answer based on your current level of ability (or how you feel at the present time). For each of the following questions, please select the number that indicates your confidence that you can do the activities regularly.
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns">
            { exercise_confidence_questions.map(eachQ =>
              <div className="row" key={'q'+eachQ.value}>
                <div className="small-12 medium-11 columns bottom_space1">{eachQ.value + '. ' + eachQ.label}</div>
                <div className="show-for-medium medium-1 columns">&nbsp;</div>
                <div className="small-12 medium-1 columns">
                { this.state['q'+eachQ.value] }
                {/*this.findLabelByValue(exercise_confidence_scale, this.state['q'+eachQ.value])*/}
                </div>
                {eachQ.value < 4 && <div className="small-12 columns sep_line1">&nbsp;</div>}
              </div>)}
            </div>
          </div>
        </div>
        <div className="small-12 columns back_button1">
          <a href={'/admin/participant_chart/'+this.props.match.params.pid+'/2/1'}>Back</a>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ExerciseConfidence)
