import React from "react";
import { Line } from "./line";
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

export const SelectLine = (props) => {
  return (
    <Line label={props.label} noPadding={props.noPadding} noHorizontalPadding={props.noHorizontalPadding}>
      <div style={props.wide ? { width: "100%" } : undefined} className={props.noClass ? undefined : "small-12 medium-10 columns"}>
        <FormControl style={props.wide ? { width: "100%" } : undefined} variant="outlined" className="w_style1"
          disabled={props.disabled} >
          <Select
            value={props.value}
            variant="outlined"
            name={props.name}
            label=""
            onChange={props.onChange}
          >
            {props.menuItems && props.menuItems.map((each) => (
                <MenuItem key={"dc" + each.id} value={each.id}>
                  {each.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    </Line>
  );
};
