export const promis_si_questions = [
  {
    label: 'I feel left out',
    value: 1,
  }, {
    label: 'I feel that people barely know me',
    value: 2,
  }, {
    label: 'I feel isolated from others',
    value: 3,
  }, {
    label: 'I feel that people are around me but not with me',
    value: 4,
  },
]

export const promis_si_scale = [
  {
    label: 'Never',
    value: 1,
  }, {
    label: 'Rarely',
    value: 2,
  }, {
    label: 'Sometimes',
    value: 3,
  }, {
    label: 'Usually',
    value: 4,
  }, {
    label: 'Always',
    value: 5,
  },
]

export const promis_si_questions_es = [
  {
    label: 'Me siento excluido(a)',
    value: 1,
  }, {
    label: 'Siento que las personas apenas me conocen',
    value: 2,
  }, {
    label: 'Me siento aislado de otros',
    value: 3,
  }, {
    label: 'Siento que hay personas a mi alrededor, pero no están conmigo',
    value: 4,
  },
]

export const promis_si_scale_es = [
  {
    label: 'Nunca',
    value: 1,
  }, {
    label: 'Raramente',
    value: 2,
  }, {
    label: 'Algunas veces',
    value: 3,
  }, {
    label: 'Usualmente',
    value: 4,
  }, {
    label: 'Siempre',
    value: 5,
  },
]
