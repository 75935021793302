import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import { gen_m } from '../../variables/registration.js'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'

class NewParticipant extends Component {
  constructor() {
    super()
    this.quill = require('react-quill')
    this.state = {
        status: false,
        status_submit: true,
        message: '',
        participant_name: '',
        date_contact: new Date().toISOString().substring(0, 10),
        email: '',
        phone: '',
        dob_d: 0,
        dob_m: 0,
        dob_y: 0,
        address: '',
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  formatDate =  (date) => {
    var d = new Date(date)
    var month = '' + (d.getMonth() + 1)
    var day = '' + d.getDate()
    var year = d.getFullYear()

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }

  calculateAge = (d, m, y) => { // birthday is a date
    var dateString = y+'/'+m+'/'+d
    var today = new Date()
    var birthDate = new Date(dateString)
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    return age
  }

  validatePhoneNumber = (number) => {
    return validator.isMobilePhone(number)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(!validator.isEmail(this.state.email)) {
      this.setState({
        message: 'Please fill email in the right format.'
      })
      window.scrollTo(0, 0)
    } else {
      if(this.calculateAge(this.state.dob_d, this.state.dob_m, this.state.dob_y) < 55) {
        this.setState({
          message: 'You must be 55 or older to participate in this study.'
        })
        window.scrollTo(0, 0)
      } else if(this.state.participant_name.trim() !== '' && this.state.email.trim() !== ''
        && this.state.phone.trim() !== '' && this.state.dob_d != 0
        && this.state.dob_m != 0 && this.state.dob_y != 0
        && this.state.address.trim() !== '' ) {
          axios({
            method: 'post',
            url: connectNode + 'admin/register_participant/add_new_participant',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
              uid: this.props.authReducer.uid,
              user: this.state,
            }
          })
          .then((result) => {
            var message = ''
            if(result['data'] > 0) {
              this.props.onLoadLocation('patient_list', 'Adding new participant completed!')
              this.props.history.push('/admin/participant_list')
            } else if(result['data'] === -1) {
              message = 'This email already exists.'
            } else if(result['data'] === -2) {
              message = 'Adding new patient completed but system couldn\'t send email reminder!'
            } else {
              message = 'Something went wrong. Please try again.'
            }
            if(message !== '') {
              this.setState({
                message: message
              })
              window.scrollTo(0, 0)
            }
          })
      } else {
        var message = ''
        if(this.state.participant_name === '') {
          message = 'Please fill Participant Name.'
        } else if(this.state.dob_d == 0 || this.state.dob_m == 0 || this.state.dob_y == 0) {
          message = 'Please fill Participant Date of Birth.'
        } else if(this.state.phone === '') {
          message = 'Please fill Participant phone number.'
        } else if(this.state.email == 0) {
          message = 'Please fill Participant email address.'
        } else if(this.state.address == 0) {
          message = 'Please Participant address.'
        }
        this.setState({
          message: message,
        })
        window.scrollTo(0, 0)
      }
    }
  }

  render() {
    var gen_d = []
    for(var i=1; i<=31; i++) { gen_d.push(i) }
    var gen_y = []
    for(var i=1920; i<=2021; i++) { gen_y.push(i) }
    return (
    <div className="assm_page1 admin_page">
        <div className="headerPage1">
          New Participant Information
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { this.state.message !== '' &&
          <div className="row margin-bottom1">
            <div className="small-12 columns warning_msg6">
              <div className="float_left1"><WarningIcon /></div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>
          </div>}

          <div className="row">
            <div className="small-12 columns">
              <div className="small-12 medium-4 columns bold_txt1">Participant Name</div>
              <div className="small-12 medium-8 columns float_left">
                <TextField onChange={this.handleChange} variant="outlined"
                type="text" defaultValue={this.state.participant_name} className="w_50p"
                InputProps={{ inputProps: { name: "participant_name" }}} />
              </div>
            </div>
          </div>

          <div className="row top_space2">
            <div className="small-12 columns">
              <div className="small-12 medium-4 columns bold_txt1">Date</div>
              <div className="small-12 medium-8 columns float_left">{/*+(10*60*60*24*1000)*/}
                <TextField onChange={this.handleChange} variant="outlined"
                type="date" defaultValue={this.state.date_contact}
                InputProps={{ inputProps: { name: "date_contact", min: this.formatDate(new Date(Date.now()-(30*60*60*24*1000))), max: this.formatDate(new Date(Date.now())) }}} />
              </div>
            </div>
          </div>

          <div className="row top_space2">
            <div className="small-12 columns">
              <div className="small-12 medium-4 columns bold_txt1">Participant Date of Birth</div>
              <div className="small-12 medium-2 columns frm_full_div">
              <FormControl variant="outlined"
              error={this.state.invalid && this.state.dob_m == 0} >
                <Select value={this.state.dob_m} variant="outlined"
                  label="" defaultValue="" name="dob_m"
                  onChange={(e) => this.handleChange(e)}>
                  <MenuItem value='0' >Month</MenuItem>
                  {gen_m.map(each =>
                    <MenuItem key={"gen_m"+each.value} value={each.value}>{each.label}</MenuItem>
                  )}
                </Select>
              </FormControl>
              </div>
              <div className="small-12 medium-2 columns frm_full_div no_padding_left">
              <FormControl variant="outlined"
              error={this.state.invalid && this.state.dob_d == 0} >
                <Select value={this.state.dob_d} variant="outlined"
                  label="" defaultValue="" name="dob_d"
                  onChange={(e) => this.handleChange(e)}>
                  <MenuItem value='0' >Day</MenuItem>
                  {gen_d.map(each =>
                    <MenuItem key={"gen_d"+each} value={each}>{each}</MenuItem>
                  )}
                </Select>
              </FormControl>
              </div>
              <div className="small-12 medium-2 columns frm_full_div no_padding_left float_left">
              <FormControl variant="outlined"
              error={this.state.invalid && this.state.dob_y == 0} >
                <Select value={this.state.dob_y} variant="outlined"
                  label="" defaultValue="" name="dob_y"
                  onChange={(e) => this.handleChange(e)}>
                  <MenuItem value='0' >Year</MenuItem>
                  {gen_y.map(each =>
                    <MenuItem key={"gen_y"+each} value={each}>{each}</MenuItem>
                  )}
                </Select>
              </FormControl>
              </div>
            </div>
          </div>

          <div className="row top_space2">
            <div className="small-12 columns">
              <div className="small-12 medium-4 columns bold_txt1">Participant email address</div>
              <div className="small-12 medium-8 columns float_left">
                <TextField onChange={this.handleChange} variant="outlined"
                type="email" defaultValue={this.state.email} className="w_50p"
                InputProps={{ inputProps: { name: "email" }}} />
              </div>
            </div>
          </div>

          <div className="row top_space2">
            <div className="small-12 columns">
              <div className="small-12 medium-4 columns bold_txt1">Participant phone number</div>
              <div className="small-12 medium-8 columns float_left">
                <TextField onChange={this.handleChange} variant="outlined"
                type="text" defaultValue={this.state.phone} className="w_50p"
                InputProps={{ inputProps: { name: "phone" }}} />
              </div>
            </div>
          </div>

          <div className="row top_space2">
            <div className="small-12 columns">
              <div className="small-12 medium-4 columns bold_txt1">Participant address</div>
              <div className="small-12 medium-8 columns float_left">
                <TextField onChange={this.handleChange} variant="outlined"
                type="text" defaultValue={this.state.address} className="w_90p"
                InputProps={{ inputProps: { name: "address" }}} />
              </div>
            </div>
          </div>

        </div>
        <div className="small-6 columns back_button1">
          <a href="/admin/participant_list">Back</a>
        </div>
        <div className="small-6 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
              Submit
          </Button>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(NewParticipant)
