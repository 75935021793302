import React, { Component } from 'react'
//import { connect } from 'react-redux'
import { connect } from 'react-redux'
import Banner1 from '../../../images/banner2.png'
import Symbol1 from '../../../images/quote_symbol.png'

import axios from 'axios'
import { connectNode } from '../../../utils/global'

class BannerDashboard extends Component {
  constructor() {
    super()
    this.state = {
      inspirational_quote: '',
      inspirational_quote_from: '',
    }
  }

  componentDidMount() {
    this.getQuote();
  }

  getQuote = () => {
    axios({
      method: 'post',
      url: connectNode + 'participants/general/get_quote',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      this.setState({
        inspirational_quote: result['data'].inspirational_quote,
        inspirational_quote_from: result['data'].inspirational_quote_from,
      })
    })
  }

  render() {
    return (
      <div className="row">
          <div className="small-12 columns bg_banner db_nopadding">
            <img src={Banner1} alt="fas_banner" />
            <div className="banner_text">
              <img className="banner_quote" src={Symbol1} alt="quote" />
              <span className="quote_txt">
                {this.state.inspirational_quote}
              </span>

            </div>
            <div className="quote_txt_by">
              - {this.state.inspirational_quote_from}
            </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(BannerDashboard)
