import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'

import '../../../../styles/assets/css/pages/settings.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import WarningIcon from '@material-ui/icons/Warning'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Cookies from 'js-cookie'

class Settings extends Component {
  constructor() {
      super(  );
      this.state = {
        status_msg: 0,
        message: '',
        name: '',
        email: '',
        username: '',
        new_password: '',
        re_new_password: '',
        timestamp_diff: '',
      }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'user/get_setting_account',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid,
        role: this.props.authReducer.role,
      }
    })
    .then((result) => {
      var name = ''
      if(result['data'].p_name != null) {
        name = result['data'].p_name
      } else if(result['data'].c_name != null) {
        name = result['data'].c_name
      } else if(result['data'].a_name != null) {
        name = result['data'].a_name
      }
      this.setState({
        name: name,
        email: result['data'].email,
        username: result['data'].username,
        timestamp_diff: result['data'].timestamp_diff,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
    })
  }

  validatePasswordStrength(password) {
    //console.log(password)
    var strength = 0
    if(password.length < 8 || !password.match(/[a-z]+/) ||
      !password.match(/[A-Z]+/) || !password.match(/[0-9]+/)) {
      strength = 2
    }
    //console.log(strength)
    return strength
  }

  handleSubmit = (e) => {
    e.preventDefault()
    //console.log((this.state.new_password == this.state.re_new_password))
    if(this.state.new_password !== '' && (this.state.new_password == this.state.re_new_password)) {
      if(this.validatePasswordStrength(this.state.new_password) === 2) {
        this.setState({
          status_msg: 1,
          message: 'Please enter the strength password.',
        })
      } else {
        axios({
          method: 'post',
          url: connectNode + 'user/update_new_password',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.match.params.pid,
            user: this.state,
          }
        })
        .then((result) => {
            if(result['data'] == this.props.match.params.pid) {
              this.setState({
                status_msg: 2,
                message: 'Changed password successfully',
                new_password: '',
                re_new_password: '',
              })
            } else {
              if(result['data'] == -4) {
                this.setState({
                  status_msg: 1,
                  message: 'Cannot reuse four (4) previous passwords',
                })
              } else {
                this.setState({
                  status_msg: 1,
                  message: 'Something went wrong. Plase try again.',
                })
              }

            }
          }
        )
      }
    } else {
      this.setState({
        status_msg: 1,
        message: 'Please fill the same new password',
      })
    }
  }

  render() {
    const lang = Cookies.get('lang_')
    return (
      <div className="settings">
        <div className="row">
          <div className="small-10 columns headerPage1">
            {(lang=='en' && 'Settings') || lang=='es' && 'Ajustes'}
          </div>
        </div>
          <div className="small-12 columns box_setting1">
            <div className="small-12 medium-2 columns ">
              <AccountCircleIcon className="profile_icon" />
            </div>
            <div className="small-12 medium-10 columns profile_data">
            {this.state.message !== "" && this.state.status_msg == 1 &&
            <div className="row warning_msg6">
              <div className="small-12 columns ">

                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>}
            {this.state.message !== "" && this.state.status_msg == 2 &&
            <div className="row warning_msg8">
              <div className="small-12 columns ">
                <div className="float_left1"><CheckCircleOutlineIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>}
            <div className="row ">
              <div className="small-12 columns title1">{(lang=='en' && 'Name') || lang=='es' && 'Nombre'}</div>
              <div className="small-12 columns">{this.state.name}</div>
            </div>
            <div className="row">
              <div className="small-12 columns title1">Email</div>
              <div className="small-12 columns">{this.state.email}</div>
            </div>
            <div className="row ">
              <div className="small-12 columns title1">Username</div>
              <div className="small-12 columns">{this.state.username}</div>
            </div>
            <div className="row">
              <div className="small-12 columns title1">New password</div>
              <div className="small-12 medium-5 columns float_left">
                <TextField onChange={this.handleChange} variant="outlined"
                type="password" value={this.state.new_password}
                InputProps={{ inputProps: { name: "new_password" }}} />
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns pw_policy">
                  <div className="bold">{(lang=='en' && 'Password strength') || lang=='es' && 'Fuerza de la contraseña'}:</div>
                  <div><ul>
                  <li id="lower_case">One lowercase character</li>
                  <li id="upper_case">One uppercase character</li>
                  <li id="one_number">One number</li>
                  <li id="eight_char">8 characters minimum</li>
                  <li>Cannot reuse four (4) previous passwords</li>
                  <li>Required to change password every 180 days
                  ({this.state.timestamp_diff} days left)</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns title1">Retype new password</div>
              <div className="small-12 medium-5 columns float_left">
                <TextField onChange={this.handleChange} variant="outlined"
                type="password" value={this.state.re_new_password}
                InputProps={{ inputProps: { name: "re_new_password" }}} />
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns title1">
                <Button variant="contained" color="primary" disableElevation
                   onClick={this.handleSubmit} type="submit">
                    Change Password
                </Button>
              </div>
            </div>
          </div>
      </div>
    </div>
)}}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Settings)
