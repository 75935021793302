import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
//import HelmetProvider from 'react-navi-helmet-async'
//import { Redirect } from 'react-router'
import Login from "../components/auth/login"
import Home from "../components/public/home"

import PUBLIC_ROUTES from "./publicRoutes"
import AUTH_ROUTES from './authRoutes'
import MEMBER_ROUTES from './memberRoutes'
import USER_ROUTES from "./userRoutes"
import ADMIN_ROUTES from './adminRoutes'

class RenderRoutes extends Component {
  constructor() {
      super();
      this.menu = [...PUBLIC_ROUTES, ...AUTH_ROUTES, ...USER_ROUTES]
  }

  UNSAFE_componentWillMount() {
    //console.log('Role: '+this.props.role)
    if(this.props.role === 1) {
      // Super admin //
      this.menu = [...PUBLIC_ROUTES, ...AUTH_ROUTES, ...USER_ROUTES, ...ADMIN_ROUTES]
    }
    if(this.props.role === 2) {
      // Admin //
      this.menu = [...PUBLIC_ROUTES, ...AUTH_ROUTES, ...USER_ROUTES, ...ADMIN_ROUTES]
    }
    if(this.props.role === 3) {
      //clinician
      this.menu = [...PUBLIC_ROUTES, ...AUTH_ROUTES, ...USER_ROUTES, ...ADMIN_ROUTES]
    }
    if(this.props.role === 5) {
      // Patient //
      if(this.props.p_st == 6) {
        this.menu = [...PUBLIC_ROUTES, ...AUTH_ROUTES, ...USER_ROUTES, ...MEMBER_ROUTES]
      } else {
        this.menu = [...PUBLIC_ROUTES, ...AUTH_ROUTES, ...USER_ROUTES, ...MEMBER_ROUTES]
      }
    }
  }

  render() {
    return (
      <Switch>
        {this.menu.map((route, i) => {
          // <RouteWithSubRoutes key={route.key} route={route} />
          if(route.requireAuth !== true || (route.requireAuth === true && this.props.uid > 0)) {
            return (
              <Route
                key={route.key}
                path={route.path}
                exact={route.exact}
                //component={route.component}
                render={props =>
                  <div>
                    <Helmet>
                      <title>{route.title}</title>
                    </Helmet>
                    <route.component {...props} routes={route.routes}  />
                  </div>}
              />
            )
          } else {
              //return <Login key={route.key} />
              return <Login />
          }
        })}
        <Route component={() => <h1>Page Not Found!</h1>} />
      </Switch>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('renderRoute uid: '+state.authReducer.uid)
  return {
    uid: state.authReducer.uid,
    role: state.authReducer.role,
    sub_role: state.authReducer.sub_role,
    p_st: state.authReducer.p_st,
  }
}

export default connect(mapStateToProps)(RenderRoutes) // , mapDispatchToProps
