import React, { useMemo } from "react";

export const Line = (props) => {
  const classes = useMemo(() => {
    return props.wide ? "small-12 medium-3 columns title1" : "small-12 medium-2 columns title1";
  }, [props.wide]);
  const paddingStyle = useMemo(() => {
    if (props.wide)
      return { display: "flex", padding: "0.25rem" };
    if (props.noPadding)
      return { display: "flex", width: "100%" };
    if (props.noHorizontalPadding)
      return { display: "flex", width: "100%", paddingTop: "0.5rem", paddingBottom: "0.5rem" };
    return { display: "flex", padding: "0.5rem" };
  }, [props.noHorizontalPadding, props.noPadding, props.wide]);
  return (
    <div style={paddingStyle}>
      {props.label !== undefined && <div className={classes}>{props.label}</div>}
      {props.children}
    </div>
  );
};
