import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'
import PatientHead from "../participant_head"
import TextField from '@material-ui/core/TextField'
import { baseline_assm_steps, yes_no, tp_type } from '../../../../variables/baseline_assm.js'
import { fall_history_questions, fall_history_questions_due_to } from '../../../../variables/baseline_fall_history.js'
import ReactHtmlParser from 'react-html-parser'

class FallHistory extends Component {
  constructor() {
    super()
    this.state = {
        completed_status: true,
        q1: 0,
        q2: 0,
        q3: 0,
        q3_problems: '',
        q3a: 0,
        q3b: 0,
        q3c: 0,
        q3d: 0,
        q3e: 0,
        q4: 0,
        q5: 0,
        q5_problems: '',
        q5a: 0,
        q5b: 0,
        q5c: 0,
        q5d: 0,
        q5e: 0,
        created: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/register_participant/get_fall_history',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        type_tp: this.props.match.params.type_tp,
        timepoint: this.props.match.params.timepoint,
      }
    })
    .then((result) => {
      console.log(result['data'])
      if(result['data'].uid > 0) {
        this.setState({
          timepoint: this.props.match.params.timepoint,
          q1: result['data'].q1,
          q2: result['data'].q2,
          q3: result['data'].q3,
          q3_problems: result['data'].q3_problems,
          q3a: result['data'].q3a,
          q3b: result['data'].q3b,
          q3c: result['data'].q3c,
          q3d: result['data'].q3d,
          q3e: result['data'].q3e,
          q4: result['data'].q4,
          q5: result['data'].q5,
          q5_problems: result['data'].q5_problems,
          q5a: result['data'].q5a,
          q5b: result['data'].q5b,
          q5c: result['data'].q5c,
          q5d: result['data'].q5d,
          q5e: result['data'].q5e,
          created: result['data'].created,
        })
      }
    })
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
    <div className="participant1">
        <div className="medium-6 columns headerPage3_1">
          Falls-history [{this.findLabelByValue(tp_type, this.props.match.params.type_tp)}]
        </div>
        <div className="medium-6 columns align-right">
        Completed: {this.state.created}
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns">
            {fall_history_questions[0].value}. {ReactHtmlParser(fall_history_questions[0].label)}
            </div>
            <div className="small-12 columns top_space1">

            {yes_no.map(each =>
              <div className="small-6 medium-2 columns float_left" key={'q1'+each.value}>
                <input type="radio" name='q1' value={each.value}
                disabled={this.state.completed_status && 'disabled'}
                checked={this.state.q1 == each.value && 'checked'} />
                <span>{each.label}</span>
              </div>
            )}
            </div>
            {this.state.q1 == 1 &&
            <div>
              <div className="small-12 medium-2 columns top_space1">
              {fall_history_questions[1].value}. {ReactHtmlParser(fall_history_questions[1].label)}
              </div>
              <div className="small-12 medium-10 columns top_space1">
                <TextField variant="outlined"
                type="number" value={this.state.q2} className="tf_num2"
                InputProps={{ name:'q2', maxLength: 3, inputProps: { min: 0 } }} />
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="small-12 columns">
              {fall_history_questions[2].value}. {ReactHtmlParser(fall_history_questions[2].label)}
              </div>
              <div className="small-12 columns top_space1">
              {yes_no.map(each =>
                <div className="small-6 medium-2 columns float_left" key={'q3'+each.value}>
                  <input type="radio" name='q3' value={each.value}
                  disabled={this.state.completed_status && 'disabled'}
                  checked={this.state.q3 == each.value && 'checked'} />
                  <span>{each.label}</span>
                </div>
              )}
              </div>
              {this.state.q1 == 1 && this.state.q3 == 1 &&
              <div className="small-12 columns">
                <div className="small-12 columns top_space1 no_top_space_small">
                  <div className="bottom_space2 width_auto">What problems?</div>
                  <TextField variant="outlined"
                  type="text" value={this.state.q3_problems} className="tf_txt1"
                  InputProps={{ name:'q3_problems', inputProps: { maxLength: 199 }}} />
                </div>
                <div className="small-12 columns top_space1 box_border2">
                  <div className="small-12 medium-8 columns bold">Was the fall due to a…..</div>
                  <div className="small-12 medium-2 columns bold show-for-medium">Yes</div>
                  <div className="small-12 medium-2 columns bold show-for-medium">No</div>
                  {fall_history_questions_due_to.map(eachQ =>
                  <div>
                    <div className="small-12 columns sep_line5">&nbsp;</div>
                    <div className="small-12 medium-8 columns">{eachQ.sub}. {eachQ.label}</div>
                    {yes_no.map(each =>
                    <div className="small-6 medium-2 columns">
                      <input type="radio" name={'q3'+eachQ.sub} value={each.value}
                      disabled={this.state.completed_status && 'disabled'}
                      checked={this.state['q3'+eachQ.sub] == each.value && 'checked'} />
                      <span className="show-for-small-only">{each.label}</span>
                    </div>
                    )}
                  </div>)}
                </div>
              </div>}
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="small-12 columns">
                {fall_history_questions[3].value}. {ReactHtmlParser(fall_history_questions[3].label)}
                <TextField variant="outlined"
                type="number" value={this.state.q4} className="tf_num2"
                InputProps={{ name:'q4', maxLength: 3, inputProps: { min: 0 } }}  />
              </div>
              <div className="small-12 columns sep_line1">&nbsp;</div>
              <div className="small-12 columns">
              {fall_history_questions[4].value}. {ReactHtmlParser(fall_history_questions[4].label)}
              </div>
              <div className="small-12 columns top_space1">
              {yes_no.map(each =>
                <div className="small-6 medium-2 columns float_left" key={'q5'+each.value}>
                  <input type="radio" name='q5' value={each.value}
                  disabled={this.state.completed_status && 'disabled'}
                  checked={this.state.q5 == each.value && 'checked'} />
                  <span>{each.label}</span>
                </div>
              )}
              </div>
              {this.state.q1 == 1 && this.state.q5 == 1 &&
              <div className="small-12 columns">
                <div className="small-12 columns top_space1 no_top_space_small">
                  <div className="bottom_space2 width_auto">What problems?</div>
                  <TextField variant="outlined"
                  type="text" value={this.state.q5_problems} className="tf_txt1"
                  InputProps={{ name:'q5_problems', inputProps: { maxLength: 199 }}} />
                </div>
                <div className="small-12 columns top_space1 box_border2">
                  <div className="small-12 medium-8 columns bold">Was the fall due to a…..</div>
                  <div className="small-12 medium-2 columns bold show-for-medium">Yes</div>
                  <div className="small-12 medium-2 columns bold show-for-medium">No</div>
                  {fall_history_questions_due_to.map(eachQ =>
                  <div>
                    <div className="small-12 columns sep_line5">&nbsp;</div>
                    <div className="small-12 medium-8 columns">{eachQ.sub}. {eachQ.label}</div>
                    {yes_no.map(each =>
                    <div className="small-6 medium-2 columns">
                      <input type="radio" name={'q5'+eachQ.sub} value={each.value}
                      disabled={this.state.completed_status && 'disabled'}
                      checked={this.state['q5'+eachQ.sub] == each.value && 'checked'} />
                      <span className="show-for-small-only">{each.label}</span>
                    </div>
                    )}
                  </div>)}
                </div>
              </div>}
            </div>}
          </div>
        </div>
        <div className="small-12 columns back_button1">
          <a href={'/admin/participant_chart/'+this.props.match.params.pid+'/2/1'}>Back</a>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(FallHistory)
