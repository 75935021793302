import React from "react";
import Dashboard from "../components/members/dashboard/dashboard"
import AssmEval from "../components/members/assessments/assm_eval"
import ExerciseVideos from "../components/members/videos/exercise_videos"
import ParticipantsManual from "../components/members/manuals/participants_manual"
import ParticipantContract from "../components/members/participant_contract/participant_contract"
import FrmCommitment from "../components/members/participant_contract/frm_commitment"
import FrmIndividualPlan from "../components/members/participant_contract/frm_individual_plan"
import FrmRevisiting from "../components/members/participant_contract/frm_revisiting"
import Assistants from "../components/members/help/assistants"
import ExerciseLog from "../components/members/dashboard/exercise_log"
import Demographic from "../components/members/registration/demographic"
import GeriAims from "../components/members/registration/geri_aims"
import Womac from "../components/members/registration/womac"
import Utilization from "../components/members/registration/utilization"
import PromisPF from "../components/members/registration/promis_pf"
import FallHistory from "../components/members/registration/fall_history"
import ExerciseConfidence from "../components/members/registration/exercise_confidence"
import Who5 from "../components/members/registration/who5"
import Phq9 from "../components/members/registration/phq9"
import Gad7 from "../components/members/registration/gad7"
import PromisSi from "../components/members/registration/promis_si"
import Csq8 from "../components/members/registration/csq8"
import Csq8Platform from "../components/members/registration/csq8_platform"
import Csq8Program from "../components/members/registration/csq8_program"
import Motivation from "../components/members/registration/motivation"
import Completed from "../components/members/registration/completed"
import ParticipantContractPDF from "../components/members/participant_contract/participant_contract_pdf"
import RecordClasses from "../components/members/dashboard/record_classes"
import VideoPlayer from "../components/members/dashboard/video_player"
import TopicList from "../components/members/forum/topic_list"
import Topic from "../components/members/forum/topic"
import NewForum from "../components/members/forum/new_forum"
import FocusBlog from "../components/members/focus_blog/focus_blog"
import GetInTouch from "../components/members/getintouch/getintouch"
import MessageList from "../components/members/getintouch/message_list"
import NewMessage from "../components/members/getintouch/new_message"
import ViewSharedGraph from "../components/members/dashboard/view_shared_graph"

const MEMBER_ROUTES = [
  /* Baseline*/
  {   path: "/member/registration/demographic",
      key: "dashboard",
      title: "Dashboard",
      exact: true,
      requireAuth: true,
      component: () => <Demographic />,
      showtitle: 'true',
  },
  {   path: "/member/registration/geri_aims",
      key: "geri_aims",
      title: "GERI AIMS",
      exact: true,
      requireAuth: true,
      component: () => <GeriAims />,
      showtitle: 'true',
  },
  {   path: "/member/registration/womac",
      key: "womac",
      title: "WOMAC",
      exact: true,
      requireAuth: true,
      component: () => <Womac />,
      showtitle: 'true',
  },
  {   path: "/member/registration/utilization",
      key: "utilization",
      title: "Health Care Utilization",
      exact: true,
      requireAuth: true,
      component: () => <Utilization />,
      showtitle: 'true',
  },
  {   path: "/member/registration/promis_pf",
      key: "promis_pf",
      title: "PROMIS Physical Function",
      exact: true,
      requireAuth: true,
      component: () => <PromisPF />,
      showtitle: 'true',
  },
  {   path: "/member/registration/fall_history",
      key: "fall_history",
      title: "Falls-history",
      exact: true,
      requireAuth: true,
      component: () => <FallHistory />,
      showtitle: 'true',
  },
  {   path: "/member/registration/exercise_confidence",
      key: "exercise_confidence",
      title: "Exercise Confidence",
      exact: true,
      requireAuth: true,
      component: () => <ExerciseConfidence />,
      showtitle: 'true',
  },
  {   path: "/member/registration/who5",
      key: "who5",
      title: "WHO5",
      exact: true,
      requireAuth: true,
      component: () => <Who5 />,
      showtitle: 'true',
  },
  {   path: "/member/registration/phq9",
      key: "phq9",
      title: "PHQ-9",
      exact: true,
      requireAuth: true,
      component: () => <Phq9 />,
      showtitle: 'true',
  },
  {   path: "/member/registration/gad7",
      key: "gad7",
      title: "GAD-7",
      exact: true,
      requireAuth: true,
      component: () => <Gad7 />,
      showtitle: 'true',
  },
  {   path: "/member/registration/promis_si",
      key: "promis_si",
      title: "PROMIS Social Isolation",
      exact: true,
      requireAuth: true,
      component: () => <PromisSi />,
      showtitle: 'true',
  },
  {   path: "/member/registration/motivation",
      key: "motivation",
      title: "Motivation",
      exact: true,
      requireAuth: true,
      component: () => <Motivation />,
      showtitle: 'true',
  },
  {   path: "/member/registration/completed",
      key: "completed",
      title: "Complete",
      exact: true,
      requireAuth: true,
      component: () => <Completed />,
      showtitle: 'true',
  },

  /* Dashboard */
  {   path: "/member/dashboard",
      key: "dashboard",
      title: "Dashboard",
      exact: true,
      requireAuth: true,
      component: () => <Dashboard />,
      showtitle: 'true',
  },
  {   path: "/member/assessment_evaluation",
      key: "assessment_evaluation",
      title: "Assessment & Evaluation",
      exact: true,
      requireAuth: true,
      component: () => <AssmEval />,
      showtitle: 'true',
  },
  {   path: "/member/exercise_videos",
      key: "exercise_videos",
      title: "Exercise Videos",
      exact: true,
      requireAuth: true,
      component: () => <ExerciseVideos />,
      showtitle: 'true',
  },
  {   path: "/member/participant_manual",
      key: "patients_manual",
      title: "Patient's Manual",
      exact: true,
      requireAuth: true,
      component: () => <ParticipantsManual />,
      showtitle: 'true',
  },
  {   path: "/member/participant_contract",
      key: "patient_contract",
      title: "Patient's Manual",
      exact: true,
      requireAuth: true,
      component: () => <ParticipantContract />,
      showtitle: 'true',
  },
  {   path: "/member/frm_commitment",
      key: "frm_commitment",
      title: "Commitment to class and practice sessions",
      exact: true,
      requireAuth: true,
      component: () => <FrmCommitment />,
      showtitle: 'true',
  },
  {   path: "/member/frm_individual_plan",
      key: "frm_individual_plan",
      title: "Individual plan for maintaining an active lifestyle",
      exact: true,
      requireAuth: true,
      component: () => <FrmIndividualPlan />,
      showtitle: 'true',
  },
  {   path: "/member/frm_visiting",
      key: "frm_visiting",
      title: "Revisiting your goals from the beginning of the program",
      exact: true,
      requireAuth: true,
      component: () => <FrmRevisiting />,
      showtitle: 'true',
  },
  {   path: "/member/participant_contract_pdf/:type_id",
      key: "participant_contract_pdf",
      title: "Participant Contract",
      exact: true,
      requireAuth: true,
      component: () => <ParticipantContractPDF />,
      showtitle: 'true',
  },
  {   path: "/member/help",
      key: "Help",
      title: "Assistants",
      exact: true,
      requireAuth: true,
      component: () => <Assistants />,
      showtitle: 'true',
  },
  {   path: "/member/recorded_classes",
      key: "recorded_classes",
      title: "Recorded Classes",
      exact: true,
      requireAuth: true,
      component: () => <RecordClasses />,
      showtitle: 'true',
  },
  {   path: "/member/video_player/:gid/:vid",
      key: "recorded_classes",
      title: "Recorded Classes",
      exact: true,
      requireAuth: true,
      component: () => <VideoPlayer />,
      showtitle: 'true',
  },
  {   path: "/member/help/:step1",
      key: "Help",
      title: "Assistants",
      exact: true,
      requireAuth: true,
      component: () => <Assistants />,
      showtitle: 'true',
  },
  {   path: "/member/help/:step1/:step2",
      key: "Help",
      title: "Assistants",
      exact: true,
      requireAuth: true,
      component: () => <Assistants />,
      showtitle: 'true',
  },
  {   path: "/member/help/:step1/:step2/:step3",
      key: "Help",
      title: "Assistants",
      exact: true,
      requireAuth: true,
      component: () => <Assistants />,
      showtitle: 'true',
  },
  {   path: "/member/exercise_log/:month/:day/:year",
      key: "exercise_log",
      title: "Exercise Log",
      exact: true,
      requireAuth: true,
      component: () => <ExerciseLog />,
      showtitle: 'true',
  },
  {   path: "/member/followup/promis_pf/:tp_type/:timepoint",
      key: "promis_pf",
      title: "PROMIS Physical Function",
      exact: true,
      requireAuth: true,
      component: () => <PromisPF />,
      showtitle: 'true',
  },
  {   path: "/member/followup/womac/:tp_type/:timepoint",
      key: "womac",
      title: "WOMAC",
      exact: true,
      requireAuth: true,
      component: () => <Womac />,
      showtitle: 'true',
  },
  {   path: "/member/followup/exercise_confidence/:tp_type/:timepoint",
      key: "exercise_confidence",
      title: "Exercise Confidence",
      exact: true,
      requireAuth: true,
      component: () => <ExerciseConfidence />,
      showtitle: 'true',
  },
  {   path: "/member/followup/who5/:tp_type/:timepoint",
      key: "who5",
      title: "WHO-5",
      exact: true,
      requireAuth: true,
      component: () => <Who5 />,
      showtitle: 'true',
  },
  {   path: "/member/followup/phq9/:tp_type/:timepoint",
      key: "phq9",
      title: "PHQ-9",
      exact: true,
      requireAuth: true,
      component: () => <Phq9 />,
      showtitle: 'true',
  },
  {   path: "/member/followup/gad7/:tp_type/:timepoint",
      key: "gad7",
      title: "GAD-7",
      exact: true,
      requireAuth: true,
      component: () => <Gad7 />,
      showtitle: 'true',
  },
  {   path: "/member/followup/promis_si/:tp_type/:timepoint",
      key: "promis_si",
      title: "PROMIS Social Isolation",
      exact: true,
      requireAuth: true,
      component: () => <PromisSi />,
      showtitle: 'true',
  },
  {   path: "/member/followup/csq8/:tp_type/:timepoint",
      key: "csq8",
      title: "CSQ-8",
      exact: true,
      requireAuth: true,
      component: () => <Csq8 />,
      showtitle: 'true',
  },
  {   path: "/member/followup/csq8_program/:tp_type/:timepoint",
      key: "csq8_program",
      title: "CSQ-8 Program",
      exact: true,
      requireAuth: true,
      component: () => <Csq8Program />,
      showtitle: 'true',
  },
  {   path: "/member/followup/csq8_platform/:tp_type/:timepoint",
      key: "csq8_platform",
      title: "CSQ-8 Platform",
      exact: true,
      requireAuth: true,
      component: () => <Csq8Platform />,
      showtitle: 'true',
  },
  {   path: "/member/followup/completed/:tp_type/:timepoint",
      key: "completed",
      title: "Complete",
      exact: true,
      requireAuth: true,
      component: () => <Completed />,
      showtitle: 'true',
  },
  {   path: "/member/forum/topic_list",
      key: "topic_list",
      title: "Forum",
      exact: true,
      requireAuth: true,
      component: () => <TopicList />,
      showtitle: 'true',
  },
  {   path: "/member/forum/topic/:tid",
      key: "topic",
      title: "Forum",
      exact: true,
      requireAuth: true,
      component: () => <Topic />,
      showtitle: 'true',
  },
  {   path: "/member/forum/new_forum",
      key: "new_forum",
      title: "Forum",
      exact: true,
      requireAuth: true,
      component: () => <NewForum />,
      showtitle: 'true',
  },
  {   path: "/member/focus_blog",
      key: "focus_blog",
      title: "Focus Blog",
      exact: true,
      requireAuth: true,
      component: () => <FocusBlog />,
      showtitle: 'true',
  },
  {   path: "/member/getintouch/getintouch",
      key: "getintouch",
      title: "Get in Touch",
      exact: true,
      requireAuth: true,
      component: () => <GetInTouch />,
      showtitle: 'true',
  },
  {   path: "/member/getintouch/message_list",
      key: "message_list",
      title: "Messages",
      exact: true,
      requireAuth: true,
      component: () => <MessageList />,
      showtitle: 'true',
  },
  {   path: "/member/getintouch/message_list/:cid",
      key: "conversation",
      title: "Messages",
      exact: true,
      requireAuth: true,
      component: () => <MessageList />,
      showtitle: 'true',
  },
  {   path: "/member/getintouch/new_message",
      key: "new_message",
      title: "New Message",
      exact: true,
      requireAuth: true,
      component: () => <NewMessage />,
      showtitle: 'true',
  },
  {   path: "/member/shared_graph",
      key: "shared_graph",
      title: "View Shared Graph",
      exact: true,
      requireAuth: true,
      component: () => <ViewSharedGraph />,
      showtitle: 'true',
  },
];

export default MEMBER_ROUTES;
